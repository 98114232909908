/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const startOneOnOne = /* GraphQL */ `mutation StartOneOnOne($input: StartOneOnOneInput!) {
  startOneOnOne(input: $input) {
    setting {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          calendarApp
          eventID
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    oneOnOne {
      id
      userID1
      userID2
      managerID
      oneOnOneSettingID
      frequency
      startDate
      startTime
      status
      agendaTemplateID
      recordingAgendaID
      recordingTarget
      aiSummary
      calendarApp
      eventID
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneSetting {
        id
        userID1
        userID2
        frequency
        nextOneOnOneDate
        nextOneOnOneTime
        agendaTemplateID
        status
        organizationGroup
        createdAt
        updatedAt
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneList {
          items {
            id
            userID1
            userID2
            managerID
            oneOnOneSettingID
            frequency
            startDate
            startTime
            status
            agendaTemplateID
            recordingAgendaID
            recordingTarget
            aiSummary
            calendarApp
            eventID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartOneOnOneMutationVariables,
  APITypes.StartOneOnOneMutation
>;
export const addTemporaryOneOnOne = /* GraphQL */ `mutation AddTemporaryOneOnOne($input: AddTemporaryOneOnOneInput!) {
  addTemporaryOneOnOne(input: $input) {
    oneOnOne {
      id
      userID1
      userID2
      managerID
      oneOnOneSettingID
      frequency
      startDate
      startTime
      status
      agendaTemplateID
      recordingAgendaID
      recordingTarget
      aiSummary
      calendarApp
      eventID
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneSetting {
        id
        userID1
        userID2
        frequency
        nextOneOnOneDate
        nextOneOnOneTime
        agendaTemplateID
        status
        organizationGroup
        createdAt
        updatedAt
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneList {
          items {
            id
            userID1
            userID2
            managerID
            oneOnOneSettingID
            frequency
            startDate
            startTime
            status
            agendaTemplateID
            recordingAgendaID
            recordingTarget
            aiSummary
            calendarApp
            eventID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddTemporaryOneOnOneMutationVariables,
  APITypes.AddTemporaryOneOnOneMutation
>;
export const saveOneOnOneSetting = /* GraphQL */ `mutation SaveOneOnOneSetting($input: SaveOneOnOneSettingInput!) {
  saveOneOnOneSetting(input: $input) {
    oneOnOneSetting {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          calendarApp
          eventID
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SaveOneOnOneSettingMutationVariables,
  APITypes.SaveOneOnOneSettingMutation
>;
export const stopOneOnOne = /* GraphQL */ `mutation StopOneOnOne($input: StopOneOnOneInput!) {
  stopOneOnOne(input: $input) {
    setting {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          calendarApp
          eventID
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StopOneOnOneMutationVariables,
  APITypes.StopOneOnOneMutation
>;
export const restartOneOnOne = /* GraphQL */ `mutation RestartOneOnOne($input: RestartOneOnOneInput!) {
  restartOneOnOne(input: $input) {
    setting {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          calendarApp
          eventID
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    oneOnOne {
      id
      userID1
      userID2
      managerID
      oneOnOneSettingID
      frequency
      startDate
      startTime
      status
      agendaTemplateID
      recordingAgendaID
      recordingTarget
      aiSummary
      calendarApp
      eventID
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneSetting {
        id
        userID1
        userID2
        frequency
        nextOneOnOneDate
        nextOneOnOneTime
        agendaTemplateID
        status
        organizationGroup
        createdAt
        updatedAt
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneList {
          items {
            id
            userID1
            userID2
            managerID
            oneOnOneSettingID
            frequency
            startDate
            startTime
            status
            agendaTemplateID
            recordingAgendaID
            recordingTarget
            aiSummary
            calendarApp
            eventID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RestartOneOnOneMutationVariables,
  APITypes.RestartOneOnOneMutation
>;
export const googleCalendarConnect = /* GraphQL */ `mutation GoogleCalendarConnect($code: String!) {
  googleCalendarConnect(code: $code) {
    events {
      title
      startDate
      endDate
      cycle
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GoogleCalendarConnectMutationVariables,
  APITypes.GoogleCalendarConnectMutation
>;
export const createCognitoGroup = /* GraphQL */ `mutation CreateCognitoGroup($group: String!) {
  createCognitoGroup(group: $group)
}
` as GeneratedMutation<
  APITypes.CreateCognitoGroupMutationVariables,
  APITypes.CreateCognitoGroupMutation
>;
export const deleteCognitoGroup = /* GraphQL */ `mutation DeleteCognitoGroup(
  $groupID: String!
  $groupName: String!
  $target: DeleteGroupTarget!
  $deleteType: DeleteGroupType!
) {
  deleteCognitoGroup(
    groupID: $groupID
    groupName: $groupName
    target: $target
    deleteType: $deleteType
  )
}
` as GeneratedMutation<
  APITypes.DeleteCognitoGroupMutationVariables,
  APITypes.DeleteCognitoGroupMutation
>;
export const disableCognitoUser = /* GraphQL */ `mutation DisableCognitoUser($username: String!) {
  disableCognitoUser(username: $username)
}
` as GeneratedMutation<
  APITypes.DisableCognitoUserMutationVariables,
  APITypes.DisableCognitoUserMutation
>;
export const sendEmail = /* GraphQL */ `mutation SendEmail($username: String!) {
  sendEmail(username: $username)
}
` as GeneratedMutation<
  APITypes.SendEmailMutationVariables,
  APITypes.SendEmailMutation
>;
export const createCognitoUser = /* GraphQL */ `mutation CreateCognitoUser(
  $username: String!
  $email: String!
  $group: String!
  $firstName: String!
  $lastName: String!
  $teamID: String
) {
  createCognitoUser(
    username: $username
    email: $email
    group: $group
    firstName: $firstName
    lastName: $lastName
    teamID: $teamID
  ) {
    sub
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCognitoUserMutationVariables,
  APITypes.CreateCognitoUserMutation
>;
export const createImportedUsers = /* GraphQL */ `mutation CreateImportedUsers($userAttributes: [CreateImportedUserInput!]!) {
  createImportedUsers(userAttributes: $userAttributes)
}
` as GeneratedMutation<
  APITypes.CreateImportedUsersMutationVariables,
  APITypes.CreateImportedUsersMutation
>;
export const deleteCognitoUser = /* GraphQL */ `mutation DeleteCognitoUser($username: String!) {
  deleteCognitoUser(username: $username)
}
` as GeneratedMutation<
  APITypes.DeleteCognitoUserMutationVariables,
  APITypes.DeleteCognitoUserMutation
>;
export const deleteUserTrigger = /* GraphQL */ `mutation DeleteUserTrigger($userIDs: [ID!]!) {
  deleteUserTrigger(userIDs: $userIDs)
}
` as GeneratedMutation<
  APITypes.DeleteUserTriggerMutationVariables,
  APITypes.DeleteUserTriggerMutation
>;
export const activateUserTrigger = /* GraphQL */ `mutation ActivateUserTrigger($userIDs: [ID!]!) {
  activateUserTrigger(userIDs: $userIDs)
}
` as GeneratedMutation<
  APITypes.ActivateUserTriggerMutationVariables,
  APITypes.ActivateUserTriggerMutation
>;
export const archiveOrganization = /* GraphQL */ `mutation ArchiveOrganization($input: ArchiveOrganizationInput!) {
  archiveOrganization(input: $input) {
    organization {
      id
      name
      domain
      webhookURL
      mailingList
      adminGroupName
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      groupName
      numberOfUsers
      agreementUpdateDate
      assessmentFromDate
      assessmentToDate
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      delete
      totalAudioDuration
      status
      startDate
      endDate
      microsoftAppId
      microsoftTenant
      microsoftClientSecret
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ArchiveOrganizationMutationVariables,
  APITypes.ArchiveOrganizationMutation
>;
export const createTeam = /* GraphQL */ `mutation CreateTeam(
  $input: CreateTeamInput!
  $condition: ModelTeamConditionInput
) {
  createTeam(input: $input, condition: $condition) {
    id
    name
    description
    createdAt
    updatedAt
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    organizationGroup
    adminGroup
    delete
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamMutationVariables,
  APITypes.CreateTeamMutation
>;
export const updateTeam = /* GraphQL */ `mutation UpdateTeam(
  $input: UpdateTeamInput!
  $condition: ModelTeamConditionInput
) {
  updateTeam(input: $input, condition: $condition) {
    id
    name
    description
    createdAt
    updatedAt
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    organizationGroup
    adminGroup
    delete
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamMutationVariables,
  APITypes.UpdateTeamMutation
>;
export const deleteTeam = /* GraphQL */ `mutation DeleteTeam(
  $input: DeleteTeamInput!
  $condition: ModelTeamConditionInput
) {
  deleteTeam(input: $input, condition: $condition) {
    id
    name
    description
    createdAt
    updatedAt
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    organizationGroup
    adminGroup
    delete
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeamMutationVariables,
  APITypes.DeleteTeamMutation
>;
export const createMemberManager = /* GraphQL */ `mutation CreateMemberManager(
  $input: CreateMemberManagerInput!
  $condition: ModelMemberManagerConditionInput
) {
  createMemberManager(input: $input, condition: $condition) {
    id
    managerID
    memberID
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    owner
    manager {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    member {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMemberManagerMutationVariables,
  APITypes.CreateMemberManagerMutation
>;
export const updateMemberManager = /* GraphQL */ `mutation UpdateMemberManager(
  $input: UpdateMemberManagerInput!
  $condition: ModelMemberManagerConditionInput
) {
  updateMemberManager(input: $input, condition: $condition) {
    id
    managerID
    memberID
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    owner
    manager {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    member {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMemberManagerMutationVariables,
  APITypes.UpdateMemberManagerMutation
>;
export const deleteMemberManager = /* GraphQL */ `mutation DeleteMemberManager(
  $input: DeleteMemberManagerInput!
  $condition: ModelMemberManagerConditionInput
) {
  deleteMemberManager(input: $input, condition: $condition) {
    id
    managerID
    memberID
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    owner
    manager {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    member {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMemberManagerMutationVariables,
  APITypes.DeleteMemberManagerMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    account
    owner
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    organizationID
    status
    admin
    sub
    socialType
    drivingScore
    analyticalScore
    amiableScore
    expressiveScore
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    email
    teamID
    managers
    googleRefreshToken
    googleAccountEmail
    about
    delete
    deleteStatus
    employeeId
    teamCode
    readingFirstName
    readingLastName
    birthDay
    gender
    address
    joinedCompanyDay
    retirementDay
    jobType
    phoneNumber
    enrollmentStatus
    recruitmentClassification
    department
    division
    section
    position
    gradeID
    allowance
    bonus
    gradeMatrixID
    myMembers {
      items {
        id
        managerID
        memberID
        organizationGroup
        adminGroup
        createdAt
        updatedAt
        delete
        owner
        manager {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        member {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    myManagers {
      items {
        id
        managerID
        memberID
        organizationGroup
        adminGroup
        createdAt
        updatedAt
        delete
        owner
        manager {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        member {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    gradeMatrix {
      gradeMatrixID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      memberList {
        items {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      competencyAreaList {
        items {
          competencyAreaID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gradeList {
        items {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      competencyItemList {
        items {
          competencyItemID
          item
          gradeID
          competencyAreaID
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    grade {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    account
    owner
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    organizationID
    status
    admin
    sub
    socialType
    drivingScore
    analyticalScore
    amiableScore
    expressiveScore
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    email
    teamID
    managers
    googleRefreshToken
    googleAccountEmail
    about
    delete
    deleteStatus
    employeeId
    teamCode
    readingFirstName
    readingLastName
    birthDay
    gender
    address
    joinedCompanyDay
    retirementDay
    jobType
    phoneNumber
    enrollmentStatus
    recruitmentClassification
    department
    division
    section
    position
    gradeID
    allowance
    bonus
    gradeMatrixID
    myMembers {
      items {
        id
        managerID
        memberID
        organizationGroup
        adminGroup
        createdAt
        updatedAt
        delete
        owner
        manager {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        member {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    myManagers {
      items {
        id
        managerID
        memberID
        organizationGroup
        adminGroup
        createdAt
        updatedAt
        delete
        owner
        manager {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        member {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    gradeMatrix {
      gradeMatrixID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      memberList {
        items {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      competencyAreaList {
        items {
          competencyAreaID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gradeList {
        items {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      competencyItemList {
        items {
          competencyItemID
          item
          gradeID
          competencyAreaID
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    grade {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    firstName
    lastName
    account
    owner
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    organizationID
    status
    admin
    sub
    socialType
    drivingScore
    analyticalScore
    amiableScore
    expressiveScore
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    email
    teamID
    managers
    googleRefreshToken
    googleAccountEmail
    about
    delete
    deleteStatus
    employeeId
    teamCode
    readingFirstName
    readingLastName
    birthDay
    gender
    address
    joinedCompanyDay
    retirementDay
    jobType
    phoneNumber
    enrollmentStatus
    recruitmentClassification
    department
    division
    section
    position
    gradeID
    allowance
    bonus
    gradeMatrixID
    myMembers {
      items {
        id
        managerID
        memberID
        organizationGroup
        adminGroup
        createdAt
        updatedAt
        delete
        owner
        manager {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        member {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    myManagers {
      items {
        id
        managerID
        memberID
        organizationGroup
        adminGroup
        createdAt
        updatedAt
        delete
        owner
        manager {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        member {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    gradeMatrix {
      gradeMatrixID
      title
      description
      organizationGroup
      createdAt
      updatedAt
      memberList {
        items {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      competencyAreaList {
        items {
          competencyAreaID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      gradeList {
        items {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      competencyItemList {
        items {
          competencyItemID
          item
          gradeID
          competencyAreaID
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    grade {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createHistory = /* GraphQL */ `mutation CreateHistory(
  $input: CreateHistoryInput!
  $condition: ModelHistoryConditionInput
) {
  createHistory(input: $input, condition: $condition) {
    id
    user
    value
    subValue
    start
    end
    type
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    groupsCanAccess
    owners
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHistoryMutationVariables,
  APITypes.CreateHistoryMutation
>;
export const updateHistory = /* GraphQL */ `mutation UpdateHistory(
  $input: UpdateHistoryInput!
  $condition: ModelHistoryConditionInput
) {
  updateHistory(input: $input, condition: $condition) {
    id
    user
    value
    subValue
    start
    end
    type
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    groupsCanAccess
    owners
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHistoryMutationVariables,
  APITypes.UpdateHistoryMutation
>;
export const deleteHistory = /* GraphQL */ `mutation DeleteHistory(
  $input: DeleteHistoryInput!
  $condition: ModelHistoryConditionInput
) {
  deleteHistory(input: $input, condition: $condition) {
    id
    user
    value
    subValue
    start
    end
    type
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    groupsCanAccess
    owners
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHistoryMutationVariables,
  APITypes.DeleteHistoryMutation
>;
export const createSetting = /* GraphQL */ `mutation CreateSetting(
  $input: CreateSettingInput!
  $condition: ModelSettingConditionInput
) {
  createSetting(input: $input, condition: $condition) {
    id
    notification {
      disableAll
      email {
        enable
        reportLike
        reportComment
        onoRemind
        onoLookBack
        goalUpdate
        goalActivity
        goalRemind
        __typename
      }
      __typename
    }
    calendar {
      gmailAddress
      syncLimit
      syncInterval
      syncToken
      __typename
    }
    organizationGroup
    adminGroup
    delete
    createdAt
    updatedAt
    groupsCanAccess
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSettingMutationVariables,
  APITypes.CreateSettingMutation
>;
export const updateSetting = /* GraphQL */ `mutation UpdateSetting(
  $input: UpdateSettingInput!
  $condition: ModelSettingConditionInput
) {
  updateSetting(input: $input, condition: $condition) {
    id
    notification {
      disableAll
      email {
        enable
        reportLike
        reportComment
        onoRemind
        onoLookBack
        goalUpdate
        goalActivity
        goalRemind
        __typename
      }
      __typename
    }
    calendar {
      gmailAddress
      syncLimit
      syncInterval
      syncToken
      __typename
    }
    organizationGroup
    adminGroup
    delete
    createdAt
    updatedAt
    groupsCanAccess
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSettingMutationVariables,
  APITypes.UpdateSettingMutation
>;
export const deleteSetting = /* GraphQL */ `mutation DeleteSetting(
  $input: DeleteSettingInput!
  $condition: ModelSettingConditionInput
) {
  deleteSetting(input: $input, condition: $condition) {
    id
    notification {
      disableAll
      email {
        enable
        reportLike
        reportComment
        onoRemind
        onoLookBack
        goalUpdate
        goalActivity
        goalRemind
        __typename
      }
      __typename
    }
    calendar {
      gmailAddress
      syncLimit
      syncInterval
      syncToken
      __typename
    }
    organizationGroup
    adminGroup
    delete
    createdAt
    updatedAt
    groupsCanAccess
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSettingMutationVariables,
  APITypes.DeleteSettingMutation
>;
export const createTask = /* GraphQL */ `mutation CreateTask(
  $input: CreateTaskInput!
  $condition: ModelTaskConditionInput
) {
  createTask(input: $input, condition: $condition) {
    taskID
    userID
    content
    title
    taskType
    complete
    createdAt
    updatedAt
    dueDate
    taskLink
    organizationGroup
    reviewSheetID
    goalID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskMutationVariables,
  APITypes.CreateTaskMutation
>;
export const updateTask = /* GraphQL */ `mutation UpdateTask(
  $input: UpdateTaskInput!
  $condition: ModelTaskConditionInput
) {
  updateTask(input: $input, condition: $condition) {
    taskID
    userID
    content
    title
    taskType
    complete
    createdAt
    updatedAt
    dueDate
    taskLink
    organizationGroup
    reviewSheetID
    goalID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskMutationVariables,
  APITypes.UpdateTaskMutation
>;
export const deleteTask = /* GraphQL */ `mutation DeleteTask(
  $input: DeleteTaskInput!
  $condition: ModelTaskConditionInput
) {
  deleteTask(input: $input, condition: $condition) {
    taskID
    userID
    content
    title
    taskType
    complete
    createdAt
    updatedAt
    dueDate
    taskLink
    organizationGroup
    reviewSheetID
    goalID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskMutationVariables,
  APITypes.DeleteTaskMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    name
    domain
    webhookURL
    mailingList
    adminGroupName
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    groupName
    numberOfUsers
    agreementUpdateDate
    assessmentFromDate
    assessmentToDate
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    totalAudioDuration
    status
    startDate
    endDate
    microsoftAppId
    microsoftTenant
    microsoftClientSecret
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    name
    domain
    webhookURL
    mailingList
    adminGroupName
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    groupName
    numberOfUsers
    agreementUpdateDate
    assessmentFromDate
    assessmentToDate
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    totalAudioDuration
    status
    startDate
    endDate
    microsoftAppId
    microsoftTenant
    microsoftClientSecret
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    name
    domain
    webhookURL
    mailingList
    adminGroupName
    originalPhotoUrl
    thumbnailPhotoUrl
    coverImageUrl
    groupName
    numberOfUsers
    agreementUpdateDate
    assessmentFromDate
    assessmentToDate
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    totalAudioDuration
    status
    startDate
    endDate
    microsoftAppId
    microsoftTenant
    microsoftClientSecret
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createGoal3 = /* GraphQL */ `mutation CreateGoal3(
  $input: CreateGoal3Input!
  $condition: ModelGoal3ConditionInput
) {
  createGoal3(input: $input, condition: $condition) {
    id
    organization
    teamID
    value
    detail
    progressType
    startDate
    endDate
    owners
    type
    visibility
    readers
    parentGoalID
    priority
    achievement
    progress
    totalValue
    start
    target
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    archiveStatus
    goalProgressList {
      items {
        goalProgressID
        organizationGroup
        goalID
        progress
        progressDetail
        value
        creatorID
        createdAt
        deleteStatus
        updatedAt
        comments {
          items {
            goalProgressCommentID
            organizationGroup
            commenterID
            commentContent
            goalProgressID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGoal3MutationVariables,
  APITypes.CreateGoal3Mutation
>;
export const updateGoal3 = /* GraphQL */ `mutation UpdateGoal3(
  $input: UpdateGoal3Input!
  $condition: ModelGoal3ConditionInput
) {
  updateGoal3(input: $input, condition: $condition) {
    id
    organization
    teamID
    value
    detail
    progressType
    startDate
    endDate
    owners
    type
    visibility
    readers
    parentGoalID
    priority
    achievement
    progress
    totalValue
    start
    target
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    archiveStatus
    goalProgressList {
      items {
        goalProgressID
        organizationGroup
        goalID
        progress
        progressDetail
        value
        creatorID
        createdAt
        deleteStatus
        updatedAt
        comments {
          items {
            goalProgressCommentID
            organizationGroup
            commenterID
            commentContent
            goalProgressID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGoal3MutationVariables,
  APITypes.UpdateGoal3Mutation
>;
export const deleteGoal3 = /* GraphQL */ `mutation DeleteGoal3(
  $input: DeleteGoal3Input!
  $condition: ModelGoal3ConditionInput
) {
  deleteGoal3(input: $input, condition: $condition) {
    id
    organization
    teamID
    value
    detail
    progressType
    startDate
    endDate
    owners
    type
    visibility
    readers
    parentGoalID
    priority
    achievement
    progress
    totalValue
    start
    target
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    archiveStatus
    goalProgressList {
      items {
        goalProgressID
        organizationGroup
        goalID
        progress
        progressDetail
        value
        creatorID
        createdAt
        deleteStatus
        updatedAt
        comments {
          items {
            goalProgressCommentID
            organizationGroup
            commenterID
            commentContent
            goalProgressID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGoal3MutationVariables,
  APITypes.DeleteGoal3Mutation
>;
export const createGoal = /* GraphQL */ `mutation CreateGoal(
  $input: CreateGoalInput!
  $condition: ModelGoalConditionInput
) {
  createGoal(input: $input, condition: $condition) {
    goalID
    organizationGroup
    parentGoalID
    departmentID
    directorIDs
    readerIDs
    goalClassification
    goalTitle
    goalDetail
    measuringType
    startDate
    targetDate
    goalType
    publicScope
    archiveStatus
    startValue
    targetValue
    createdAt
    updatedAt
    deleteStatus
    goalWeightPoint
    goalProgressList {
      items {
        goalProgressID
        organizationGroup
        goalID
        progress
        progressDetail
        value
        creatorID
        createdAt
        deleteStatus
        updatedAt
        comments {
          items {
            goalProgressCommentID
            organizationGroup
            commenterID
            commentContent
            goalProgressID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGoalMutationVariables,
  APITypes.CreateGoalMutation
>;
export const updateGoal = /* GraphQL */ `mutation UpdateGoal(
  $input: UpdateGoalInput!
  $condition: ModelGoalConditionInput
) {
  updateGoal(input: $input, condition: $condition) {
    goalID
    organizationGroup
    parentGoalID
    departmentID
    directorIDs
    readerIDs
    goalClassification
    goalTitle
    goalDetail
    measuringType
    startDate
    targetDate
    goalType
    publicScope
    archiveStatus
    startValue
    targetValue
    createdAt
    updatedAt
    deleteStatus
    goalWeightPoint
    goalProgressList {
      items {
        goalProgressID
        organizationGroup
        goalID
        progress
        progressDetail
        value
        creatorID
        createdAt
        deleteStatus
        updatedAt
        comments {
          items {
            goalProgressCommentID
            organizationGroup
            commenterID
            commentContent
            goalProgressID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGoalMutationVariables,
  APITypes.UpdateGoalMutation
>;
export const deleteGoal = /* GraphQL */ `mutation DeleteGoal(
  $input: DeleteGoalInput!
  $condition: ModelGoalConditionInput
) {
  deleteGoal(input: $input, condition: $condition) {
    goalID
    organizationGroup
    parentGoalID
    departmentID
    directorIDs
    readerIDs
    goalClassification
    goalTitle
    goalDetail
    measuringType
    startDate
    targetDate
    goalType
    publicScope
    archiveStatus
    startValue
    targetValue
    createdAt
    updatedAt
    deleteStatus
    goalWeightPoint
    goalProgressList {
      items {
        goalProgressID
        organizationGroup
        goalID
        progress
        progressDetail
        value
        creatorID
        createdAt
        deleteStatus
        updatedAt
        comments {
          items {
            goalProgressCommentID
            organizationGroup
            commenterID
            commentContent
            goalProgressID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGoalMutationVariables,
  APITypes.DeleteGoalMutation
>;
export const createGoalActivity = /* GraphQL */ `mutation CreateGoalActivity(
  $input: CreateGoalActivityInput!
  $condition: ModelGoalActivityConditionInput
) {
  createGoalActivity(input: $input, condition: $condition) {
    id
    userID
    goalID
    keyResultID
    date
    value
    detail
    progress
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGoalActivityMutationVariables,
  APITypes.CreateGoalActivityMutation
>;
export const updateGoalActivity = /* GraphQL */ `mutation UpdateGoalActivity(
  $input: UpdateGoalActivityInput!
  $condition: ModelGoalActivityConditionInput
) {
  updateGoalActivity(input: $input, condition: $condition) {
    id
    userID
    goalID
    keyResultID
    date
    value
    detail
    progress
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGoalActivityMutationVariables,
  APITypes.UpdateGoalActivityMutation
>;
export const deleteGoalActivity = /* GraphQL */ `mutation DeleteGoalActivity(
  $input: DeleteGoalActivityInput!
  $condition: ModelGoalActivityConditionInput
) {
  deleteGoalActivity(input: $input, condition: $condition) {
    id
    userID
    goalID
    keyResultID
    date
    value
    detail
    progress
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGoalActivityMutationVariables,
  APITypes.DeleteGoalActivityMutation
>;
export const createGoalProgress = /* GraphQL */ `mutation CreateGoalProgress(
  $input: CreateGoalProgressInput!
  $condition: ModelGoalProgressConditionInput
) {
  createGoalProgress(input: $input, condition: $condition) {
    goalProgressID
    organizationGroup
    goalID
    progress
    progressDetail
    value
    creatorID
    createdAt
    deleteStatus
    updatedAt
    comments {
      items {
        goalProgressCommentID
        organizationGroup
        commenterID
        commentContent
        goalProgressID
        createdAt
        updatedAt
        commenter {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGoalProgressMutationVariables,
  APITypes.CreateGoalProgressMutation
>;
export const updateGoalProgress = /* GraphQL */ `mutation UpdateGoalProgress(
  $input: UpdateGoalProgressInput!
  $condition: ModelGoalProgressConditionInput
) {
  updateGoalProgress(input: $input, condition: $condition) {
    goalProgressID
    organizationGroup
    goalID
    progress
    progressDetail
    value
    creatorID
    createdAt
    deleteStatus
    updatedAt
    comments {
      items {
        goalProgressCommentID
        organizationGroup
        commenterID
        commentContent
        goalProgressID
        createdAt
        updatedAt
        commenter {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGoalProgressMutationVariables,
  APITypes.UpdateGoalProgressMutation
>;
export const deleteGoalProgress = /* GraphQL */ `mutation DeleteGoalProgress(
  $input: DeleteGoalProgressInput!
  $condition: ModelGoalProgressConditionInput
) {
  deleteGoalProgress(input: $input, condition: $condition) {
    goalProgressID
    organizationGroup
    goalID
    progress
    progressDetail
    value
    creatorID
    createdAt
    deleteStatus
    updatedAt
    comments {
      items {
        goalProgressCommentID
        organizationGroup
        commenterID
        commentContent
        goalProgressID
        createdAt
        updatedAt
        commenter {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGoalProgressMutationVariables,
  APITypes.DeleteGoalProgressMutation
>;
export const createGoalProgressComment = /* GraphQL */ `mutation CreateGoalProgressComment(
  $input: CreateGoalProgressCommentInput!
  $condition: ModelGoalProgressCommentConditionInput
) {
  createGoalProgressComment(input: $input, condition: $condition) {
    goalProgressCommentID
    organizationGroup
    commenterID
    commentContent
    goalProgressID
    createdAt
    updatedAt
    commenter {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGoalProgressCommentMutationVariables,
  APITypes.CreateGoalProgressCommentMutation
>;
export const updateGoalProgressComment = /* GraphQL */ `mutation UpdateGoalProgressComment(
  $input: UpdateGoalProgressCommentInput!
  $condition: ModelGoalProgressCommentConditionInput
) {
  updateGoalProgressComment(input: $input, condition: $condition) {
    goalProgressCommentID
    organizationGroup
    commenterID
    commentContent
    goalProgressID
    createdAt
    updatedAt
    commenter {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGoalProgressCommentMutationVariables,
  APITypes.UpdateGoalProgressCommentMutation
>;
export const deleteGoalProgressComment = /* GraphQL */ `mutation DeleteGoalProgressComment(
  $input: DeleteGoalProgressCommentInput!
  $condition: ModelGoalProgressCommentConditionInput
) {
  deleteGoalProgressComment(input: $input, condition: $condition) {
    goalProgressCommentID
    organizationGroup
    commenterID
    commentContent
    goalProgressID
    createdAt
    updatedAt
    commenter {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGoalProgressCommentMutationVariables,
  APITypes.DeleteGoalProgressCommentMutation
>;
export const createOno = /* GraphQL */ `mutation CreateOno(
  $input: CreateOnoInput!
  $condition: ModelOnoConditionInput
) {
  createOno(input: $input, condition: $condition) {
    id
    member
    manager
    frequency
    nextMeetingDate
    nextMeetingStart
    templateID
    googleChannelResourceID
    iCalUID
    googleAccountEmail
    status
    editors
    createdAt
    updatedAt
    adminGroup
    organizationGroup
    groupsCanAccess
    delete
    deleteStatus
    outlookUserId
    outlookEventId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOnoMutationVariables,
  APITypes.CreateOnoMutation
>;
export const updateOno = /* GraphQL */ `mutation UpdateOno(
  $input: UpdateOnoInput!
  $condition: ModelOnoConditionInput
) {
  updateOno(input: $input, condition: $condition) {
    id
    member
    manager
    frequency
    nextMeetingDate
    nextMeetingStart
    templateID
    googleChannelResourceID
    iCalUID
    googleAccountEmail
    status
    editors
    createdAt
    updatedAt
    adminGroup
    organizationGroup
    groupsCanAccess
    delete
    deleteStatus
    outlookUserId
    outlookEventId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOnoMutationVariables,
  APITypes.UpdateOnoMutation
>;
export const deleteOno = /* GraphQL */ `mutation DeleteOno(
  $input: DeleteOnoInput!
  $condition: ModelOnoConditionInput
) {
  deleteOno(input: $input, condition: $condition) {
    id
    member
    manager
    frequency
    nextMeetingDate
    nextMeetingStart
    templateID
    googleChannelResourceID
    iCalUID
    googleAccountEmail
    status
    editors
    createdAt
    updatedAt
    adminGroup
    organizationGroup
    groupsCanAccess
    delete
    deleteStatus
    outlookUserId
    outlookEventId
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOnoMutationVariables,
  APITypes.DeleteOnoMutation
>;
export const createMeeting = /* GraphQL */ `mutation CreateMeeting(
  $input: CreateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  createMeeting(input: $input, condition: $condition) {
    id
    member
    manager
    startDate
    ono
    status
    templateID
    temporary
    createdAt
    updatedAt
    adminGroup
    organizationGroup
    delete
    deleteStatus
    participated
    groupsCanAccess
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingMutationVariables,
  APITypes.CreateMeetingMutation
>;
export const updateMeeting = /* GraphQL */ `mutation UpdateMeeting(
  $input: UpdateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  updateMeeting(input: $input, condition: $condition) {
    id
    member
    manager
    startDate
    ono
    status
    templateID
    temporary
    createdAt
    updatedAt
    adminGroup
    organizationGroup
    delete
    deleteStatus
    participated
    groupsCanAccess
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingMutationVariables,
  APITypes.UpdateMeetingMutation
>;
export const deleteMeeting = /* GraphQL */ `mutation DeleteMeeting(
  $input: DeleteMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  deleteMeeting(input: $input, condition: $condition) {
    id
    member
    manager
    startDate
    ono
    status
    templateID
    temporary
    createdAt
    updatedAt
    adminGroup
    organizationGroup
    delete
    deleteStatus
    participated
    groupsCanAccess
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingMutationVariables,
  APITypes.DeleteMeetingMutation
>;
export const createTodo = /* GraphQL */ `mutation CreateTodo(
  $input: CreateTodoInput!
  $condition: ModelTodoConditionInput
) {
  createTodo(input: $input, condition: $condition) {
    id
    name
    ono
    assignmentUserId
    status
    createdMeetingID
    completedMeetingID
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    deleteStatus
    owners
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTodoMutationVariables,
  APITypes.CreateTodoMutation
>;
export const updateTodo = /* GraphQL */ `mutation UpdateTodo(
  $input: UpdateTodoInput!
  $condition: ModelTodoConditionInput
) {
  updateTodo(input: $input, condition: $condition) {
    id
    name
    ono
    assignmentUserId
    status
    createdMeetingID
    completedMeetingID
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    deleteStatus
    owners
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTodoMutationVariables,
  APITypes.UpdateTodoMutation
>;
export const deleteTodo = /* GraphQL */ `mutation DeleteTodo(
  $input: DeleteTodoInput!
  $condition: ModelTodoConditionInput
) {
  deleteTodo(input: $input, condition: $condition) {
    id
    name
    ono
    assignmentUserId
    status
    createdMeetingID
    completedMeetingID
    createdAt
    updatedAt
    organizationGroup
    adminGroup
    delete
    deleteStatus
    owners
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTodoMutationVariables,
  APITypes.DeleteTodoMutation
>;
export const createNextAction = /* GraphQL */ `mutation CreateNextAction(
  $input: CreateNextActionInput!
  $condition: ModelNextActionConditionInput
) {
  createNextAction(input: $input, condition: $condition) {
    id
    title
    oneOnOneSettingID
    managerID
    addedOneOnOneID
    completedOneOnOneID
    targetDate
    isDone
    createdAt
    updatedAt
    organizationGroup
    manager {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNextActionMutationVariables,
  APITypes.CreateNextActionMutation
>;
export const updateNextAction = /* GraphQL */ `mutation UpdateNextAction(
  $input: UpdateNextActionInput!
  $condition: ModelNextActionConditionInput
) {
  updateNextAction(input: $input, condition: $condition) {
    id
    title
    oneOnOneSettingID
    managerID
    addedOneOnOneID
    completedOneOnOneID
    targetDate
    isDone
    createdAt
    updatedAt
    organizationGroup
    manager {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNextActionMutationVariables,
  APITypes.UpdateNextActionMutation
>;
export const deleteNextAction = /* GraphQL */ `mutation DeleteNextAction(
  $input: DeleteNextActionInput!
  $condition: ModelNextActionConditionInput
) {
  deleteNextAction(input: $input, condition: $condition) {
    id
    title
    oneOnOneSettingID
    managerID
    addedOneOnOneID
    completedOneOnOneID
    targetDate
    isDone
    createdAt
    updatedAt
    organizationGroup
    manager {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNextActionMutationVariables,
  APITypes.DeleteNextActionMutation
>;
export const createOrganizationAgenda = /* GraphQL */ `mutation CreateOrganizationAgenda(
  $input: CreateOrganizationAgendaInput!
  $condition: ModelOrganizationAgendaConditionInput
) {
  createOrganizationAgenda(input: $input, condition: $condition) {
    id
    title
    order
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationAgendaMutationVariables,
  APITypes.CreateOrganizationAgendaMutation
>;
export const updateOrganizationAgenda = /* GraphQL */ `mutation UpdateOrganizationAgenda(
  $input: UpdateOrganizationAgendaInput!
  $condition: ModelOrganizationAgendaConditionInput
) {
  updateOrganizationAgenda(input: $input, condition: $condition) {
    id
    title
    order
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationAgendaMutationVariables,
  APITypes.UpdateOrganizationAgendaMutation
>;
export const deleteOrganizationAgenda = /* GraphQL */ `mutation DeleteOrganizationAgenda(
  $input: DeleteOrganizationAgendaInput!
  $condition: ModelOrganizationAgendaConditionInput
) {
  deleteOrganizationAgenda(input: $input, condition: $condition) {
    id
    title
    order
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationAgendaMutationVariables,
  APITypes.DeleteOrganizationAgendaMutation
>;
export const createAgendaTemplate = /* GraphQL */ `mutation CreateAgendaTemplate(
  $input: CreateAgendaTemplateInput!
  $condition: ModelAgendaTemplateConditionInput
) {
  createAgendaTemplate(input: $input, condition: $condition) {
    id
    title
    purpose
    organizationGroup
    ownerID
    status
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    agendas {
      items {
        id
        agendaTemplateID
        title
        order
        ownerID
        createdAt
        updatedAt
        organizationGroup
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAgendaTemplateMutationVariables,
  APITypes.CreateAgendaTemplateMutation
>;
export const updateAgendaTemplate = /* GraphQL */ `mutation UpdateAgendaTemplate(
  $input: UpdateAgendaTemplateInput!
  $condition: ModelAgendaTemplateConditionInput
) {
  updateAgendaTemplate(input: $input, condition: $condition) {
    id
    title
    purpose
    organizationGroup
    ownerID
    status
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    agendas {
      items {
        id
        agendaTemplateID
        title
        order
        ownerID
        createdAt
        updatedAt
        organizationGroup
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAgendaTemplateMutationVariables,
  APITypes.UpdateAgendaTemplateMutation
>;
export const deleteAgendaTemplate = /* GraphQL */ `mutation DeleteAgendaTemplate(
  $input: DeleteAgendaTemplateInput!
  $condition: ModelAgendaTemplateConditionInput
) {
  deleteAgendaTemplate(input: $input, condition: $condition) {
    id
    title
    purpose
    organizationGroup
    ownerID
    status
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    agendas {
      items {
        id
        agendaTemplateID
        title
        order
        ownerID
        createdAt
        updatedAt
        organizationGroup
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAgendaTemplateMutationVariables,
  APITypes.DeleteAgendaTemplateMutation
>;
export const createAgendaTemplateAgenda = /* GraphQL */ `mutation CreateAgendaTemplateAgenda(
  $input: CreateAgendaTemplateAgendaInput!
  $condition: ModelAgendaTemplateAgendaConditionInput
) {
  createAgendaTemplateAgenda(input: $input, condition: $condition) {
    id
    agendaTemplateID
    title
    order
    ownerID
    createdAt
    updatedAt
    organizationGroup
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAgendaTemplateAgendaMutationVariables,
  APITypes.CreateAgendaTemplateAgendaMutation
>;
export const updateAgendaTemplateAgenda = /* GraphQL */ `mutation UpdateAgendaTemplateAgenda(
  $input: UpdateAgendaTemplateAgendaInput!
  $condition: ModelAgendaTemplateAgendaConditionInput
) {
  updateAgendaTemplateAgenda(input: $input, condition: $condition) {
    id
    agendaTemplateID
    title
    order
    ownerID
    createdAt
    updatedAt
    organizationGroup
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAgendaTemplateAgendaMutationVariables,
  APITypes.UpdateAgendaTemplateAgendaMutation
>;
export const deleteAgendaTemplateAgenda = /* GraphQL */ `mutation DeleteAgendaTemplateAgenda(
  $input: DeleteAgendaTemplateAgendaInput!
  $condition: ModelAgendaTemplateAgendaConditionInput
) {
  deleteAgendaTemplateAgenda(input: $input, condition: $condition) {
    id
    agendaTemplateID
    title
    order
    ownerID
    createdAt
    updatedAt
    organizationGroup
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAgendaTemplateAgendaMutationVariables,
  APITypes.DeleteAgendaTemplateAgendaMutation
>;
export const createTemplate = /* GraphQL */ `mutation CreateTemplate(
  $input: CreateTemplateInput!
  $condition: ModelTemplateConditionInput
) {
  createTemplate(input: $input, condition: $condition) {
    id
    title
    ono
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTemplateMutationVariables,
  APITypes.CreateTemplateMutation
>;
export const updateTemplate = /* GraphQL */ `mutation UpdateTemplate(
  $input: UpdateTemplateInput!
  $condition: ModelTemplateConditionInput
) {
  updateTemplate(input: $input, condition: $condition) {
    id
    title
    ono
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTemplateMutationVariables,
  APITypes.UpdateTemplateMutation
>;
export const deleteTemplate = /* GraphQL */ `mutation DeleteTemplate(
  $input: DeleteTemplateInput!
  $condition: ModelTemplateConditionInput
) {
  deleteTemplate(input: $input, condition: $condition) {
    id
    title
    ono
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTemplateMutationVariables,
  APITypes.DeleteTemplateMutation
>;
export const createTemplateAgenda = /* GraphQL */ `mutation CreateTemplateAgenda(
  $input: CreateTemplateAgendaInput!
  $condition: ModelTemplateAgendaConditionInput
) {
  createTemplateAgenda(input: $input, condition: $condition) {
    id
    template
    title
    fromOrganization
    order
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTemplateAgendaMutationVariables,
  APITypes.CreateTemplateAgendaMutation
>;
export const updateTemplateAgenda = /* GraphQL */ `mutation UpdateTemplateAgenda(
  $input: UpdateTemplateAgendaInput!
  $condition: ModelTemplateAgendaConditionInput
) {
  updateTemplateAgenda(input: $input, condition: $condition) {
    id
    template
    title
    fromOrganization
    order
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTemplateAgendaMutationVariables,
  APITypes.UpdateTemplateAgendaMutation
>;
export const deleteTemplateAgenda = /* GraphQL */ `mutation DeleteTemplateAgenda(
  $input: DeleteTemplateAgendaInput!
  $condition: ModelTemplateAgendaConditionInput
) {
  deleteTemplateAgenda(input: $input, condition: $condition) {
    id
    template
    title
    fromOrganization
    order
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTemplateAgendaMutationVariables,
  APITypes.DeleteTemplateAgendaMutation
>;
export const createAgenda = /* GraphQL */ `mutation CreateAgenda(
  $input: CreateAgendaInput!
  $condition: ModelAgendaConditionInput
) {
  createAgenda(input: $input, condition: $condition) {
    id
    oneOnOneID
    title
    order
    referenceID
    agendaType
    isDone
    organizationGroup
    createdAt
    updatedAt
    owners
    creator {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        oneOnOneID
        agendaID
        comment
        commenterID
        organizationGroup
        aiAdvice
        createdAt
        updatedAt
        commenter {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAgendaMutationVariables,
  APITypes.CreateAgendaMutation
>;
export const updateAgenda = /* GraphQL */ `mutation UpdateAgenda(
  $input: UpdateAgendaInput!
  $condition: ModelAgendaConditionInput
) {
  updateAgenda(input: $input, condition: $condition) {
    id
    oneOnOneID
    title
    order
    referenceID
    agendaType
    isDone
    organizationGroup
    createdAt
    updatedAt
    owners
    creator {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        oneOnOneID
        agendaID
        comment
        commenterID
        organizationGroup
        aiAdvice
        createdAt
        updatedAt
        commenter {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAgendaMutationVariables,
  APITypes.UpdateAgendaMutation
>;
export const deleteAgenda = /* GraphQL */ `mutation DeleteAgenda(
  $input: DeleteAgendaInput!
  $condition: ModelAgendaConditionInput
) {
  deleteAgenda(input: $input, condition: $condition) {
    id
    oneOnOneID
    title
    order
    referenceID
    agendaType
    isDone
    organizationGroup
    createdAt
    updatedAt
    owners
    creator {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        oneOnOneID
        agendaID
        comment
        commenterID
        organizationGroup
        aiAdvice
        createdAt
        updatedAt
        commenter {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAgendaMutationVariables,
  APITypes.DeleteAgendaMutation
>;
export const createAgendaComment = /* GraphQL */ `mutation CreateAgendaComment(
  $input: CreateAgendaCommentInput!
  $condition: ModelAgendaCommentConditionInput
) {
  createAgendaComment(input: $input, condition: $condition) {
    id
    oneOnOneID
    agendaID
    comment
    commenterID
    organizationGroup
    aiAdvice
    createdAt
    updatedAt
    commenter {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAgendaCommentMutationVariables,
  APITypes.CreateAgendaCommentMutation
>;
export const updateAgendaComment = /* GraphQL */ `mutation UpdateAgendaComment(
  $input: UpdateAgendaCommentInput!
  $condition: ModelAgendaCommentConditionInput
) {
  updateAgendaComment(input: $input, condition: $condition) {
    id
    oneOnOneID
    agendaID
    comment
    commenterID
    organizationGroup
    aiAdvice
    createdAt
    updatedAt
    commenter {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAgendaCommentMutationVariables,
  APITypes.UpdateAgendaCommentMutation
>;
export const deleteAgendaComment = /* GraphQL */ `mutation DeleteAgendaComment(
  $input: DeleteAgendaCommentInput!
  $condition: ModelAgendaCommentConditionInput
) {
  deleteAgendaComment(input: $input, condition: $condition) {
    id
    oneOnOneID
    agendaID
    comment
    commenterID
    organizationGroup
    aiAdvice
    createdAt
    updatedAt
    commenter {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAgendaCommentMutationVariables,
  APITypes.DeleteAgendaCommentMutation
>;
export const createNote = /* GraphQL */ `mutation CreateNote(
  $input: CreateNoteInput!
  $condition: ModelNoteConditionInput
) {
  createNote(input: $input, condition: $condition) {
    id
    meeting
    agendaID
    referenceID
    user
    note
    type
    owners
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNoteMutationVariables,
  APITypes.CreateNoteMutation
>;
export const updateNote = /* GraphQL */ `mutation UpdateNote(
  $input: UpdateNoteInput!
  $condition: ModelNoteConditionInput
) {
  updateNote(input: $input, condition: $condition) {
    id
    meeting
    agendaID
    referenceID
    user
    note
    type
    owners
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNoteMutationVariables,
  APITypes.UpdateNoteMutation
>;
export const deleteNote = /* GraphQL */ `mutation DeleteNote(
  $input: DeleteNoteInput!
  $condition: ModelNoteConditionInput
) {
  deleteNote(input: $input, condition: $condition) {
    id
    meeting
    agendaID
    referenceID
    user
    note
    type
    owners
    organizationGroup
    adminGroup
    createdAt
    updatedAt
    delete
    deleteStatus
    groupsCanAccess
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNoteMutationVariables,
  APITypes.DeleteNoteMutation
>;
export const createOneOnOneSetting = /* GraphQL */ `mutation CreateOneOnOneSetting(
  $input: CreateOneOnOneSettingInput!
  $condition: ModelOneOnOneSettingConditionInput
) {
  createOneOnOneSetting(input: $input, condition: $condition) {
    id
    userID1
    userID2
    frequency
    nextOneOnOneDate
    nextOneOnOneTime
    agendaTemplateID
    status
    organizationGroup
    createdAt
    updatedAt
    user1 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    user2 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    oneOnOneList {
      items {
        id
        userID1
        userID2
        managerID
        oneOnOneSettingID
        frequency
        startDate
        startTime
        status
        agendaTemplateID
        recordingAgendaID
        recordingTarget
        aiSummary
        calendarApp
        eventID
        organizationGroup
        createdAt
        updatedAt
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneSetting {
          id
          userID1
          userID2
          frequency
          nextOneOnOneDate
          nextOneOnOneTime
          agendaTemplateID
          status
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneList {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOneOnOneSettingMutationVariables,
  APITypes.CreateOneOnOneSettingMutation
>;
export const updateOneOnOneSetting = /* GraphQL */ `mutation UpdateOneOnOneSetting(
  $input: UpdateOneOnOneSettingInput!
  $condition: ModelOneOnOneSettingConditionInput
) {
  updateOneOnOneSetting(input: $input, condition: $condition) {
    id
    userID1
    userID2
    frequency
    nextOneOnOneDate
    nextOneOnOneTime
    agendaTemplateID
    status
    organizationGroup
    createdAt
    updatedAt
    user1 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    user2 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    oneOnOneList {
      items {
        id
        userID1
        userID2
        managerID
        oneOnOneSettingID
        frequency
        startDate
        startTime
        status
        agendaTemplateID
        recordingAgendaID
        recordingTarget
        aiSummary
        calendarApp
        eventID
        organizationGroup
        createdAt
        updatedAt
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneSetting {
          id
          userID1
          userID2
          frequency
          nextOneOnOneDate
          nextOneOnOneTime
          agendaTemplateID
          status
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneList {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOneOnOneSettingMutationVariables,
  APITypes.UpdateOneOnOneSettingMutation
>;
export const deleteOneOnOneSetting = /* GraphQL */ `mutation DeleteOneOnOneSetting(
  $input: DeleteOneOnOneSettingInput!
  $condition: ModelOneOnOneSettingConditionInput
) {
  deleteOneOnOneSetting(input: $input, condition: $condition) {
    id
    userID1
    userID2
    frequency
    nextOneOnOneDate
    nextOneOnOneTime
    agendaTemplateID
    status
    organizationGroup
    createdAt
    updatedAt
    user1 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    user2 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    oneOnOneList {
      items {
        id
        userID1
        userID2
        managerID
        oneOnOneSettingID
        frequency
        startDate
        startTime
        status
        agendaTemplateID
        recordingAgendaID
        recordingTarget
        aiSummary
        calendarApp
        eventID
        organizationGroup
        createdAt
        updatedAt
        user1 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        user2 {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        oneOnOneSetting {
          id
          userID1
          userID2
          frequency
          nextOneOnOneDate
          nextOneOnOneTime
          agendaTemplateID
          status
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneList {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOneOnOneSettingMutationVariables,
  APITypes.DeleteOneOnOneSettingMutation
>;
export const createOneOnOne = /* GraphQL */ `mutation CreateOneOnOne(
  $input: CreateOneOnOneInput!
  $condition: ModelOneOnOneConditionInput
) {
  createOneOnOne(input: $input, condition: $condition) {
    id
    userID1
    userID2
    managerID
    oneOnOneSettingID
    frequency
    startDate
    startTime
    status
    agendaTemplateID
    recordingAgendaID
    recordingTarget
    aiSummary
    calendarApp
    eventID
    organizationGroup
    createdAt
    updatedAt
    user1 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    user2 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    oneOnOneSetting {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          calendarApp
          eventID
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOneOnOneMutationVariables,
  APITypes.CreateOneOnOneMutation
>;
export const updateOneOnOne = /* GraphQL */ `mutation UpdateOneOnOne(
  $input: UpdateOneOnOneInput!
  $condition: ModelOneOnOneConditionInput
) {
  updateOneOnOne(input: $input, condition: $condition) {
    id
    userID1
    userID2
    managerID
    oneOnOneSettingID
    frequency
    startDate
    startTime
    status
    agendaTemplateID
    recordingAgendaID
    recordingTarget
    aiSummary
    calendarApp
    eventID
    organizationGroup
    createdAt
    updatedAt
    user1 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    user2 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    oneOnOneSetting {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          calendarApp
          eventID
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOneOnOneMutationVariables,
  APITypes.UpdateOneOnOneMutation
>;
export const deleteOneOnOne = /* GraphQL */ `mutation DeleteOneOnOne(
  $input: DeleteOneOnOneInput!
  $condition: ModelOneOnOneConditionInput
) {
  deleteOneOnOne(input: $input, condition: $condition) {
    id
    userID1
    userID2
    managerID
    oneOnOneSettingID
    frequency
    startDate
    startTime
    status
    agendaTemplateID
    recordingAgendaID
    recordingTarget
    aiSummary
    calendarApp
    eventID
    organizationGroup
    createdAt
    updatedAt
    user1 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    user2 {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    oneOnOneSetting {
      id
      userID1
      userID2
      frequency
      nextOneOnOneDate
      nextOneOnOneTime
      agendaTemplateID
      status
      organizationGroup
      createdAt
      updatedAt
      user1 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      user2 {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      oneOnOneList {
        items {
          id
          userID1
          userID2
          managerID
          oneOnOneSettingID
          frequency
          startDate
          startTime
          status
          agendaTemplateID
          recordingAgendaID
          recordingTarget
          aiSummary
          calendarApp
          eventID
          organizationGroup
          createdAt
          updatedAt
          user1 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          user2 {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          oneOnOneSetting {
            id
            userID1
            userID2
            frequency
            nextOneOnOneDate
            nextOneOnOneTime
            agendaTemplateID
            status
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOneOnOneMutationVariables,
  APITypes.DeleteOneOnOneMutation
>;
export const createShareMemo = /* GraphQL */ `mutation CreateShareMemo(
  $input: CreateShareMemoInput!
  $condition: ModelShareMemoConditionInput
) {
  createShareMemo(input: $input, condition: $condition) {
    id
    oneOnOneID
    ownerID
    memo
    organizationGroup
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShareMemoMutationVariables,
  APITypes.CreateShareMemoMutation
>;
export const updateShareMemo = /* GraphQL */ `mutation UpdateShareMemo(
  $input: UpdateShareMemoInput!
  $condition: ModelShareMemoConditionInput
) {
  updateShareMemo(input: $input, condition: $condition) {
    id
    oneOnOneID
    ownerID
    memo
    organizationGroup
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShareMemoMutationVariables,
  APITypes.UpdateShareMemoMutation
>;
export const deleteShareMemo = /* GraphQL */ `mutation DeleteShareMemo(
  $input: DeleteShareMemoInput!
  $condition: ModelShareMemoConditionInput
) {
  deleteShareMemo(input: $input, condition: $condition) {
    id
    oneOnOneID
    ownerID
    memo
    organizationGroup
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteShareMemoMutationVariables,
  APITypes.DeleteShareMemoMutation
>;
export const createPrivateMemo = /* GraphQL */ `mutation CreatePrivateMemo(
  $input: CreatePrivateMemoInput!
  $condition: ModelPrivateMemoConditionInput
) {
  createPrivateMemo(input: $input, condition: $condition) {
    id
    oneOnOneID
    memo
    ownerID
    organizationGroup
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePrivateMemoMutationVariables,
  APITypes.CreatePrivateMemoMutation
>;
export const updatePrivateMemo = /* GraphQL */ `mutation UpdatePrivateMemo(
  $input: UpdatePrivateMemoInput!
  $condition: ModelPrivateMemoConditionInput
) {
  updatePrivateMemo(input: $input, condition: $condition) {
    id
    oneOnOneID
    memo
    ownerID
    organizationGroup
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrivateMemoMutationVariables,
  APITypes.UpdatePrivateMemoMutation
>;
export const deletePrivateMemo = /* GraphQL */ `mutation DeletePrivateMemo(
  $input: DeletePrivateMemoInput!
  $condition: ModelPrivateMemoConditionInput
) {
  deletePrivateMemo(input: $input, condition: $condition) {
    id
    oneOnOneID
    memo
    ownerID
    organizationGroup
    createdAt
    updatedAt
    owner {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePrivateMemoMutationVariables,
  APITypes.DeletePrivateMemoMutation
>;
export const createGradeMatrix = /* GraphQL */ `mutation CreateGradeMatrix(
  $input: CreateGradeMatrixInput!
  $condition: ModelGradeMatrixConditionInput
) {
  createGradeMatrix(input: $input, condition: $condition) {
    gradeMatrixID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    memberList {
      items {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    competencyAreaList {
      items {
        competencyAreaID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    gradeList {
      items {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    competencyItemList {
      items {
        competencyItemID
        item
        gradeID
        competencyAreaID
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGradeMatrixMutationVariables,
  APITypes.CreateGradeMatrixMutation
>;
export const updateGradeMatrix = /* GraphQL */ `mutation UpdateGradeMatrix(
  $input: UpdateGradeMatrixInput!
  $condition: ModelGradeMatrixConditionInput
) {
  updateGradeMatrix(input: $input, condition: $condition) {
    gradeMatrixID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    memberList {
      items {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    competencyAreaList {
      items {
        competencyAreaID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    gradeList {
      items {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    competencyItemList {
      items {
        competencyItemID
        item
        gradeID
        competencyAreaID
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGradeMatrixMutationVariables,
  APITypes.UpdateGradeMatrixMutation
>;
export const deleteGradeMatrix = /* GraphQL */ `mutation DeleteGradeMatrix(
  $input: DeleteGradeMatrixInput!
  $condition: ModelGradeMatrixConditionInput
) {
  deleteGradeMatrix(input: $input, condition: $condition) {
    gradeMatrixID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    memberList {
      items {
        id
        firstName
        lastName
        account
        owner
        originalPhotoUrl
        thumbnailPhotoUrl
        coverImageUrl
        organizationID
        status
        admin
        sub
        socialType
        drivingScore
        analyticalScore
        amiableScore
        expressiveScore
        createdAt
        updatedAt
        organizationGroup
        adminGroup
        email
        teamID
        managers
        googleRefreshToken
        googleAccountEmail
        about
        delete
        deleteStatus
        employeeId
        teamCode
        readingFirstName
        readingLastName
        birthDay
        gender
        address
        joinedCompanyDay
        retirementDay
        jobType
        phoneNumber
        enrollmentStatus
        recruitmentClassification
        department
        division
        section
        position
        gradeID
        allowance
        bonus
        gradeMatrixID
        myMembers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        myManagers {
          items {
            id
            managerID
            memberID
            organizationGroup
            adminGroup
            createdAt
            updatedAt
            delete
            owner
            __typename
          }
          nextToken
          __typename
        }
        gradeMatrix {
          gradeMatrixID
          title
          description
          organizationGroup
          createdAt
          updatedAt
          memberList {
            nextToken
            __typename
          }
          competencyAreaList {
            nextToken
            __typename
          }
          gradeList {
            nextToken
            __typename
          }
          competencyItemList {
            nextToken
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    competencyAreaList {
      items {
        competencyAreaID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    gradeList {
      items {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    competencyItemList {
      items {
        competencyItemID
        item
        gradeID
        competencyAreaID
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGradeMatrixMutationVariables,
  APITypes.DeleteGradeMatrixMutation
>;
export const createCompetencyArea = /* GraphQL */ `mutation CreateCompetencyArea(
  $input: CreateCompetencyAreaInput!
  $condition: ModelCompetencyAreaConditionInput
) {
  createCompetencyArea(input: $input, condition: $condition) {
    competencyAreaID
    title
    description
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompetencyAreaMutationVariables,
  APITypes.CreateCompetencyAreaMutation
>;
export const updateCompetencyArea = /* GraphQL */ `mutation UpdateCompetencyArea(
  $input: UpdateCompetencyAreaInput!
  $condition: ModelCompetencyAreaConditionInput
) {
  updateCompetencyArea(input: $input, condition: $condition) {
    competencyAreaID
    title
    description
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompetencyAreaMutationVariables,
  APITypes.UpdateCompetencyAreaMutation
>;
export const deleteCompetencyArea = /* GraphQL */ `mutation DeleteCompetencyArea(
  $input: DeleteCompetencyAreaInput!
  $condition: ModelCompetencyAreaConditionInput
) {
  deleteCompetencyArea(input: $input, condition: $condition) {
    competencyAreaID
    title
    description
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompetencyAreaMutationVariables,
  APITypes.DeleteCompetencyAreaMutation
>;
export const createGrade = /* GraphQL */ `mutation CreateGrade(
  $input: CreateGradeInput!
  $condition: ModelGradeConditionInput
) {
  createGrade(input: $input, condition: $condition) {
    gradeID
    title
    description
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGradeMutationVariables,
  APITypes.CreateGradeMutation
>;
export const updateGrade = /* GraphQL */ `mutation UpdateGrade(
  $input: UpdateGradeInput!
  $condition: ModelGradeConditionInput
) {
  updateGrade(input: $input, condition: $condition) {
    gradeID
    title
    description
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGradeMutationVariables,
  APITypes.UpdateGradeMutation
>;
export const deleteGrade = /* GraphQL */ `mutation DeleteGrade(
  $input: DeleteGradeInput!
  $condition: ModelGradeConditionInput
) {
  deleteGrade(input: $input, condition: $condition) {
    gradeID
    title
    description
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGradeMutationVariables,
  APITypes.DeleteGradeMutation
>;
export const createCompetencyItem = /* GraphQL */ `mutation CreateCompetencyItem(
  $input: CreateCompetencyItemInput!
  $condition: ModelCompetencyItemConditionInput
) {
  createCompetencyItem(input: $input, condition: $condition) {
    competencyItemID
    item
    gradeID
    competencyAreaID
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompetencyItemMutationVariables,
  APITypes.CreateCompetencyItemMutation
>;
export const updateCompetencyItem = /* GraphQL */ `mutation UpdateCompetencyItem(
  $input: UpdateCompetencyItemInput!
  $condition: ModelCompetencyItemConditionInput
) {
  updateCompetencyItem(input: $input, condition: $condition) {
    competencyItemID
    item
    gradeID
    competencyAreaID
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompetencyItemMutationVariables,
  APITypes.UpdateCompetencyItemMutation
>;
export const deleteCompetencyItem = /* GraphQL */ `mutation DeleteCompetencyItem(
  $input: DeleteCompetencyItemInput!
  $condition: ModelCompetencyItemConditionInput
) {
  deleteCompetencyItem(input: $input, condition: $condition) {
    competencyItemID
    item
    gradeID
    competencyAreaID
    gradeMatrixID
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompetencyItemMutationVariables,
  APITypes.DeleteCompetencyItemMutation
>;
export const createSkillMap = /* GraphQL */ `mutation CreateSkillMap(
  $input: CreateSkillMapInput!
  $condition: ModelSkillMapConditionInput
) {
  createSkillMap(input: $input, condition: $condition) {
    id
    name
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSkillMapMutationVariables,
  APITypes.CreateSkillMapMutation
>;
export const updateSkillMap = /* GraphQL */ `mutation UpdateSkillMap(
  $input: UpdateSkillMapInput!
  $condition: ModelSkillMapConditionInput
) {
  updateSkillMap(input: $input, condition: $condition) {
    id
    name
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSkillMapMutationVariables,
  APITypes.UpdateSkillMapMutation
>;
export const deleteSkillMap = /* GraphQL */ `mutation DeleteSkillMap(
  $input: DeleteSkillMapInput!
  $condition: ModelSkillMapConditionInput
) {
  deleteSkillMap(input: $input, condition: $condition) {
    id
    name
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSkillMapMutationVariables,
  APITypes.DeleteSkillMapMutation
>;
export const createSkillItem = /* GraphQL */ `mutation CreateSkillItem(
  $input: CreateSkillItemInput!
  $condition: ModelSkillItemConditionInput
) {
  createSkillItem(input: $input, condition: $condition) {
    id
    skillMapID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSkillItemMutationVariables,
  APITypes.CreateSkillItemMutation
>;
export const updateSkillItem = /* GraphQL */ `mutation UpdateSkillItem(
  $input: UpdateSkillItemInput!
  $condition: ModelSkillItemConditionInput
) {
  updateSkillItem(input: $input, condition: $condition) {
    id
    skillMapID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSkillItemMutationVariables,
  APITypes.UpdateSkillItemMutation
>;
export const deleteSkillItem = /* GraphQL */ `mutation DeleteSkillItem(
  $input: DeleteSkillItemInput!
  $condition: ModelSkillItemConditionInput
) {
  deleteSkillItem(input: $input, condition: $condition) {
    id
    skillMapID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSkillItemMutationVariables,
  APITypes.DeleteSkillItemMutation
>;
export const createLevel = /* GraphQL */ `mutation CreateLevel(
  $input: CreateLevelInput!
  $condition: ModelLevelConditionInput
) {
  createLevel(input: $input, condition: $condition) {
    id
    skillMapID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLevelMutationVariables,
  APITypes.CreateLevelMutation
>;
export const updateLevel = /* GraphQL */ `mutation UpdateLevel(
  $input: UpdateLevelInput!
  $condition: ModelLevelConditionInput
) {
  updateLevel(input: $input, condition: $condition) {
    id
    skillMapID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLevelMutationVariables,
  APITypes.UpdateLevelMutation
>;
export const deleteLevel = /* GraphQL */ `mutation DeleteLevel(
  $input: DeleteLevelInput!
  $condition: ModelLevelConditionInput
) {
  deleteLevel(input: $input, condition: $condition) {
    id
    skillMapID
    title
    description
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLevelMutationVariables,
  APITypes.DeleteLevelMutation
>;
export const createSkill = /* GraphQL */ `mutation CreateSkill(
  $input: CreateSkillInput!
  $condition: ModelSkillConditionInput
) {
  createSkill(input: $input, condition: $condition) {
    id
    skillMapID
    levelID
    skillItemID
    outcome
    process
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSkillMutationVariables,
  APITypes.CreateSkillMutation
>;
export const updateSkill = /* GraphQL */ `mutation UpdateSkill(
  $input: UpdateSkillInput!
  $condition: ModelSkillConditionInput
) {
  updateSkill(input: $input, condition: $condition) {
    id
    skillMapID
    levelID
    skillItemID
    outcome
    process
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSkillMutationVariables,
  APITypes.UpdateSkillMutation
>;
export const deleteSkill = /* GraphQL */ `mutation DeleteSkill(
  $input: DeleteSkillInput!
  $condition: ModelSkillConditionInput
) {
  deleteSkill(input: $input, condition: $condition) {
    id
    skillMapID
    levelID
    skillItemID
    outcome
    process
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSkillMutationVariables,
  APITypes.DeleteSkillMutation
>;
export const createIndividualSkill = /* GraphQL */ `mutation CreateIndividualSkill(
  $input: CreateIndividualSkillInput!
  $condition: ModelIndividualSkillConditionInput
) {
  createIndividualSkill(input: $input, condition: $condition) {
    id
    skillMapID
    skillID
    acquisitionStatus
    acquisitionStartDate
    acquisitionEndDate
    userID
    organizationGroup
    createdAt
    updatedAt
    user {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIndividualSkillMutationVariables,
  APITypes.CreateIndividualSkillMutation
>;
export const updateIndividualSkill = /* GraphQL */ `mutation UpdateIndividualSkill(
  $input: UpdateIndividualSkillInput!
  $condition: ModelIndividualSkillConditionInput
) {
  updateIndividualSkill(input: $input, condition: $condition) {
    id
    skillMapID
    skillID
    acquisitionStatus
    acquisitionStartDate
    acquisitionEndDate
    userID
    organizationGroup
    createdAt
    updatedAt
    user {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIndividualSkillMutationVariables,
  APITypes.UpdateIndividualSkillMutation
>;
export const deleteIndividualSkill = /* GraphQL */ `mutation DeleteIndividualSkill(
  $input: DeleteIndividualSkillInput!
  $condition: ModelIndividualSkillConditionInput
) {
  deleteIndividualSkill(input: $input, condition: $condition) {
    id
    skillMapID
    skillID
    acquisitionStatus
    acquisitionStartDate
    acquisitionEndDate
    userID
    organizationGroup
    createdAt
    updatedAt
    user {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIndividualSkillMutationVariables,
  APITypes.DeleteIndividualSkillMutation
>;
export const createReviewResult = /* GraphQL */ `mutation CreateReviewResult(
  $input: CreateReviewResultInput!
  $condition: ModelReviewResultConditionInput
) {
  createReviewResult(input: $input, condition: $condition) {
    reviewResultID
    reviewID
    revieweeID
    finalReview
    nextGradeID
    nextBasicSalary
    salaryIncrease
    bonus
    shareStatus
    openStatus
    calculateResult {
      overallCalculateResult
      averageCalculateResult
      customReviewCalculateResults {
        customReviewID
        result
        average
        __typename
      }
      goalReviewCalculateResult
      goalReviewCalculateAverage
      competencyReviewCalculateResult
      competencyReviewCalculateAverage
      __typename
    }
    organizationGroup
    createdAt
    updatedAt
    reviewee {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    grade {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    owner
    individualReviewSheetList {
      items {
        individualReviewSheetID
        name
        reviewerID
        revieweeID
        reviewOrder
        summary
        responseStage
        reviewID
        organizationGroup
        createdAt
        updatedAt
        reviewAnswerList {
          reviewItemTitle
          description
          customReviewID
          customReviewName
          goalID
          goalProgress
          finalValue
          targetValue
          goalMeasuringType
          goalResultDate
          competencyItemID
          weight
          answer
          point
          answerBasis
          __typename
        }
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewResultMutationVariables,
  APITypes.CreateReviewResultMutation
>;
export const updateReviewResult = /* GraphQL */ `mutation UpdateReviewResult(
  $input: UpdateReviewResultInput!
  $condition: ModelReviewResultConditionInput
) {
  updateReviewResult(input: $input, condition: $condition) {
    reviewResultID
    reviewID
    revieweeID
    finalReview
    nextGradeID
    nextBasicSalary
    salaryIncrease
    bonus
    shareStatus
    openStatus
    calculateResult {
      overallCalculateResult
      averageCalculateResult
      customReviewCalculateResults {
        customReviewID
        result
        average
        __typename
      }
      goalReviewCalculateResult
      goalReviewCalculateAverage
      competencyReviewCalculateResult
      competencyReviewCalculateAverage
      __typename
    }
    organizationGroup
    createdAt
    updatedAt
    reviewee {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    grade {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    owner
    individualReviewSheetList {
      items {
        individualReviewSheetID
        name
        reviewerID
        revieweeID
        reviewOrder
        summary
        responseStage
        reviewID
        organizationGroup
        createdAt
        updatedAt
        reviewAnswerList {
          reviewItemTitle
          description
          customReviewID
          customReviewName
          goalID
          goalProgress
          finalValue
          targetValue
          goalMeasuringType
          goalResultDate
          competencyItemID
          weight
          answer
          point
          answerBasis
          __typename
        }
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewResultMutationVariables,
  APITypes.UpdateReviewResultMutation
>;
export const deleteReviewResult = /* GraphQL */ `mutation DeleteReviewResult(
  $input: DeleteReviewResultInput!
  $condition: ModelReviewResultConditionInput
) {
  deleteReviewResult(input: $input, condition: $condition) {
    reviewResultID
    reviewID
    revieweeID
    finalReview
    nextGradeID
    nextBasicSalary
    salaryIncrease
    bonus
    shareStatus
    openStatus
    calculateResult {
      overallCalculateResult
      averageCalculateResult
      customReviewCalculateResults {
        customReviewID
        result
        average
        __typename
      }
      goalReviewCalculateResult
      goalReviewCalculateAverage
      competencyReviewCalculateResult
      competencyReviewCalculateAverage
      __typename
    }
    organizationGroup
    createdAt
    updatedAt
    reviewee {
      id
      firstName
      lastName
      account
      owner
      originalPhotoUrl
      thumbnailPhotoUrl
      coverImageUrl
      organizationID
      status
      admin
      sub
      socialType
      drivingScore
      analyticalScore
      amiableScore
      expressiveScore
      createdAt
      updatedAt
      organizationGroup
      adminGroup
      email
      teamID
      managers
      googleRefreshToken
      googleAccountEmail
      about
      delete
      deleteStatus
      employeeId
      teamCode
      readingFirstName
      readingLastName
      birthDay
      gender
      address
      joinedCompanyDay
      retirementDay
      jobType
      phoneNumber
      enrollmentStatus
      recruitmentClassification
      department
      division
      section
      position
      gradeID
      allowance
      bonus
      gradeMatrixID
      myMembers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      myManagers {
        items {
          id
          managerID
          memberID
          organizationGroup
          adminGroup
          createdAt
          updatedAt
          delete
          owner
          manager {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          member {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      gradeMatrix {
        gradeMatrixID
        title
        description
        organizationGroup
        createdAt
        updatedAt
        memberList {
          items {
            id
            firstName
            lastName
            account
            owner
            originalPhotoUrl
            thumbnailPhotoUrl
            coverImageUrl
            organizationID
            status
            admin
            sub
            socialType
            drivingScore
            analyticalScore
            amiableScore
            expressiveScore
            createdAt
            updatedAt
            organizationGroup
            adminGroup
            email
            teamID
            managers
            googleRefreshToken
            googleAccountEmail
            about
            delete
            deleteStatus
            employeeId
            teamCode
            readingFirstName
            readingLastName
            birthDay
            gender
            address
            joinedCompanyDay
            retirementDay
            jobType
            phoneNumber
            enrollmentStatus
            recruitmentClassification
            department
            division
            section
            position
            gradeID
            allowance
            bonus
            gradeMatrixID
            __typename
          }
          nextToken
          __typename
        }
        competencyAreaList {
          items {
            competencyAreaID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        gradeList {
          items {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        competencyItemList {
          items {
            competencyItemID
            item
            gradeID
            competencyAreaID
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      grade {
        gradeID
        title
        description
        gradeMatrixID
        organizationGroup
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    grade {
      gradeID
      title
      description
      gradeMatrixID
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    owner
    individualReviewSheetList {
      items {
        individualReviewSheetID
        name
        reviewerID
        revieweeID
        reviewOrder
        summary
        responseStage
        reviewID
        organizationGroup
        createdAt
        updatedAt
        reviewAnswerList {
          reviewItemTitle
          description
          customReviewID
          customReviewName
          goalID
          goalProgress
          finalValue
          targetValue
          goalMeasuringType
          goalResultDate
          competencyItemID
          weight
          answer
          point
          answerBasis
          __typename
        }
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewResultMutationVariables,
  APITypes.DeleteReviewResultMutation
>;
export const createIndividualReviewSheet = /* GraphQL */ `mutation CreateIndividualReviewSheet(
  $input: CreateIndividualReviewSheetInput!
  $condition: ModelIndividualReviewSheetConditionInput
) {
  createIndividualReviewSheet(input: $input, condition: $condition) {
    individualReviewSheetID
    name
    reviewerID
    revieweeID
    reviewOrder
    summary
    responseStage
    reviewID
    organizationGroup
    createdAt
    updatedAt
    reviewAnswerList {
      reviewItemTitle
      description
      customReviewID
      customReviewName
      goalID
      goalProgress
      finalValue
      targetValue
      goalMeasuringType
      goalResultDate
      competencyItemID
      weight
      answer
      point
      answerBasis
      __typename
    }
    calculateResult {
      overallCalculateResult
      averageCalculateResult
      customReviewCalculateResults {
        customReviewID
        result
        average
        __typename
      }
      goalReviewCalculateResult
      goalReviewCalculateAverage
      competencyReviewCalculateResult
      competencyReviewCalculateAverage
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIndividualReviewSheetMutationVariables,
  APITypes.CreateIndividualReviewSheetMutation
>;
export const updateIndividualReviewSheet = /* GraphQL */ `mutation UpdateIndividualReviewSheet(
  $input: UpdateIndividualReviewSheetInput!
  $condition: ModelIndividualReviewSheetConditionInput
) {
  updateIndividualReviewSheet(input: $input, condition: $condition) {
    individualReviewSheetID
    name
    reviewerID
    revieweeID
    reviewOrder
    summary
    responseStage
    reviewID
    organizationGroup
    createdAt
    updatedAt
    reviewAnswerList {
      reviewItemTitle
      description
      customReviewID
      customReviewName
      goalID
      goalProgress
      finalValue
      targetValue
      goalMeasuringType
      goalResultDate
      competencyItemID
      weight
      answer
      point
      answerBasis
      __typename
    }
    calculateResult {
      overallCalculateResult
      averageCalculateResult
      customReviewCalculateResults {
        customReviewID
        result
        average
        __typename
      }
      goalReviewCalculateResult
      goalReviewCalculateAverage
      competencyReviewCalculateResult
      competencyReviewCalculateAverage
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIndividualReviewSheetMutationVariables,
  APITypes.UpdateIndividualReviewSheetMutation
>;
export const deleteIndividualReviewSheet = /* GraphQL */ `mutation DeleteIndividualReviewSheet(
  $input: DeleteIndividualReviewSheetInput!
  $condition: ModelIndividualReviewSheetConditionInput
) {
  deleteIndividualReviewSheet(input: $input, condition: $condition) {
    individualReviewSheetID
    name
    reviewerID
    revieweeID
    reviewOrder
    summary
    responseStage
    reviewID
    organizationGroup
    createdAt
    updatedAt
    reviewAnswerList {
      reviewItemTitle
      description
      customReviewID
      customReviewName
      goalID
      goalProgress
      finalValue
      targetValue
      goalMeasuringType
      goalResultDate
      competencyItemID
      weight
      answer
      point
      answerBasis
      __typename
    }
    calculateResult {
      overallCalculateResult
      averageCalculateResult
      customReviewCalculateResults {
        customReviewID
        result
        average
        __typename
      }
      goalReviewCalculateResult
      goalReviewCalculateAverage
      competencyReviewCalculateResult
      competencyReviewCalculateAverage
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIndividualReviewSheetMutationVariables,
  APITypes.DeleteIndividualReviewSheetMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    reviewID
    name
    revieweeSetting
    revieweeReviewerSettings {
      revieweeID
      gradeID
      firstReviewerIDs
      secondReviewerIDs
      __typename
    }
    reviewTemplateID
    reviewPeriod {
      startDate
      endDate
      __typename
    }
    targetGoalPeriod {
      startDate
      endDate
      __typename
    }
    remindNotificationDate
    isSummary
    isCalculate
    reviewStage
    organizationGroup
    createdAt
    updatedAt
    reviewResultList {
      items {
        reviewResultID
        reviewID
        revieweeID
        finalReview
        nextGradeID
        nextBasicSalary
        salaryIncrease
        bonus
        shareStatus
        openStatus
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        organizationGroup
        createdAt
        updatedAt
        reviewee {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        owner
        individualReviewSheetList {
          items {
            individualReviewSheetID
            name
            reviewerID
            revieweeID
            reviewOrder
            summary
            responseStage
            reviewID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    individualReviewSheetList {
      items {
        individualReviewSheetID
        name
        reviewerID
        revieweeID
        reviewOrder
        summary
        responseStage
        reviewID
        organizationGroup
        createdAt
        updatedAt
        reviewAnswerList {
          reviewItemTitle
          description
          customReviewID
          customReviewName
          goalID
          goalProgress
          finalValue
          targetValue
          goalMeasuringType
          goalResultDate
          competencyItemID
          weight
          answer
          point
          answerBasis
          __typename
        }
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviewTemplate {
      reviewTemplateID
      name
      description
      reviewMethod {
        isSelfReview
        isFirstReview
        isSecondReview
        isFinalReview
        __typename
      }
      reviewSettings {
        customReviewSettings {
          customReviewID
          weight
          __typename
        }
        goalReviewSetting {
          isReviewInclude
          question
          description
          goalRange
          goalStatus
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        competencyReviewSetting {
          isReviewInclude
          question
          description
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        isWeight
        __typename
      }
      calculable
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    reviewID
    name
    revieweeSetting
    revieweeReviewerSettings {
      revieweeID
      gradeID
      firstReviewerIDs
      secondReviewerIDs
      __typename
    }
    reviewTemplateID
    reviewPeriod {
      startDate
      endDate
      __typename
    }
    targetGoalPeriod {
      startDate
      endDate
      __typename
    }
    remindNotificationDate
    isSummary
    isCalculate
    reviewStage
    organizationGroup
    createdAt
    updatedAt
    reviewResultList {
      items {
        reviewResultID
        reviewID
        revieweeID
        finalReview
        nextGradeID
        nextBasicSalary
        salaryIncrease
        bonus
        shareStatus
        openStatus
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        organizationGroup
        createdAt
        updatedAt
        reviewee {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        owner
        individualReviewSheetList {
          items {
            individualReviewSheetID
            name
            reviewerID
            revieweeID
            reviewOrder
            summary
            responseStage
            reviewID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    individualReviewSheetList {
      items {
        individualReviewSheetID
        name
        reviewerID
        revieweeID
        reviewOrder
        summary
        responseStage
        reviewID
        organizationGroup
        createdAt
        updatedAt
        reviewAnswerList {
          reviewItemTitle
          description
          customReviewID
          customReviewName
          goalID
          goalProgress
          finalValue
          targetValue
          goalMeasuringType
          goalResultDate
          competencyItemID
          weight
          answer
          point
          answerBasis
          __typename
        }
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviewTemplate {
      reviewTemplateID
      name
      description
      reviewMethod {
        isSelfReview
        isFirstReview
        isSecondReview
        isFinalReview
        __typename
      }
      reviewSettings {
        customReviewSettings {
          customReviewID
          weight
          __typename
        }
        goalReviewSetting {
          isReviewInclude
          question
          description
          goalRange
          goalStatus
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        competencyReviewSetting {
          isReviewInclude
          question
          description
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        isWeight
        __typename
      }
      calculable
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    reviewID
    name
    revieweeSetting
    revieweeReviewerSettings {
      revieweeID
      gradeID
      firstReviewerIDs
      secondReviewerIDs
      __typename
    }
    reviewTemplateID
    reviewPeriod {
      startDate
      endDate
      __typename
    }
    targetGoalPeriod {
      startDate
      endDate
      __typename
    }
    remindNotificationDate
    isSummary
    isCalculate
    reviewStage
    organizationGroup
    createdAt
    updatedAt
    reviewResultList {
      items {
        reviewResultID
        reviewID
        revieweeID
        finalReview
        nextGradeID
        nextBasicSalary
        salaryIncrease
        bonus
        shareStatus
        openStatus
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        organizationGroup
        createdAt
        updatedAt
        reviewee {
          id
          firstName
          lastName
          account
          owner
          originalPhotoUrl
          thumbnailPhotoUrl
          coverImageUrl
          organizationID
          status
          admin
          sub
          socialType
          drivingScore
          analyticalScore
          amiableScore
          expressiveScore
          createdAt
          updatedAt
          organizationGroup
          adminGroup
          email
          teamID
          managers
          googleRefreshToken
          googleAccountEmail
          about
          delete
          deleteStatus
          employeeId
          teamCode
          readingFirstName
          readingLastName
          birthDay
          gender
          address
          joinedCompanyDay
          retirementDay
          jobType
          phoneNumber
          enrollmentStatus
          recruitmentClassification
          department
          division
          section
          position
          gradeID
          allowance
          bonus
          gradeMatrixID
          myMembers {
            nextToken
            __typename
          }
          myManagers {
            nextToken
            __typename
          }
          gradeMatrix {
            gradeMatrixID
            title
            description
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          grade {
            gradeID
            title
            description
            gradeMatrixID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        grade {
          gradeID
          title
          description
          gradeMatrixID
          organizationGroup
          createdAt
          updatedAt
          __typename
        }
        owner
        individualReviewSheetList {
          items {
            individualReviewSheetID
            name
            reviewerID
            revieweeID
            reviewOrder
            summary
            responseStage
            reviewID
            organizationGroup
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    individualReviewSheetList {
      items {
        individualReviewSheetID
        name
        reviewerID
        revieweeID
        reviewOrder
        summary
        responseStage
        reviewID
        organizationGroup
        createdAt
        updatedAt
        reviewAnswerList {
          reviewItemTitle
          description
          customReviewID
          customReviewName
          goalID
          goalProgress
          finalValue
          targetValue
          goalMeasuringType
          goalResultDate
          competencyItemID
          weight
          answer
          point
          answerBasis
          __typename
        }
        calculateResult {
          overallCalculateResult
          averageCalculateResult
          customReviewCalculateResults {
            customReviewID
            result
            average
            __typename
          }
          goalReviewCalculateResult
          goalReviewCalculateAverage
          competencyReviewCalculateResult
          competencyReviewCalculateAverage
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reviewTemplate {
      reviewTemplateID
      name
      description
      reviewMethod {
        isSelfReview
        isFirstReview
        isSecondReview
        isFinalReview
        __typename
      }
      reviewSettings {
        customReviewSettings {
          customReviewID
          weight
          __typename
        }
        goalReviewSetting {
          isReviewInclude
          question
          description
          goalRange
          goalStatus
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        competencyReviewSetting {
          isReviewInclude
          question
          description
          answerType
          options {
            item
            pointAllocation
            __typename
          }
          numberRange {
            lowerLimit
            upperLimit
            __typename
          }
          isAnswerBasis
          isAnswerBasisEntryOptional
          weight
          __typename
        }
        isWeight
        __typename
      }
      calculable
      organizationGroup
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createNewReviewTemplate = /* GraphQL */ `mutation CreateNewReviewTemplate(
  $input: CreateNewReviewTemplateInput!
  $condition: ModelNewReviewTemplateConditionInput
) {
  createNewReviewTemplate(input: $input, condition: $condition) {
    reviewTemplateID
    name
    description
    reviewMethod {
      isSelfReview
      isFirstReview
      isSecondReview
      isFinalReview
      __typename
    }
    reviewSettings {
      customReviewSettings {
        customReviewID
        weight
        __typename
      }
      goalReviewSetting {
        isReviewInclude
        question
        description
        goalRange
        goalStatus
        answerType
        options {
          item
          pointAllocation
          __typename
        }
        numberRange {
          lowerLimit
          upperLimit
          __typename
        }
        isAnswerBasis
        isAnswerBasisEntryOptional
        weight
        __typename
      }
      competencyReviewSetting {
        isReviewInclude
        question
        description
        answerType
        options {
          item
          pointAllocation
          __typename
        }
        numberRange {
          lowerLimit
          upperLimit
          __typename
        }
        isAnswerBasis
        isAnswerBasisEntryOptional
        weight
        __typename
      }
      isWeight
      __typename
    }
    calculable
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNewReviewTemplateMutationVariables,
  APITypes.CreateNewReviewTemplateMutation
>;
export const updateNewReviewTemplate = /* GraphQL */ `mutation UpdateNewReviewTemplate(
  $input: UpdateNewReviewTemplateInput!
  $condition: ModelNewReviewTemplateConditionInput
) {
  updateNewReviewTemplate(input: $input, condition: $condition) {
    reviewTemplateID
    name
    description
    reviewMethod {
      isSelfReview
      isFirstReview
      isSecondReview
      isFinalReview
      __typename
    }
    reviewSettings {
      customReviewSettings {
        customReviewID
        weight
        __typename
      }
      goalReviewSetting {
        isReviewInclude
        question
        description
        goalRange
        goalStatus
        answerType
        options {
          item
          pointAllocation
          __typename
        }
        numberRange {
          lowerLimit
          upperLimit
          __typename
        }
        isAnswerBasis
        isAnswerBasisEntryOptional
        weight
        __typename
      }
      competencyReviewSetting {
        isReviewInclude
        question
        description
        answerType
        options {
          item
          pointAllocation
          __typename
        }
        numberRange {
          lowerLimit
          upperLimit
          __typename
        }
        isAnswerBasis
        isAnswerBasisEntryOptional
        weight
        __typename
      }
      isWeight
      __typename
    }
    calculable
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNewReviewTemplateMutationVariables,
  APITypes.UpdateNewReviewTemplateMutation
>;
export const deleteNewReviewTemplate = /* GraphQL */ `mutation DeleteNewReviewTemplate(
  $input: DeleteNewReviewTemplateInput!
  $condition: ModelNewReviewTemplateConditionInput
) {
  deleteNewReviewTemplate(input: $input, condition: $condition) {
    reviewTemplateID
    name
    description
    reviewMethod {
      isSelfReview
      isFirstReview
      isSecondReview
      isFinalReview
      __typename
    }
    reviewSettings {
      customReviewSettings {
        customReviewID
        weight
        __typename
      }
      goalReviewSetting {
        isReviewInclude
        question
        description
        goalRange
        goalStatus
        answerType
        options {
          item
          pointAllocation
          __typename
        }
        numberRange {
          lowerLimit
          upperLimit
          __typename
        }
        isAnswerBasis
        isAnswerBasisEntryOptional
        weight
        __typename
      }
      competencyReviewSetting {
        isReviewInclude
        question
        description
        answerType
        options {
          item
          pointAllocation
          __typename
        }
        numberRange {
          lowerLimit
          upperLimit
          __typename
        }
        isAnswerBasis
        isAnswerBasisEntryOptional
        weight
        __typename
      }
      isWeight
      __typename
    }
    calculable
    organizationGroup
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNewReviewTemplateMutationVariables,
  APITypes.DeleteNewReviewTemplateMutation
>;
export const createCustomReview = /* GraphQL */ `mutation CreateCustomReview(
  $input: CreateCustomReviewInput!
  $condition: ModelCustomReviewConditionInput
) {
  createCustomReview(input: $input, condition: $condition) {
    customReviewID
    name
    isWeight
    customReviewItemList {
      name
      weight
      __typename
    }
    question
    description
    answerType
    organizationGroup
    options {
      item
      pointAllocation
      __typename
    }
    numberRange {
      lowerLimit
      upperLimit
      __typename
    }
    isAnswerBasis
    isAnswerBasisEntryOptional
    calculable
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomReviewMutationVariables,
  APITypes.CreateCustomReviewMutation
>;
export const updateCustomReview = /* GraphQL */ `mutation UpdateCustomReview(
  $input: UpdateCustomReviewInput!
  $condition: ModelCustomReviewConditionInput
) {
  updateCustomReview(input: $input, condition: $condition) {
    customReviewID
    name
    isWeight
    customReviewItemList {
      name
      weight
      __typename
    }
    question
    description
    answerType
    organizationGroup
    options {
      item
      pointAllocation
      __typename
    }
    numberRange {
      lowerLimit
      upperLimit
      __typename
    }
    isAnswerBasis
    isAnswerBasisEntryOptional
    calculable
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomReviewMutationVariables,
  APITypes.UpdateCustomReviewMutation
>;
export const deleteCustomReview = /* GraphQL */ `mutation DeleteCustomReview(
  $input: DeleteCustomReviewInput!
  $condition: ModelCustomReviewConditionInput
) {
  deleteCustomReview(input: $input, condition: $condition) {
    customReviewID
    name
    isWeight
    customReviewItemList {
      name
      weight
      __typename
    }
    question
    description
    answerType
    organizationGroup
    options {
      item
      pointAllocation
      __typename
    }
    numberRange {
      lowerLimit
      upperLimit
      __typename
    }
    isAnswerBasis
    isAnswerBasisEntryOptional
    calculable
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomReviewMutationVariables,
  APITypes.DeleteCustomReviewMutation
>;
