import { useQuery, gql, useMutation } from '@apollo/client';
import { getOrganization, listOrganizations, listOrganizationsByName } from 'src/graphql/queries';
import {
  CreateCognitoGroupMutation,
  CreateCognitoGroupMutationVariables,
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  ListOrganizationsByNameQuery,
  ListOrganizationsByNameQueryVariables,
  ListOrganizationsQuery,
  ListOrganizationsQueryVariables,
  Organization,
} from 'src/API';
import {
  createCognitoGroup,
  createOrganization,
  deleteCognitoGroup,
  deleteOrganization,
  updateOrganization,
} from 'src/graphql/mutations';
import { useContext, useState } from 'react';
import { AuthContext } from 'src/contexts/AmplifyAuthContext';
import { useError } from 'src/contexts/ErrorContext';
import { API } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { nonNullable } from 'src/utils/typeUtil';

// ----------------------------------------------------------------------
export const useListOrganizations = () => {
  const [loading, setLoading] = useState(false);
  const { setErrorSnackbar } = useError();

  const listOrganization = async () => {
    setLoading(true);

    try {
      const { data } = await API.graphql<GraphQLQuery<ListOrganizationsQuery>>({
        query: listOrganizations,
      });

      const items = data?.listOrganizations?.items.filter(nonNullable) ?? ([] as Organization[]);
      return {
        items,
        nextToken: data?.listOrganizations?.nextToken,
      };
    } catch (e) {
      console.error('組織の取得に失敗しました', e);
      setErrorSnackbar();
    } finally {
      setLoading(false);
    }
  };

  return {
    listOrganization,
    loading,
  };
};

export const useOrganizationsQuery = () => {
  const organizationRes = useQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(
    gql(listOrganizations),
    {
      variables: {
        limit: 1000,
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const organizations = organizationRes.data?.listOrganizations?.items.filter(
    (v): v is Organization => v !== null
  );

  return { organizations, ...organizationRes };
};

export const useCurrentOrganizationsQuery = (name?: string) => {
  const context = useContext(AuthContext);
  const organizationName = name ? name : context?.organization;

  const organizationRes = useQuery<
    ListOrganizationsByNameQuery,
    ListOrganizationsByNameQueryVariables
  >(gql(listOrganizationsByName), {
    variables: {
      name: organizationName,
      limit: 1,
    },
    skip: !organizationName,
    notifyOnNetworkStatusChange: true,
  });
  const organization = organizationRes.data?.listOrganizationsByName?.items[0]
    ? organizationRes.data?.listOrganizationsByName?.items[0]
    : null;

  return { organization, ...organizationRes };
};

export const useOrganizationQuery = (organizationId?: string | null) => {
  const organizationRes = useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    gql(getOrganization),
    {
      variables: { id: organizationId ?? '' },
      skip: !organizationId,
      notifyOnNetworkStatusChange: true,
    }
  );

  const organization = organizationRes.data?.getOrganization;

  return { organization, ...organizationRes };
};

// ----------------------------------------------------------------------

export const useOrganizationUpdateMutation = (refetch?: { refetchQueries: any[] }) => {
  const organizationMutation = useMutation(gql(updateOrganization), refetch);
  return organizationMutation;
};

export const useOrganizationCreateMutation = (refetch?: { refetchQueries: any[] }) => {
  const organizationMutation = useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(gql(createOrganization), refetch);
  return organizationMutation;
};

export const useCognitoOrganizationCreateMutation = (refetch?: { refetchQueries: any[] }) => {
  const organizationMutation = useMutation<
    CreateCognitoGroupMutation,
    CreateCognitoGroupMutationVariables
  >(gql(createCognitoGroup), refetch);
  return organizationMutation;
};

export const useOrganizationDeleteMutation = (refetch?: { refetchQueries: any[] }) => {
  const organizationMutation = useMutation(gql(deleteOrganization), refetch);
  return organizationMutation;
};

export const useCognitoOrganizationDeleteMutation = (refetch?: { refetchQueries: any[] }) => {
  const organizationMutation = useMutation(gql(deleteCognitoGroup), refetch);
  return organizationMutation;
};

// ----------------------------------------------------------------------
