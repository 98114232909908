import { useQuery, gql, useMutation } from '@apollo/client';
import {
  createCognitoUser,
  createImportedUsers,
  createUser,
  deleteCognitoUser,
  deleteUser,
  deleteUserTrigger,
  sendEmail,
  updateUser,
} from 'src/graphql/mutations';
import {
  CreateCognitoUserMutation,
  CreateCognitoUserMutationVariables,
  CreateImportedUsersMutation,
  CreateImportedUsersMutationVariables,
  CreateUserMutation,
  CreateUserMutationVariables,
  DeleteStatus,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  DeleteUserTriggerMutation,
  DeleteUserTriggerMutationVariables,
  GetUserQuery,
  GetUserQueryVariables,
  ListUserByOrganizationIDQuery,
  ListUserByOrganizationIDQueryVariables,
  SendEmailMutation,
  SendEmailMutationVariables,
  UpdateUserInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
  UserStatus,
} from 'src/API';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts/AmplifyAuthContext';
import { nonNullable } from 'src/utils/typeUtil';
import { getUser, listUserByOrganizationID } from 'src/graphql/queries';
import { useCurrentOrganizationsQuery } from '../organization';
import { showUserFullName } from 'src/utils/showUserFullName';
import { addToGroup, removeUserFromGroup } from 'src/utils/apiTypeUtil';
import { useMemberManagerDeleteMutation } from '../memberManager';
import { logError } from 'src/utils/log';
import { useError } from 'src/contexts/ErrorContext';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { GraphQLQuery } from '@aws-amplify/api';
import * as mutations from 'coteam4/src/graphql/mutations';

//該当するユーザーを取得する
export const useGetUser = (userID: string) => {
  const { setErrorSnackbar } = useError();
  const [user, setUser] = useState<User>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!userID) return;

    const fetchUser = async () => {
      setLoading(true);
      try {
        const variables: GetUserQueryVariables = {
          id: userID,
        };
        const userData = (await API.graphql(
          graphqlOperation(getUser, variables)
        )) as GraphQLResult<GetUserQuery>;
        if (userData.data?.getUser) {
          setUser(userData.data.getUser as User);
        } else {
          console.error('ユーザーデータが存在しません', userData);
          setErrorSnackbar();
        }
      } catch (error) {
        console.error('ユーザー取得エラー', error);
        setErrorSnackbar();
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  return { user, setUser, loading };
};

// 組織ごとにユーザーをリスト
export const useListUsersByOrganizationID = () => {
  const { setErrorSnackbar } = useError();
  const [users, setUsers] = useState<User[]>([]);

  const {
    organization,
    loading: organizationLoading,
    error: organizationError,
  } = useCurrentOrganizationsQuery();

  const {
    data,
    loading: userLoading,
    error: userError,
  } = useQuery<ListUserByOrganizationIDQuery, ListUserByOrganizationIDQueryVariables>(
    gql(listUserByOrganizationID),
    {
      variables: {
        organizationID: organization?.id ?? '',
        limit: 1000,
      },
      skip: !organization?.id,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  );

  const errors = organizationError || userError;

  useEffect(() => {
    if (errors) {
      console.error('ユーザー取得エラー', errors);
      setErrorSnackbar();
    }

    const state = (data?.listUserByOrganizationID?.items.filter(nonNullable) ?? []) as User[];

    if (organization?.name === 'SELF-PRODUCE') {
      const notDeletedUsers = state.filter((user) => user.deleteStatus !== DeleteStatus.deleted);
      setUsers(notDeletedUsers);
      return;
    }

    setUsers(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, errors]);

  const loading = organizationLoading || userLoading;

  return { users, loading };
};

// 組織に属する招待済みユーザーをリスト（できたらGSI?）
export const useListActiveUsersByOrganizationID = () => {
  const {
    organization,
    loading: organizationLoading,
    error: organizationError,
  } = useCurrentOrganizationsQuery();

  const listUserRes = useQuery<
    ListUserByOrganizationIDQuery,
    ListUserByOrganizationIDQueryVariables
  >(gql(listUserByOrganizationID), {
    variables: {
      organizationID: organization?.id ?? '',
      limit: 1000,
    },
    skip: !organization,
    notifyOnNetworkStatusChange: true,
  });
  const users = (listUserRes.data?.listUserByOrganizationID?.items
    .filter(nonNullable)
    .filter((user) => user.status === UserStatus.ActivateComplete) ?? []) as User[];
  const loading = listUserRes.loading || organizationLoading;
  const error = listUserRes.error || organizationError;

  return { users, loading, error };
};

// 組織に属する招待済みかつ削除されていないユーザーをリスト（できたらGSI?）
export const useListActiveAndNotDeletedUsers = () => {
  const {
    organization,
    loading: organizationLoading,
    error: organizationError,
  } = useCurrentOrganizationsQuery();

  const listUserRes = useQuery<
    ListUserByOrganizationIDQuery,
    ListUserByOrganizationIDQueryVariables
  >(gql(listUserByOrganizationID), {
    variables: {
      organizationID: organization?.id ?? '',
      limit: 1000,
    },
    skip: !organization,
    notifyOnNetworkStatusChange: true,
  });
  const users = (listUserRes.data?.listUserByOrganizationID?.items
    .filter(nonNullable)
    .filter(
      (user) =>
        user.status === UserStatus.ActivateComplete && user.deleteStatus === DeleteStatus.notDeleted
    ) ?? []) as User[];
  const loading = listUserRes.loading || organizationLoading;
  const error = listUserRes.error || organizationError;

  return { users, loading, error };
};

// 渡されたユーザーIDと合致するアイテムを取得してくる
export const useUserQuery = (userId?: string) => {
  const userRes = useQuery<GetUserQuery, GetUserQueryVariables>(gql(getUser), {
    variables: { id: userId ?? '' },
    // skip: !userId,
    // notifyOnNetworkStatusChange: true,
  });

  const user = userRes?.data?.getUser as User | null | undefined;

  return { user, ...userRes };
};

export const useUserManagersQuery = (userId?: string) => {
  const { user, ...userQuery } = useUserQuery(userId);

  const userManagers = user?.myManagers?.items.map((memberManager) => memberManager?.manager);

  return { userManagers, ...userQuery };
};
export const useUserMembersQuery = (userId?: string) => {
  const { user, ...userQuery } = useUserQuery(userId);

  const userMembers = user?.myMembers?.items.map((member) => member?.member);

  return { userMembers, ...userQuery };
};

// ----------------------------------------------------------------------

export const updateUserService = async (input: UpdateUserInput) => {
  const variables: UpdateUserMutationVariables = { input };
  return API.graphql<GraphQLQuery<UpdateUserMutation>>({
    query: mutations.updateUser,
    variables,
  });
};

export const useUserUpdateMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    gql(updateUser),
    refetch
  );

  return userMutation;
};

export const useUserCreateMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation<CreateUserMutation, CreateUserMutationVariables>(
    gql(createUser),
    refetch
  );
  return userMutation;
};

export const useUserDeleteMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    gql(deleteUser),
    refetch
  );
  return userMutation;
};

// ----------------------------------------------------------------------

export const useCognitoUserCreateMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation<CreateCognitoUserMutation, CreateCognitoUserMutationVariables>(
    gql(createCognitoUser),
    refetch
  );
  return userMutation;
};

export const useImportedUsersCreateMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation<
    CreateImportedUsersMutation,
    CreateImportedUsersMutationVariables
  >(gql(createImportedUsers), refetch);
  return userMutation;
};

export const useCognitoUserDeleteMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation(gql(deleteCognitoUser), refetch);
  return userMutation;
};

export const useDeleteUserTriggerMutation = (refetch?: { refetchQueries: any[] }) => {
  const deleteUserTriggerMutation = useMutation<
    DeleteUserTriggerMutation,
    DeleteUserTriggerMutationVariables
  >(gql(deleteUserTrigger), refetch);
  return deleteUserTriggerMutation;
};

// ----------------------------------------------------------------------

/**
 * 派生パターン
 */
export const useCurrentUserQuery = (refetch?: { refetchQueries: any[] }) => {
  const context = useContext(AuthContext);
  const { user: currentUser, ...rest } = useUserQuery(context?.user?.username);
  const { setErrorSnackbar } = useError();

  if (rest.error) {
    setErrorSnackbar();
  }
  return { currentUser, ...rest };
};

export const useCognitoResentEmailMutation = (refetch?: { refetchQueries: any[] }) => {
  const userMutation = useMutation<SendEmailMutation, SendEmailMutationVariables>(
    gql(sendEmail),
    refetch
  );
  return userMutation;
};

export const useUsersByTeamIDQuery = (teamID?: string) => {
  const { users, loading, error } = useListActiveUsersByOrganizationID();

  const usersByTeamID = users?.filter((user) => user.teamID === teamID);

  const teamUsers = usersByTeamID?.map((user) => ({
    fullName: showUserFullName(user),
    statusActions:
      user.status === UserStatus.Invited
        ? 'CancelInvitation'
        : user.admin
        ? 'removeAdmin'
        : 'addAdmin',
    managerItems: user.myManagers?.items.map((memberManager) => ({
      id: memberManager?.id,
      fullName: showUserFullName(memberManager?.manager),
      thumbnailPhotoUrl: memberManager?.manager.thumbnailPhotoUrl,
    })),
    ...user,
  }));

  return {
    teamUsers,
    loading,
    error,
  };
};

export const useUserChangePermissionToAdminMutation = () => {
  const [updateUser, updateUserMutation] = useUserUpdateMutation();

  const changePermissionToAdmin = async (user: User) => {
    const tasks = [];
    tasks.push(updateUser({ variables: { input: { id: user.id, admin: true } } }));

    tasks.push(addToGroup(user.id, user.adminGroup));
    tasks.push(addToGroup(user.id, 'Admins'));
    await Promise.all(tasks);
  };

  const errors = updateUserMutation.error;
  const loading = updateUserMutation.loading;

  return {
    changePermissionToAdmin,
    errors,
    loading,
  };
};

export const useUserChangePermissionToMemberMutation = () => {
  const [updateUser, updateUserMutation] = useUserUpdateMutation();

  const changePermissionToMember = async (user: User) => {
    const tasks = [];
    tasks.push(updateUser({ variables: { input: { id: user.id, admin: false } } }));

    tasks.push(removeUserFromGroup(user.id, user.adminGroup));
    tasks.push(removeUserFromGroup(user.id, 'Admins'));
    await Promise.all(tasks);
  };

  const errors = updateUserMutation.error;
  const loading = updateUserMutation.loading;

  return {
    changePermissionToMember,
    errors,
    loading,
  };
};

export const useUserChangeDeleteMutation = () => {
  const [updateUser, updateUserMutation] = useUserUpdateMutation();

  const userChangeDeleteToTrue = async (user: User) => {
    const tasks = [];
    tasks.push(
      updateUser({ variables: { input: { id: user.id, deleteStatus: DeleteStatus.deleted } } })
    );
    await Promise.all(tasks);
  };

  const errors = updateUserMutation.error;
  const loading = updateUserMutation.loading;

  return {
    userChangeDeleteToTrue,
    errors,
    loading,
  };
};

export const useMultiUserChangeDeleteMutation = () => {
  const [updateUser, updateUserMutation] = useUserUpdateMutation();

  const multiUserChangeDeleteToTrue = async (users: User[]) => {
    const deleteUserIds = users.map((user) => user.id);
    await Promise.all(
      deleteUserIds.map((userId) =>
        updateUser({ variables: { input: { id: userId, deleteStatus: 'deleted' } } })
      )
    );
  };

  const errors = updateUserMutation.error;
  const loading = updateUserMutation.loading;

  return {
    multiUserChangeDeleteToTrue,
    errors,
    loading,
  };
};

export const useDeleteUserTrigger = () => {
  const [deleteUserTrigger, deleteUserTriggerMutation] = useDeleteUserTriggerMutation({
    refetchQueries: ['ListFullUsers', 'GetUser'],
  });
  const { setErrorSnackbar } = useError();
  const logicalDeleteUsers = async (userIDs: string[]) => {
    await deleteUserTrigger({ variables: { userIDs } });
    if (error) {
      setErrorSnackbar();
      return;
    }
  };

  const loading = deleteUserTriggerMutation.loading;
  const error = deleteUserTriggerMutation.error;

  return {
    logicalDeleteUsers,
    error,
    loading,
  };
};

export const useUserAndMemberManagerDeleteMutation = () => {
  const [deleteUser, deleteUserMutation] = useUserDeleteMutation({
    refetchQueries: ['ListFullUsers'],
  });
  const [deleteCognitoUser, deleteCognitoUserMutation] = useCognitoUserDeleteMutation();
  const [deleteMemberManager, deleteMemberManagerMutation] = useMemberManagerDeleteMutation({
    refetchQueries: ['ListMemberManagerByAdminGroup'],
  });

  const deleteUserAndMemberManager = async (user: User) => {
    const tasks: Promise<any>[] = [];
    const memberManagerIds: string[] = [];

    user?.myManagers?.items.forEach((memberManager) => {
      if (memberManager) {
        memberManagerIds.push(memberManager?.id);
      }
    });
    user?.myMembers?.items.forEach((memberManager) => {
      if (memberManager) {
        memberManagerIds.push(memberManager?.id);
      }
    });

    memberManagerIds.forEach((memberManagerId) => {
      tasks.push(deleteMemberManager({ variables: { input: { id: memberManagerId } } }));
    });

    await Promise.all(tasks);

    try {
      await deleteCognitoUser({ variables: { username: user.id } });
    } catch (e) {
      //cognitoのデータが存在していない場合はエラー握り潰す
      //Sentryには通知する
      logError(`failed to delete userID: ${user.id} ${e}`);
    }

    await deleteUser({ variables: { input: { id: user.id } } });
  };

  const errors =
    deleteUserMutation.error ||
    deleteCognitoUserMutation.error ||
    deleteMemberManagerMutation.error;
  const loading =
    deleteUserMutation.loading ||
    deleteCognitoUserMutation.loading ||
    deleteMemberManagerMutation.loading;

  return {
    deleteUserAndMemberManager,
    errors,
    loading,
  };
};

export const useMultiUserAndMemberManagerDeleteMutation = () => {
  const [deleteUser, deleteUserMutation] = useUserDeleteMutation({
    refetchQueries: ['ListFullUsers'],
  });
  const [deleteCognitoUser, deleteCognitoUserMutation] = useCognitoUserDeleteMutation();
  const [deleteMemberManager, deleteMemberManagerMutation] = useMemberManagerDeleteMutation({
    refetchQueries: ['ListMemberManagerByAdminGroup'],
  });

  const deleteMultiUserAndMemberManager = async (users: User[]) => {
    const tasks: Promise<any>[] = [];
    const memberManagerIds: string[] = [];

    const deleteUserIds = users.map((user) => user.id);

    users.forEach((user) => {
      user.myManagers?.items.forEach((memberManager) => {
        if (memberManager) {
          memberManagerIds.push(memberManager?.id);
        }
      });
    });
    users.forEach((user) => {
      user.myMembers?.items.forEach((memberManager) => {
        if (memberManager) {
          memberManagerIds.push(memberManager.id);
        }
      });
    });

    memberManagerIds.forEach((memberManagerId) => {
      tasks.push(deleteMemberManager({ variables: { input: { id: memberManagerId } } }));
    });

    await Promise.all(tasks);

    await Promise.allSettled(
      deleteUserIds.map((userId) => {
        try {
          deleteCognitoUser({ variables: { username: userId } });
          return true;
        } catch (e) {
          //cognitoのデータが存在していない場合はエラー握り潰す
          logError(`failed to delete userID: ${userId} ${e}`);

          return false;
        }
      })
    );

    await Promise.all(
      deleteUserIds.map((userId) =>
        deleteUser({
          variables: { input: { id: userId } },
        })
      )
    );
  };

  const errors =
    deleteUserMutation.error ||
    deleteCognitoUserMutation.error ||
    deleteMemberManagerMutation.error;
  const loading =
    deleteUserMutation.loading ||
    deleteCognitoUserMutation.loading ||
    deleteMemberManagerMutation.loading;

  return {
    deleteMultiUserAndMemberManager,
    errors,
    loading,
  };
};

export const useGet1on1UserByPartnerUserID = (partnerUserId: string) => {
  const {
    currentUser,
    loading: currentUserLoading,
    error: currentUserError,
  } = useCurrentUserQuery();
  const {
    user: partnerUser,
    loading: partnerUserLoading,
    error: partnerUserError,
  } = useUserQuery(partnerUserId);
  const {
    userManagers: myManagers,
    loading: myManagersLoading,
    error: myManagersError,
  } = useUserManagersQuery(currentUser?.id);

  const {
    userManagers: partnerUserManagers,
    loading: partnerUserManagersLoading,
    error: partnerUserManagersError,
  } = useUserManagersQuery(partnerUserId);

  // 1on1相手がマネージャーかチェック
  let findManager = myManagers?.find((user) => user?.id === partnerUserId);

  if (findManager) {
    return {
      userInfo: {
        member: currentUser,
        manager: partnerUser,
        individualMeeting: false,
      },
      loading:
        myManagersLoading || partnerUserManagersLoading || currentUserLoading || partnerUserLoading,
      error: myManagersError || partnerUserManagersError || currentUserError || partnerUserError,
    };
  } else {
    // 自分がマネージャーかチェック
    findManager = partnerUserManagers?.find((user) => user?.id === currentUser?.id);

    return {
      userInfo: {
        member: partnerUser,
        manager: currentUser,
        individualMeeting: !findManager,
      },
      loading:
        myManagersLoading || partnerUserManagersLoading || currentUserLoading || partnerUserLoading,
      error: myManagersError || partnerUserManagersError || currentUserError || partnerUserError,
    };
  }
};
export const useActivateUserMutation = () => {
  const [updateUser, updateUserMutation] = useUserUpdateMutation();

  const activateUser = async (userID: string) => {
    const tasks = [];

    tasks.push(
      updateUser({ variables: { input: { id: userID, deleteStatus: DeleteStatus.notDeleted } } })
    );
    await Promise.all(tasks);
  };

  const error = updateUserMutation.error;
  const loading = updateUserMutation.loading;

  return {
    activateUser,
    error,
    loading,
  };
};
