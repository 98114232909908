import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Skeleton(theme: Theme) {
  return {
    MuiSkeleton: {
      defaultProps: {
        variant: 'wave',
      },
      styleOverrides: {
        rectangular: {
          borderRadius: '5px',
        },
        wave: {
          borderRadius: '5px',
        },
      },
    },
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.secondary,
      },
    },
  };
}
