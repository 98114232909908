import { Auth } from 'aws-amplify';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  DeleteStatus,
  GetUserQuery,
  ListOrganizationsByNameQuery,
  ListOrganizationsByNameQueryVariables,
  ModelSortDirection,
  User,
  UserStatus,
} from 'src/API';

//
// import { AuthContext } from '../contexts/JWTContext';
// import { AuthContext } from '../contexts/Auth0Context';
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/AwsCognitoContext';
import { AuthContext } from 'src/contexts/AmplifyAuthContext';
import appSlice from 'src/redux/slices/appSlice';
import { matchGroup, keyQuery, getQuery, addToGroup } from 'src/utils/apiTypeUtil';
import { useUserCreateMutation, useUserUpdateMutation } from './api/user';
// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const [createUser] = useUserCreateMutation({
    refetchQueries: ['ListFullUsers'],
  });

  const [updateUser] = useUserUpdateMutation({
    refetchQueries: ['ListFullUsers'],
  });

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  const checkUser = useCallback(
    async (cognito) => {
      if (cognito?.username) {
        const isGlobalAdmin = await matchGroup('GlobalAdmins');
        const isAdmin = await matchGroup('Admins');
        const isAdminGroup = await matchGroup(`admin${cognito.attributes['custom:organization']}`);
        const getUser = await getQuery<GetUserQuery>('user', cognito.username, true);
        const user = getUser?.data?.getUser;

        if (isGlobalAdmin !== null && isAdmin !== null) {
          dispatch(appSlice.actions.setAdmin({ isGlobalAdmin, isAdmin }));
        }
        if (cognito.username === 'globalAdmin') {
          if (isGlobalAdmin && isAdmin) {
            dispatch(appSlice.actions.setGlobalMode(isGlobalAdmin));
            dispatch(appSlice.actions.setUser(null));
          } else {
            throw new Error('globalAdmin に権限がありません。');
          }
        }
        //管理者の場合AdminsとAdminGroupはセットで所属している必要がある
        //Adminsには所属しているがadminGroupには所属していない場合
        if (isAdmin && !isAdminGroup) {
          await addToGroup(
            cognito.username ?? '',
            `admin${cognito.attributes['custom:organization']}`
          );
        }
        if (cognito?.username) {
          const res = await keyQuery<
            ListOrganizationsByNameQuery,
            ListOrganizationsByNameQueryVariables
          >('listOrganizationsByName', {
            name: cognito.attributes['custom:organization'],
            limit: 1000,
            sortDirection: ModelSortDirection.DESC,
          });

          const organizations = res?.data?.listOrganizationsByName?.items;

          if (!organizations || !organizations[0]) {
            await Auth.signOut();
            return null;
          }

          const organization = organizations[0];

          if (organization) {
            dispatch(appSlice.actions.setOrganization(organization));
          }

          //通常ログイン時
          if (user) {
            if (user.deleteStatus === DeleteStatus.deleted) {
              dispatch(
                appSlice.actions.setSnackbarMessage({
                  message:
                    'ログインに失敗しました。このアカウントは管理者によって停止されています。',
                  type: 'error',
                })
              );
              return null;
            }
            if (user.status === UserStatus.Invited) {
              updateUser({
                variables: {
                  input: {
                    id: user.id,
                    status: UserStatus.ActivateComplete,
                    updatedAt: new Date().toISOString(),
                  },
                },
              });
            }

            dispatch(appSlice.actions.setUser(user as User));

            return user;

            //mock時cognitoにはユーザーが作成済みの場合
          } else if (!user) {
            const createUserResponse = await createUser({
              variables: {
                input: {
                  id: cognito.username,
                  account: cognito.username,
                  sub: cognito?.attributes?.sub,
                  organizationID: organization.id,
                  organizationGroup: organization.organizationGroup,
                  adminGroup: organization.adminGroup,
                  email: cognito.attributes.email,
                  //実装ミスによりカスタム属性に姓名が設定されているユーザーと標準属性に姓名が設定されているユーザーが混在しているためいずれの場合にも対応
                  firstName:
                    cognito.attributes.given_name ?? cognito.attributes['custom:firstName'],
                  lastName: cognito.attributes.family_name ?? cognito.attributes['custom:lastName'],
                  admin: isAdmin,
                  status: cognito.attributes['custom:teamID']
                    ? UserStatus.ActivateComplete
                    : UserStatus.Invited,
                  teamID: cognito.attributes['custom:teamID'],
                  deleteStatus: DeleteStatus.notDeleted,
                },
              },
            });
            const createData = createUserResponse.data?.createUser;

            if (createData) {
              dispatch(appSlice.actions.setUser(createData as User));
            }
            return createData;
          }
        }
      }
    },
    // TODO lintエラーを解消する
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const initialUser = useCallback(async () => {
    const user = context?.user;
    if (user) {
      await checkUser(user);
    }
  }, [checkUser, context?.user]);

  useEffect(() => {
    initialUser();
  }, [context.user, initialUser]);

  return { ...context };
};

export default useAuth;
