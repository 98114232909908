/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type StartOneOnOneInput = {
  userID1: string,
  userID2: string,
  managerID?: string | null,
  startDate: string,
  startTime: string,
  agendaTemplateID?: string | null,
  organizationGroup: string,
  frequency: Frequency,
  calendarApp?: CalendarApp | null,
  eventID?: string | null,
};

export enum Frequency {
  Weekly = "Weekly",
  Biweekly = "Biweekly",
  Monthly = "Monthly",
  Once = "Once",
}


export enum CalendarApp {
  Outlook = "Outlook",
  Google = "Google",
}


export type StartOneOnOnePayload = {
  __typename: "StartOneOnOnePayload",
  setting: OneOnOneSetting,
  oneOnOne: OneOnOne,
};

export type OneOnOneSetting = {
  __typename: "OneOnOneSetting",
  id: string,
  userID1: string,
  userID2: string,
  frequency: Frequency,
  nextOneOnOneDate: string,
  nextOneOnOneTime: string,
  agendaTemplateID?: string | null,
  status: OneOnOneSettingStatus,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  user1: User,
  user2: User,
  oneOnOneList?: ModelOneOnOneConnection | null,
};

export enum OneOnOneSettingStatus {
  Open = "Open",
  Stop = "Stop",
}


export type User = {
  __typename: "User",
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  account: string,
  owner?: string | null,
  originalPhotoUrl?: string | null,
  thumbnailPhotoUrl?: string | null,
  coverImageUrl?: string | null,
  organizationID: string,
  status: UserStatus,
  admin?: boolean | null,
  sub: string,
  socialType?: SocialType | null,
  drivingScore?: number | null,
  analyticalScore?: number | null,
  amiableScore?: number | null,
  expressiveScore?: number | null,
  createdAt: string,
  updatedAt: string,
  organizationGroup?: string | null,
  adminGroup: string,
  email?: string | null,
  teamID?: string | null,
  managers?: Array< string | null > | null,
  googleRefreshToken?: string | null,
  googleAccountEmail?: string | null,
  about?: string | null,
  delete?: boolean | null,
  deleteStatus: string,
  employeeId?: string | null,
  teamCode?: string | null,
  readingFirstName?: string | null,
  readingLastName?: string | null,
  birthDay?: string | null,
  gender?: Gender | null,
  address?: string | null,
  joinedCompanyDay?: string | null,
  retirementDay?: string | null,
  jobType?: string | null,
  phoneNumber?: string | null,
  enrollmentStatus?: EnrollmentStatus | null,
  recruitmentClassification?: RecruitmentClassification | null,
  department?: string | null,
  division?: string | null,
  section?: string | null,
  position?: string | null,
  gradeID?: string | null,
  allowance?: number | null,
  bonus?: number | null,
  gradeMatrixID?: string | null,
  myMembers?: ModelMemberManagerConnection | null,
  myManagers?: ModelMemberManagerConnection | null,
  gradeMatrix?: GradeMatrix | null,
  grade?: Grade | null,
};

export enum UserStatus {
  Invited = "Invited",
  ActivateComplete = "ActivateComplete",
}


export enum SocialType {
  Driving = "Driving",
  Analytical = "Analytical",
  Amiable = "Amiable",
  Expressive = "Expressive",
}


export enum Gender {
  man = "man",
  woman = "woman",
  other = "other",
}


export enum EnrollmentStatus {
  existence = "existence",
  absence = "absence",
  retirement = "retirement",
}


export enum RecruitmentClassification {
  newGraduate = "newGraduate",
  midCareer = "midCareer",
}


export type ModelMemberManagerConnection = {
  __typename: "ModelMemberManagerConnection",
  items:  Array<MemberManager | null >,
  nextToken?: string | null,
};

export type MemberManager = {
  __typename: "MemberManager",
  id: string,
  managerID: string,
  memberID: string,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt: string,
  updatedAt: string,
  delete?: boolean | null,
  owner?: string | null,
  manager: User,
  member: User,
};

export type GradeMatrix = {
  __typename: "GradeMatrix",
  gradeMatrixID: string,
  title: string,
  description?: string | null,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  memberList?: ModelUserConnection | null,
  competencyAreaList?: ModelCompetencyAreaConnection | null,
  gradeList?: ModelGradeConnection | null,
  competencyItemList?: ModelCompetencyItemConnection | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelCompetencyAreaConnection = {
  __typename: "ModelCompetencyAreaConnection",
  items:  Array<CompetencyArea | null >,
  nextToken?: string | null,
};

export type CompetencyArea = {
  __typename: "CompetencyArea",
  competencyAreaID: string,
  title: string,
  description?: string | null,
  gradeMatrixID: string,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelGradeConnection = {
  __typename: "ModelGradeConnection",
  items:  Array<Grade | null >,
  nextToken?: string | null,
};

export type Grade = {
  __typename: "Grade",
  gradeID: string,
  title: string,
  description?: string | null,
  gradeMatrixID: string,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelCompetencyItemConnection = {
  __typename: "ModelCompetencyItemConnection",
  items:  Array<CompetencyItem | null >,
  nextToken?: string | null,
};

export type CompetencyItem = {
  __typename: "CompetencyItem",
  competencyItemID: string,
  item: string,
  gradeID: string,
  competencyAreaID: string,
  gradeMatrixID: string,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelOneOnOneConnection = {
  __typename: "ModelOneOnOneConnection",
  items:  Array<OneOnOne | null >,
  nextToken?: string | null,
};

export type OneOnOne = {
  __typename: "OneOnOne",
  id: string,
  userID1: string,
  userID2: string,
  managerID?: string | null,
  oneOnOneSettingID: string,
  frequency: Frequency,
  startDate: string,
  startTime: string,
  status: OneOnOneStatus,
  agendaTemplateID?: string | null,
  recordingAgendaID?: string | null,
  recordingTarget?: RecordingTarget | null,
  aiSummary?: string | null,
  calendarApp?: CalendarApp | null,
  eventID?: string | null,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  user1: User,
  user2: User,
  oneOnOneSetting: OneOnOneSetting,
};

export enum OneOnOneStatus {
  Scheduled = "Scheduled",
  Canceled = "Canceled",
}


export enum RecordingTarget {
  agendaComment = "agendaComment",
  shareMemo = "shareMemo",
}


export type AddTemporaryOneOnOneInput = {
  oneOnOneSettingID: string,
  userID1: string,
  userID2: string,
  managerID?: string | null,
  startDate: string,
  startTime: string,
  agendaTemplateID?: string | null,
  organizationGroup: string,
  calendarApp?: CalendarApp | null,
  eventID?: string | null,
};

export type AddTemporaryOneOnOnePayload = {
  __typename: "AddTemporaryOneOnOnePayload",
  oneOnOne: OneOnOne,
};

export type SaveOneOnOneSettingInput = {
  oneOnOneSettingID: string,
  frequency: Frequency,
  startDate: string,
  startTime: string,
  agendaTemplateID?: string | null,
};

export type SaveOneOnOneSettingPayload = {
  __typename: "SaveOneOnOneSettingPayload",
  oneOnOneSetting: OneOnOneSetting,
};

export type StopOneOnOneInput = {
  oneOnOneSettingID: string,
};

export type StopOneOnOnePayload = {
  __typename: "StopOneOnOnePayload",
  setting: OneOnOneSetting,
};

export type RestartOneOnOneInput = {
  oneOnOneSettingID: string,
  frequency: Frequency,
  startDate: string,
  startTime: string,
  agendaTemplateID?: string | null,
  organizationGroup: string,
  calendarApp?: CalendarApp | null,
  eventID?: string | null,
};

export type RestartOneOnOnePayload = {
  __typename: "RestartOneOnOnePayload",
  setting: OneOnOneSetting,
  oneOnOne: OneOnOne,
};

export type googleCalendarResponse = {
  __typename: "googleCalendarResponse",
  events:  Array<Event | null >,
};

export type Event = {
  __typename: "Event",
  title?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  cycle?: string | null,
};

export enum DeleteGroupTarget {
  team = "team",
  organization = "organization",
}


export enum DeleteGroupType {
  physical = "physical",
  logical = "logical",
}


export type CreateCognitoUserResponse = {
  __typename: "CreateCognitoUserResponse",
  sub: string,
};

export type CreateImportedUserInput = {
  userID: string,
  email: string,
  group: string,
  organizationID: string,
  firstName: string,
  lastName: string,
  isAdmin?: boolean | null,
  teamID: string,
  managerID?: string | null,
};

export type ArchiveOrganizationInput = {
  organizationGroup: string,
};

export type ArchiveOrganizationPayload = {
  __typename: "ArchiveOrganizationPayload",
  organization: Organization,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name: string,
  domain?: string | null,
  webhookURL?: string | null,
  mailingList?: string | null,
  adminGroupName: string,
  originalPhotoUrl?: string | null,
  thumbnailPhotoUrl?: string | null,
  coverImageUrl?: string | null,
  groupName: string,
  numberOfUsers?: number | null,
  agreementUpdateDate?: string | null,
  assessmentFromDate?: string | null,
  assessmentToDate?: string | null,
  createdAt: string,
  updatedAt: string,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
  totalAudioDuration?: number | null,
  status?: OrganizationStatus | null,
  startDate?: string | null,
  endDate?: string | null,
  microsoftAppId?: string | null,
  microsoftTenant?: string | null,
  microsoftClientSecret?: string | null,
};

export enum OrganizationStatus {
  trial = "trial",
  underContract = "underContract",
  notContracted = "notContracted",
  Archived = "Archived",
}


export type CreateTeamInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  originalPhotoUrl?: string | null,
  thumbnailPhotoUrl?: string | null,
  coverImageUrl?: string | null,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
};

export type ModelTeamConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  originalPhotoUrl?: ModelStringInput | null,
  thumbnailPhotoUrl?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  and?: Array< ModelTeamConditionInput | null > | null,
  or?: Array< ModelTeamConditionInput | null > | null,
  not?: ModelTeamConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Team = {
  __typename: "Team",
  id: string,
  name?: string | null,
  description?: string | null,
  createdAt: string,
  updatedAt: string,
  originalPhotoUrl?: string | null,
  thumbnailPhotoUrl?: string | null,
  coverImageUrl?: string | null,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
  owner?: string | null,
};

export type UpdateTeamInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  originalPhotoUrl?: string | null,
  thumbnailPhotoUrl?: string | null,
  coverImageUrl?: string | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  delete?: boolean | null,
};

export type DeleteTeamInput = {
  id: string,
};

export type CreateMemberManagerInput = {
  id?: string | null,
  managerID: string,
  memberID: string,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
};

export type ModelMemberManagerConditionInput = {
  managerID?: ModelIDInput | null,
  memberID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  and?: Array< ModelMemberManagerConditionInput | null > | null,
  or?: Array< ModelMemberManagerConditionInput | null > | null,
  not?: ModelMemberManagerConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateMemberManagerInput = {
  id: string,
  managerID?: string | null,
  memberID?: string | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
};

export type DeleteMemberManagerInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  account: string,
  owner?: string | null,
  originalPhotoUrl?: string | null,
  thumbnailPhotoUrl?: string | null,
  coverImageUrl?: string | null,
  organizationID: string,
  status: UserStatus,
  admin?: boolean | null,
  sub: string,
  socialType?: SocialType | null,
  drivingScore?: number | null,
  analyticalScore?: number | null,
  amiableScore?: number | null,
  expressiveScore?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
  adminGroup: string,
  email?: string | null,
  teamID?: string | null,
  managers?: Array< string | null > | null,
  googleRefreshToken?: string | null,
  googleAccountEmail?: string | null,
  about?: string | null,
  delete?: boolean | null,
  deleteStatus: string,
  employeeId?: string | null,
  teamCode?: string | null,
  readingFirstName?: string | null,
  readingLastName?: string | null,
  birthDay?: string | null,
  gender?: Gender | null,
  address?: string | null,
  joinedCompanyDay?: string | null,
  retirementDay?: string | null,
  jobType?: string | null,
  phoneNumber?: string | null,
  enrollmentStatus?: EnrollmentStatus | null,
  recruitmentClassification?: RecruitmentClassification | null,
  department?: string | null,
  division?: string | null,
  section?: string | null,
  position?: string | null,
  gradeID?: string | null,
  allowance?: number | null,
  bonus?: number | null,
  gradeMatrixID?: string | null,
};

export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  account?: ModelStringInput | null,
  originalPhotoUrl?: ModelStringInput | null,
  thumbnailPhotoUrl?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  status?: ModelUserStatusInput | null,
  admin?: ModelBooleanInput | null,
  sub?: ModelStringInput | null,
  socialType?: ModelSocialTypeInput | null,
  drivingScore?: ModelIntInput | null,
  analyticalScore?: ModelIntInput | null,
  amiableScore?: ModelIntInput | null,
  expressiveScore?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  email?: ModelStringInput | null,
  teamID?: ModelStringInput | null,
  managers?: ModelIDInput | null,
  googleRefreshToken?: ModelIDInput | null,
  googleAccountEmail?: ModelStringInput | null,
  about?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelStringInput | null,
  employeeId?: ModelStringInput | null,
  teamCode?: ModelStringInput | null,
  readingFirstName?: ModelStringInput | null,
  readingLastName?: ModelStringInput | null,
  birthDay?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  address?: ModelStringInput | null,
  joinedCompanyDay?: ModelStringInput | null,
  retirementDay?: ModelStringInput | null,
  jobType?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  enrollmentStatus?: ModelEnrollmentStatusInput | null,
  recruitmentClassification?: ModelRecruitmentClassificationInput | null,
  department?: ModelStringInput | null,
  division?: ModelStringInput | null,
  section?: ModelStringInput | null,
  position?: ModelStringInput | null,
  gradeID?: ModelIDInput | null,
  allowance?: ModelIntInput | null,
  bonus?: ModelIntInput | null,
  gradeMatrixID?: ModelIDInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type ModelSocialTypeInput = {
  eq?: SocialType | null,
  ne?: SocialType | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type ModelEnrollmentStatusInput = {
  eq?: EnrollmentStatus | null,
  ne?: EnrollmentStatus | null,
};

export type ModelRecruitmentClassificationInput = {
  eq?: RecruitmentClassification | null,
  ne?: RecruitmentClassification | null,
};

export type UpdateUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  account?: string | null,
  owner?: string | null,
  originalPhotoUrl?: string | null,
  thumbnailPhotoUrl?: string | null,
  coverImageUrl?: string | null,
  organizationID?: string | null,
  status?: UserStatus | null,
  admin?: boolean | null,
  sub?: string | null,
  socialType?: SocialType | null,
  drivingScore?: number | null,
  analyticalScore?: number | null,
  amiableScore?: number | null,
  expressiveScore?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  email?: string | null,
  teamID?: string | null,
  managers?: Array< string | null > | null,
  googleRefreshToken?: string | null,
  googleAccountEmail?: string | null,
  about?: string | null,
  delete?: boolean | null,
  deleteStatus?: string | null,
  employeeId?: string | null,
  teamCode?: string | null,
  readingFirstName?: string | null,
  readingLastName?: string | null,
  birthDay?: string | null,
  gender?: Gender | null,
  address?: string | null,
  joinedCompanyDay?: string | null,
  retirementDay?: string | null,
  jobType?: string | null,
  phoneNumber?: string | null,
  enrollmentStatus?: EnrollmentStatus | null,
  recruitmentClassification?: RecruitmentClassification | null,
  department?: string | null,
  division?: string | null,
  section?: string | null,
  position?: string | null,
  gradeID?: string | null,
  allowance?: number | null,
  bonus?: number | null,
  gradeMatrixID?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateHistoryInput = {
  id?: string | null,
  user: string,
  value?: string | null,
  subValue?: string | null,
  start?: string | null,
  end?: string | null,
  type: HistoryType,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  groupsCanAccess: Array< string | null >,
};

export enum HistoryType {
  leavePeriod = "leavePeriod",
  certification = "certification",
  training = "training",
  transfer = "transfer",
  basicSalary = "basicSalary",
  review = "review",
}


export type ModelHistoryConditionInput = {
  user?: ModelIDInput | null,
  value?: ModelStringInput | null,
  subValue?: ModelStringInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  type?: ModelHistoryTypeInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  and?: Array< ModelHistoryConditionInput | null > | null,
  or?: Array< ModelHistoryConditionInput | null > | null,
  not?: ModelHistoryConditionInput | null,
};

export type ModelHistoryTypeInput = {
  eq?: HistoryType | null,
  ne?: HistoryType | null,
};

export type History = {
  __typename: "History",
  id: string,
  user: string,
  value?: string | null,
  subValue?: string | null,
  start?: string | null,
  end?: string | null,
  type: HistoryType,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt: string,
  updatedAt: string,
  delete?: boolean | null,
  groupsCanAccess: Array< string | null >,
  owners?: string | null,
  owner?: string | null,
};

export type UpdateHistoryInput = {
  id: string,
  user?: string | null,
  value?: string | null,
  subValue?: string | null,
  start?: string | null,
  end?: string | null,
  type?: HistoryType | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteHistoryInput = {
  id: string,
};

export type CreateSettingInput = {
  id?: string | null,
  notification?: SettingNotificationPartInput | null,
  calendar?: SettingCalendarPartInput | null,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  groupsCanAccess: Array< string | null >,
};

export type SettingNotificationPartInput = {
  disableAll?: boolean | null,
  email?: SettingNotificationItemInput | null,
};

export type SettingNotificationItemInput = {
  enable?: boolean | null,
  reportLike?: boolean | null,
  reportComment?: boolean | null,
  onoRemind?: boolean | null,
  onoLookBack?: boolean | null,
  goalUpdate?: boolean | null,
  goalActivity?: boolean | null,
  goalRemind?: boolean | null,
};

export type SettingCalendarPartInput = {
  gmailAddress?: string | null,
  syncLimit?: number | null,
  syncInterval?: number | null,
  syncToken?: string | null,
};

export type ModelSettingConditionInput = {
  adminGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelSettingConditionInput | null > | null,
  or?: Array< ModelSettingConditionInput | null > | null,
  not?: ModelSettingConditionInput | null,
};

export type Setting = {
  __typename: "Setting",
  id: string,
  notification?: SettingNotificationPart | null,
  calendar?: SettingCalendarPart | null,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
  createdAt: string,
  updatedAt: string,
  groupsCanAccess: Array< string | null >,
};

export type SettingNotificationPart = {
  __typename: "SettingNotificationPart",
  disableAll?: boolean | null,
  email?: SettingNotificationItem | null,
};

export type SettingNotificationItem = {
  __typename: "SettingNotificationItem",
  enable?: boolean | null,
  reportLike?: boolean | null,
  reportComment?: boolean | null,
  onoRemind?: boolean | null,
  onoLookBack?: boolean | null,
  goalUpdate?: boolean | null,
  goalActivity?: boolean | null,
  goalRemind?: boolean | null,
};

export type SettingCalendarPart = {
  __typename: "SettingCalendarPart",
  gmailAddress?: string | null,
  syncLimit?: number | null,
  syncInterval?: number | null,
  syncToken?: string | null,
};

export type UpdateSettingInput = {
  id: string,
  notification?: SettingNotificationPartInput | null,
  calendar?: SettingCalendarPartInput | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  delete?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteSettingInput = {
  id: string,
};

export type CreateTaskInput = {
  taskID: string,
  userID: string,
  content: string,
  title: string,
  taskType: TaskType,
  complete: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
  dueDate?: string | null,
  taskLink?: string | null,
  organizationGroup: string,
  reviewSheetID?: string | null,
  goalID?: string | null,
};

export enum TaskType {
  OneOnOneTask = "OneOnOneTask",
  GoalProgressUpdateTask = "GoalProgressUpdateTask",
  ReviewWriteTask = "ReviewWriteTask",
  ReviewResultOpenTask = "ReviewResultOpenTask",
}


export type ModelTaskConditionInput = {
  userID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  title?: ModelStringInput | null,
  taskType?: ModelTaskTypeInput | null,
  complete?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  taskLink?: ModelStringInput | null,
  reviewSheetID?: ModelIDInput | null,
  goalID?: ModelIDInput | null,
  and?: Array< ModelTaskConditionInput | null > | null,
  or?: Array< ModelTaskConditionInput | null > | null,
  not?: ModelTaskConditionInput | null,
};

export type ModelTaskTypeInput = {
  eq?: TaskType | null,
  ne?: TaskType | null,
};

export type Task = {
  __typename: "Task",
  taskID: string,
  userID: string,
  content: string,
  title: string,
  taskType: TaskType,
  complete: boolean,
  createdAt: string,
  updatedAt: string,
  dueDate?: string | null,
  taskLink?: string | null,
  organizationGroup: string,
  reviewSheetID?: string | null,
  goalID?: string | null,
};

export type UpdateTaskInput = {
  taskID: string,
  userID?: string | null,
  content?: string | null,
  title?: string | null,
  taskType?: TaskType | null,
  complete?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  dueDate?: string | null,
  taskLink?: string | null,
  organizationGroup?: string | null,
  reviewSheetID?: string | null,
  goalID?: string | null,
};

export type DeleteTaskInput = {
  taskID: string,
};

export type CreateOrganizationInput = {
  id?: string | null,
  name: string,
  domain?: string | null,
  webhookURL?: string | null,
  mailingList?: string | null,
  adminGroupName: string,
  originalPhotoUrl?: string | null,
  thumbnailPhotoUrl?: string | null,
  coverImageUrl?: string | null,
  groupName: string,
  numberOfUsers?: number | null,
  agreementUpdateDate?: string | null,
  assessmentFromDate?: string | null,
  assessmentToDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
  totalAudioDuration?: number | null,
  status?: OrganizationStatus | null,
  startDate?: string | null,
  endDate?: string | null,
  microsoftAppId?: string | null,
  microsoftTenant?: string | null,
  microsoftClientSecret?: string | null,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  webhookURL?: ModelStringInput | null,
  mailingList?: ModelStringInput | null,
  adminGroupName?: ModelStringInput | null,
  originalPhotoUrl?: ModelStringInput | null,
  thumbnailPhotoUrl?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  groupName?: ModelStringInput | null,
  numberOfUsers?: ModelIntInput | null,
  agreementUpdateDate?: ModelStringInput | null,
  assessmentFromDate?: ModelStringInput | null,
  assessmentToDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  totalAudioDuration?: ModelIntInput | null,
  status?: ModelOrganizationStatusInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  microsoftAppId?: ModelStringInput | null,
  microsoftTenant?: ModelStringInput | null,
  microsoftClientSecret?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type ModelOrganizationStatusInput = {
  eq?: OrganizationStatus | null,
  ne?: OrganizationStatus | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  domain?: string | null,
  webhookURL?: string | null,
  mailingList?: string | null,
  adminGroupName?: string | null,
  originalPhotoUrl?: string | null,
  thumbnailPhotoUrl?: string | null,
  coverImageUrl?: string | null,
  groupName?: string | null,
  numberOfUsers?: number | null,
  agreementUpdateDate?: string | null,
  assessmentFromDate?: string | null,
  assessmentToDate?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  delete?: boolean | null,
  totalAudioDuration?: number | null,
  status?: OrganizationStatus | null,
  startDate?: string | null,
  endDate?: string | null,
  microsoftAppId?: string | null,
  microsoftTenant?: string | null,
  microsoftClientSecret?: string | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateGoal3Input = {
  id?: string | null,
  organization?: string | null,
  teamID?: string | null,
  value?: string | null,
  detail?: string | null,
  progressType: GoalProgressType,
  startDate?: string | null,
  endDate?: string | null,
  owners?: Array< string > | null,
  type: GoalType2,
  visibility: GoalVisibility,
  readers?: Array< string | null > | null,
  parentGoalID?: string | null,
  priority?: number | null,
  achievement?: GoalAchievement | null,
  progress: Progress,
  totalValue?: number | null,
  start?: number | null,
  target?: number | null,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  archiveStatus: string,
};

export enum GoalProgressType {
  binary = "binary",
  yen = "yen",
  number = "number",
  percent = "percent",
  summary = "summary",
}


export enum GoalType2 {
  individual = "individual",
  department = "department",
  company = "company",
}


export enum GoalVisibility {
  public = "public",
  private = "private",
}


export enum GoalAchievement {
  ongoing = "ongoing",
  imperfect = "imperfect",
  perfect = "perfect",
}


export enum Progress {
  NotUpdated = "NotUpdated",
  OffTrack = "OffTrack",
  InProgress = "InProgress",
  OnTrack = "OnTrack",
  Incompleted = "Incompleted",
  Completed = "Completed",
}


export type ModelGoal3ConditionInput = {
  organization?: ModelIDInput | null,
  teamID?: ModelIDInput | null,
  value?: ModelStringInput | null,
  detail?: ModelStringInput | null,
  progressType?: ModelGoalProgressTypeInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  owners?: ModelIDInput | null,
  type?: ModelGoalType2Input | null,
  visibility?: ModelGoalVisibilityInput | null,
  readers?: ModelIDInput | null,
  parentGoalID?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  achievement?: ModelGoalAchievementInput | null,
  progress?: ModelProgressInput | null,
  totalValue?: ModelFloatInput | null,
  start?: ModelFloatInput | null,
  target?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  archiveStatus?: ModelStringInput | null,
  and?: Array< ModelGoal3ConditionInput | null > | null,
  or?: Array< ModelGoal3ConditionInput | null > | null,
  not?: ModelGoal3ConditionInput | null,
};

export type ModelGoalProgressTypeInput = {
  eq?: GoalProgressType | null,
  ne?: GoalProgressType | null,
};

export type ModelGoalType2Input = {
  eq?: GoalType2 | null,
  ne?: GoalType2 | null,
};

export type ModelGoalVisibilityInput = {
  eq?: GoalVisibility | null,
  ne?: GoalVisibility | null,
};

export type ModelGoalAchievementInput = {
  eq?: GoalAchievement | null,
  ne?: GoalAchievement | null,
};

export type ModelProgressInput = {
  eq?: Progress | null,
  ne?: Progress | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Goal3 = {
  __typename: "Goal3",
  id: string,
  organization?: string | null,
  teamID?: string | null,
  value?: string | null,
  detail?: string | null,
  progressType: GoalProgressType,
  startDate?: string | null,
  endDate?: string | null,
  owners?: Array< string > | null,
  type: GoalType2,
  visibility: GoalVisibility,
  readers?: Array< string | null > | null,
  parentGoalID?: string | null,
  priority?: number | null,
  achievement?: GoalAchievement | null,
  progress: Progress,
  totalValue?: number | null,
  start?: number | null,
  target?: number | null,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt: string,
  updatedAt: string,
  delete?: boolean | null,
  archiveStatus: string,
  goalProgressList?: ModelGoalProgressConnection | null,
};

export type ModelGoalProgressConnection = {
  __typename: "ModelGoalProgressConnection",
  items:  Array<GoalProgress | null >,
  nextToken?: string | null,
};

export type GoalProgress = {
  __typename: "GoalProgress",
  goalProgressID: string,
  organizationGroup: string,
  goalID: string,
  progress: Progress,
  progressDetail?: string | null,
  value?: number | null,
  creatorID: string,
  createdAt: string,
  deleteStatus: string,
  updatedAt: string,
  comments?: ModelGoalProgressCommentConnection | null,
};

export type ModelGoalProgressCommentConnection = {
  __typename: "ModelGoalProgressCommentConnection",
  items:  Array<GoalProgressComment | null >,
  nextToken?: string | null,
};

export type GoalProgressComment = {
  __typename: "GoalProgressComment",
  goalProgressCommentID: string,
  organizationGroup: string,
  commenterID: string,
  commentContent?: string | null,
  goalProgressID: string,
  createdAt: string,
  updatedAt: string,
  commenter: User,
};

export type UpdateGoal3Input = {
  id: string,
  organization?: string | null,
  teamID?: string | null,
  value?: string | null,
  detail?: string | null,
  progressType?: GoalProgressType | null,
  startDate?: string | null,
  endDate?: string | null,
  owners?: Array< string > | null,
  type?: GoalType2 | null,
  visibility?: GoalVisibility | null,
  readers?: Array< string | null > | null,
  parentGoalID?: string | null,
  priority?: number | null,
  achievement?: GoalAchievement | null,
  progress?: Progress | null,
  totalValue?: number | null,
  start?: number | null,
  target?: number | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  archiveStatus?: string | null,
};

export type DeleteGoal3Input = {
  id: string,
};

export type CreateGoalInput = {
  goalID: string,
  organizationGroup: string,
  parentGoalID?: string | null,
  departmentID?: string | null,
  directorIDs: Array< string >,
  readerIDs?: Array< string > | null,
  goalClassification: GoalClassification,
  goalTitle: string,
  goalDetail?: string | null,
  measuringType: MeasuringType,
  startDate: string,
  targetDate: string,
  goalType: GoalType,
  publicScope: PublicScope,
  archiveStatus: string,
  startValue?: number | null,
  targetValue?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deleteStatus: string,
  goalWeightPoint?: number | null,
};

export enum GoalClassification {
  Main = "Main",
  Sub = "Sub",
}


export enum MeasuringType {
  Qualitative = "Qualitative",
  Money = "Money",
  Number = "Number",
  Percent = "Percent",
  Summary = "Summary",
}


export enum GoalType {
  Whole = "Whole",
  Department = "Department",
  Individual = "Individual",
}


export enum PublicScope {
  Public = "Public",
  Private = "Private",
}


export type ModelGoalConditionInput = {
  parentGoalID?: ModelIDInput | null,
  departmentID?: ModelStringInput | null,
  directorIDs?: ModelIDInput | null,
  readerIDs?: ModelIDInput | null,
  goalClassification?: ModelGoalClassificationInput | null,
  goalTitle?: ModelStringInput | null,
  goalDetail?: ModelStringInput | null,
  measuringType?: ModelMeasuringTypeInput | null,
  startDate?: ModelStringInput | null,
  targetDate?: ModelStringInput | null,
  goalType?: ModelGoalTypeInput | null,
  publicScope?: ModelPublicScopeInput | null,
  archiveStatus?: ModelStringInput | null,
  startValue?: ModelFloatInput | null,
  targetValue?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deleteStatus?: ModelStringInput | null,
  goalWeightPoint?: ModelIntInput | null,
  and?: Array< ModelGoalConditionInput | null > | null,
  or?: Array< ModelGoalConditionInput | null > | null,
  not?: ModelGoalConditionInput | null,
};

export type ModelGoalClassificationInput = {
  eq?: GoalClassification | null,
  ne?: GoalClassification | null,
};

export type ModelMeasuringTypeInput = {
  eq?: MeasuringType | null,
  ne?: MeasuringType | null,
};

export type ModelGoalTypeInput = {
  eq?: GoalType | null,
  ne?: GoalType | null,
};

export type ModelPublicScopeInput = {
  eq?: PublicScope | null,
  ne?: PublicScope | null,
};

export type Goal = {
  __typename: "Goal",
  goalID: string,
  organizationGroup: string,
  parentGoalID?: string | null,
  departmentID?: string | null,
  directorIDs: Array< string >,
  readerIDs?: Array< string > | null,
  goalClassification: GoalClassification,
  goalTitle: string,
  goalDetail?: string | null,
  measuringType: MeasuringType,
  startDate: string,
  targetDate: string,
  goalType: GoalType,
  publicScope: PublicScope,
  archiveStatus: string,
  startValue?: number | null,
  targetValue?: number | null,
  createdAt: string,
  updatedAt: string,
  deleteStatus: string,
  goalWeightPoint?: number | null,
  goalProgressList?: ModelGoalProgressConnection | null,
};

export type UpdateGoalInput = {
  goalID: string,
  organizationGroup?: string | null,
  parentGoalID?: string | null,
  departmentID?: string | null,
  directorIDs?: Array< string > | null,
  readerIDs?: Array< string > | null,
  goalClassification?: GoalClassification | null,
  goalTitle?: string | null,
  goalDetail?: string | null,
  measuringType?: MeasuringType | null,
  startDate?: string | null,
  targetDate?: string | null,
  goalType?: GoalType | null,
  publicScope?: PublicScope | null,
  archiveStatus?: string | null,
  startValue?: number | null,
  targetValue?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  deleteStatus?: string | null,
  goalWeightPoint?: number | null,
};

export type DeleteGoalInput = {
  goalID: string,
};

export type CreateGoalActivityInput = {
  id?: string | null,
  userID: string,
  goalID?: string | null,
  keyResultID?: string | null,
  date?: string | null,
  value?: number | null,
  detail?: string | null,
  progress?: GoalProgress2 | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
};

export enum GoalProgress2 {
  notUpdated = "notUpdated",
  offTrack = "offTrack",
  progressing = "progressing",
  onTrack = "onTrack",
  imperfect = "imperfect",
  perfect = "perfect",
}


export type ModelGoalActivityConditionInput = {
  userID?: ModelIDInput | null,
  goalID?: ModelIDInput | null,
  keyResultID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  value?: ModelIntInput | null,
  detail?: ModelStringInput | null,
  progress?: ModelGoalProgress2Input | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  and?: Array< ModelGoalActivityConditionInput | null > | null,
  or?: Array< ModelGoalActivityConditionInput | null > | null,
  not?: ModelGoalActivityConditionInput | null,
};

export type ModelGoalProgress2Input = {
  eq?: GoalProgress2 | null,
  ne?: GoalProgress2 | null,
};

export type GoalActivity = {
  __typename: "GoalActivity",
  id: string,
  userID: string,
  goalID?: string | null,
  keyResultID?: string | null,
  date?: string | null,
  value?: number | null,
  detail?: string | null,
  progress?: GoalProgress2 | null,
  createdAt: string,
  updatedAt: string,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
};

export type UpdateGoalActivityInput = {
  id: string,
  userID?: string | null,
  goalID?: string | null,
  keyResultID?: string | null,
  date?: string | null,
  value?: number | null,
  detail?: string | null,
  progress?: GoalProgress2 | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  delete?: boolean | null,
};

export type DeleteGoalActivityInput = {
  id: string,
};

export type CreateGoalProgressInput = {
  goalProgressID: string,
  organizationGroup: string,
  goalID: string,
  progress: Progress,
  progressDetail?: string | null,
  value?: number | null,
  creatorID: string,
  createdAt?: string | null,
  deleteStatus: string,
};

export type ModelGoalProgressConditionInput = {
  goalID?: ModelIDInput | null,
  progress?: ModelProgressInput | null,
  progressDetail?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  creatorID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  deleteStatus?: ModelStringInput | null,
  and?: Array< ModelGoalProgressConditionInput | null > | null,
  or?: Array< ModelGoalProgressConditionInput | null > | null,
  not?: ModelGoalProgressConditionInput | null,
};

export type UpdateGoalProgressInput = {
  goalProgressID: string,
  organizationGroup?: string | null,
  goalID?: string | null,
  progress?: Progress | null,
  progressDetail?: string | null,
  value?: number | null,
  creatorID?: string | null,
  createdAt?: string | null,
  deleteStatus?: string | null,
};

export type DeleteGoalProgressInput = {
  goalProgressID: string,
};

export type CreateGoalProgressCommentInput = {
  goalProgressCommentID: string,
  organizationGroup: string,
  commenterID: string,
  commentContent?: string | null,
  goalProgressID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelGoalProgressCommentConditionInput = {
  commentContent?: ModelStringInput | null,
  goalProgressID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGoalProgressCommentConditionInput | null > | null,
  or?: Array< ModelGoalProgressCommentConditionInput | null > | null,
  not?: ModelGoalProgressCommentConditionInput | null,
};

export type UpdateGoalProgressCommentInput = {
  goalProgressCommentID: string,
  organizationGroup?: string | null,
  commenterID?: string | null,
  commentContent?: string | null,
  goalProgressID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteGoalProgressCommentInput = {
  goalProgressCommentID: string,
};

export type CreateOnoInput = {
  id?: string | null,
  member: string,
  manager: string,
  frequency?: FrequencyType | null,
  nextMeetingDate?: string | null,
  nextMeetingStart: string,
  templateID?: string | null,
  googleChannelResourceID?: string | null,
  iCalUID?: string | null,
  googleAccountEmail?: string | null,
  status?: OnoStatus | null,
  editors: Array< string | null >,
  createdAt?: string | null,
  updatedAt?: string | null,
  adminGroup: string,
  organizationGroup?: string | null,
  groupsCanAccess: Array< string | null >,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  outlookUserId?: string | null,
  outlookEventId?: string | null,
};

export enum FrequencyType {
  once = "once",
  weekly = "weekly",
  biWeekly = "biWeekly",
  monthly = "monthly",
}


export enum OnoStatus {
  incoming = "incoming",
  inactive = "inactive",
}


export enum DeleteStatus {
  deleted = "deleted",
  notDeleted = "notDeleted",
}


export type ModelOnoConditionInput = {
  member?: ModelIDInput | null,
  manager?: ModelIDInput | null,
  frequency?: ModelFrequencyTypeInput | null,
  nextMeetingDate?: ModelStringInput | null,
  nextMeetingStart?: ModelStringInput | null,
  templateID?: ModelIDInput | null,
  googleChannelResourceID?: ModelStringInput | null,
  iCalUID?: ModelIDInput | null,
  googleAccountEmail?: ModelStringInput | null,
  status?: ModelOnoStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  outlookUserId?: ModelStringInput | null,
  outlookEventId?: ModelStringInput | null,
  and?: Array< ModelOnoConditionInput | null > | null,
  or?: Array< ModelOnoConditionInput | null > | null,
  not?: ModelOnoConditionInput | null,
};

export type ModelFrequencyTypeInput = {
  eq?: FrequencyType | null,
  ne?: FrequencyType | null,
};

export type ModelOnoStatusInput = {
  eq?: OnoStatus | null,
  ne?: OnoStatus | null,
};

export type ModelDeleteStatusInput = {
  eq?: DeleteStatus | null,
  ne?: DeleteStatus | null,
};

export type Ono = {
  __typename: "Ono",
  id: string,
  member: string,
  manager: string,
  frequency?: FrequencyType | null,
  nextMeetingDate?: string | null,
  nextMeetingStart: string,
  templateID?: string | null,
  googleChannelResourceID?: string | null,
  iCalUID?: string | null,
  googleAccountEmail?: string | null,
  status?: OnoStatus | null,
  editors: Array< string | null >,
  createdAt: string,
  updatedAt: string,
  adminGroup: string,
  organizationGroup?: string | null,
  groupsCanAccess: Array< string | null >,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  outlookUserId?: string | null,
  outlookEventId?: string | null,
  owner?: string | null,
};

export type UpdateOnoInput = {
  id: string,
  member?: string | null,
  manager?: string | null,
  frequency?: FrequencyType | null,
  nextMeetingDate?: string | null,
  nextMeetingStart?: string | null,
  templateID?: string | null,
  googleChannelResourceID?: string | null,
  iCalUID?: string | null,
  googleAccountEmail?: string | null,
  status?: OnoStatus | null,
  editors?: Array< string | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  adminGroup?: string | null,
  organizationGroup?: string | null,
  groupsCanAccess?: Array< string | null > | null,
  delete?: boolean | null,
  deleteStatus?: DeleteStatus | null,
  outlookUserId?: string | null,
  outlookEventId?: string | null,
};

export type DeleteOnoInput = {
  id: string,
};

export type CreateMeetingInput = {
  id?: string | null,
  member: string,
  manager: string,
  startDate: string,
  ono?: string | null,
  status?: OnoStatus | null,
  templateID?: string | null,
  temporary?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  adminGroup: string,
  organizationGroup?: string | null,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  participated?: boolean | null,
  groupsCanAccess: Array< string | null >,
};

export type ModelMeetingConditionInput = {
  startDate?: ModelStringInput | null,
  ono?: ModelIDInput | null,
  status?: ModelOnoStatusInput | null,
  templateID?: ModelStringInput | null,
  temporary?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  participated?: ModelBooleanInput | null,
  and?: Array< ModelMeetingConditionInput | null > | null,
  or?: Array< ModelMeetingConditionInput | null > | null,
  not?: ModelMeetingConditionInput | null,
};

export type Meeting = {
  __typename: "Meeting",
  id: string,
  member: string,
  manager: string,
  startDate: string,
  ono?: string | null,
  status?: OnoStatus | null,
  templateID?: string | null,
  temporary?: boolean | null,
  createdAt: string,
  updatedAt: string,
  adminGroup: string,
  organizationGroup?: string | null,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  participated?: boolean | null,
  groupsCanAccess: Array< string | null >,
};

export type UpdateMeetingInput = {
  id: string,
  member?: string | null,
  manager?: string | null,
  startDate?: string | null,
  ono?: string | null,
  status?: OnoStatus | null,
  templateID?: string | null,
  temporary?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  adminGroup?: string | null,
  organizationGroup?: string | null,
  delete?: boolean | null,
  deleteStatus?: DeleteStatus | null,
  participated?: boolean | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteMeetingInput = {
  id: string,
};

export type CreateTodoInput = {
  id?: string | null,
  name: string,
  ono: string,
  assignmentUserId?: string | null,
  status: TodoStatus,
  createdMeetingID?: string | null,
  completedMeetingID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  owners: Array< string | null >,
};

export enum TodoStatus {
  waiting = "waiting",
  done = "done",
}


export type ModelTodoConditionInput = {
  name?: ModelStringInput | null,
  ono?: ModelIDInput | null,
  assignmentUserId?: ModelIDInput | null,
  status?: ModelTodoStatusInput | null,
  createdMeetingID?: ModelIDInput | null,
  completedMeetingID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  and?: Array< ModelTodoConditionInput | null > | null,
  or?: Array< ModelTodoConditionInput | null > | null,
  not?: ModelTodoConditionInput | null,
};

export type ModelTodoStatusInput = {
  eq?: TodoStatus | null,
  ne?: TodoStatus | null,
};

export type Todo = {
  __typename: "Todo",
  id: string,
  name: string,
  ono: string,
  assignmentUserId?: string | null,
  status: TodoStatus,
  createdMeetingID?: string | null,
  completedMeetingID?: string | null,
  createdAt: string,
  updatedAt: string,
  organizationGroup?: string | null,
  adminGroup: string,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  owners: Array< string | null >,
  owner?: string | null,
};

export type UpdateTodoInput = {
  id: string,
  name?: string | null,
  ono?: string | null,
  assignmentUserId?: string | null,
  status?: TodoStatus | null,
  createdMeetingID?: string | null,
  completedMeetingID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  delete?: boolean | null,
  deleteStatus?: DeleteStatus | null,
  owners?: Array< string | null > | null,
};

export type DeleteTodoInput = {
  id: string,
};

export type CreateNextActionInput = {
  id?: string | null,
  title: string,
  oneOnOneSettingID: string,
  managerID: string,
  addedOneOnOneID: string,
  completedOneOnOneID?: string | null,
  targetDate: string,
  isDone: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup: string,
};

export type ModelNextActionConditionInput = {
  title?: ModelStringInput | null,
  oneOnOneSettingID?: ModelIDInput | null,
  managerID?: ModelIDInput | null,
  addedOneOnOneID?: ModelIDInput | null,
  completedOneOnOneID?: ModelStringInput | null,
  targetDate?: ModelStringInput | null,
  isDone?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNextActionConditionInput | null > | null,
  or?: Array< ModelNextActionConditionInput | null > | null,
  not?: ModelNextActionConditionInput | null,
};

export type NextAction = {
  __typename: "NextAction",
  id: string,
  title: string,
  oneOnOneSettingID: string,
  managerID: string,
  addedOneOnOneID: string,
  completedOneOnOneID?: string | null,
  targetDate: string,
  isDone: boolean,
  createdAt: string,
  updatedAt: string,
  organizationGroup: string,
  manager: User,
};

export type UpdateNextActionInput = {
  id: string,
  title?: string | null,
  oneOnOneSettingID?: string | null,
  managerID?: string | null,
  addedOneOnOneID?: string | null,
  completedOneOnOneID?: string | null,
  targetDate?: string | null,
  isDone?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
};

export type DeleteNextActionInput = {
  id: string,
};

export type CreateOrganizationAgendaInput = {
  id?: string | null,
  title: string,
  order: number,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelOrganizationAgendaConditionInput = {
  title?: ModelStringInput | null,
  order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganizationAgendaConditionInput | null > | null,
  or?: Array< ModelOrganizationAgendaConditionInput | null > | null,
  not?: ModelOrganizationAgendaConditionInput | null,
};

export type OrganizationAgenda = {
  __typename: "OrganizationAgenda",
  id: string,
  title: string,
  order: number,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateOrganizationAgendaInput = {
  id: string,
  title?: string | null,
  order?: number | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOrganizationAgendaInput = {
  id: string,
};

export type CreateAgendaTemplateInput = {
  id?: string | null,
  title: string,
  purpose?: string | null,
  organizationGroup: string,
  ownerID: string,
  status: AgendaTemplateStatus,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum AgendaTemplateStatus {
  IsShared = "IsShared",
  Private = "Private",
  Recommend = "Recommend",
}


export type ModelAgendaTemplateConditionInput = {
  title?: ModelStringInput | null,
  purpose?: ModelStringInput | null,
  status?: ModelAgendaTemplateStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAgendaTemplateConditionInput | null > | null,
  or?: Array< ModelAgendaTemplateConditionInput | null > | null,
  not?: ModelAgendaTemplateConditionInput | null,
};

export type ModelAgendaTemplateStatusInput = {
  eq?: AgendaTemplateStatus | null,
  ne?: AgendaTemplateStatus | null,
};

export type AgendaTemplate = {
  __typename: "AgendaTemplate",
  id: string,
  title: string,
  purpose?: string | null,
  organizationGroup: string,
  ownerID: string,
  status: AgendaTemplateStatus,
  createdAt: string,
  updatedAt: string,
  owner: User,
  agendas?: ModelAgendaTemplateAgendaConnection | null,
};

export type ModelAgendaTemplateAgendaConnection = {
  __typename: "ModelAgendaTemplateAgendaConnection",
  items:  Array<AgendaTemplateAgenda | null >,
  nextToken?: string | null,
};

export type AgendaTemplateAgenda = {
  __typename: "AgendaTemplateAgenda",
  id: string,
  agendaTemplateID: string,
  title: string,
  order: number,
  ownerID: string,
  createdAt: string,
  updatedAt: string,
  organizationGroup: string,
};

export type UpdateAgendaTemplateInput = {
  id: string,
  title?: string | null,
  purpose?: string | null,
  organizationGroup?: string | null,
  ownerID?: string | null,
  status?: AgendaTemplateStatus | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteAgendaTemplateInput = {
  id: string,
};

export type CreateAgendaTemplateAgendaInput = {
  id?: string | null,
  agendaTemplateID: string,
  title: string,
  order: number,
  ownerID: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup: string,
};

export type ModelAgendaTemplateAgendaConditionInput = {
  agendaTemplateID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAgendaTemplateAgendaConditionInput | null > | null,
  or?: Array< ModelAgendaTemplateAgendaConditionInput | null > | null,
  not?: ModelAgendaTemplateAgendaConditionInput | null,
};

export type UpdateAgendaTemplateAgendaInput = {
  id: string,
  agendaTemplateID?: string | null,
  title?: string | null,
  order?: number | null,
  ownerID?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  organizationGroup?: string | null,
};

export type DeleteAgendaTemplateAgendaInput = {
  id: string,
};

export type CreateTemplateInput = {
  id?: string | null,
  title?: string | null,
  ono?: string | null,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  groupsCanAccess: Array< string | null >,
};

export type ModelTemplateConditionInput = {
  title?: ModelStringInput | null,
  ono?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  and?: Array< ModelTemplateConditionInput | null > | null,
  or?: Array< ModelTemplateConditionInput | null > | null,
  not?: ModelTemplateConditionInput | null,
};

export type Template = {
  __typename: "Template",
  id: string,
  title?: string | null,
  ono?: string | null,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt: string,
  updatedAt: string,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  groupsCanAccess: Array< string | null >,
  owner?: string | null,
};

export type UpdateTemplateInput = {
  id: string,
  title?: string | null,
  ono?: string | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  deleteStatus?: DeleteStatus | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteTemplateInput = {
  id: string,
};

export type CreateTemplateAgendaInput = {
  id?: string | null,
  template?: string | null,
  title: string,
  fromOrganization?: string | null,
  order: number,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  groupsCanAccess: Array< string | null >,
};

export type ModelTemplateAgendaConditionInput = {
  template?: ModelIDInput | null,
  title?: ModelStringInput | null,
  fromOrganization?: ModelIDInput | null,
  order?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  and?: Array< ModelTemplateAgendaConditionInput | null > | null,
  or?: Array< ModelTemplateAgendaConditionInput | null > | null,
  not?: ModelTemplateAgendaConditionInput | null,
};

export type TemplateAgenda = {
  __typename: "TemplateAgenda",
  id: string,
  template?: string | null,
  title: string,
  fromOrganization?: string | null,
  order: number,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt: string,
  updatedAt: string,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  groupsCanAccess: Array< string | null >,
  owner?: string | null,
};

export type UpdateTemplateAgendaInput = {
  id: string,
  template?: string | null,
  title?: string | null,
  fromOrganization?: string | null,
  order?: number | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  deleteStatus?: DeleteStatus | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteTemplateAgendaInput = {
  id: string,
};

export type CreateAgendaInput = {
  id?: string | null,
  oneOnOneID: string,
  title: string,
  order: number,
  referenceID: string,
  agendaType: AgendaType,
  isDone: boolean,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  owners: Array< string | null >,
};

export enum AgendaType {
  individualAgenda = "individualAgenda",
  goalAgenda = "goalAgenda",
  templateAgenda = "templateAgenda",
  organizationAgenda = "organizationAgenda",
}


export type ModelAgendaConditionInput = {
  oneOnOneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  order?: ModelIntInput | null,
  referenceID?: ModelIDInput | null,
  agendaType?: ModelAgendaTypeInput | null,
  isDone?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAgendaConditionInput | null > | null,
  or?: Array< ModelAgendaConditionInput | null > | null,
  not?: ModelAgendaConditionInput | null,
};

export type ModelAgendaTypeInput = {
  eq?: AgendaType | null,
  ne?: AgendaType | null,
};

export type Agenda = {
  __typename: "Agenda",
  id: string,
  oneOnOneID: string,
  title: string,
  order: number,
  referenceID: string,
  agendaType: AgendaType,
  isDone: boolean,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  owners: Array< string | null >,
  creator?: User | null,
  comments?: ModelAgendaCommentConnection | null,
};

export type ModelAgendaCommentConnection = {
  __typename: "ModelAgendaCommentConnection",
  items:  Array<AgendaComment | null >,
  nextToken?: string | null,
};

export type AgendaComment = {
  __typename: "AgendaComment",
  id: string,
  oneOnOneID: string,
  agendaID: string,
  comment: string,
  commenterID: string,
  organizationGroup: string,
  aiAdvice?: string | null,
  createdAt: string,
  updatedAt: string,
  commenter: User,
};

export type UpdateAgendaInput = {
  id: string,
  oneOnOneID?: string | null,
  title?: string | null,
  order?: number | null,
  referenceID?: string | null,
  agendaType?: AgendaType | null,
  isDone?: boolean | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owners?: Array< string | null > | null,
};

export type DeleteAgendaInput = {
  id: string,
};

export type CreateAgendaCommentInput = {
  id?: string | null,
  oneOnOneID: string,
  agendaID: string,
  comment: string,
  commenterID: string,
  organizationGroup: string,
  aiAdvice?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelAgendaCommentConditionInput = {
  oneOnOneID?: ModelIDInput | null,
  agendaID?: ModelIDInput | null,
  comment?: ModelStringInput | null,
  aiAdvice?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAgendaCommentConditionInput | null > | null,
  or?: Array< ModelAgendaCommentConditionInput | null > | null,
  not?: ModelAgendaCommentConditionInput | null,
};

export type UpdateAgendaCommentInput = {
  id: string,
  oneOnOneID?: string | null,
  agendaID?: string | null,
  comment?: string | null,
  commenterID?: string | null,
  organizationGroup?: string | null,
  aiAdvice?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteAgendaCommentInput = {
  id: string,
};

export type CreateNoteInput = {
  id?: string | null,
  meeting: string,
  agendaID?: string | null,
  referenceID?: string | null,
  user: string,
  note?: string | null,
  type: NoteType,
  owners: Array< string | null >,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  groupsCanAccess: Array< string | null >,
  owner?: string | null,
};

export enum NoteType {
  comment = "comment",
  reportComment = "reportComment",
  goalComment = "goalComment",
  publicNote = "publicNote",
  privateNote = "privateNote",
}


export type ModelNoteConditionInput = {
  meeting?: ModelIDInput | null,
  agendaID?: ModelIDInput | null,
  referenceID?: ModelIDInput | null,
  user?: ModelIDInput | null,
  note?: ModelStringInput | null,
  type?: ModelNoteTypeInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  and?: Array< ModelNoteConditionInput | null > | null,
  or?: Array< ModelNoteConditionInput | null > | null,
  not?: ModelNoteConditionInput | null,
};

export type ModelNoteTypeInput = {
  eq?: NoteType | null,
  ne?: NoteType | null,
};

export type Note = {
  __typename: "Note",
  id: string,
  meeting: string,
  agendaID?: string | null,
  referenceID?: string | null,
  user: string,
  note?: string | null,
  type: NoteType,
  owners: Array< string | null >,
  organizationGroup?: string | null,
  adminGroup: string,
  createdAt: string,
  updatedAt: string,
  delete?: boolean | null,
  deleteStatus: DeleteStatus,
  groupsCanAccess: Array< string | null >,
  owner?: string | null,
};

export type UpdateNoteInput = {
  id: string,
  meeting?: string | null,
  agendaID?: string | null,
  referenceID?: string | null,
  user?: string | null,
  note?: string | null,
  type?: NoteType | null,
  owners?: Array< string | null > | null,
  organizationGroup?: string | null,
  adminGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  delete?: boolean | null,
  deleteStatus?: DeleteStatus | null,
  groupsCanAccess?: Array< string | null > | null,
};

export type DeleteNoteInput = {
  id: string,
};

export type CreateOneOnOneSettingInput = {
  id?: string | null,
  userID1: string,
  userID2: string,
  frequency: Frequency,
  nextOneOnOneDate: string,
  nextOneOnOneTime: string,
  agendaTemplateID?: string | null,
  status: OneOnOneSettingStatus,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelOneOnOneSettingConditionInput = {
  userID1?: ModelIDInput | null,
  userID2?: ModelIDInput | null,
  frequency?: ModelFrequencyInput | null,
  nextOneOnOneDate?: ModelStringInput | null,
  nextOneOnOneTime?: ModelStringInput | null,
  agendaTemplateID?: ModelIDInput | null,
  status?: ModelOneOnOneSettingStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOneOnOneSettingConditionInput | null > | null,
  or?: Array< ModelOneOnOneSettingConditionInput | null > | null,
  not?: ModelOneOnOneSettingConditionInput | null,
};

export type ModelFrequencyInput = {
  eq?: Frequency | null,
  ne?: Frequency | null,
};

export type ModelOneOnOneSettingStatusInput = {
  eq?: OneOnOneSettingStatus | null,
  ne?: OneOnOneSettingStatus | null,
};

export type UpdateOneOnOneSettingInput = {
  id: string,
  userID1?: string | null,
  userID2?: string | null,
  frequency?: Frequency | null,
  nextOneOnOneDate?: string | null,
  nextOneOnOneTime?: string | null,
  agendaTemplateID?: string | null,
  status?: OneOnOneSettingStatus | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOneOnOneSettingInput = {
  id: string,
};

export type CreateOneOnOneInput = {
  id?: string | null,
  userID1: string,
  userID2: string,
  managerID?: string | null,
  oneOnOneSettingID: string,
  frequency: Frequency,
  startDate: string,
  startTime: string,
  status: OneOnOneStatus,
  agendaTemplateID?: string | null,
  recordingAgendaID?: string | null,
  recordingTarget?: RecordingTarget | null,
  aiSummary?: string | null,
  calendarApp?: CalendarApp | null,
  eventID?: string | null,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelOneOnOneConditionInput = {
  userID1?: ModelIDInput | null,
  userID2?: ModelIDInput | null,
  managerID?: ModelIDInput | null,
  oneOnOneSettingID?: ModelIDInput | null,
  frequency?: ModelFrequencyInput | null,
  startDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  status?: ModelOneOnOneStatusInput | null,
  agendaTemplateID?: ModelIDInput | null,
  recordingAgendaID?: ModelIDInput | null,
  recordingTarget?: ModelRecordingTargetInput | null,
  aiSummary?: ModelStringInput | null,
  calendarApp?: ModelCalendarAppInput | null,
  eventID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOneOnOneConditionInput | null > | null,
  or?: Array< ModelOneOnOneConditionInput | null > | null,
  not?: ModelOneOnOneConditionInput | null,
};

export type ModelOneOnOneStatusInput = {
  eq?: OneOnOneStatus | null,
  ne?: OneOnOneStatus | null,
};

export type ModelRecordingTargetInput = {
  eq?: RecordingTarget | null,
  ne?: RecordingTarget | null,
};

export type ModelCalendarAppInput = {
  eq?: CalendarApp | null,
  ne?: CalendarApp | null,
};

export type UpdateOneOnOneInput = {
  id: string,
  userID1?: string | null,
  userID2?: string | null,
  managerID?: string | null,
  oneOnOneSettingID?: string | null,
  frequency?: Frequency | null,
  startDate?: string | null,
  startTime?: string | null,
  status?: OneOnOneStatus | null,
  agendaTemplateID?: string | null,
  recordingAgendaID?: string | null,
  recordingTarget?: RecordingTarget | null,
  aiSummary?: string | null,
  calendarApp?: CalendarApp | null,
  eventID?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteOneOnOneInput = {
  id: string,
};

export type CreateShareMemoInput = {
  id?: string | null,
  oneOnOneID: string,
  ownerID: string,
  memo?: string | null,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelShareMemoConditionInput = {
  oneOnOneID?: ModelIDInput | null,
  ownerID?: ModelIDInput | null,
  memo?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShareMemoConditionInput | null > | null,
  or?: Array< ModelShareMemoConditionInput | null > | null,
  not?: ModelShareMemoConditionInput | null,
};

export type ShareMemo = {
  __typename: "ShareMemo",
  id: string,
  oneOnOneID: string,
  ownerID: string,
  memo?: string | null,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  owner: User,
};

export type UpdateShareMemoInput = {
  id: string,
  oneOnOneID?: string | null,
  ownerID?: string | null,
  memo?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteShareMemoInput = {
  id: string,
};

export type CreatePrivateMemoInput = {
  id?: string | null,
  oneOnOneID: string,
  memo?: string | null,
  ownerID: string,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelPrivateMemoConditionInput = {
  oneOnOneID?: ModelIDInput | null,
  memo?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPrivateMemoConditionInput | null > | null,
  or?: Array< ModelPrivateMemoConditionInput | null > | null,
  not?: ModelPrivateMemoConditionInput | null,
};

export type PrivateMemo = {
  __typename: "PrivateMemo",
  id: string,
  oneOnOneID: string,
  memo?: string | null,
  ownerID: string,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  owner: User,
};

export type UpdatePrivateMemoInput = {
  id: string,
  oneOnOneID?: string | null,
  memo?: string | null,
  ownerID?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeletePrivateMemoInput = {
  id: string,
};

export type CreateGradeMatrixInput = {
  gradeMatrixID: string,
  title: string,
  description?: string | null,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelGradeMatrixConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGradeMatrixConditionInput | null > | null,
  or?: Array< ModelGradeMatrixConditionInput | null > | null,
  not?: ModelGradeMatrixConditionInput | null,
};

export type UpdateGradeMatrixInput = {
  gradeMatrixID: string,
  title?: string | null,
  description?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteGradeMatrixInput = {
  gradeMatrixID: string,
};

export type CreateCompetencyAreaInput = {
  competencyAreaID: string,
  title: string,
  description?: string | null,
  gradeMatrixID: string,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCompetencyAreaConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  gradeMatrixID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompetencyAreaConditionInput | null > | null,
  or?: Array< ModelCompetencyAreaConditionInput | null > | null,
  not?: ModelCompetencyAreaConditionInput | null,
};

export type UpdateCompetencyAreaInput = {
  competencyAreaID: string,
  title?: string | null,
  description?: string | null,
  gradeMatrixID?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCompetencyAreaInput = {
  competencyAreaID: string,
};

export type CreateGradeInput = {
  gradeID: string,
  title: string,
  description?: string | null,
  gradeMatrixID: string,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelGradeConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  gradeMatrixID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGradeConditionInput | null > | null,
  or?: Array< ModelGradeConditionInput | null > | null,
  not?: ModelGradeConditionInput | null,
};

export type UpdateGradeInput = {
  gradeID: string,
  title?: string | null,
  description?: string | null,
  gradeMatrixID?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteGradeInput = {
  gradeID: string,
};

export type CreateCompetencyItemInput = {
  competencyItemID: string,
  item: string,
  gradeID: string,
  competencyAreaID: string,
  gradeMatrixID: string,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelCompetencyItemConditionInput = {
  item?: ModelStringInput | null,
  gradeID?: ModelIDInput | null,
  competencyAreaID?: ModelIDInput | null,
  gradeMatrixID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompetencyItemConditionInput | null > | null,
  or?: Array< ModelCompetencyItemConditionInput | null > | null,
  not?: ModelCompetencyItemConditionInput | null,
};

export type UpdateCompetencyItemInput = {
  competencyItemID: string,
  item?: string | null,
  gradeID?: string | null,
  competencyAreaID?: string | null,
  gradeMatrixID?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCompetencyItemInput = {
  competencyItemID: string,
};

export type CreateSkillMapInput = {
  id?: string | null,
  name: string,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelSkillMapConditionInput = {
  name?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSkillMapConditionInput | null > | null,
  or?: Array< ModelSkillMapConditionInput | null > | null,
  not?: ModelSkillMapConditionInput | null,
};

export type SkillMap = {
  __typename: "SkillMap",
  id: string,
  name: string,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSkillMapInput = {
  id: string,
  name?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteSkillMapInput = {
  id: string,
};

export type CreateSkillItemInput = {
  id?: string | null,
  skillMapID: string,
  title: string,
  description?: string | null,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelSkillItemConditionInput = {
  skillMapID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSkillItemConditionInput | null > | null,
  or?: Array< ModelSkillItemConditionInput | null > | null,
  not?: ModelSkillItemConditionInput | null,
};

export type SkillItem = {
  __typename: "SkillItem",
  id: string,
  skillMapID: string,
  title: string,
  description?: string | null,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSkillItemInput = {
  id: string,
  skillMapID?: string | null,
  title?: string | null,
  description?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteSkillItemInput = {
  id: string,
};

export type CreateLevelInput = {
  id?: string | null,
  skillMapID: string,
  title: string,
  description?: string | null,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelLevelConditionInput = {
  skillMapID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLevelConditionInput | null > | null,
  or?: Array< ModelLevelConditionInput | null > | null,
  not?: ModelLevelConditionInput | null,
};

export type Level = {
  __typename: "Level",
  id: string,
  skillMapID: string,
  title: string,
  description?: string | null,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateLevelInput = {
  id: string,
  skillMapID?: string | null,
  title?: string | null,
  description?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteLevelInput = {
  id: string,
};

export type CreateSkillInput = {
  id?: string | null,
  skillMapID: string,
  levelID: string,
  skillItemID: string,
  outcome: string,
  process: string,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelSkillConditionInput = {
  skillMapID?: ModelIDInput | null,
  levelID?: ModelIDInput | null,
  skillItemID?: ModelIDInput | null,
  outcome?: ModelStringInput | null,
  process?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSkillConditionInput | null > | null,
  or?: Array< ModelSkillConditionInput | null > | null,
  not?: ModelSkillConditionInput | null,
};

export type Skill = {
  __typename: "Skill",
  id: string,
  skillMapID: string,
  levelID: string,
  skillItemID: string,
  outcome: string,
  process: string,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSkillInput = {
  id: string,
  skillMapID?: string | null,
  levelID?: string | null,
  skillItemID?: string | null,
  outcome?: string | null,
  process?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteSkillInput = {
  id: string,
};

export type CreateIndividualSkillInput = {
  id?: string | null,
  skillMapID: string,
  skillID: string,
  acquisitionStatus: AcquisitionStatus,
  acquisitionStartDate?: string | null,
  acquisitionEndDate?: string | null,
  userID: string,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum AcquisitionStatus {
  notStarted = "notStarted",
  inProgress = "inProgress",
  completed = "completed",
}


export type ModelIndividualSkillConditionInput = {
  skillMapID?: ModelIDInput | null,
  skillID?: ModelIDInput | null,
  acquisitionStatus?: ModelAcquisitionStatusInput | null,
  acquisitionStartDate?: ModelStringInput | null,
  acquisitionEndDate?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIndividualSkillConditionInput | null > | null,
  or?: Array< ModelIndividualSkillConditionInput | null > | null,
  not?: ModelIndividualSkillConditionInput | null,
};

export type ModelAcquisitionStatusInput = {
  eq?: AcquisitionStatus | null,
  ne?: AcquisitionStatus | null,
};

export type IndividualSkill = {
  __typename: "IndividualSkill",
  id: string,
  skillMapID: string,
  skillID: string,
  acquisitionStatus: AcquisitionStatus,
  acquisitionStartDate?: string | null,
  acquisitionEndDate?: string | null,
  userID: string,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  user: User,
};

export type UpdateIndividualSkillInput = {
  id: string,
  skillMapID?: string | null,
  skillID?: string | null,
  acquisitionStatus?: AcquisitionStatus | null,
  acquisitionStartDate?: string | null,
  acquisitionEndDate?: string | null,
  userID?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteIndividualSkillInput = {
  id: string,
};

export type CreateReviewResultInput = {
  reviewResultID: string,
  reviewID: string,
  revieweeID: string,
  finalReview?: string | null,
  nextGradeID?: string | null,
  nextBasicSalary?: number | null,
  salaryIncrease?: number | null,
  bonus?: number | null,
  shareStatus: ShareStatus,
  openStatus: OpenStatus,
  calculateResult?: CalculateResultInput | null,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum ShareStatus {
  Shared = "Shared",
  Unshared = "Unshared",
}


export enum OpenStatus {
  Unopened = "Unopened",
  Opened = "Opened",
}


export type CalculateResultInput = {
  overallCalculateResult?: number | null,
  averageCalculateResult?: number | null,
  customReviewCalculateResults?: Array< CustomReviewCalculateResultInput > | null,
  goalReviewCalculateResult?: number | null,
  goalReviewCalculateAverage?: number | null,
  competencyReviewCalculateResult?: number | null,
  competencyReviewCalculateAverage?: number | null,
};

export type CustomReviewCalculateResultInput = {
  customReviewID?: string | null,
  result?: number | null,
  average?: number | null,
};

export type ModelReviewResultConditionInput = {
  reviewID?: ModelIDInput | null,
  revieweeID?: ModelIDInput | null,
  finalReview?: ModelStringInput | null,
  nextGradeID?: ModelIDInput | null,
  nextBasicSalary?: ModelIntInput | null,
  salaryIncrease?: ModelIntInput | null,
  bonus?: ModelIntInput | null,
  shareStatus?: ModelShareStatusInput | null,
  openStatus?: ModelOpenStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReviewResultConditionInput | null > | null,
  or?: Array< ModelReviewResultConditionInput | null > | null,
  not?: ModelReviewResultConditionInput | null,
};

export type ModelShareStatusInput = {
  eq?: ShareStatus | null,
  ne?: ShareStatus | null,
};

export type ModelOpenStatusInput = {
  eq?: OpenStatus | null,
  ne?: OpenStatus | null,
};

export type ReviewResult = {
  __typename: "ReviewResult",
  reviewResultID: string,
  reviewID: string,
  revieweeID: string,
  finalReview?: string | null,
  nextGradeID?: string | null,
  nextBasicSalary?: number | null,
  salaryIncrease?: number | null,
  bonus?: number | null,
  shareStatus: ShareStatus,
  openStatus: OpenStatus,
  calculateResult?: CalculateResult | null,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  reviewee?: User | null,
  grade?: Grade | null,
  owner?: string | null,
  individualReviewSheetList?: ModelIndividualReviewSheetConnection | null,
};

export type CalculateResult = {
  __typename: "CalculateResult",
  overallCalculateResult?: number | null,
  averageCalculateResult?: number | null,
  customReviewCalculateResults?:  Array<CustomReviewCalculateResult > | null,
  goalReviewCalculateResult?: number | null,
  goalReviewCalculateAverage?: number | null,
  competencyReviewCalculateResult?: number | null,
  competencyReviewCalculateAverage?: number | null,
};

export type CustomReviewCalculateResult = {
  __typename: "CustomReviewCalculateResult",
  customReviewID?: string | null,
  result?: number | null,
  average?: number | null,
};

export type ModelIndividualReviewSheetConnection = {
  __typename: "ModelIndividualReviewSheetConnection",
  items:  Array<IndividualReviewSheet | null >,
  nextToken?: string | null,
};

export type IndividualReviewSheet = {
  __typename: "IndividualReviewSheet",
  individualReviewSheetID: string,
  name: string,
  reviewerID: string,
  revieweeID: string,
  reviewOrder: ReviewOrder,
  summary?: string | null,
  responseStage: ResponseStage,
  reviewID: string,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  reviewAnswerList?:  Array<ReviewAnswer | null > | null,
  calculateResult?: CalculateResult | null,
};

export enum ReviewOrder {
  SelfReview = "SelfReview",
  FirstReview = "FirstReview",
  SecondReview = "SecondReview",
}


export enum ResponseStage {
  NotStarted = "NotStarted",
  Writing = "Writing",
  Complete = "Complete",
}


export type ReviewAnswer = {
  __typename: "ReviewAnswer",
  reviewItemTitle: string,
  description?: string | null,
  customReviewID?: string | null,
  customReviewName?: string | null,
  goalID?: string | null,
  goalProgress?: Progress | null,
  finalValue?: number | null,
  targetValue?: number | null,
  goalMeasuringType?: MeasuringType | null,
  goalResultDate?: string | null,
  competencyItemID?: string | null,
  weight?: number | null,
  answer: string,
  point?: number | null,
  answerBasis?: string | null,
};

export type UpdateReviewResultInput = {
  reviewResultID: string,
  reviewID?: string | null,
  revieweeID?: string | null,
  finalReview?: string | null,
  nextGradeID?: string | null,
  nextBasicSalary?: number | null,
  salaryIncrease?: number | null,
  bonus?: number | null,
  shareStatus?: ShareStatus | null,
  openStatus?: OpenStatus | null,
  calculateResult?: CalculateResultInput | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteReviewResultInput = {
  reviewResultID: string,
};

export type CreateIndividualReviewSheetInput = {
  individualReviewSheetID: string,
  name: string,
  reviewerID: string,
  revieweeID: string,
  reviewOrder: ReviewOrder,
  summary?: string | null,
  responseStage: ResponseStage,
  reviewID: string,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  reviewAnswerList?: Array< ReviewAnswerInput | null > | null,
  calculateResult?: CalculateResultInput | null,
};

export type ReviewAnswerInput = {
  reviewItemTitle: string,
  description?: string | null,
  customReviewID?: string | null,
  customReviewName?: string | null,
  goalID?: string | null,
  goalProgress?: Progress | null,
  finalValue?: number | null,
  targetValue?: number | null,
  goalMeasuringType?: MeasuringType | null,
  goalResultDate?: string | null,
  competencyItemID?: string | null,
  weight?: number | null,
  answer: string,
  point?: number | null,
  answerBasis?: string | null,
};

export type ModelIndividualReviewSheetConditionInput = {
  name?: ModelStringInput | null,
  reviewerID?: ModelIDInput | null,
  revieweeID?: ModelIDInput | null,
  reviewOrder?: ModelReviewOrderInput | null,
  summary?: ModelStringInput | null,
  responseStage?: ModelResponseStageInput | null,
  reviewID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIndividualReviewSheetConditionInput | null > | null,
  or?: Array< ModelIndividualReviewSheetConditionInput | null > | null,
  not?: ModelIndividualReviewSheetConditionInput | null,
};

export type ModelReviewOrderInput = {
  eq?: ReviewOrder | null,
  ne?: ReviewOrder | null,
};

export type ModelResponseStageInput = {
  eq?: ResponseStage | null,
  ne?: ResponseStage | null,
};

export type UpdateIndividualReviewSheetInput = {
  individualReviewSheetID: string,
  name?: string | null,
  reviewerID?: string | null,
  revieweeID?: string | null,
  reviewOrder?: ReviewOrder | null,
  summary?: string | null,
  responseStage?: ResponseStage | null,
  reviewID?: string | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  reviewAnswerList?: Array< ReviewAnswerInput | null > | null,
  calculateResult?: CalculateResultInput | null,
};

export type DeleteIndividualReviewSheetInput = {
  individualReviewSheetID: string,
};

export type CreateReviewInput = {
  reviewID: string,
  name: string,
  revieweeSetting: RevieweeSetting,
  revieweeReviewerSettings?: Array< RevieweeReviewerSettingInput > | null,
  reviewTemplateID?: string | null,
  reviewPeriod?: ReviewPeriodInput | null,
  targetGoalPeriod?: TargetGoalPeriodInput | null,
  remindNotificationDate?: string | null,
  isSummary: boolean,
  isCalculate: boolean,
  reviewStage: ReviewStage,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export enum RevieweeSetting {
  All = "All",
  Individual = "Individual",
}


export type RevieweeReviewerSettingInput = {
  revieweeID: string,
  gradeID?: string | null,
  firstReviewerIDs?: Array< string > | null,
  secondReviewerIDs?: Array< string > | null,
};

export type ReviewPeriodInput = {
  startDate?: string | null,
  endDate?: string | null,
};

export type TargetGoalPeriodInput = {
  startDate?: string | null,
  endDate?: string | null,
};

export enum ReviewStage {
  Draft = "Draft",
  Upcoming = "Upcoming",
  Active = "Active",
  Scoring = "Scoring",
  BeforeShare = "BeforeShare",
  Shared = "Shared",
}


export type ModelReviewConditionInput = {
  name?: ModelStringInput | null,
  revieweeSetting?: ModelRevieweeSettingInput | null,
  reviewTemplateID?: ModelIDInput | null,
  remindNotificationDate?: ModelStringInput | null,
  isSummary?: ModelBooleanInput | null,
  isCalculate?: ModelBooleanInput | null,
  reviewStage?: ModelReviewStageInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
};

export type ModelRevieweeSettingInput = {
  eq?: RevieweeSetting | null,
  ne?: RevieweeSetting | null,
};

export type ModelReviewStageInput = {
  eq?: ReviewStage | null,
  ne?: ReviewStage | null,
};

export type Review = {
  __typename: "Review",
  reviewID: string,
  name: string,
  revieweeSetting: RevieweeSetting,
  revieweeReviewerSettings?:  Array<RevieweeReviewerSetting > | null,
  reviewTemplateID?: string | null,
  reviewPeriod?: ReviewPeriod | null,
  targetGoalPeriod?: TargetGoalPeriod | null,
  remindNotificationDate?: string | null,
  isSummary: boolean,
  isCalculate: boolean,
  reviewStage: ReviewStage,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
  reviewResultList?: ModelReviewResultConnection | null,
  individualReviewSheetList?: ModelIndividualReviewSheetConnection | null,
  reviewTemplate?: NewReviewTemplate | null,
};

export type RevieweeReviewerSetting = {
  __typename: "RevieweeReviewerSetting",
  revieweeID: string,
  gradeID?: string | null,
  firstReviewerIDs?: Array< string > | null,
  secondReviewerIDs?: Array< string > | null,
};

export type ReviewPeriod = {
  __typename: "ReviewPeriod",
  startDate?: string | null,
  endDate?: string | null,
};

export type TargetGoalPeriod = {
  __typename: "TargetGoalPeriod",
  startDate?: string | null,
  endDate?: string | null,
};

export type ModelReviewResultConnection = {
  __typename: "ModelReviewResultConnection",
  items:  Array<ReviewResult | null >,
  nextToken?: string | null,
};

export type NewReviewTemplate = {
  __typename: "NewReviewTemplate",
  reviewTemplateID: string,
  name: string,
  description?: string | null,
  reviewMethod: ReviewMethod,
  reviewSettings: ReviewSetting,
  calculable: boolean,
  organizationGroup: string,
  createdAt: string,
  updatedAt: string,
};

export type ReviewMethod = {
  __typename: "ReviewMethod",
  isSelfReview: boolean,
  isFirstReview: boolean,
  isSecondReview: boolean,
  isFinalReview: boolean,
};

export type ReviewSetting = {
  __typename: "ReviewSetting",
  customReviewSettings?:  Array<CustomReviewSetting > | null,
  goalReviewSetting: GoalReviewSetting,
  competencyReviewSetting: CompetencyReviewSetting,
  isWeight: boolean,
};

export type CustomReviewSetting = {
  __typename: "CustomReviewSetting",
  customReviewID: string,
  weight?: number | null,
};

export type GoalReviewSetting = {
  __typename: "GoalReviewSetting",
  isReviewInclude: boolean,
  question: string,
  description?: string | null,
  goalRange: GoalRange,
  goalStatus: GoalStatus,
  answerType: AnswerType,
  options?:  Array<Option > | null,
  numberRange?: NumberRange | null,
  isAnswerBasis: boolean,
  isAnswerBasisEntryOptional: boolean,
  weight?: number | null,
};

export enum GoalRange {
  Main = "Main",
  Sub = "Sub",
  Both = "Both",
}


export enum GoalStatus {
  All = "All",
  Completed = "Completed",
}


export enum AnswerType {
  OptionAnswer = "OptionAnswer",
  NumberAnswer = "NumberAnswer",
  QualitativeAnswer = "QualitativeAnswer",
}


export type Option = {
  __typename: "Option",
  item?: string | null,
  pointAllocation?: number | null,
};

export type NumberRange = {
  __typename: "NumberRange",
  lowerLimit?: number | null,
  upperLimit?: number | null,
};

export type CompetencyReviewSetting = {
  __typename: "CompetencyReviewSetting",
  isReviewInclude: boolean,
  question: string,
  description?: string | null,
  answerType: AnswerType,
  options?:  Array<Option > | null,
  numberRange?: NumberRange | null,
  isAnswerBasis: boolean,
  isAnswerBasisEntryOptional: boolean,
  weight?: number | null,
};

export type UpdateReviewInput = {
  reviewID: string,
  name?: string | null,
  revieweeSetting?: RevieweeSetting | null,
  revieweeReviewerSettings?: Array< RevieweeReviewerSettingInput > | null,
  reviewTemplateID?: string | null,
  reviewPeriod?: ReviewPeriodInput | null,
  targetGoalPeriod?: TargetGoalPeriodInput | null,
  remindNotificationDate?: string | null,
  isSummary?: boolean | null,
  isCalculate?: boolean | null,
  reviewStage?: ReviewStage | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteReviewInput = {
  reviewID: string,
};

export type CreateNewReviewTemplateInput = {
  reviewTemplateID: string,
  name: string,
  description?: string | null,
  reviewMethod: ReviewMethodInput,
  reviewSettings: ReviewSettingInput,
  calculable: boolean,
  organizationGroup: string,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ReviewMethodInput = {
  isSelfReview: boolean,
  isFirstReview: boolean,
  isSecondReview: boolean,
  isFinalReview: boolean,
};

export type ReviewSettingInput = {
  customReviewSettings?: Array< CustomReviewSettingInput > | null,
  goalReviewSetting: GoalReviewSettingInput,
  competencyReviewSetting: CompetencyReviewSettingInput,
  isWeight: boolean,
};

export type CustomReviewSettingInput = {
  customReviewID: string,
  weight?: number | null,
};

export type GoalReviewSettingInput = {
  isReviewInclude: boolean,
  question: string,
  description?: string | null,
  goalRange: GoalRange,
  goalStatus: GoalStatus,
  answerType: AnswerType,
  options?: Array< OptionInput > | null,
  numberRange?: NumberRangeInput | null,
  isAnswerBasis: boolean,
  isAnswerBasisEntryOptional: boolean,
  weight?: number | null,
};

export type OptionInput = {
  item?: string | null,
  pointAllocation?: number | null,
};

export type NumberRangeInput = {
  lowerLimit?: number | null,
  upperLimit?: number | null,
};

export type CompetencyReviewSettingInput = {
  isReviewInclude: boolean,
  question: string,
  description?: string | null,
  answerType: AnswerType,
  options?: Array< OptionInput > | null,
  numberRange?: NumberRangeInput | null,
  isAnswerBasis: boolean,
  isAnswerBasisEntryOptional: boolean,
  weight?: number | null,
};

export type ModelNewReviewTemplateConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  calculable?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNewReviewTemplateConditionInput | null > | null,
  or?: Array< ModelNewReviewTemplateConditionInput | null > | null,
  not?: ModelNewReviewTemplateConditionInput | null,
};

export type UpdateNewReviewTemplateInput = {
  reviewTemplateID: string,
  name?: string | null,
  description?: string | null,
  reviewMethod?: ReviewMethodInput | null,
  reviewSettings?: ReviewSettingInput | null,
  calculable?: boolean | null,
  organizationGroup?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteNewReviewTemplateInput = {
  reviewTemplateID: string,
};

export type CreateCustomReviewInput = {
  customReviewID: string,
  name: string,
  isWeight: boolean,
  customReviewItemList: Array< CustomReviewItemInput >,
  question: string,
  description?: string | null,
  answerType: AnswerType,
  organizationGroup: string,
  options?: Array< OptionInput > | null,
  numberRange?: NumberRangeInput | null,
  isAnswerBasis: boolean,
  isAnswerBasisEntryOptional: boolean,
  calculable: boolean,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type CustomReviewItemInput = {
  name: string,
  weight?: number | null,
};

export type ModelCustomReviewConditionInput = {
  name?: ModelStringInput | null,
  isWeight?: ModelBooleanInput | null,
  question?: ModelStringInput | null,
  description?: ModelStringInput | null,
  answerType?: ModelAnswerTypeInput | null,
  isAnswerBasis?: ModelBooleanInput | null,
  isAnswerBasisEntryOptional?: ModelBooleanInput | null,
  calculable?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCustomReviewConditionInput | null > | null,
  or?: Array< ModelCustomReviewConditionInput | null > | null,
  not?: ModelCustomReviewConditionInput | null,
};

export type ModelAnswerTypeInput = {
  eq?: AnswerType | null,
  ne?: AnswerType | null,
};

export type CustomReview = {
  __typename: "CustomReview",
  customReviewID: string,
  name: string,
  isWeight: boolean,
  customReviewItemList:  Array<CustomReviewItem >,
  question: string,
  description?: string | null,
  answerType: AnswerType,
  organizationGroup: string,
  options?:  Array<Option > | null,
  numberRange?: NumberRange | null,
  isAnswerBasis: boolean,
  isAnswerBasisEntryOptional: boolean,
  calculable: boolean,
  createdAt: string,
  updatedAt: string,
};

export type CustomReviewItem = {
  __typename: "CustomReviewItem",
  name: string,
  weight?: number | null,
};

export type UpdateCustomReviewInput = {
  customReviewID: string,
  name?: string | null,
  isWeight?: boolean | null,
  customReviewItemList?: Array< CustomReviewItemInput > | null,
  question?: string | null,
  description?: string | null,
  answerType?: AnswerType | null,
  organizationGroup?: string | null,
  options?: Array< OptionInput > | null,
  numberRange?: NumberRangeInput | null,
  isAnswerBasis?: boolean | null,
  isAnswerBasisEntryOptional?: boolean | null,
  calculable?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteCustomReviewInput = {
  customReviewID: string,
};

export enum OnoReportQueriesGroupBy {
  individual = "individual",
  manager = "manager",
  team = "team",
  organization = "organization",
}


export type SearchPeriod = {
  fromDate: string,
  toDate: string,
};

export type ListGoalReportsResponse = {
  __typename: "ListGoalReportsResponse",
  graphDataLabels: Array< string | null >,
  graphData:  Array<GoalReportGraphDatum | null >,
  tableData:  Array<GoalReportTableDatum | null >,
};

export type GoalReportGraphDatum = {
  __typename: "GoalReportGraphDatum",
  id: Progress,
  title: string,
  values: Array< number | null >,
};

export type GoalReportTableDatum = {
  __typename: "GoalReportTableDatum",
  id: string,
  title: string,
  allCount: number,
  notUpdatedCount: number,
  offTrackCount: number,
  progressingCount: number,
  onTrackCount: number,
  imperfectCount: number,
  perfectCount: number,
};

export enum AnalysisReportType {
  ono = "ono",
  agenda = "agenda",
  all = "all",
}


export type AnalysisReportQueriesResponse = {
  __typename: "AnalysisReportQueriesResponse",
  agendaTableData?:  Array<AnalysisAgendaTableData | null > | null,
  onoTableData?:  Array<AnalysisOnoTableData | null > | null,
};

export type AnalysisAgendaTableData = {
  __typename: "AnalysisAgendaTableData",
  type: AgendaType,
  title: string,
  completedCount: number,
  wholeCount: number,
};

export type AnalysisOnoTableData = {
  __typename: "AnalysisOnoTableData",
  id: string,
  name: string,
  settingRate: number,
  memberItems:  Array<AnalysisOnoMemberItem | null >,
};

export type AnalysisOnoMemberItem = {
  __typename: "AnalysisOnoMemberItem",
  id: string,
  name: string,
  setting: boolean,
  wholeMeetings: number,
  participatedMeetings: number,
};

export type OneOnOneAnalysisResponse = {
  __typename: "OneOnOneAnalysisResponse",
  managerID: string,
  managerName: string,
  settingRate: number,
  memberOneOnOneAnalysisDataArray:  Array<MemberOneOnOneAnalysisData | null >,
};

export type MemberOneOnOneAnalysisData = {
  __typename: "MemberOneOnOneAnalysisData",
  memberID: string,
  memberName: string,
  oneOnOneCount: number,
  participatedOneOnOneCount: number,
};

export type AgendaAnalysisResponse = {
  __typename: "AgendaAnalysisResponse",
  type: AgendaType,
  title: string,
  displayCount: number,
  usedCount: number,
};

export enum OneOnOneHistoryType {
  Organization = "Organization",
  Individual = "Individual",
  Profile = "Profile",
}


export type DownloadOneOnOneHistoryResponse = {
  __typename: "DownloadOneOnOneHistoryResponse",
  id: string,
  managerName?: string | null,
  user1Name: string,
  user2Name?: string | null,
  startDateTime: string,
  status: OneOnOneStatus,
  agendaContents?:  Array<AgendaContent > | null,
  nextActions?:  Array<NextActionContent > | null,
  shareMemos?:  Array<ShareMemoContent > | null,
};

export type AgendaContent = {
  __typename: "AgendaContent",
  agenda: string,
  comments?: Array< string > | null,
};

export type NextActionContent = {
  __typename: "NextActionContent",
  nextAction: string,
  targetDate: string,
  manager: string,
};

export type ShareMemoContent = {
  __typename: "ShareMemoContent",
  memo: string,
  writer: string,
};

export type ModelTeamFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  originalPhotoUrl?: ModelStringInput | null,
  thumbnailPhotoUrl?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  and?: Array< ModelTeamFilterInput | null > | null,
  or?: Array< ModelTeamFilterInput | null > | null,
  not?: ModelTeamFilterInput | null,
};

export type ModelTeamConnection = {
  __typename: "ModelTeamConnection",
  items:  Array<Team | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelMemberManagerFilterInput = {
  id?: ModelIDInput | null,
  managerID?: ModelIDInput | null,
  memberID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  and?: Array< ModelMemberManagerFilterInput | null > | null,
  or?: Array< ModelMemberManagerFilterInput | null > | null,
  not?: ModelMemberManagerFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  account?: ModelStringInput | null,
  owner?: ModelIDInput | null,
  originalPhotoUrl?: ModelStringInput | null,
  thumbnailPhotoUrl?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  organizationID?: ModelIDInput | null,
  status?: ModelUserStatusInput | null,
  admin?: ModelBooleanInput | null,
  sub?: ModelStringInput | null,
  socialType?: ModelSocialTypeInput | null,
  drivingScore?: ModelIntInput | null,
  analyticalScore?: ModelIntInput | null,
  amiableScore?: ModelIntInput | null,
  expressiveScore?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  email?: ModelStringInput | null,
  teamID?: ModelStringInput | null,
  managers?: ModelIDInput | null,
  googleRefreshToken?: ModelIDInput | null,
  googleAccountEmail?: ModelStringInput | null,
  about?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelStringInput | null,
  employeeId?: ModelStringInput | null,
  teamCode?: ModelStringInput | null,
  readingFirstName?: ModelStringInput | null,
  readingLastName?: ModelStringInput | null,
  birthDay?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  address?: ModelStringInput | null,
  joinedCompanyDay?: ModelStringInput | null,
  retirementDay?: ModelStringInput | null,
  jobType?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  enrollmentStatus?: ModelEnrollmentStatusInput | null,
  recruitmentClassification?: ModelRecruitmentClassificationInput | null,
  department?: ModelStringInput | null,
  division?: ModelStringInput | null,
  section?: ModelStringInput | null,
  position?: ModelStringInput | null,
  gradeID?: ModelIDInput | null,
  allowance?: ModelIntInput | null,
  bonus?: ModelIntInput | null,
  gradeMatrixID?: ModelIDInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelHistoryFilterInput = {
  id?: ModelIDInput | null,
  user?: ModelIDInput | null,
  value?: ModelStringInput | null,
  subValue?: ModelStringInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  type?: ModelHistoryTypeInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelHistoryFilterInput | null > | null,
  or?: Array< ModelHistoryFilterInput | null > | null,
  not?: ModelHistoryFilterInput | null,
};

export type ModelHistoryConnection = {
  __typename: "ModelHistoryConnection",
  items:  Array<History | null >,
  nextToken?: string | null,
};

export type ModelSettingFilterInput = {
  id?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelSettingFilterInput | null > | null,
  or?: Array< ModelSettingFilterInput | null > | null,
  not?: ModelSettingFilterInput | null,
};

export type ModelSettingConnection = {
  __typename: "ModelSettingConnection",
  items:  Array<Setting | null >,
  nextToken?: string | null,
};

export type ModelTaskFilterInput = {
  taskID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  title?: ModelStringInput | null,
  taskType?: ModelTaskTypeInput | null,
  complete?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  taskLink?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  reviewSheetID?: ModelIDInput | null,
  goalID?: ModelIDInput | null,
  and?: Array< ModelTaskFilterInput | null > | null,
  or?: Array< ModelTaskFilterInput | null > | null,
  not?: ModelTaskFilterInput | null,
};

export type ModelTaskConnection = {
  __typename: "ModelTaskConnection",
  items:  Array<Task | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  webhookURL?: ModelStringInput | null,
  mailingList?: ModelStringInput | null,
  adminGroupName?: ModelStringInput | null,
  originalPhotoUrl?: ModelStringInput | null,
  thumbnailPhotoUrl?: ModelStringInput | null,
  coverImageUrl?: ModelStringInput | null,
  groupName?: ModelStringInput | null,
  numberOfUsers?: ModelIntInput | null,
  agreementUpdateDate?: ModelStringInput | null,
  assessmentFromDate?: ModelStringInput | null,
  assessmentToDate?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  totalAudioDuration?: ModelIntInput | null,
  status?: ModelOrganizationStatusInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  microsoftAppId?: ModelStringInput | null,
  microsoftTenant?: ModelStringInput | null,
  microsoftClientSecret?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelGoal3FilterInput = {
  id?: ModelIDInput | null,
  organization?: ModelIDInput | null,
  teamID?: ModelIDInput | null,
  value?: ModelStringInput | null,
  detail?: ModelStringInput | null,
  progressType?: ModelGoalProgressTypeInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  owners?: ModelIDInput | null,
  type?: ModelGoalType2Input | null,
  visibility?: ModelGoalVisibilityInput | null,
  readers?: ModelIDInput | null,
  parentGoalID?: ModelStringInput | null,
  priority?: ModelIntInput | null,
  achievement?: ModelGoalAchievementInput | null,
  progress?: ModelProgressInput | null,
  totalValue?: ModelFloatInput | null,
  start?: ModelFloatInput | null,
  target?: ModelFloatInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  archiveStatus?: ModelStringInput | null,
  and?: Array< ModelGoal3FilterInput | null > | null,
  or?: Array< ModelGoal3FilterInput | null > | null,
  not?: ModelGoal3FilterInput | null,
};

export type ModelGoal3Connection = {
  __typename: "ModelGoal3Connection",
  items:  Array<Goal3 | null >,
  nextToken?: string | null,
};

export type ModelGoalFilterInput = {
  goalID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  parentGoalID?: ModelIDInput | null,
  departmentID?: ModelStringInput | null,
  directorIDs?: ModelIDInput | null,
  readerIDs?: ModelIDInput | null,
  goalClassification?: ModelGoalClassificationInput | null,
  goalTitle?: ModelStringInput | null,
  goalDetail?: ModelStringInput | null,
  measuringType?: ModelMeasuringTypeInput | null,
  startDate?: ModelStringInput | null,
  targetDate?: ModelStringInput | null,
  goalType?: ModelGoalTypeInput | null,
  publicScope?: ModelPublicScopeInput | null,
  archiveStatus?: ModelStringInput | null,
  startValue?: ModelFloatInput | null,
  targetValue?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  deleteStatus?: ModelStringInput | null,
  goalWeightPoint?: ModelIntInput | null,
  and?: Array< ModelGoalFilterInput | null > | null,
  or?: Array< ModelGoalFilterInput | null > | null,
  not?: ModelGoalFilterInput | null,
};

export type ModelGoalConnection = {
  __typename: "ModelGoalConnection",
  items:  Array<Goal | null >,
  nextToken?: string | null,
};

export type ModelGoalListGoalByOrganizationGroupCompositeKeyConditionInput = {
  eq?: ModelGoalListGoalByOrganizationGroupCompositeKeyInput | null,
  le?: ModelGoalListGoalByOrganizationGroupCompositeKeyInput | null,
  lt?: ModelGoalListGoalByOrganizationGroupCompositeKeyInput | null,
  ge?: ModelGoalListGoalByOrganizationGroupCompositeKeyInput | null,
  gt?: ModelGoalListGoalByOrganizationGroupCompositeKeyInput | null,
  between?: Array< ModelGoalListGoalByOrganizationGroupCompositeKeyInput | null > | null,
  beginsWith?: ModelGoalListGoalByOrganizationGroupCompositeKeyInput | null,
};

export type ModelGoalListGoalByOrganizationGroupCompositeKeyInput = {
  archiveStatus?: string | null,
  deleteStatus?: string | null,
};

export type ModelGoalActivityFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  goalID?: ModelIDInput | null,
  keyResultID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  value?: ModelIntInput | null,
  detail?: ModelStringInput | null,
  progress?: ModelGoalProgress2Input | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  and?: Array< ModelGoalActivityFilterInput | null > | null,
  or?: Array< ModelGoalActivityFilterInput | null > | null,
  not?: ModelGoalActivityFilterInput | null,
};

export type ModelGoalActivityConnection = {
  __typename: "ModelGoalActivityConnection",
  items:  Array<GoalActivity | null >,
  nextToken?: string | null,
};

export type ModelGoalProgressFilterInput = {
  goalProgressID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  goalID?: ModelIDInput | null,
  progress?: ModelProgressInput | null,
  progressDetail?: ModelStringInput | null,
  value?: ModelFloatInput | null,
  creatorID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  deleteStatus?: ModelStringInput | null,
  and?: Array< ModelGoalProgressFilterInput | null > | null,
  or?: Array< ModelGoalProgressFilterInput | null > | null,
  not?: ModelGoalProgressFilterInput | null,
};

export type ModelGoalProgressCommentFilterInput = {
  goalProgressCommentID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  commenterID?: ModelIDInput | null,
  commentContent?: ModelStringInput | null,
  goalProgressID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGoalProgressCommentFilterInput | null > | null,
  or?: Array< ModelGoalProgressCommentFilterInput | null > | null,
  not?: ModelGoalProgressCommentFilterInput | null,
};

export type ModelOnoFilterInput = {
  id?: ModelIDInput | null,
  member?: ModelIDInput | null,
  manager?: ModelIDInput | null,
  frequency?: ModelFrequencyTypeInput | null,
  nextMeetingDate?: ModelStringInput | null,
  nextMeetingStart?: ModelStringInput | null,
  templateID?: ModelIDInput | null,
  googleChannelResourceID?: ModelStringInput | null,
  iCalUID?: ModelIDInput | null,
  googleAccountEmail?: ModelStringInput | null,
  status?: ModelOnoStatusInput | null,
  editors?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  groupsCanAccess?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  outlookUserId?: ModelStringInput | null,
  outlookEventId?: ModelStringInput | null,
  and?: Array< ModelOnoFilterInput | null > | null,
  or?: Array< ModelOnoFilterInput | null > | null,
  not?: ModelOnoFilterInput | null,
};

export type ModelOnoConnection = {
  __typename: "ModelOnoConnection",
  items:  Array<Ono | null >,
  nextToken?: string | null,
};

export type ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyConditionInput = {
  eq?: ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyInput | null,
  le?: ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyInput | null,
  lt?: ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyInput | null,
  ge?: ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyInput | null,
  gt?: ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyInput | null,
  between?: Array< ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyInput | null > | null,
  beginsWith?: ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyInput | null,
};

export type ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyInput = {
  deleteStatus?: DeleteStatus | null,
  updatedAt?: string | null,
};

export type ModelMeetingFilterInput = {
  id?: ModelIDInput | null,
  member?: ModelIDInput | null,
  manager?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  ono?: ModelIDInput | null,
  status?: ModelOnoStatusInput | null,
  templateID?: ModelStringInput | null,
  temporary?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  participated?: ModelBooleanInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelMeetingFilterInput | null > | null,
  or?: Array< ModelMeetingFilterInput | null > | null,
  not?: ModelMeetingFilterInput | null,
};

export type ModelMeetingConnection = {
  __typename: "ModelMeetingConnection",
  items:  Array<Meeting | null >,
  nextToken?: string | null,
};

export type ModelMeetingListMeetingByOnoWithDeletionCompositeKeyConditionInput = {
  eq?: ModelMeetingListMeetingByOnoWithDeletionCompositeKeyInput | null,
  le?: ModelMeetingListMeetingByOnoWithDeletionCompositeKeyInput | null,
  lt?: ModelMeetingListMeetingByOnoWithDeletionCompositeKeyInput | null,
  ge?: ModelMeetingListMeetingByOnoWithDeletionCompositeKeyInput | null,
  gt?: ModelMeetingListMeetingByOnoWithDeletionCompositeKeyInput | null,
  between?: Array< ModelMeetingListMeetingByOnoWithDeletionCompositeKeyInput | null > | null,
  beginsWith?: ModelMeetingListMeetingByOnoWithDeletionCompositeKeyInput | null,
};

export type ModelMeetingListMeetingByOnoWithDeletionCompositeKeyInput = {
  startDate?: string | null,
  deleteStatus?: DeleteStatus | null,
};

export type ModelTodoFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  ono?: ModelIDInput | null,
  assignmentUserId?: ModelIDInput | null,
  status?: ModelTodoStatusInput | null,
  createdMeetingID?: ModelIDInput | null,
  completedMeetingID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  owners?: ModelIDInput | null,
  and?: Array< ModelTodoFilterInput | null > | null,
  or?: Array< ModelTodoFilterInput | null > | null,
  not?: ModelTodoFilterInput | null,
};

export type ModelTodoConnection = {
  __typename: "ModelTodoConnection",
  items:  Array<Todo | null >,
  nextToken?: string | null,
};

export type ModelTodoListTodoByOnoWithDeletionCompositeKeyConditionInput = {
  eq?: ModelTodoListTodoByOnoWithDeletionCompositeKeyInput | null,
  le?: ModelTodoListTodoByOnoWithDeletionCompositeKeyInput | null,
  lt?: ModelTodoListTodoByOnoWithDeletionCompositeKeyInput | null,
  ge?: ModelTodoListTodoByOnoWithDeletionCompositeKeyInput | null,
  gt?: ModelTodoListTodoByOnoWithDeletionCompositeKeyInput | null,
  between?: Array< ModelTodoListTodoByOnoWithDeletionCompositeKeyInput | null > | null,
  beginsWith?: ModelTodoListTodoByOnoWithDeletionCompositeKeyInput | null,
};

export type ModelTodoListTodoByOnoWithDeletionCompositeKeyInput = {
  deleteStatus?: DeleteStatus | null,
  updatedAt?: string | null,
};

export type ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyConditionInput = {
  eq?: ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyInput | null,
  le?: ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyInput | null,
  lt?: ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyInput | null,
  ge?: ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyInput | null,
  gt?: ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyInput | null,
  between?: Array< ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyInput | null > | null,
  beginsWith?: ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyInput | null,
};

export type ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyInput = {
  deleteStatus?: DeleteStatus | null,
  updatedAt?: string | null,
};

export type ModelNextActionFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  oneOnOneSettingID?: ModelIDInput | null,
  managerID?: ModelIDInput | null,
  addedOneOnOneID?: ModelIDInput | null,
  completedOneOnOneID?: ModelStringInput | null,
  targetDate?: ModelStringInput | null,
  isDone?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  and?: Array< ModelNextActionFilterInput | null > | null,
  or?: Array< ModelNextActionFilterInput | null > | null,
  not?: ModelNextActionFilterInput | null,
};

export type ModelNextActionConnection = {
  __typename: "ModelNextActionConnection",
  items:  Array<NextAction | null >,
  nextToken?: string | null,
};

export type ModelOrganizationAgendaFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  order?: ModelIntInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganizationAgendaFilterInput | null > | null,
  or?: Array< ModelOrganizationAgendaFilterInput | null > | null,
  not?: ModelOrganizationAgendaFilterInput | null,
};

export type ModelOrganizationAgendaConnection = {
  __typename: "ModelOrganizationAgendaConnection",
  items:  Array<OrganizationAgenda | null >,
  nextToken?: string | null,
};

export type ModelAgendaTemplateFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  purpose?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  ownerID?: ModelIDInput | null,
  status?: ModelAgendaTemplateStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAgendaTemplateFilterInput | null > | null,
  or?: Array< ModelAgendaTemplateFilterInput | null > | null,
  not?: ModelAgendaTemplateFilterInput | null,
};

export type ModelAgendaTemplateConnection = {
  __typename: "ModelAgendaTemplateConnection",
  items:  Array<AgendaTemplate | null >,
  nextToken?: string | null,
};

export type ModelAgendaTemplateAgendaFilterInput = {
  id?: ModelIDInput | null,
  agendaTemplateID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  order?: ModelIntInput | null,
  ownerID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  and?: Array< ModelAgendaTemplateAgendaFilterInput | null > | null,
  or?: Array< ModelAgendaTemplateAgendaFilterInput | null > | null,
  not?: ModelAgendaTemplateAgendaFilterInput | null,
};

export type ModelTemplateFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  ono?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelTemplateFilterInput | null > | null,
  or?: Array< ModelTemplateFilterInput | null > | null,
  not?: ModelTemplateFilterInput | null,
};

export type ModelTemplateConnection = {
  __typename: "ModelTemplateConnection",
  items:  Array<Template | null >,
  nextToken?: string | null,
};

export type ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyConditionInput = {
  eq?: ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyInput | null,
  le?: ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyInput | null,
  lt?: ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyInput | null,
  ge?: ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyInput | null,
  gt?: ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyInput | null,
  between?: Array< ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyInput | null > | null,
  beginsWith?: ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyInput | null,
};

export type ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyInput = {
  deleteStatus?: DeleteStatus | null,
  updatedAt?: string | null,
};

export type ModelTemplateAgendaFilterInput = {
  id?: ModelIDInput | null,
  template?: ModelIDInput | null,
  title?: ModelStringInput | null,
  fromOrganization?: ModelIDInput | null,
  order?: ModelIntInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelTemplateAgendaFilterInput | null > | null,
  or?: Array< ModelTemplateAgendaFilterInput | null > | null,
  not?: ModelTemplateAgendaFilterInput | null,
};

export type ModelTemplateAgendaConnection = {
  __typename: "ModelTemplateAgendaConnection",
  items:  Array<TemplateAgenda | null >,
  nextToken?: string | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyConditionInput = {
  eq?: ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyInput | null,
  le?: ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyInput | null,
  lt?: ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyInput | null,
  ge?: ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyInput | null,
  gt?: ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyInput | null,
  between?: Array< ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyInput | null > | null,
  beginsWith?: ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyInput | null,
};

export type ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyInput = {
  deleteStatus?: DeleteStatus | null,
  order?: number | null,
};

export type ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyConditionInput = {
  eq?: ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyInput | null,
  le?: ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyInput | null,
  lt?: ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyInput | null,
  ge?: ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyInput | null,
  gt?: ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyInput | null,
  between?: Array< ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyInput | null > | null,
  beginsWith?: ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyInput | null,
};

export type ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyInput = {
  deleteStatus?: DeleteStatus | null,
  order?: number | null,
};

export type ModelAgendaFilterInput = {
  id?: ModelIDInput | null,
  oneOnOneID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  order?: ModelIntInput | null,
  referenceID?: ModelIDInput | null,
  agendaType?: ModelAgendaTypeInput | null,
  isDone?: ModelBooleanInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owners?: ModelIDInput | null,
  and?: Array< ModelAgendaFilterInput | null > | null,
  or?: Array< ModelAgendaFilterInput | null > | null,
  not?: ModelAgendaFilterInput | null,
};

export type ModelAgendaConnection = {
  __typename: "ModelAgendaConnection",
  items:  Array<Agenda | null >,
  nextToken?: string | null,
};

export type ModelAgendaCommentFilterInput = {
  id?: ModelIDInput | null,
  oneOnOneID?: ModelIDInput | null,
  agendaID?: ModelIDInput | null,
  comment?: ModelStringInput | null,
  commenterID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  aiAdvice?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAgendaCommentFilterInput | null > | null,
  or?: Array< ModelAgendaCommentFilterInput | null > | null,
  not?: ModelAgendaCommentFilterInput | null,
};

export type ModelNoteFilterInput = {
  id?: ModelIDInput | null,
  meeting?: ModelIDInput | null,
  agendaID?: ModelIDInput | null,
  referenceID?: ModelIDInput | null,
  user?: ModelIDInput | null,
  note?: ModelStringInput | null,
  type?: ModelNoteTypeInput | null,
  owners?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  adminGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  delete?: ModelBooleanInput | null,
  deleteStatus?: ModelDeleteStatusInput | null,
  groupsCanAccess?: ModelStringInput | null,
  and?: Array< ModelNoteFilterInput | null > | null,
  or?: Array< ModelNoteFilterInput | null > | null,
  not?: ModelNoteFilterInput | null,
};

export type ModelNoteConnection = {
  __typename: "ModelNoteConnection",
  items:  Array<Note | null >,
  nextToken?: string | null,
};

export type ModelNoteListNoteByMeetingWithDeletionCompositeKeyConditionInput = {
  eq?: ModelNoteListNoteByMeetingWithDeletionCompositeKeyInput | null,
  le?: ModelNoteListNoteByMeetingWithDeletionCompositeKeyInput | null,
  lt?: ModelNoteListNoteByMeetingWithDeletionCompositeKeyInput | null,
  ge?: ModelNoteListNoteByMeetingWithDeletionCompositeKeyInput | null,
  gt?: ModelNoteListNoteByMeetingWithDeletionCompositeKeyInput | null,
  between?: Array< ModelNoteListNoteByMeetingWithDeletionCompositeKeyInput | null > | null,
  beginsWith?: ModelNoteListNoteByMeetingWithDeletionCompositeKeyInput | null,
};

export type ModelNoteListNoteByMeetingWithDeletionCompositeKeyInput = {
  deleteStatus?: DeleteStatus | null,
  type?: NoteType | null,
};

export type ModelOneOnOneSettingFilterInput = {
  id?: ModelIDInput | null,
  userID1?: ModelIDInput | null,
  userID2?: ModelIDInput | null,
  frequency?: ModelFrequencyInput | null,
  nextOneOnOneDate?: ModelStringInput | null,
  nextOneOnOneTime?: ModelStringInput | null,
  agendaTemplateID?: ModelIDInput | null,
  status?: ModelOneOnOneSettingStatusInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOneOnOneSettingFilterInput | null > | null,
  or?: Array< ModelOneOnOneSettingFilterInput | null > | null,
  not?: ModelOneOnOneSettingFilterInput | null,
};

export type ModelOneOnOneSettingConnection = {
  __typename: "ModelOneOnOneSettingConnection",
  items:  Array<OneOnOneSetting | null >,
  nextToken?: string | null,
};

export type ModelOneOnOneFilterInput = {
  id?: ModelIDInput | null,
  userID1?: ModelIDInput | null,
  userID2?: ModelIDInput | null,
  managerID?: ModelIDInput | null,
  oneOnOneSettingID?: ModelIDInput | null,
  frequency?: ModelFrequencyInput | null,
  startDate?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  status?: ModelOneOnOneStatusInput | null,
  agendaTemplateID?: ModelIDInput | null,
  recordingAgendaID?: ModelIDInput | null,
  recordingTarget?: ModelRecordingTargetInput | null,
  aiSummary?: ModelStringInput | null,
  calendarApp?: ModelCalendarAppInput | null,
  eventID?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOneOnOneFilterInput | null > | null,
  or?: Array< ModelOneOnOneFilterInput | null > | null,
  not?: ModelOneOnOneFilterInput | null,
};

export type ModelShareMemoFilterInput = {
  id?: ModelIDInput | null,
  oneOnOneID?: ModelIDInput | null,
  ownerID?: ModelIDInput | null,
  memo?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelShareMemoFilterInput | null > | null,
  or?: Array< ModelShareMemoFilterInput | null > | null,
  not?: ModelShareMemoFilterInput | null,
};

export type ModelShareMemoConnection = {
  __typename: "ModelShareMemoConnection",
  items:  Array<ShareMemo | null >,
  nextToken?: string | null,
};

export type ModelPrivateMemoFilterInput = {
  id?: ModelIDInput | null,
  oneOnOneID?: ModelIDInput | null,
  memo?: ModelStringInput | null,
  ownerID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPrivateMemoFilterInput | null > | null,
  or?: Array< ModelPrivateMemoFilterInput | null > | null,
  not?: ModelPrivateMemoFilterInput | null,
};

export type ModelPrivateMemoConnection = {
  __typename: "ModelPrivateMemoConnection",
  items:  Array<PrivateMemo | null >,
  nextToken?: string | null,
};

export type ModelGradeMatrixFilterInput = {
  gradeMatrixID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGradeMatrixFilterInput | null > | null,
  or?: Array< ModelGradeMatrixFilterInput | null > | null,
  not?: ModelGradeMatrixFilterInput | null,
};

export type ModelGradeMatrixConnection = {
  __typename: "ModelGradeMatrixConnection",
  items:  Array<GradeMatrix | null >,
  nextToken?: string | null,
};

export type ModelCompetencyAreaFilterInput = {
  competencyAreaID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  gradeMatrixID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompetencyAreaFilterInput | null > | null,
  or?: Array< ModelCompetencyAreaFilterInput | null > | null,
  not?: ModelCompetencyAreaFilterInput | null,
};

export type ModelGradeFilterInput = {
  gradeID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  gradeMatrixID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGradeFilterInput | null > | null,
  or?: Array< ModelGradeFilterInput | null > | null,
  not?: ModelGradeFilterInput | null,
};

export type ModelCompetencyItemFilterInput = {
  competencyItemID?: ModelIDInput | null,
  item?: ModelStringInput | null,
  gradeID?: ModelIDInput | null,
  competencyAreaID?: ModelIDInput | null,
  gradeMatrixID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompetencyItemFilterInput | null > | null,
  or?: Array< ModelCompetencyItemFilterInput | null > | null,
  not?: ModelCompetencyItemFilterInput | null,
};

export type ModelSkillMapFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSkillMapFilterInput | null > | null,
  or?: Array< ModelSkillMapFilterInput | null > | null,
  not?: ModelSkillMapFilterInput | null,
};

export type ModelSkillMapConnection = {
  __typename: "ModelSkillMapConnection",
  items:  Array<SkillMap | null >,
  nextToken?: string | null,
};

export type ModelSkillItemFilterInput = {
  id?: ModelIDInput | null,
  skillMapID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSkillItemFilterInput | null > | null,
  or?: Array< ModelSkillItemFilterInput | null > | null,
  not?: ModelSkillItemFilterInput | null,
};

export type ModelSkillItemConnection = {
  __typename: "ModelSkillItemConnection",
  items:  Array<SkillItem | null >,
  nextToken?: string | null,
};

export type ModelLevelFilterInput = {
  id?: ModelIDInput | null,
  skillMapID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLevelFilterInput | null > | null,
  or?: Array< ModelLevelFilterInput | null > | null,
  not?: ModelLevelFilterInput | null,
};

export type ModelLevelConnection = {
  __typename: "ModelLevelConnection",
  items:  Array<Level | null >,
  nextToken?: string | null,
};

export type ModelSkillFilterInput = {
  id?: ModelIDInput | null,
  skillMapID?: ModelIDInput | null,
  levelID?: ModelIDInput | null,
  skillItemID?: ModelIDInput | null,
  outcome?: ModelStringInput | null,
  process?: ModelStringInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSkillFilterInput | null > | null,
  or?: Array< ModelSkillFilterInput | null > | null,
  not?: ModelSkillFilterInput | null,
};

export type ModelSkillConnection = {
  __typename: "ModelSkillConnection",
  items:  Array<Skill | null >,
  nextToken?: string | null,
};

export type ModelIndividualSkillFilterInput = {
  id?: ModelIDInput | null,
  skillMapID?: ModelIDInput | null,
  skillID?: ModelIDInput | null,
  acquisitionStatus?: ModelAcquisitionStatusInput | null,
  acquisitionStartDate?: ModelStringInput | null,
  acquisitionEndDate?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIndividualSkillFilterInput | null > | null,
  or?: Array< ModelIndividualSkillFilterInput | null > | null,
  not?: ModelIndividualSkillFilterInput | null,
};

export type ModelIndividualSkillConnection = {
  __typename: "ModelIndividualSkillConnection",
  items:  Array<IndividualSkill | null >,
  nextToken?: string | null,
};

export type ModelReviewResultFilterInput = {
  reviewResultID?: ModelIDInput | null,
  reviewID?: ModelIDInput | null,
  revieweeID?: ModelIDInput | null,
  finalReview?: ModelStringInput | null,
  nextGradeID?: ModelIDInput | null,
  nextBasicSalary?: ModelIntInput | null,
  salaryIncrease?: ModelIntInput | null,
  bonus?: ModelIntInput | null,
  shareStatus?: ModelShareStatusInput | null,
  openStatus?: ModelOpenStatusInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReviewResultFilterInput | null > | null,
  or?: Array< ModelReviewResultFilterInput | null > | null,
  not?: ModelReviewResultFilterInput | null,
};

export type ModelIndividualReviewSheetFilterInput = {
  individualReviewSheetID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  reviewerID?: ModelIDInput | null,
  revieweeID?: ModelIDInput | null,
  reviewOrder?: ModelReviewOrderInput | null,
  summary?: ModelStringInput | null,
  responseStage?: ModelResponseStageInput | null,
  reviewID?: ModelIDInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelIndividualReviewSheetFilterInput | null > | null,
  or?: Array< ModelIndividualReviewSheetFilterInput | null > | null,
  not?: ModelIndividualReviewSheetFilterInput | null,
};

export type ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyConditionInput = {
  eq?: ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyInput | null,
  le?: ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyInput | null,
  lt?: ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyInput | null,
  ge?: ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyInput | null,
  gt?: ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyInput | null,
  between?: Array< ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyInput | null > | null,
  beginsWith?: ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyInput | null,
};

export type ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyInput = {
  reviewerID?: string | null,
  revieweeID?: string | null,
};

export type ModelReviewFilterInput = {
  reviewID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  revieweeSetting?: ModelRevieweeSettingInput | null,
  reviewTemplateID?: ModelIDInput | null,
  remindNotificationDate?: ModelStringInput | null,
  isSummary?: ModelBooleanInput | null,
  isCalculate?: ModelBooleanInput | null,
  reviewStage?: ModelReviewStageInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
};

export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
};

export type ModelNewReviewTemplateFilterInput = {
  reviewTemplateID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  calculable?: ModelBooleanInput | null,
  organizationGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNewReviewTemplateFilterInput | null > | null,
  or?: Array< ModelNewReviewTemplateFilterInput | null > | null,
  not?: ModelNewReviewTemplateFilterInput | null,
};

export type ModelNewReviewTemplateConnection = {
  __typename: "ModelNewReviewTemplateConnection",
  items:  Array<NewReviewTemplate | null >,
  nextToken?: string | null,
};

export type ModelCustomReviewFilterInput = {
  customReviewID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  isWeight?: ModelBooleanInput | null,
  question?: ModelStringInput | null,
  description?: ModelStringInput | null,
  answerType?: ModelAnswerTypeInput | null,
  organizationGroup?: ModelStringInput | null,
  isAnswerBasis?: ModelBooleanInput | null,
  isAnswerBasisEntryOptional?: ModelBooleanInput | null,
  calculable?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCustomReviewFilterInput | null > | null,
  or?: Array< ModelCustomReviewFilterInput | null > | null,
  not?: ModelCustomReviewFilterInput | null,
};

export type ModelCustomReviewConnection = {
  __typename: "ModelCustomReviewConnection",
  items:  Array<CustomReview | null >,
  nextToken?: string | null,
};

export type StartOneOnOneMutationVariables = {
  input: StartOneOnOneInput,
};

export type StartOneOnOneMutation = {
  startOneOnOne:  {
    __typename: "StartOneOnOnePayload",
    setting:  {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    oneOnOne:  {
      __typename: "OneOnOne",
      id: string,
      userID1: string,
      userID2: string,
      managerID?: string | null,
      oneOnOneSettingID: string,
      frequency: Frequency,
      startDate: string,
      startTime: string,
      status: OneOnOneStatus,
      agendaTemplateID?: string | null,
      recordingAgendaID?: string | null,
      recordingTarget?: RecordingTarget | null,
      aiSummary?: string | null,
      calendarApp?: CalendarApp | null,
      eventID?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneSetting:  {
        __typename: "OneOnOneSetting",
        id: string,
        userID1: string,
        userID2: string,
        frequency: Frequency,
        nextOneOnOneDate: string,
        nextOneOnOneTime: string,
        agendaTemplateID?: string | null,
        status: OneOnOneSettingStatus,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneList?:  {
          __typename: "ModelOneOnOneConnection",
          items:  Array< {
            __typename: "OneOnOne",
            id: string,
            userID1: string,
            userID2: string,
            managerID?: string | null,
            oneOnOneSettingID: string,
            frequency: Frequency,
            startDate: string,
            startTime: string,
            status: OneOnOneStatus,
            agendaTemplateID?: string | null,
            recordingAgendaID?: string | null,
            recordingTarget?: RecordingTarget | null,
            aiSummary?: string | null,
            calendarApp?: CalendarApp | null,
            eventID?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    },
  },
};

export type AddTemporaryOneOnOneMutationVariables = {
  input: AddTemporaryOneOnOneInput,
};

export type AddTemporaryOneOnOneMutation = {
  addTemporaryOneOnOne:  {
    __typename: "AddTemporaryOneOnOnePayload",
    oneOnOne:  {
      __typename: "OneOnOne",
      id: string,
      userID1: string,
      userID2: string,
      managerID?: string | null,
      oneOnOneSettingID: string,
      frequency: Frequency,
      startDate: string,
      startTime: string,
      status: OneOnOneStatus,
      agendaTemplateID?: string | null,
      recordingAgendaID?: string | null,
      recordingTarget?: RecordingTarget | null,
      aiSummary?: string | null,
      calendarApp?: CalendarApp | null,
      eventID?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneSetting:  {
        __typename: "OneOnOneSetting",
        id: string,
        userID1: string,
        userID2: string,
        frequency: Frequency,
        nextOneOnOneDate: string,
        nextOneOnOneTime: string,
        agendaTemplateID?: string | null,
        status: OneOnOneSettingStatus,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneList?:  {
          __typename: "ModelOneOnOneConnection",
          items:  Array< {
            __typename: "OneOnOne",
            id: string,
            userID1: string,
            userID2: string,
            managerID?: string | null,
            oneOnOneSettingID: string,
            frequency: Frequency,
            startDate: string,
            startTime: string,
            status: OneOnOneStatus,
            agendaTemplateID?: string | null,
            recordingAgendaID?: string | null,
            recordingTarget?: RecordingTarget | null,
            aiSummary?: string | null,
            calendarApp?: CalendarApp | null,
            eventID?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    },
  },
};

export type SaveOneOnOneSettingMutationVariables = {
  input: SaveOneOnOneSettingInput,
};

export type SaveOneOnOneSettingMutation = {
  saveOneOnOneSetting:  {
    __typename: "SaveOneOnOneSettingPayload",
    oneOnOneSetting:  {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  },
};

export type StopOneOnOneMutationVariables = {
  input: StopOneOnOneInput,
};

export type StopOneOnOneMutation = {
  stopOneOnOne:  {
    __typename: "StopOneOnOnePayload",
    setting:  {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  },
};

export type RestartOneOnOneMutationVariables = {
  input: RestartOneOnOneInput,
};

export type RestartOneOnOneMutation = {
  restartOneOnOne:  {
    __typename: "RestartOneOnOnePayload",
    setting:  {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    oneOnOne:  {
      __typename: "OneOnOne",
      id: string,
      userID1: string,
      userID2: string,
      managerID?: string | null,
      oneOnOneSettingID: string,
      frequency: Frequency,
      startDate: string,
      startTime: string,
      status: OneOnOneStatus,
      agendaTemplateID?: string | null,
      recordingAgendaID?: string | null,
      recordingTarget?: RecordingTarget | null,
      aiSummary?: string | null,
      calendarApp?: CalendarApp | null,
      eventID?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneSetting:  {
        __typename: "OneOnOneSetting",
        id: string,
        userID1: string,
        userID2: string,
        frequency: Frequency,
        nextOneOnOneDate: string,
        nextOneOnOneTime: string,
        agendaTemplateID?: string | null,
        status: OneOnOneSettingStatus,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneList?:  {
          __typename: "ModelOneOnOneConnection",
          items:  Array< {
            __typename: "OneOnOne",
            id: string,
            userID1: string,
            userID2: string,
            managerID?: string | null,
            oneOnOneSettingID: string,
            frequency: Frequency,
            startDate: string,
            startTime: string,
            status: OneOnOneStatus,
            agendaTemplateID?: string | null,
            recordingAgendaID?: string | null,
            recordingTarget?: RecordingTarget | null,
            aiSummary?: string | null,
            calendarApp?: CalendarApp | null,
            eventID?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    },
  },
};

export type GoogleCalendarConnectMutationVariables = {
  code: string,
};

export type GoogleCalendarConnectMutation = {
  googleCalendarConnect?:  {
    __typename: "googleCalendarResponse",
    events:  Array< {
      __typename: "Event",
      title?: string | null,
      startDate?: string | null,
      endDate?: string | null,
      cycle?: string | null,
    } | null >,
  } | null,
};

export type CreateCognitoGroupMutationVariables = {
  group: string,
};

export type CreateCognitoGroupMutation = {
  createCognitoGroup?: boolean | null,
};

export type DeleteCognitoGroupMutationVariables = {
  groupID: string,
  groupName: string,
  target: DeleteGroupTarget,
  deleteType: DeleteGroupType,
};

export type DeleteCognitoGroupMutation = {
  deleteCognitoGroup?: boolean | null,
};

export type DisableCognitoUserMutationVariables = {
  username: string,
};

export type DisableCognitoUserMutation = {
  disableCognitoUser?: boolean | null,
};

export type SendEmailMutationVariables = {
  username: string,
};

export type SendEmailMutation = {
  sendEmail?: boolean | null,
};

export type CreateCognitoUserMutationVariables = {
  username: string,
  email: string,
  group: string,
  firstName: string,
  lastName: string,
  teamID?: string | null,
};

export type CreateCognitoUserMutation = {
  createCognitoUser?:  {
    __typename: "CreateCognitoUserResponse",
    sub: string,
  } | null,
};

export type CreateImportedUsersMutationVariables = {
  userAttributes: Array< CreateImportedUserInput >,
};

export type CreateImportedUsersMutation = {
  createImportedUsers?: boolean | null,
};

export type DeleteCognitoUserMutationVariables = {
  username: string,
};

export type DeleteCognitoUserMutation = {
  deleteCognitoUser?: boolean | null,
};

export type DeleteUserTriggerMutationVariables = {
  userIDs: Array< string >,
};

export type DeleteUserTriggerMutation = {
  deleteUserTrigger?: boolean | null,
};

export type ActivateUserTriggerMutationVariables = {
  userIDs: Array< string >,
};

export type ActivateUserTriggerMutation = {
  activateUserTrigger?: boolean | null,
};

export type ArchiveOrganizationMutationVariables = {
  input: ArchiveOrganizationInput,
};

export type ArchiveOrganizationMutation = {
  archiveOrganization:  {
    __typename: "ArchiveOrganizationPayload",
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      domain?: string | null,
      webhookURL?: string | null,
      mailingList?: string | null,
      adminGroupName: string,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      groupName: string,
      numberOfUsers?: number | null,
      agreementUpdateDate?: string | null,
      assessmentFromDate?: string | null,
      assessmentToDate?: string | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      totalAudioDuration?: number | null,
      status?: OrganizationStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      microsoftAppId?: string | null,
      microsoftTenant?: string | null,
      microsoftClientSecret?: string | null,
    },
  },
};

export type CreateTeamMutationVariables = {
  input: CreateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type CreateTeamMutation = {
  createTeam?:  {
    __typename: "Team",
    id: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    owner?: string | null,
  } | null,
};

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type UpdateTeamMutation = {
  updateTeam?:  {
    __typename: "Team",
    id: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    owner?: string | null,
  } | null,
};

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type DeleteTeamMutation = {
  deleteTeam?:  {
    __typename: "Team",
    id: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    owner?: string | null,
  } | null,
};

export type CreateMemberManagerMutationVariables = {
  input: CreateMemberManagerInput,
  condition?: ModelMemberManagerConditionInput | null,
};

export type CreateMemberManagerMutation = {
  createMemberManager?:  {
    __typename: "MemberManager",
    id: string,
    managerID: string,
    memberID: string,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    owner?: string | null,
    manager:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    member:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type UpdateMemberManagerMutationVariables = {
  input: UpdateMemberManagerInput,
  condition?: ModelMemberManagerConditionInput | null,
};

export type UpdateMemberManagerMutation = {
  updateMemberManager?:  {
    __typename: "MemberManager",
    id: string,
    managerID: string,
    memberID: string,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    owner?: string | null,
    manager:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    member:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type DeleteMemberManagerMutationVariables = {
  input: DeleteMemberManagerInput,
  condition?: ModelMemberManagerConditionInput | null,
};

export type DeleteMemberManagerMutation = {
  deleteMemberManager?:  {
    __typename: "MemberManager",
    id: string,
    managerID: string,
    memberID: string,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    owner?: string | null,
    manager:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    member:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    account: string,
    owner?: string | null,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationID: string,
    status: UserStatus,
    admin?: boolean | null,
    sub: string,
    socialType?: SocialType | null,
    drivingScore?: number | null,
    analyticalScore?: number | null,
    amiableScore?: number | null,
    expressiveScore?: number | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    email?: string | null,
    teamID?: string | null,
    managers?: Array< string | null > | null,
    googleRefreshToken?: string | null,
    googleAccountEmail?: string | null,
    about?: string | null,
    delete?: boolean | null,
    deleteStatus: string,
    employeeId?: string | null,
    teamCode?: string | null,
    readingFirstName?: string | null,
    readingLastName?: string | null,
    birthDay?: string | null,
    gender?: Gender | null,
    address?: string | null,
    joinedCompanyDay?: string | null,
    retirementDay?: string | null,
    jobType?: string | null,
    phoneNumber?: string | null,
    enrollmentStatus?: EnrollmentStatus | null,
    recruitmentClassification?: RecruitmentClassification | null,
    department?: string | null,
    division?: string | null,
    section?: string | null,
    position?: string | null,
    gradeID?: string | null,
    allowance?: number | null,
    bonus?: number | null,
    gradeMatrixID?: string | null,
    myMembers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    myManagers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeMatrix?:  {
      __typename: "GradeMatrix",
      gradeMatrixID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      memberList?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyAreaList?:  {
        __typename: "ModelCompetencyAreaConnection",
        items:  Array< {
          __typename: "CompetencyArea",
          competencyAreaID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeList?:  {
        __typename: "ModelGradeConnection",
        items:  Array< {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyItemList?:  {
        __typename: "ModelCompetencyItemConnection",
        items:  Array< {
          __typename: "CompetencyItem",
          competencyItemID: string,
          item: string,
          gradeID: string,
          competencyAreaID: string,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    account: string,
    owner?: string | null,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationID: string,
    status: UserStatus,
    admin?: boolean | null,
    sub: string,
    socialType?: SocialType | null,
    drivingScore?: number | null,
    analyticalScore?: number | null,
    amiableScore?: number | null,
    expressiveScore?: number | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    email?: string | null,
    teamID?: string | null,
    managers?: Array< string | null > | null,
    googleRefreshToken?: string | null,
    googleAccountEmail?: string | null,
    about?: string | null,
    delete?: boolean | null,
    deleteStatus: string,
    employeeId?: string | null,
    teamCode?: string | null,
    readingFirstName?: string | null,
    readingLastName?: string | null,
    birthDay?: string | null,
    gender?: Gender | null,
    address?: string | null,
    joinedCompanyDay?: string | null,
    retirementDay?: string | null,
    jobType?: string | null,
    phoneNumber?: string | null,
    enrollmentStatus?: EnrollmentStatus | null,
    recruitmentClassification?: RecruitmentClassification | null,
    department?: string | null,
    division?: string | null,
    section?: string | null,
    position?: string | null,
    gradeID?: string | null,
    allowance?: number | null,
    bonus?: number | null,
    gradeMatrixID?: string | null,
    myMembers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    myManagers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeMatrix?:  {
      __typename: "GradeMatrix",
      gradeMatrixID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      memberList?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyAreaList?:  {
        __typename: "ModelCompetencyAreaConnection",
        items:  Array< {
          __typename: "CompetencyArea",
          competencyAreaID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeList?:  {
        __typename: "ModelGradeConnection",
        items:  Array< {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyItemList?:  {
        __typename: "ModelCompetencyItemConnection",
        items:  Array< {
          __typename: "CompetencyItem",
          competencyItemID: string,
          item: string,
          gradeID: string,
          competencyAreaID: string,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    account: string,
    owner?: string | null,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationID: string,
    status: UserStatus,
    admin?: boolean | null,
    sub: string,
    socialType?: SocialType | null,
    drivingScore?: number | null,
    analyticalScore?: number | null,
    amiableScore?: number | null,
    expressiveScore?: number | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    email?: string | null,
    teamID?: string | null,
    managers?: Array< string | null > | null,
    googleRefreshToken?: string | null,
    googleAccountEmail?: string | null,
    about?: string | null,
    delete?: boolean | null,
    deleteStatus: string,
    employeeId?: string | null,
    teamCode?: string | null,
    readingFirstName?: string | null,
    readingLastName?: string | null,
    birthDay?: string | null,
    gender?: Gender | null,
    address?: string | null,
    joinedCompanyDay?: string | null,
    retirementDay?: string | null,
    jobType?: string | null,
    phoneNumber?: string | null,
    enrollmentStatus?: EnrollmentStatus | null,
    recruitmentClassification?: RecruitmentClassification | null,
    department?: string | null,
    division?: string | null,
    section?: string | null,
    position?: string | null,
    gradeID?: string | null,
    allowance?: number | null,
    bonus?: number | null,
    gradeMatrixID?: string | null,
    myMembers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    myManagers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeMatrix?:  {
      __typename: "GradeMatrix",
      gradeMatrixID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      memberList?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyAreaList?:  {
        __typename: "ModelCompetencyAreaConnection",
        items:  Array< {
          __typename: "CompetencyArea",
          competencyAreaID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeList?:  {
        __typename: "ModelGradeConnection",
        items:  Array< {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyItemList?:  {
        __typename: "ModelCompetencyItemConnection",
        items:  Array< {
          __typename: "CompetencyItem",
          competencyItemID: string,
          item: string,
          gradeID: string,
          competencyAreaID: string,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateHistoryMutationVariables = {
  input: CreateHistoryInput,
  condition?: ModelHistoryConditionInput | null,
};

export type CreateHistoryMutation = {
  createHistory?:  {
    __typename: "History",
    id: string,
    user: string,
    value?: string | null,
    subValue?: string | null,
    start?: string | null,
    end?: string | null,
    type: HistoryType,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    groupsCanAccess: Array< string | null >,
    owners?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateHistoryMutationVariables = {
  input: UpdateHistoryInput,
  condition?: ModelHistoryConditionInput | null,
};

export type UpdateHistoryMutation = {
  updateHistory?:  {
    __typename: "History",
    id: string,
    user: string,
    value?: string | null,
    subValue?: string | null,
    start?: string | null,
    end?: string | null,
    type: HistoryType,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    groupsCanAccess: Array< string | null >,
    owners?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteHistoryMutationVariables = {
  input: DeleteHistoryInput,
  condition?: ModelHistoryConditionInput | null,
};

export type DeleteHistoryMutation = {
  deleteHistory?:  {
    __typename: "History",
    id: string,
    user: string,
    value?: string | null,
    subValue?: string | null,
    start?: string | null,
    end?: string | null,
    type: HistoryType,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    groupsCanAccess: Array< string | null >,
    owners?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateSettingMutationVariables = {
  input: CreateSettingInput,
  condition?: ModelSettingConditionInput | null,
};

export type CreateSettingMutation = {
  createSetting?:  {
    __typename: "Setting",
    id: string,
    notification?:  {
      __typename: "SettingNotificationPart",
      disableAll?: boolean | null,
      email?:  {
        __typename: "SettingNotificationItem",
        enable?: boolean | null,
        reportLike?: boolean | null,
        reportComment?: boolean | null,
        onoRemind?: boolean | null,
        onoLookBack?: boolean | null,
        goalUpdate?: boolean | null,
        goalActivity?: boolean | null,
        goalRemind?: boolean | null,
      } | null,
    } | null,
    calendar?:  {
      __typename: "SettingCalendarPart",
      gmailAddress?: string | null,
      syncLimit?: number | null,
      syncInterval?: number | null,
      syncToken?: string | null,
    } | null,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    createdAt: string,
    updatedAt: string,
    groupsCanAccess: Array< string | null >,
  } | null,
};

export type UpdateSettingMutationVariables = {
  input: UpdateSettingInput,
  condition?: ModelSettingConditionInput | null,
};

export type UpdateSettingMutation = {
  updateSetting?:  {
    __typename: "Setting",
    id: string,
    notification?:  {
      __typename: "SettingNotificationPart",
      disableAll?: boolean | null,
      email?:  {
        __typename: "SettingNotificationItem",
        enable?: boolean | null,
        reportLike?: boolean | null,
        reportComment?: boolean | null,
        onoRemind?: boolean | null,
        onoLookBack?: boolean | null,
        goalUpdate?: boolean | null,
        goalActivity?: boolean | null,
        goalRemind?: boolean | null,
      } | null,
    } | null,
    calendar?:  {
      __typename: "SettingCalendarPart",
      gmailAddress?: string | null,
      syncLimit?: number | null,
      syncInterval?: number | null,
      syncToken?: string | null,
    } | null,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    createdAt: string,
    updatedAt: string,
    groupsCanAccess: Array< string | null >,
  } | null,
};

export type DeleteSettingMutationVariables = {
  input: DeleteSettingInput,
  condition?: ModelSettingConditionInput | null,
};

export type DeleteSettingMutation = {
  deleteSetting?:  {
    __typename: "Setting",
    id: string,
    notification?:  {
      __typename: "SettingNotificationPart",
      disableAll?: boolean | null,
      email?:  {
        __typename: "SettingNotificationItem",
        enable?: boolean | null,
        reportLike?: boolean | null,
        reportComment?: boolean | null,
        onoRemind?: boolean | null,
        onoLookBack?: boolean | null,
        goalUpdate?: boolean | null,
        goalActivity?: boolean | null,
        goalRemind?: boolean | null,
      } | null,
    } | null,
    calendar?:  {
      __typename: "SettingCalendarPart",
      gmailAddress?: string | null,
      syncLimit?: number | null,
      syncInterval?: number | null,
      syncToken?: string | null,
    } | null,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    createdAt: string,
    updatedAt: string,
    groupsCanAccess: Array< string | null >,
  } | null,
};

export type CreateTaskMutationVariables = {
  input: CreateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type CreateTaskMutation = {
  createTask?:  {
    __typename: "Task",
    taskID: string,
    userID: string,
    content: string,
    title: string,
    taskType: TaskType,
    complete: boolean,
    createdAt: string,
    updatedAt: string,
    dueDate?: string | null,
    taskLink?: string | null,
    organizationGroup: string,
    reviewSheetID?: string | null,
    goalID?: string | null,
  } | null,
};

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type UpdateTaskMutation = {
  updateTask?:  {
    __typename: "Task",
    taskID: string,
    userID: string,
    content: string,
    title: string,
    taskType: TaskType,
    complete: boolean,
    createdAt: string,
    updatedAt: string,
    dueDate?: string | null,
    taskLink?: string | null,
    organizationGroup: string,
    reviewSheetID?: string | null,
    goalID?: string | null,
  } | null,
};

export type DeleteTaskMutationVariables = {
  input: DeleteTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type DeleteTaskMutation = {
  deleteTask?:  {
    __typename: "Task",
    taskID: string,
    userID: string,
    content: string,
    title: string,
    taskType: TaskType,
    complete: boolean,
    createdAt: string,
    updatedAt: string,
    dueDate?: string | null,
    taskLink?: string | null,
    organizationGroup: string,
    reviewSheetID?: string | null,
    goalID?: string | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    domain?: string | null,
    webhookURL?: string | null,
    mailingList?: string | null,
    adminGroupName: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    groupName: string,
    numberOfUsers?: number | null,
    agreementUpdateDate?: string | null,
    assessmentFromDate?: string | null,
    assessmentToDate?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    totalAudioDuration?: number | null,
    status?: OrganizationStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    microsoftAppId?: string | null,
    microsoftTenant?: string | null,
    microsoftClientSecret?: string | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    domain?: string | null,
    webhookURL?: string | null,
    mailingList?: string | null,
    adminGroupName: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    groupName: string,
    numberOfUsers?: number | null,
    agreementUpdateDate?: string | null,
    assessmentFromDate?: string | null,
    assessmentToDate?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    totalAudioDuration?: number | null,
    status?: OrganizationStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    microsoftAppId?: string | null,
    microsoftTenant?: string | null,
    microsoftClientSecret?: string | null,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    domain?: string | null,
    webhookURL?: string | null,
    mailingList?: string | null,
    adminGroupName: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    groupName: string,
    numberOfUsers?: number | null,
    agreementUpdateDate?: string | null,
    assessmentFromDate?: string | null,
    assessmentToDate?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    totalAudioDuration?: number | null,
    status?: OrganizationStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    microsoftAppId?: string | null,
    microsoftTenant?: string | null,
    microsoftClientSecret?: string | null,
  } | null,
};

export type CreateGoal3MutationVariables = {
  input: CreateGoal3Input,
  condition?: ModelGoal3ConditionInput | null,
};

export type CreateGoal3Mutation = {
  createGoal3?:  {
    __typename: "Goal3",
    id: string,
    organization?: string | null,
    teamID?: string | null,
    value?: string | null,
    detail?: string | null,
    progressType: GoalProgressType,
    startDate?: string | null,
    endDate?: string | null,
    owners?: Array< string > | null,
    type: GoalType2,
    visibility: GoalVisibility,
    readers?: Array< string | null > | null,
    parentGoalID?: string | null,
    priority?: number | null,
    achievement?: GoalAchievement | null,
    progress: Progress,
    totalValue?: number | null,
    start?: number | null,
    target?: number | null,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    archiveStatus: string,
    goalProgressList?:  {
      __typename: "ModelGoalProgressConnection",
      items:  Array< {
        __typename: "GoalProgress",
        goalProgressID: string,
        organizationGroup: string,
        goalID: string,
        progress: Progress,
        progressDetail?: string | null,
        value?: number | null,
        creatorID: string,
        createdAt: string,
        deleteStatus: string,
        updatedAt: string,
        comments?:  {
          __typename: "ModelGoalProgressCommentConnection",
          items:  Array< {
            __typename: "GoalProgressComment",
            goalProgressCommentID: string,
            organizationGroup: string,
            commenterID: string,
            commentContent?: string | null,
            goalProgressID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateGoal3MutationVariables = {
  input: UpdateGoal3Input,
  condition?: ModelGoal3ConditionInput | null,
};

export type UpdateGoal3Mutation = {
  updateGoal3?:  {
    __typename: "Goal3",
    id: string,
    organization?: string | null,
    teamID?: string | null,
    value?: string | null,
    detail?: string | null,
    progressType: GoalProgressType,
    startDate?: string | null,
    endDate?: string | null,
    owners?: Array< string > | null,
    type: GoalType2,
    visibility: GoalVisibility,
    readers?: Array< string | null > | null,
    parentGoalID?: string | null,
    priority?: number | null,
    achievement?: GoalAchievement | null,
    progress: Progress,
    totalValue?: number | null,
    start?: number | null,
    target?: number | null,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    archiveStatus: string,
    goalProgressList?:  {
      __typename: "ModelGoalProgressConnection",
      items:  Array< {
        __typename: "GoalProgress",
        goalProgressID: string,
        organizationGroup: string,
        goalID: string,
        progress: Progress,
        progressDetail?: string | null,
        value?: number | null,
        creatorID: string,
        createdAt: string,
        deleteStatus: string,
        updatedAt: string,
        comments?:  {
          __typename: "ModelGoalProgressCommentConnection",
          items:  Array< {
            __typename: "GoalProgressComment",
            goalProgressCommentID: string,
            organizationGroup: string,
            commenterID: string,
            commentContent?: string | null,
            goalProgressID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteGoal3MutationVariables = {
  input: DeleteGoal3Input,
  condition?: ModelGoal3ConditionInput | null,
};

export type DeleteGoal3Mutation = {
  deleteGoal3?:  {
    __typename: "Goal3",
    id: string,
    organization?: string | null,
    teamID?: string | null,
    value?: string | null,
    detail?: string | null,
    progressType: GoalProgressType,
    startDate?: string | null,
    endDate?: string | null,
    owners?: Array< string > | null,
    type: GoalType2,
    visibility: GoalVisibility,
    readers?: Array< string | null > | null,
    parentGoalID?: string | null,
    priority?: number | null,
    achievement?: GoalAchievement | null,
    progress: Progress,
    totalValue?: number | null,
    start?: number | null,
    target?: number | null,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    archiveStatus: string,
    goalProgressList?:  {
      __typename: "ModelGoalProgressConnection",
      items:  Array< {
        __typename: "GoalProgress",
        goalProgressID: string,
        organizationGroup: string,
        goalID: string,
        progress: Progress,
        progressDetail?: string | null,
        value?: number | null,
        creatorID: string,
        createdAt: string,
        deleteStatus: string,
        updatedAt: string,
        comments?:  {
          __typename: "ModelGoalProgressCommentConnection",
          items:  Array< {
            __typename: "GoalProgressComment",
            goalProgressCommentID: string,
            organizationGroup: string,
            commenterID: string,
            commentContent?: string | null,
            goalProgressID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateGoalMutationVariables = {
  input: CreateGoalInput,
  condition?: ModelGoalConditionInput | null,
};

export type CreateGoalMutation = {
  createGoal?:  {
    __typename: "Goal",
    goalID: string,
    organizationGroup: string,
    parentGoalID?: string | null,
    departmentID?: string | null,
    directorIDs: Array< string >,
    readerIDs?: Array< string > | null,
    goalClassification: GoalClassification,
    goalTitle: string,
    goalDetail?: string | null,
    measuringType: MeasuringType,
    startDate: string,
    targetDate: string,
    goalType: GoalType,
    publicScope: PublicScope,
    archiveStatus: string,
    startValue?: number | null,
    targetValue?: number | null,
    createdAt: string,
    updatedAt: string,
    deleteStatus: string,
    goalWeightPoint?: number | null,
    goalProgressList?:  {
      __typename: "ModelGoalProgressConnection",
      items:  Array< {
        __typename: "GoalProgress",
        goalProgressID: string,
        organizationGroup: string,
        goalID: string,
        progress: Progress,
        progressDetail?: string | null,
        value?: number | null,
        creatorID: string,
        createdAt: string,
        deleteStatus: string,
        updatedAt: string,
        comments?:  {
          __typename: "ModelGoalProgressCommentConnection",
          items:  Array< {
            __typename: "GoalProgressComment",
            goalProgressCommentID: string,
            organizationGroup: string,
            commenterID: string,
            commentContent?: string | null,
            goalProgressID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateGoalMutationVariables = {
  input: UpdateGoalInput,
  condition?: ModelGoalConditionInput | null,
};

export type UpdateGoalMutation = {
  updateGoal?:  {
    __typename: "Goal",
    goalID: string,
    organizationGroup: string,
    parentGoalID?: string | null,
    departmentID?: string | null,
    directorIDs: Array< string >,
    readerIDs?: Array< string > | null,
    goalClassification: GoalClassification,
    goalTitle: string,
    goalDetail?: string | null,
    measuringType: MeasuringType,
    startDate: string,
    targetDate: string,
    goalType: GoalType,
    publicScope: PublicScope,
    archiveStatus: string,
    startValue?: number | null,
    targetValue?: number | null,
    createdAt: string,
    updatedAt: string,
    deleteStatus: string,
    goalWeightPoint?: number | null,
    goalProgressList?:  {
      __typename: "ModelGoalProgressConnection",
      items:  Array< {
        __typename: "GoalProgress",
        goalProgressID: string,
        organizationGroup: string,
        goalID: string,
        progress: Progress,
        progressDetail?: string | null,
        value?: number | null,
        creatorID: string,
        createdAt: string,
        deleteStatus: string,
        updatedAt: string,
        comments?:  {
          __typename: "ModelGoalProgressCommentConnection",
          items:  Array< {
            __typename: "GoalProgressComment",
            goalProgressCommentID: string,
            organizationGroup: string,
            commenterID: string,
            commentContent?: string | null,
            goalProgressID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteGoalMutationVariables = {
  input: DeleteGoalInput,
  condition?: ModelGoalConditionInput | null,
};

export type DeleteGoalMutation = {
  deleteGoal?:  {
    __typename: "Goal",
    goalID: string,
    organizationGroup: string,
    parentGoalID?: string | null,
    departmentID?: string | null,
    directorIDs: Array< string >,
    readerIDs?: Array< string > | null,
    goalClassification: GoalClassification,
    goalTitle: string,
    goalDetail?: string | null,
    measuringType: MeasuringType,
    startDate: string,
    targetDate: string,
    goalType: GoalType,
    publicScope: PublicScope,
    archiveStatus: string,
    startValue?: number | null,
    targetValue?: number | null,
    createdAt: string,
    updatedAt: string,
    deleteStatus: string,
    goalWeightPoint?: number | null,
    goalProgressList?:  {
      __typename: "ModelGoalProgressConnection",
      items:  Array< {
        __typename: "GoalProgress",
        goalProgressID: string,
        organizationGroup: string,
        goalID: string,
        progress: Progress,
        progressDetail?: string | null,
        value?: number | null,
        creatorID: string,
        createdAt: string,
        deleteStatus: string,
        updatedAt: string,
        comments?:  {
          __typename: "ModelGoalProgressCommentConnection",
          items:  Array< {
            __typename: "GoalProgressComment",
            goalProgressCommentID: string,
            organizationGroup: string,
            commenterID: string,
            commentContent?: string | null,
            goalProgressID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateGoalActivityMutationVariables = {
  input: CreateGoalActivityInput,
  condition?: ModelGoalActivityConditionInput | null,
};

export type CreateGoalActivityMutation = {
  createGoalActivity?:  {
    __typename: "GoalActivity",
    id: string,
    userID: string,
    goalID?: string | null,
    keyResultID?: string | null,
    date?: string | null,
    value?: number | null,
    detail?: string | null,
    progress?: GoalProgress2 | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
  } | null,
};

export type UpdateGoalActivityMutationVariables = {
  input: UpdateGoalActivityInput,
  condition?: ModelGoalActivityConditionInput | null,
};

export type UpdateGoalActivityMutation = {
  updateGoalActivity?:  {
    __typename: "GoalActivity",
    id: string,
    userID: string,
    goalID?: string | null,
    keyResultID?: string | null,
    date?: string | null,
    value?: number | null,
    detail?: string | null,
    progress?: GoalProgress2 | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
  } | null,
};

export type DeleteGoalActivityMutationVariables = {
  input: DeleteGoalActivityInput,
  condition?: ModelGoalActivityConditionInput | null,
};

export type DeleteGoalActivityMutation = {
  deleteGoalActivity?:  {
    __typename: "GoalActivity",
    id: string,
    userID: string,
    goalID?: string | null,
    keyResultID?: string | null,
    date?: string | null,
    value?: number | null,
    detail?: string | null,
    progress?: GoalProgress2 | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
  } | null,
};

export type CreateGoalProgressMutationVariables = {
  input: CreateGoalProgressInput,
  condition?: ModelGoalProgressConditionInput | null,
};

export type CreateGoalProgressMutation = {
  createGoalProgress?:  {
    __typename: "GoalProgress",
    goalProgressID: string,
    organizationGroup: string,
    goalID: string,
    progress: Progress,
    progressDetail?: string | null,
    value?: number | null,
    creatorID: string,
    createdAt: string,
    deleteStatus: string,
    updatedAt: string,
    comments?:  {
      __typename: "ModelGoalProgressCommentConnection",
      items:  Array< {
        __typename: "GoalProgressComment",
        goalProgressCommentID: string,
        organizationGroup: string,
        commenterID: string,
        commentContent?: string | null,
        goalProgressID: string,
        createdAt: string,
        updatedAt: string,
        commenter:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateGoalProgressMutationVariables = {
  input: UpdateGoalProgressInput,
  condition?: ModelGoalProgressConditionInput | null,
};

export type UpdateGoalProgressMutation = {
  updateGoalProgress?:  {
    __typename: "GoalProgress",
    goalProgressID: string,
    organizationGroup: string,
    goalID: string,
    progress: Progress,
    progressDetail?: string | null,
    value?: number | null,
    creatorID: string,
    createdAt: string,
    deleteStatus: string,
    updatedAt: string,
    comments?:  {
      __typename: "ModelGoalProgressCommentConnection",
      items:  Array< {
        __typename: "GoalProgressComment",
        goalProgressCommentID: string,
        organizationGroup: string,
        commenterID: string,
        commentContent?: string | null,
        goalProgressID: string,
        createdAt: string,
        updatedAt: string,
        commenter:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteGoalProgressMutationVariables = {
  input: DeleteGoalProgressInput,
  condition?: ModelGoalProgressConditionInput | null,
};

export type DeleteGoalProgressMutation = {
  deleteGoalProgress?:  {
    __typename: "GoalProgress",
    goalProgressID: string,
    organizationGroup: string,
    goalID: string,
    progress: Progress,
    progressDetail?: string | null,
    value?: number | null,
    creatorID: string,
    createdAt: string,
    deleteStatus: string,
    updatedAt: string,
    comments?:  {
      __typename: "ModelGoalProgressCommentConnection",
      items:  Array< {
        __typename: "GoalProgressComment",
        goalProgressCommentID: string,
        organizationGroup: string,
        commenterID: string,
        commentContent?: string | null,
        goalProgressID: string,
        createdAt: string,
        updatedAt: string,
        commenter:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateGoalProgressCommentMutationVariables = {
  input: CreateGoalProgressCommentInput,
  condition?: ModelGoalProgressCommentConditionInput | null,
};

export type CreateGoalProgressCommentMutation = {
  createGoalProgressComment?:  {
    __typename: "GoalProgressComment",
    goalProgressCommentID: string,
    organizationGroup: string,
    commenterID: string,
    commentContent?: string | null,
    goalProgressID: string,
    createdAt: string,
    updatedAt: string,
    commenter:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type UpdateGoalProgressCommentMutationVariables = {
  input: UpdateGoalProgressCommentInput,
  condition?: ModelGoalProgressCommentConditionInput | null,
};

export type UpdateGoalProgressCommentMutation = {
  updateGoalProgressComment?:  {
    __typename: "GoalProgressComment",
    goalProgressCommentID: string,
    organizationGroup: string,
    commenterID: string,
    commentContent?: string | null,
    goalProgressID: string,
    createdAt: string,
    updatedAt: string,
    commenter:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type DeleteGoalProgressCommentMutationVariables = {
  input: DeleteGoalProgressCommentInput,
  condition?: ModelGoalProgressCommentConditionInput | null,
};

export type DeleteGoalProgressCommentMutation = {
  deleteGoalProgressComment?:  {
    __typename: "GoalProgressComment",
    goalProgressCommentID: string,
    organizationGroup: string,
    commenterID: string,
    commentContent?: string | null,
    goalProgressID: string,
    createdAt: string,
    updatedAt: string,
    commenter:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type CreateOnoMutationVariables = {
  input: CreateOnoInput,
  condition?: ModelOnoConditionInput | null,
};

export type CreateOnoMutation = {
  createOno?:  {
    __typename: "Ono",
    id: string,
    member: string,
    manager: string,
    frequency?: FrequencyType | null,
    nextMeetingDate?: string | null,
    nextMeetingStart: string,
    templateID?: string | null,
    googleChannelResourceID?: string | null,
    iCalUID?: string | null,
    googleAccountEmail?: string | null,
    status?: OnoStatus | null,
    editors: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    adminGroup: string,
    organizationGroup?: string | null,
    groupsCanAccess: Array< string | null >,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    outlookUserId?: string | null,
    outlookEventId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateOnoMutationVariables = {
  input: UpdateOnoInput,
  condition?: ModelOnoConditionInput | null,
};

export type UpdateOnoMutation = {
  updateOno?:  {
    __typename: "Ono",
    id: string,
    member: string,
    manager: string,
    frequency?: FrequencyType | null,
    nextMeetingDate?: string | null,
    nextMeetingStart: string,
    templateID?: string | null,
    googleChannelResourceID?: string | null,
    iCalUID?: string | null,
    googleAccountEmail?: string | null,
    status?: OnoStatus | null,
    editors: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    adminGroup: string,
    organizationGroup?: string | null,
    groupsCanAccess: Array< string | null >,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    outlookUserId?: string | null,
    outlookEventId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteOnoMutationVariables = {
  input: DeleteOnoInput,
  condition?: ModelOnoConditionInput | null,
};

export type DeleteOnoMutation = {
  deleteOno?:  {
    __typename: "Ono",
    id: string,
    member: string,
    manager: string,
    frequency?: FrequencyType | null,
    nextMeetingDate?: string | null,
    nextMeetingStart: string,
    templateID?: string | null,
    googleChannelResourceID?: string | null,
    iCalUID?: string | null,
    googleAccountEmail?: string | null,
    status?: OnoStatus | null,
    editors: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    adminGroup: string,
    organizationGroup?: string | null,
    groupsCanAccess: Array< string | null >,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    outlookUserId?: string | null,
    outlookEventId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateMeetingMutationVariables = {
  input: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type CreateMeetingMutation = {
  createMeeting?:  {
    __typename: "Meeting",
    id: string,
    member: string,
    manager: string,
    startDate: string,
    ono?: string | null,
    status?: OnoStatus | null,
    templateID?: string | null,
    temporary?: boolean | null,
    createdAt: string,
    updatedAt: string,
    adminGroup: string,
    organizationGroup?: string | null,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    participated?: boolean | null,
    groupsCanAccess: Array< string | null >,
  } | null,
};

export type UpdateMeetingMutationVariables = {
  input: UpdateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type UpdateMeetingMutation = {
  updateMeeting?:  {
    __typename: "Meeting",
    id: string,
    member: string,
    manager: string,
    startDate: string,
    ono?: string | null,
    status?: OnoStatus | null,
    templateID?: string | null,
    temporary?: boolean | null,
    createdAt: string,
    updatedAt: string,
    adminGroup: string,
    organizationGroup?: string | null,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    participated?: boolean | null,
    groupsCanAccess: Array< string | null >,
  } | null,
};

export type DeleteMeetingMutationVariables = {
  input: DeleteMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type DeleteMeetingMutation = {
  deleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    member: string,
    manager: string,
    startDate: string,
    ono?: string | null,
    status?: OnoStatus | null,
    templateID?: string | null,
    temporary?: boolean | null,
    createdAt: string,
    updatedAt: string,
    adminGroup: string,
    organizationGroup?: string | null,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    participated?: boolean | null,
    groupsCanAccess: Array< string | null >,
  } | null,
};

export type CreateTodoMutationVariables = {
  input: CreateTodoInput,
  condition?: ModelTodoConditionInput | null,
};

export type CreateTodoMutation = {
  createTodo?:  {
    __typename: "Todo",
    id: string,
    name: string,
    ono: string,
    assignmentUserId?: string | null,
    status: TodoStatus,
    createdMeetingID?: string | null,
    completedMeetingID?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    owners: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type UpdateTodoMutationVariables = {
  input: UpdateTodoInput,
  condition?: ModelTodoConditionInput | null,
};

export type UpdateTodoMutation = {
  updateTodo?:  {
    __typename: "Todo",
    id: string,
    name: string,
    ono: string,
    assignmentUserId?: string | null,
    status: TodoStatus,
    createdMeetingID?: string | null,
    completedMeetingID?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    owners: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type DeleteTodoMutationVariables = {
  input: DeleteTodoInput,
  condition?: ModelTodoConditionInput | null,
};

export type DeleteTodoMutation = {
  deleteTodo?:  {
    __typename: "Todo",
    id: string,
    name: string,
    ono: string,
    assignmentUserId?: string | null,
    status: TodoStatus,
    createdMeetingID?: string | null,
    completedMeetingID?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    owners: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type CreateNextActionMutationVariables = {
  input: CreateNextActionInput,
  condition?: ModelNextActionConditionInput | null,
};

export type CreateNextActionMutation = {
  createNextAction?:  {
    __typename: "NextAction",
    id: string,
    title: string,
    oneOnOneSettingID: string,
    managerID: string,
    addedOneOnOneID: string,
    completedOneOnOneID?: string | null,
    targetDate: string,
    isDone: boolean,
    createdAt: string,
    updatedAt: string,
    organizationGroup: string,
    manager:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type UpdateNextActionMutationVariables = {
  input: UpdateNextActionInput,
  condition?: ModelNextActionConditionInput | null,
};

export type UpdateNextActionMutation = {
  updateNextAction?:  {
    __typename: "NextAction",
    id: string,
    title: string,
    oneOnOneSettingID: string,
    managerID: string,
    addedOneOnOneID: string,
    completedOneOnOneID?: string | null,
    targetDate: string,
    isDone: boolean,
    createdAt: string,
    updatedAt: string,
    organizationGroup: string,
    manager:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type DeleteNextActionMutationVariables = {
  input: DeleteNextActionInput,
  condition?: ModelNextActionConditionInput | null,
};

export type DeleteNextActionMutation = {
  deleteNextAction?:  {
    __typename: "NextAction",
    id: string,
    title: string,
    oneOnOneSettingID: string,
    managerID: string,
    addedOneOnOneID: string,
    completedOneOnOneID?: string | null,
    targetDate: string,
    isDone: boolean,
    createdAt: string,
    updatedAt: string,
    organizationGroup: string,
    manager:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type CreateOrganizationAgendaMutationVariables = {
  input: CreateOrganizationAgendaInput,
  condition?: ModelOrganizationAgendaConditionInput | null,
};

export type CreateOrganizationAgendaMutation = {
  createOrganizationAgenda?:  {
    __typename: "OrganizationAgenda",
    id: string,
    title: string,
    order: number,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrganizationAgendaMutationVariables = {
  input: UpdateOrganizationAgendaInput,
  condition?: ModelOrganizationAgendaConditionInput | null,
};

export type UpdateOrganizationAgendaMutation = {
  updateOrganizationAgenda?:  {
    __typename: "OrganizationAgenda",
    id: string,
    title: string,
    order: number,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrganizationAgendaMutationVariables = {
  input: DeleteOrganizationAgendaInput,
  condition?: ModelOrganizationAgendaConditionInput | null,
};

export type DeleteOrganizationAgendaMutation = {
  deleteOrganizationAgenda?:  {
    __typename: "OrganizationAgenda",
    id: string,
    title: string,
    order: number,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAgendaTemplateMutationVariables = {
  input: CreateAgendaTemplateInput,
  condition?: ModelAgendaTemplateConditionInput | null,
};

export type CreateAgendaTemplateMutation = {
  createAgendaTemplate?:  {
    __typename: "AgendaTemplate",
    id: string,
    title: string,
    purpose?: string | null,
    organizationGroup: string,
    ownerID: string,
    status: AgendaTemplateStatus,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    agendas?:  {
      __typename: "ModelAgendaTemplateAgendaConnection",
      items:  Array< {
        __typename: "AgendaTemplateAgenda",
        id: string,
        agendaTemplateID: string,
        title: string,
        order: number,
        ownerID: string,
        createdAt: string,
        updatedAt: string,
        organizationGroup: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateAgendaTemplateMutationVariables = {
  input: UpdateAgendaTemplateInput,
  condition?: ModelAgendaTemplateConditionInput | null,
};

export type UpdateAgendaTemplateMutation = {
  updateAgendaTemplate?:  {
    __typename: "AgendaTemplate",
    id: string,
    title: string,
    purpose?: string | null,
    organizationGroup: string,
    ownerID: string,
    status: AgendaTemplateStatus,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    agendas?:  {
      __typename: "ModelAgendaTemplateAgendaConnection",
      items:  Array< {
        __typename: "AgendaTemplateAgenda",
        id: string,
        agendaTemplateID: string,
        title: string,
        order: number,
        ownerID: string,
        createdAt: string,
        updatedAt: string,
        organizationGroup: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteAgendaTemplateMutationVariables = {
  input: DeleteAgendaTemplateInput,
  condition?: ModelAgendaTemplateConditionInput | null,
};

export type DeleteAgendaTemplateMutation = {
  deleteAgendaTemplate?:  {
    __typename: "AgendaTemplate",
    id: string,
    title: string,
    purpose?: string | null,
    organizationGroup: string,
    ownerID: string,
    status: AgendaTemplateStatus,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    agendas?:  {
      __typename: "ModelAgendaTemplateAgendaConnection",
      items:  Array< {
        __typename: "AgendaTemplateAgenda",
        id: string,
        agendaTemplateID: string,
        title: string,
        order: number,
        ownerID: string,
        createdAt: string,
        updatedAt: string,
        organizationGroup: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateAgendaTemplateAgendaMutationVariables = {
  input: CreateAgendaTemplateAgendaInput,
  condition?: ModelAgendaTemplateAgendaConditionInput | null,
};

export type CreateAgendaTemplateAgendaMutation = {
  createAgendaTemplateAgenda?:  {
    __typename: "AgendaTemplateAgenda",
    id: string,
    agendaTemplateID: string,
    title: string,
    order: number,
    ownerID: string,
    createdAt: string,
    updatedAt: string,
    organizationGroup: string,
  } | null,
};

export type UpdateAgendaTemplateAgendaMutationVariables = {
  input: UpdateAgendaTemplateAgendaInput,
  condition?: ModelAgendaTemplateAgendaConditionInput | null,
};

export type UpdateAgendaTemplateAgendaMutation = {
  updateAgendaTemplateAgenda?:  {
    __typename: "AgendaTemplateAgenda",
    id: string,
    agendaTemplateID: string,
    title: string,
    order: number,
    ownerID: string,
    createdAt: string,
    updatedAt: string,
    organizationGroup: string,
  } | null,
};

export type DeleteAgendaTemplateAgendaMutationVariables = {
  input: DeleteAgendaTemplateAgendaInput,
  condition?: ModelAgendaTemplateAgendaConditionInput | null,
};

export type DeleteAgendaTemplateAgendaMutation = {
  deleteAgendaTemplateAgenda?:  {
    __typename: "AgendaTemplateAgenda",
    id: string,
    agendaTemplateID: string,
    title: string,
    order: number,
    ownerID: string,
    createdAt: string,
    updatedAt: string,
    organizationGroup: string,
  } | null,
};

export type CreateTemplateMutationVariables = {
  input: CreateTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type CreateTemplateMutation = {
  createTemplate?:  {
    __typename: "Template",
    id: string,
    title?: string | null,
    ono?: string | null,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type UpdateTemplateMutationVariables = {
  input: UpdateTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type UpdateTemplateMutation = {
  updateTemplate?:  {
    __typename: "Template",
    id: string,
    title?: string | null,
    ono?: string | null,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type DeleteTemplateMutationVariables = {
  input: DeleteTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type DeleteTemplateMutation = {
  deleteTemplate?:  {
    __typename: "Template",
    id: string,
    title?: string | null,
    ono?: string | null,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type CreateTemplateAgendaMutationVariables = {
  input: CreateTemplateAgendaInput,
  condition?: ModelTemplateAgendaConditionInput | null,
};

export type CreateTemplateAgendaMutation = {
  createTemplateAgenda?:  {
    __typename: "TemplateAgenda",
    id: string,
    template?: string | null,
    title: string,
    fromOrganization?: string | null,
    order: number,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type UpdateTemplateAgendaMutationVariables = {
  input: UpdateTemplateAgendaInput,
  condition?: ModelTemplateAgendaConditionInput | null,
};

export type UpdateTemplateAgendaMutation = {
  updateTemplateAgenda?:  {
    __typename: "TemplateAgenda",
    id: string,
    template?: string | null,
    title: string,
    fromOrganization?: string | null,
    order: number,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type DeleteTemplateAgendaMutationVariables = {
  input: DeleteTemplateAgendaInput,
  condition?: ModelTemplateAgendaConditionInput | null,
};

export type DeleteTemplateAgendaMutation = {
  deleteTemplateAgenda?:  {
    __typename: "TemplateAgenda",
    id: string,
    template?: string | null,
    title: string,
    fromOrganization?: string | null,
    order: number,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type CreateAgendaMutationVariables = {
  input: CreateAgendaInput,
  condition?: ModelAgendaConditionInput | null,
};

export type CreateAgendaMutation = {
  createAgenda?:  {
    __typename: "Agenda",
    id: string,
    oneOnOneID: string,
    title: string,
    order: number,
    referenceID: string,
    agendaType: AgendaType,
    isDone: boolean,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owners: Array< string | null >,
    creator?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelAgendaCommentConnection",
      items:  Array< {
        __typename: "AgendaComment",
        id: string,
        oneOnOneID: string,
        agendaID: string,
        comment: string,
        commenterID: string,
        organizationGroup: string,
        aiAdvice?: string | null,
        createdAt: string,
        updatedAt: string,
        commenter:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateAgendaMutationVariables = {
  input: UpdateAgendaInput,
  condition?: ModelAgendaConditionInput | null,
};

export type UpdateAgendaMutation = {
  updateAgenda?:  {
    __typename: "Agenda",
    id: string,
    oneOnOneID: string,
    title: string,
    order: number,
    referenceID: string,
    agendaType: AgendaType,
    isDone: boolean,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owners: Array< string | null >,
    creator?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelAgendaCommentConnection",
      items:  Array< {
        __typename: "AgendaComment",
        id: string,
        oneOnOneID: string,
        agendaID: string,
        comment: string,
        commenterID: string,
        organizationGroup: string,
        aiAdvice?: string | null,
        createdAt: string,
        updatedAt: string,
        commenter:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteAgendaMutationVariables = {
  input: DeleteAgendaInput,
  condition?: ModelAgendaConditionInput | null,
};

export type DeleteAgendaMutation = {
  deleteAgenda?:  {
    __typename: "Agenda",
    id: string,
    oneOnOneID: string,
    title: string,
    order: number,
    referenceID: string,
    agendaType: AgendaType,
    isDone: boolean,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owners: Array< string | null >,
    creator?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelAgendaCommentConnection",
      items:  Array< {
        __typename: "AgendaComment",
        id: string,
        oneOnOneID: string,
        agendaID: string,
        comment: string,
        commenterID: string,
        organizationGroup: string,
        aiAdvice?: string | null,
        createdAt: string,
        updatedAt: string,
        commenter:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateAgendaCommentMutationVariables = {
  input: CreateAgendaCommentInput,
  condition?: ModelAgendaCommentConditionInput | null,
};

export type CreateAgendaCommentMutation = {
  createAgendaComment?:  {
    __typename: "AgendaComment",
    id: string,
    oneOnOneID: string,
    agendaID: string,
    comment: string,
    commenterID: string,
    organizationGroup: string,
    aiAdvice?: string | null,
    createdAt: string,
    updatedAt: string,
    commenter:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type UpdateAgendaCommentMutationVariables = {
  input: UpdateAgendaCommentInput,
  condition?: ModelAgendaCommentConditionInput | null,
};

export type UpdateAgendaCommentMutation = {
  updateAgendaComment?:  {
    __typename: "AgendaComment",
    id: string,
    oneOnOneID: string,
    agendaID: string,
    comment: string,
    commenterID: string,
    organizationGroup: string,
    aiAdvice?: string | null,
    createdAt: string,
    updatedAt: string,
    commenter:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type DeleteAgendaCommentMutationVariables = {
  input: DeleteAgendaCommentInput,
  condition?: ModelAgendaCommentConditionInput | null,
};

export type DeleteAgendaCommentMutation = {
  deleteAgendaComment?:  {
    __typename: "AgendaComment",
    id: string,
    oneOnOneID: string,
    agendaID: string,
    comment: string,
    commenterID: string,
    organizationGroup: string,
    aiAdvice?: string | null,
    createdAt: string,
    updatedAt: string,
    commenter:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type CreateNoteMutationVariables = {
  input: CreateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type CreateNoteMutation = {
  createNote?:  {
    __typename: "Note",
    id: string,
    meeting: string,
    agendaID?: string | null,
    referenceID?: string | null,
    user: string,
    note?: string | null,
    type: NoteType,
    owners: Array< string | null >,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type UpdateNoteMutationVariables = {
  input: UpdateNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type UpdateNoteMutation = {
  updateNote?:  {
    __typename: "Note",
    id: string,
    meeting: string,
    agendaID?: string | null,
    referenceID?: string | null,
    user: string,
    note?: string | null,
    type: NoteType,
    owners: Array< string | null >,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type DeleteNoteMutationVariables = {
  input: DeleteNoteInput,
  condition?: ModelNoteConditionInput | null,
};

export type DeleteNoteMutation = {
  deleteNote?:  {
    __typename: "Note",
    id: string,
    meeting: string,
    agendaID?: string | null,
    referenceID?: string | null,
    user: string,
    note?: string | null,
    type: NoteType,
    owners: Array< string | null >,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type CreateOneOnOneSettingMutationVariables = {
  input: CreateOneOnOneSettingInput,
  condition?: ModelOneOnOneSettingConditionInput | null,
};

export type CreateOneOnOneSettingMutation = {
  createOneOnOneSetting?:  {
    __typename: "OneOnOneSetting",
    id: string,
    userID1: string,
    userID2: string,
    frequency: Frequency,
    nextOneOnOneDate: string,
    nextOneOnOneTime: string,
    agendaTemplateID?: string | null,
    status: OneOnOneSettingStatus,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user1:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    user2:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    oneOnOneList?:  {
      __typename: "ModelOneOnOneConnection",
      items:  Array< {
        __typename: "OneOnOne",
        id: string,
        userID1: string,
        userID2: string,
        managerID?: string | null,
        oneOnOneSettingID: string,
        frequency: Frequency,
        startDate: string,
        startTime: string,
        status: OneOnOneStatus,
        agendaTemplateID?: string | null,
        recordingAgendaID?: string | null,
        recordingTarget?: RecordingTarget | null,
        aiSummary?: string | null,
        calendarApp?: CalendarApp | null,
        eventID?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneSetting:  {
          __typename: "OneOnOneSetting",
          id: string,
          userID1: string,
          userID2: string,
          frequency: Frequency,
          nextOneOnOneDate: string,
          nextOneOnOneTime: string,
          agendaTemplateID?: string | null,
          status: OneOnOneSettingStatus,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneList?:  {
            __typename: "ModelOneOnOneConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOneOnOneSettingMutationVariables = {
  input: UpdateOneOnOneSettingInput,
  condition?: ModelOneOnOneSettingConditionInput | null,
};

export type UpdateOneOnOneSettingMutation = {
  updateOneOnOneSetting?:  {
    __typename: "OneOnOneSetting",
    id: string,
    userID1: string,
    userID2: string,
    frequency: Frequency,
    nextOneOnOneDate: string,
    nextOneOnOneTime: string,
    agendaTemplateID?: string | null,
    status: OneOnOneSettingStatus,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user1:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    user2:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    oneOnOneList?:  {
      __typename: "ModelOneOnOneConnection",
      items:  Array< {
        __typename: "OneOnOne",
        id: string,
        userID1: string,
        userID2: string,
        managerID?: string | null,
        oneOnOneSettingID: string,
        frequency: Frequency,
        startDate: string,
        startTime: string,
        status: OneOnOneStatus,
        agendaTemplateID?: string | null,
        recordingAgendaID?: string | null,
        recordingTarget?: RecordingTarget | null,
        aiSummary?: string | null,
        calendarApp?: CalendarApp | null,
        eventID?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneSetting:  {
          __typename: "OneOnOneSetting",
          id: string,
          userID1: string,
          userID2: string,
          frequency: Frequency,
          nextOneOnOneDate: string,
          nextOneOnOneTime: string,
          agendaTemplateID?: string | null,
          status: OneOnOneSettingStatus,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneList?:  {
            __typename: "ModelOneOnOneConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOneOnOneSettingMutationVariables = {
  input: DeleteOneOnOneSettingInput,
  condition?: ModelOneOnOneSettingConditionInput | null,
};

export type DeleteOneOnOneSettingMutation = {
  deleteOneOnOneSetting?:  {
    __typename: "OneOnOneSetting",
    id: string,
    userID1: string,
    userID2: string,
    frequency: Frequency,
    nextOneOnOneDate: string,
    nextOneOnOneTime: string,
    agendaTemplateID?: string | null,
    status: OneOnOneSettingStatus,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user1:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    user2:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    oneOnOneList?:  {
      __typename: "ModelOneOnOneConnection",
      items:  Array< {
        __typename: "OneOnOne",
        id: string,
        userID1: string,
        userID2: string,
        managerID?: string | null,
        oneOnOneSettingID: string,
        frequency: Frequency,
        startDate: string,
        startTime: string,
        status: OneOnOneStatus,
        agendaTemplateID?: string | null,
        recordingAgendaID?: string | null,
        recordingTarget?: RecordingTarget | null,
        aiSummary?: string | null,
        calendarApp?: CalendarApp | null,
        eventID?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneSetting:  {
          __typename: "OneOnOneSetting",
          id: string,
          userID1: string,
          userID2: string,
          frequency: Frequency,
          nextOneOnOneDate: string,
          nextOneOnOneTime: string,
          agendaTemplateID?: string | null,
          status: OneOnOneSettingStatus,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneList?:  {
            __typename: "ModelOneOnOneConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateOneOnOneMutationVariables = {
  input: CreateOneOnOneInput,
  condition?: ModelOneOnOneConditionInput | null,
};

export type CreateOneOnOneMutation = {
  createOneOnOne?:  {
    __typename: "OneOnOne",
    id: string,
    userID1: string,
    userID2: string,
    managerID?: string | null,
    oneOnOneSettingID: string,
    frequency: Frequency,
    startDate: string,
    startTime: string,
    status: OneOnOneStatus,
    agendaTemplateID?: string | null,
    recordingAgendaID?: string | null,
    recordingTarget?: RecordingTarget | null,
    aiSummary?: string | null,
    calendarApp?: CalendarApp | null,
    eventID?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user1:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    user2:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    oneOnOneSetting:  {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateOneOnOneMutationVariables = {
  input: UpdateOneOnOneInput,
  condition?: ModelOneOnOneConditionInput | null,
};

export type UpdateOneOnOneMutation = {
  updateOneOnOne?:  {
    __typename: "OneOnOne",
    id: string,
    userID1: string,
    userID2: string,
    managerID?: string | null,
    oneOnOneSettingID: string,
    frequency: Frequency,
    startDate: string,
    startTime: string,
    status: OneOnOneStatus,
    agendaTemplateID?: string | null,
    recordingAgendaID?: string | null,
    recordingTarget?: RecordingTarget | null,
    aiSummary?: string | null,
    calendarApp?: CalendarApp | null,
    eventID?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user1:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    user2:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    oneOnOneSetting:  {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteOneOnOneMutationVariables = {
  input: DeleteOneOnOneInput,
  condition?: ModelOneOnOneConditionInput | null,
};

export type DeleteOneOnOneMutation = {
  deleteOneOnOne?:  {
    __typename: "OneOnOne",
    id: string,
    userID1: string,
    userID2: string,
    managerID?: string | null,
    oneOnOneSettingID: string,
    frequency: Frequency,
    startDate: string,
    startTime: string,
    status: OneOnOneStatus,
    agendaTemplateID?: string | null,
    recordingAgendaID?: string | null,
    recordingTarget?: RecordingTarget | null,
    aiSummary?: string | null,
    calendarApp?: CalendarApp | null,
    eventID?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user1:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    user2:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    oneOnOneSetting:  {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateShareMemoMutationVariables = {
  input: CreateShareMemoInput,
  condition?: ModelShareMemoConditionInput | null,
};

export type CreateShareMemoMutation = {
  createShareMemo?:  {
    __typename: "ShareMemo",
    id: string,
    oneOnOneID: string,
    ownerID: string,
    memo?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type UpdateShareMemoMutationVariables = {
  input: UpdateShareMemoInput,
  condition?: ModelShareMemoConditionInput | null,
};

export type UpdateShareMemoMutation = {
  updateShareMemo?:  {
    __typename: "ShareMemo",
    id: string,
    oneOnOneID: string,
    ownerID: string,
    memo?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type DeleteShareMemoMutationVariables = {
  input: DeleteShareMemoInput,
  condition?: ModelShareMemoConditionInput | null,
};

export type DeleteShareMemoMutation = {
  deleteShareMemo?:  {
    __typename: "ShareMemo",
    id: string,
    oneOnOneID: string,
    ownerID: string,
    memo?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type CreatePrivateMemoMutationVariables = {
  input: CreatePrivateMemoInput,
  condition?: ModelPrivateMemoConditionInput | null,
};

export type CreatePrivateMemoMutation = {
  createPrivateMemo?:  {
    __typename: "PrivateMemo",
    id: string,
    oneOnOneID: string,
    memo?: string | null,
    ownerID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type UpdatePrivateMemoMutationVariables = {
  input: UpdatePrivateMemoInput,
  condition?: ModelPrivateMemoConditionInput | null,
};

export type UpdatePrivateMemoMutation = {
  updatePrivateMemo?:  {
    __typename: "PrivateMemo",
    id: string,
    oneOnOneID: string,
    memo?: string | null,
    ownerID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type DeletePrivateMemoMutationVariables = {
  input: DeletePrivateMemoInput,
  condition?: ModelPrivateMemoConditionInput | null,
};

export type DeletePrivateMemoMutation = {
  deletePrivateMemo?:  {
    __typename: "PrivateMemo",
    id: string,
    oneOnOneID: string,
    memo?: string | null,
    ownerID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type CreateGradeMatrixMutationVariables = {
  input: CreateGradeMatrixInput,
  condition?: ModelGradeMatrixConditionInput | null,
};

export type CreateGradeMatrixMutation = {
  createGradeMatrix?:  {
    __typename: "GradeMatrix",
    gradeMatrixID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    memberList?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    competencyAreaList?:  {
      __typename: "ModelCompetencyAreaConnection",
      items:  Array< {
        __typename: "CompetencyArea",
        competencyAreaID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeList?:  {
      __typename: "ModelGradeConnection",
      items:  Array< {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    competencyItemList?:  {
      __typename: "ModelCompetencyItemConnection",
      items:  Array< {
        __typename: "CompetencyItem",
        competencyItemID: string,
        item: string,
        gradeID: string,
        competencyAreaID: string,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateGradeMatrixMutationVariables = {
  input: UpdateGradeMatrixInput,
  condition?: ModelGradeMatrixConditionInput | null,
};

export type UpdateGradeMatrixMutation = {
  updateGradeMatrix?:  {
    __typename: "GradeMatrix",
    gradeMatrixID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    memberList?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    competencyAreaList?:  {
      __typename: "ModelCompetencyAreaConnection",
      items:  Array< {
        __typename: "CompetencyArea",
        competencyAreaID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeList?:  {
      __typename: "ModelGradeConnection",
      items:  Array< {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    competencyItemList?:  {
      __typename: "ModelCompetencyItemConnection",
      items:  Array< {
        __typename: "CompetencyItem",
        competencyItemID: string,
        item: string,
        gradeID: string,
        competencyAreaID: string,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteGradeMatrixMutationVariables = {
  input: DeleteGradeMatrixInput,
  condition?: ModelGradeMatrixConditionInput | null,
};

export type DeleteGradeMatrixMutation = {
  deleteGradeMatrix?:  {
    __typename: "GradeMatrix",
    gradeMatrixID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    memberList?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    competencyAreaList?:  {
      __typename: "ModelCompetencyAreaConnection",
      items:  Array< {
        __typename: "CompetencyArea",
        competencyAreaID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeList?:  {
      __typename: "ModelGradeConnection",
      items:  Array< {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    competencyItemList?:  {
      __typename: "ModelCompetencyItemConnection",
      items:  Array< {
        __typename: "CompetencyItem",
        competencyItemID: string,
        item: string,
        gradeID: string,
        competencyAreaID: string,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCompetencyAreaMutationVariables = {
  input: CreateCompetencyAreaInput,
  condition?: ModelCompetencyAreaConditionInput | null,
};

export type CreateCompetencyAreaMutation = {
  createCompetencyArea?:  {
    __typename: "CompetencyArea",
    competencyAreaID: string,
    title: string,
    description?: string | null,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompetencyAreaMutationVariables = {
  input: UpdateCompetencyAreaInput,
  condition?: ModelCompetencyAreaConditionInput | null,
};

export type UpdateCompetencyAreaMutation = {
  updateCompetencyArea?:  {
    __typename: "CompetencyArea",
    competencyAreaID: string,
    title: string,
    description?: string | null,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompetencyAreaMutationVariables = {
  input: DeleteCompetencyAreaInput,
  condition?: ModelCompetencyAreaConditionInput | null,
};

export type DeleteCompetencyAreaMutation = {
  deleteCompetencyArea?:  {
    __typename: "CompetencyArea",
    competencyAreaID: string,
    title: string,
    description?: string | null,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGradeMutationVariables = {
  input: CreateGradeInput,
  condition?: ModelGradeConditionInput | null,
};

export type CreateGradeMutation = {
  createGrade?:  {
    __typename: "Grade",
    gradeID: string,
    title: string,
    description?: string | null,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGradeMutationVariables = {
  input: UpdateGradeInput,
  condition?: ModelGradeConditionInput | null,
};

export type UpdateGradeMutation = {
  updateGrade?:  {
    __typename: "Grade",
    gradeID: string,
    title: string,
    description?: string | null,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGradeMutationVariables = {
  input: DeleteGradeInput,
  condition?: ModelGradeConditionInput | null,
};

export type DeleteGradeMutation = {
  deleteGrade?:  {
    __typename: "Grade",
    gradeID: string,
    title: string,
    description?: string | null,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCompetencyItemMutationVariables = {
  input: CreateCompetencyItemInput,
  condition?: ModelCompetencyItemConditionInput | null,
};

export type CreateCompetencyItemMutation = {
  createCompetencyItem?:  {
    __typename: "CompetencyItem",
    competencyItemID: string,
    item: string,
    gradeID: string,
    competencyAreaID: string,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompetencyItemMutationVariables = {
  input: UpdateCompetencyItemInput,
  condition?: ModelCompetencyItemConditionInput | null,
};

export type UpdateCompetencyItemMutation = {
  updateCompetencyItem?:  {
    __typename: "CompetencyItem",
    competencyItemID: string,
    item: string,
    gradeID: string,
    competencyAreaID: string,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompetencyItemMutationVariables = {
  input: DeleteCompetencyItemInput,
  condition?: ModelCompetencyItemConditionInput | null,
};

export type DeleteCompetencyItemMutation = {
  deleteCompetencyItem?:  {
    __typename: "CompetencyItem",
    competencyItemID: string,
    item: string,
    gradeID: string,
    competencyAreaID: string,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSkillMapMutationVariables = {
  input: CreateSkillMapInput,
  condition?: ModelSkillMapConditionInput | null,
};

export type CreateSkillMapMutation = {
  createSkillMap?:  {
    __typename: "SkillMap",
    id: string,
    name: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSkillMapMutationVariables = {
  input: UpdateSkillMapInput,
  condition?: ModelSkillMapConditionInput | null,
};

export type UpdateSkillMapMutation = {
  updateSkillMap?:  {
    __typename: "SkillMap",
    id: string,
    name: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSkillMapMutationVariables = {
  input: DeleteSkillMapInput,
  condition?: ModelSkillMapConditionInput | null,
};

export type DeleteSkillMapMutation = {
  deleteSkillMap?:  {
    __typename: "SkillMap",
    id: string,
    name: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSkillItemMutationVariables = {
  input: CreateSkillItemInput,
  condition?: ModelSkillItemConditionInput | null,
};

export type CreateSkillItemMutation = {
  createSkillItem?:  {
    __typename: "SkillItem",
    id: string,
    skillMapID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSkillItemMutationVariables = {
  input: UpdateSkillItemInput,
  condition?: ModelSkillItemConditionInput | null,
};

export type UpdateSkillItemMutation = {
  updateSkillItem?:  {
    __typename: "SkillItem",
    id: string,
    skillMapID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSkillItemMutationVariables = {
  input: DeleteSkillItemInput,
  condition?: ModelSkillItemConditionInput | null,
};

export type DeleteSkillItemMutation = {
  deleteSkillItem?:  {
    __typename: "SkillItem",
    id: string,
    skillMapID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLevelMutationVariables = {
  input: CreateLevelInput,
  condition?: ModelLevelConditionInput | null,
};

export type CreateLevelMutation = {
  createLevel?:  {
    __typename: "Level",
    id: string,
    skillMapID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLevelMutationVariables = {
  input: UpdateLevelInput,
  condition?: ModelLevelConditionInput | null,
};

export type UpdateLevelMutation = {
  updateLevel?:  {
    __typename: "Level",
    id: string,
    skillMapID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLevelMutationVariables = {
  input: DeleteLevelInput,
  condition?: ModelLevelConditionInput | null,
};

export type DeleteLevelMutation = {
  deleteLevel?:  {
    __typename: "Level",
    id: string,
    skillMapID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSkillMutationVariables = {
  input: CreateSkillInput,
  condition?: ModelSkillConditionInput | null,
};

export type CreateSkillMutation = {
  createSkill?:  {
    __typename: "Skill",
    id: string,
    skillMapID: string,
    levelID: string,
    skillItemID: string,
    outcome: string,
    process: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSkillMutationVariables = {
  input: UpdateSkillInput,
  condition?: ModelSkillConditionInput | null,
};

export type UpdateSkillMutation = {
  updateSkill?:  {
    __typename: "Skill",
    id: string,
    skillMapID: string,
    levelID: string,
    skillItemID: string,
    outcome: string,
    process: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSkillMutationVariables = {
  input: DeleteSkillInput,
  condition?: ModelSkillConditionInput | null,
};

export type DeleteSkillMutation = {
  deleteSkill?:  {
    __typename: "Skill",
    id: string,
    skillMapID: string,
    levelID: string,
    skillItemID: string,
    outcome: string,
    process: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIndividualSkillMutationVariables = {
  input: CreateIndividualSkillInput,
  condition?: ModelIndividualSkillConditionInput | null,
};

export type CreateIndividualSkillMutation = {
  createIndividualSkill?:  {
    __typename: "IndividualSkill",
    id: string,
    skillMapID: string,
    skillID: string,
    acquisitionStatus: AcquisitionStatus,
    acquisitionStartDate?: string | null,
    acquisitionEndDate?: string | null,
    userID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type UpdateIndividualSkillMutationVariables = {
  input: UpdateIndividualSkillInput,
  condition?: ModelIndividualSkillConditionInput | null,
};

export type UpdateIndividualSkillMutation = {
  updateIndividualSkill?:  {
    __typename: "IndividualSkill",
    id: string,
    skillMapID: string,
    skillID: string,
    acquisitionStatus: AcquisitionStatus,
    acquisitionStartDate?: string | null,
    acquisitionEndDate?: string | null,
    userID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type DeleteIndividualSkillMutationVariables = {
  input: DeleteIndividualSkillInput,
  condition?: ModelIndividualSkillConditionInput | null,
};

export type DeleteIndividualSkillMutation = {
  deleteIndividualSkill?:  {
    __typename: "IndividualSkill",
    id: string,
    skillMapID: string,
    skillID: string,
    acquisitionStatus: AcquisitionStatus,
    acquisitionStartDate?: string | null,
    acquisitionEndDate?: string | null,
    userID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type CreateReviewResultMutationVariables = {
  input: CreateReviewResultInput,
  condition?: ModelReviewResultConditionInput | null,
};

export type CreateReviewResultMutation = {
  createReviewResult?:  {
    __typename: "ReviewResult",
    reviewResultID: string,
    reviewID: string,
    revieweeID: string,
    finalReview?: string | null,
    nextGradeID?: string | null,
    nextBasicSalary?: number | null,
    salaryIncrease?: number | null,
    bonus?: number | null,
    shareStatus: ShareStatus,
    openStatus: OpenStatus,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewee?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    individualReviewSheetList?:  {
      __typename: "ModelIndividualReviewSheetConnection",
      items:  Array< {
        __typename: "IndividualReviewSheet",
        individualReviewSheetID: string,
        name: string,
        reviewerID: string,
        revieweeID: string,
        reviewOrder: ReviewOrder,
        summary?: string | null,
        responseStage: ResponseStage,
        reviewID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewAnswerList?:  Array< {
          __typename: "ReviewAnswer",
          reviewItemTitle: string,
          description?: string | null,
          customReviewID?: string | null,
          customReviewName?: string | null,
          goalID?: string | null,
          goalProgress?: Progress | null,
          finalValue?: number | null,
          targetValue?: number | null,
          goalMeasuringType?: MeasuringType | null,
          goalResultDate?: string | null,
          competencyItemID?: string | null,
          weight?: number | null,
          answer: string,
          point?: number | null,
          answerBasis?: string | null,
        } | null > | null,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateReviewResultMutationVariables = {
  input: UpdateReviewResultInput,
  condition?: ModelReviewResultConditionInput | null,
};

export type UpdateReviewResultMutation = {
  updateReviewResult?:  {
    __typename: "ReviewResult",
    reviewResultID: string,
    reviewID: string,
    revieweeID: string,
    finalReview?: string | null,
    nextGradeID?: string | null,
    nextBasicSalary?: number | null,
    salaryIncrease?: number | null,
    bonus?: number | null,
    shareStatus: ShareStatus,
    openStatus: OpenStatus,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewee?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    individualReviewSheetList?:  {
      __typename: "ModelIndividualReviewSheetConnection",
      items:  Array< {
        __typename: "IndividualReviewSheet",
        individualReviewSheetID: string,
        name: string,
        reviewerID: string,
        revieweeID: string,
        reviewOrder: ReviewOrder,
        summary?: string | null,
        responseStage: ResponseStage,
        reviewID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewAnswerList?:  Array< {
          __typename: "ReviewAnswer",
          reviewItemTitle: string,
          description?: string | null,
          customReviewID?: string | null,
          customReviewName?: string | null,
          goalID?: string | null,
          goalProgress?: Progress | null,
          finalValue?: number | null,
          targetValue?: number | null,
          goalMeasuringType?: MeasuringType | null,
          goalResultDate?: string | null,
          competencyItemID?: string | null,
          weight?: number | null,
          answer: string,
          point?: number | null,
          answerBasis?: string | null,
        } | null > | null,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteReviewResultMutationVariables = {
  input: DeleteReviewResultInput,
  condition?: ModelReviewResultConditionInput | null,
};

export type DeleteReviewResultMutation = {
  deleteReviewResult?:  {
    __typename: "ReviewResult",
    reviewResultID: string,
    reviewID: string,
    revieweeID: string,
    finalReview?: string | null,
    nextGradeID?: string | null,
    nextBasicSalary?: number | null,
    salaryIncrease?: number | null,
    bonus?: number | null,
    shareStatus: ShareStatus,
    openStatus: OpenStatus,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewee?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    individualReviewSheetList?:  {
      __typename: "ModelIndividualReviewSheetConnection",
      items:  Array< {
        __typename: "IndividualReviewSheet",
        individualReviewSheetID: string,
        name: string,
        reviewerID: string,
        revieweeID: string,
        reviewOrder: ReviewOrder,
        summary?: string | null,
        responseStage: ResponseStage,
        reviewID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewAnswerList?:  Array< {
          __typename: "ReviewAnswer",
          reviewItemTitle: string,
          description?: string | null,
          customReviewID?: string | null,
          customReviewName?: string | null,
          goalID?: string | null,
          goalProgress?: Progress | null,
          finalValue?: number | null,
          targetValue?: number | null,
          goalMeasuringType?: MeasuringType | null,
          goalResultDate?: string | null,
          competencyItemID?: string | null,
          weight?: number | null,
          answer: string,
          point?: number | null,
          answerBasis?: string | null,
        } | null > | null,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateIndividualReviewSheetMutationVariables = {
  input: CreateIndividualReviewSheetInput,
  condition?: ModelIndividualReviewSheetConditionInput | null,
};

export type CreateIndividualReviewSheetMutation = {
  createIndividualReviewSheet?:  {
    __typename: "IndividualReviewSheet",
    individualReviewSheetID: string,
    name: string,
    reviewerID: string,
    revieweeID: string,
    reviewOrder: ReviewOrder,
    summary?: string | null,
    responseStage: ResponseStage,
    reviewID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewAnswerList?:  Array< {
      __typename: "ReviewAnswer",
      reviewItemTitle: string,
      description?: string | null,
      customReviewID?: string | null,
      customReviewName?: string | null,
      goalID?: string | null,
      goalProgress?: Progress | null,
      finalValue?: number | null,
      targetValue?: number | null,
      goalMeasuringType?: MeasuringType | null,
      goalResultDate?: string | null,
      competencyItemID?: string | null,
      weight?: number | null,
      answer: string,
      point?: number | null,
      answerBasis?: string | null,
    } | null > | null,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
  } | null,
};

export type UpdateIndividualReviewSheetMutationVariables = {
  input: UpdateIndividualReviewSheetInput,
  condition?: ModelIndividualReviewSheetConditionInput | null,
};

export type UpdateIndividualReviewSheetMutation = {
  updateIndividualReviewSheet?:  {
    __typename: "IndividualReviewSheet",
    individualReviewSheetID: string,
    name: string,
    reviewerID: string,
    revieweeID: string,
    reviewOrder: ReviewOrder,
    summary?: string | null,
    responseStage: ResponseStage,
    reviewID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewAnswerList?:  Array< {
      __typename: "ReviewAnswer",
      reviewItemTitle: string,
      description?: string | null,
      customReviewID?: string | null,
      customReviewName?: string | null,
      goalID?: string | null,
      goalProgress?: Progress | null,
      finalValue?: number | null,
      targetValue?: number | null,
      goalMeasuringType?: MeasuringType | null,
      goalResultDate?: string | null,
      competencyItemID?: string | null,
      weight?: number | null,
      answer: string,
      point?: number | null,
      answerBasis?: string | null,
    } | null > | null,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
  } | null,
};

export type DeleteIndividualReviewSheetMutationVariables = {
  input: DeleteIndividualReviewSheetInput,
  condition?: ModelIndividualReviewSheetConditionInput | null,
};

export type DeleteIndividualReviewSheetMutation = {
  deleteIndividualReviewSheet?:  {
    __typename: "IndividualReviewSheet",
    individualReviewSheetID: string,
    name: string,
    reviewerID: string,
    revieweeID: string,
    reviewOrder: ReviewOrder,
    summary?: string | null,
    responseStage: ResponseStage,
    reviewID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewAnswerList?:  Array< {
      __typename: "ReviewAnswer",
      reviewItemTitle: string,
      description?: string | null,
      customReviewID?: string | null,
      customReviewName?: string | null,
      goalID?: string | null,
      goalProgress?: Progress | null,
      finalValue?: number | null,
      targetValue?: number | null,
      goalMeasuringType?: MeasuringType | null,
      goalResultDate?: string | null,
      competencyItemID?: string | null,
      weight?: number | null,
      answer: string,
      point?: number | null,
      answerBasis?: string | null,
    } | null > | null,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    reviewID: string,
    name: string,
    revieweeSetting: RevieweeSetting,
    revieweeReviewerSettings?:  Array< {
      __typename: "RevieweeReviewerSetting",
      revieweeID: string,
      gradeID?: string | null,
      firstReviewerIDs?: Array< string > | null,
      secondReviewerIDs?: Array< string > | null,
    } > | null,
    reviewTemplateID?: string | null,
    reviewPeriod?:  {
      __typename: "ReviewPeriod",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    targetGoalPeriod?:  {
      __typename: "TargetGoalPeriod",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    remindNotificationDate?: string | null,
    isSummary: boolean,
    isCalculate: boolean,
    reviewStage: ReviewStage,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewResultList?:  {
      __typename: "ModelReviewResultConnection",
      items:  Array< {
        __typename: "ReviewResult",
        reviewResultID: string,
        reviewID: string,
        revieweeID: string,
        finalReview?: string | null,
        nextGradeID?: string | null,
        nextBasicSalary?: number | null,
        salaryIncrease?: number | null,
        bonus?: number | null,
        shareStatus: ShareStatus,
        openStatus: OpenStatus,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewee?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        owner?: string | null,
        individualReviewSheetList?:  {
          __typename: "ModelIndividualReviewSheetConnection",
          items:  Array< {
            __typename: "IndividualReviewSheet",
            individualReviewSheetID: string,
            name: string,
            reviewerID: string,
            revieweeID: string,
            reviewOrder: ReviewOrder,
            summary?: string | null,
            responseStage: ResponseStage,
            reviewID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    individualReviewSheetList?:  {
      __typename: "ModelIndividualReviewSheetConnection",
      items:  Array< {
        __typename: "IndividualReviewSheet",
        individualReviewSheetID: string,
        name: string,
        reviewerID: string,
        revieweeID: string,
        reviewOrder: ReviewOrder,
        summary?: string | null,
        responseStage: ResponseStage,
        reviewID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewAnswerList?:  Array< {
          __typename: "ReviewAnswer",
          reviewItemTitle: string,
          description?: string | null,
          customReviewID?: string | null,
          customReviewName?: string | null,
          goalID?: string | null,
          goalProgress?: Progress | null,
          finalValue?: number | null,
          targetValue?: number | null,
          goalMeasuringType?: MeasuringType | null,
          goalResultDate?: string | null,
          competencyItemID?: string | null,
          weight?: number | null,
          answer: string,
          point?: number | null,
          answerBasis?: string | null,
        } | null > | null,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewTemplate?:  {
      __typename: "NewReviewTemplate",
      reviewTemplateID: string,
      name: string,
      description?: string | null,
      reviewMethod:  {
        __typename: "ReviewMethod",
        isSelfReview: boolean,
        isFirstReview: boolean,
        isSecondReview: boolean,
        isFinalReview: boolean,
      },
      reviewSettings:  {
        __typename: "ReviewSetting",
        customReviewSettings?:  Array< {
          __typename: "CustomReviewSetting",
          customReviewID: string,
          weight?: number | null,
        } > | null,
        goalReviewSetting:  {
          __typename: "GoalReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          goalRange: GoalRange,
          goalStatus: GoalStatus,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        competencyReviewSetting:  {
          __typename: "CompetencyReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        isWeight: boolean,
      },
      calculable: boolean,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    reviewID: string,
    name: string,
    revieweeSetting: RevieweeSetting,
    revieweeReviewerSettings?:  Array< {
      __typename: "RevieweeReviewerSetting",
      revieweeID: string,
      gradeID?: string | null,
      firstReviewerIDs?: Array< string > | null,
      secondReviewerIDs?: Array< string > | null,
    } > | null,
    reviewTemplateID?: string | null,
    reviewPeriod?:  {
      __typename: "ReviewPeriod",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    targetGoalPeriod?:  {
      __typename: "TargetGoalPeriod",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    remindNotificationDate?: string | null,
    isSummary: boolean,
    isCalculate: boolean,
    reviewStage: ReviewStage,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewResultList?:  {
      __typename: "ModelReviewResultConnection",
      items:  Array< {
        __typename: "ReviewResult",
        reviewResultID: string,
        reviewID: string,
        revieweeID: string,
        finalReview?: string | null,
        nextGradeID?: string | null,
        nextBasicSalary?: number | null,
        salaryIncrease?: number | null,
        bonus?: number | null,
        shareStatus: ShareStatus,
        openStatus: OpenStatus,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewee?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        owner?: string | null,
        individualReviewSheetList?:  {
          __typename: "ModelIndividualReviewSheetConnection",
          items:  Array< {
            __typename: "IndividualReviewSheet",
            individualReviewSheetID: string,
            name: string,
            reviewerID: string,
            revieweeID: string,
            reviewOrder: ReviewOrder,
            summary?: string | null,
            responseStage: ResponseStage,
            reviewID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    individualReviewSheetList?:  {
      __typename: "ModelIndividualReviewSheetConnection",
      items:  Array< {
        __typename: "IndividualReviewSheet",
        individualReviewSheetID: string,
        name: string,
        reviewerID: string,
        revieweeID: string,
        reviewOrder: ReviewOrder,
        summary?: string | null,
        responseStage: ResponseStage,
        reviewID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewAnswerList?:  Array< {
          __typename: "ReviewAnswer",
          reviewItemTitle: string,
          description?: string | null,
          customReviewID?: string | null,
          customReviewName?: string | null,
          goalID?: string | null,
          goalProgress?: Progress | null,
          finalValue?: number | null,
          targetValue?: number | null,
          goalMeasuringType?: MeasuringType | null,
          goalResultDate?: string | null,
          competencyItemID?: string | null,
          weight?: number | null,
          answer: string,
          point?: number | null,
          answerBasis?: string | null,
        } | null > | null,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewTemplate?:  {
      __typename: "NewReviewTemplate",
      reviewTemplateID: string,
      name: string,
      description?: string | null,
      reviewMethod:  {
        __typename: "ReviewMethod",
        isSelfReview: boolean,
        isFirstReview: boolean,
        isSecondReview: boolean,
        isFinalReview: boolean,
      },
      reviewSettings:  {
        __typename: "ReviewSetting",
        customReviewSettings?:  Array< {
          __typename: "CustomReviewSetting",
          customReviewID: string,
          weight?: number | null,
        } > | null,
        goalReviewSetting:  {
          __typename: "GoalReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          goalRange: GoalRange,
          goalStatus: GoalStatus,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        competencyReviewSetting:  {
          __typename: "CompetencyReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        isWeight: boolean,
      },
      calculable: boolean,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    reviewID: string,
    name: string,
    revieweeSetting: RevieweeSetting,
    revieweeReviewerSettings?:  Array< {
      __typename: "RevieweeReviewerSetting",
      revieweeID: string,
      gradeID?: string | null,
      firstReviewerIDs?: Array< string > | null,
      secondReviewerIDs?: Array< string > | null,
    } > | null,
    reviewTemplateID?: string | null,
    reviewPeriod?:  {
      __typename: "ReviewPeriod",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    targetGoalPeriod?:  {
      __typename: "TargetGoalPeriod",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    remindNotificationDate?: string | null,
    isSummary: boolean,
    isCalculate: boolean,
    reviewStage: ReviewStage,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewResultList?:  {
      __typename: "ModelReviewResultConnection",
      items:  Array< {
        __typename: "ReviewResult",
        reviewResultID: string,
        reviewID: string,
        revieweeID: string,
        finalReview?: string | null,
        nextGradeID?: string | null,
        nextBasicSalary?: number | null,
        salaryIncrease?: number | null,
        bonus?: number | null,
        shareStatus: ShareStatus,
        openStatus: OpenStatus,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewee?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        owner?: string | null,
        individualReviewSheetList?:  {
          __typename: "ModelIndividualReviewSheetConnection",
          items:  Array< {
            __typename: "IndividualReviewSheet",
            individualReviewSheetID: string,
            name: string,
            reviewerID: string,
            revieweeID: string,
            reviewOrder: ReviewOrder,
            summary?: string | null,
            responseStage: ResponseStage,
            reviewID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    individualReviewSheetList?:  {
      __typename: "ModelIndividualReviewSheetConnection",
      items:  Array< {
        __typename: "IndividualReviewSheet",
        individualReviewSheetID: string,
        name: string,
        reviewerID: string,
        revieweeID: string,
        reviewOrder: ReviewOrder,
        summary?: string | null,
        responseStage: ResponseStage,
        reviewID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewAnswerList?:  Array< {
          __typename: "ReviewAnswer",
          reviewItemTitle: string,
          description?: string | null,
          customReviewID?: string | null,
          customReviewName?: string | null,
          goalID?: string | null,
          goalProgress?: Progress | null,
          finalValue?: number | null,
          targetValue?: number | null,
          goalMeasuringType?: MeasuringType | null,
          goalResultDate?: string | null,
          competencyItemID?: string | null,
          weight?: number | null,
          answer: string,
          point?: number | null,
          answerBasis?: string | null,
        } | null > | null,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewTemplate?:  {
      __typename: "NewReviewTemplate",
      reviewTemplateID: string,
      name: string,
      description?: string | null,
      reviewMethod:  {
        __typename: "ReviewMethod",
        isSelfReview: boolean,
        isFirstReview: boolean,
        isSecondReview: boolean,
        isFinalReview: boolean,
      },
      reviewSettings:  {
        __typename: "ReviewSetting",
        customReviewSettings?:  Array< {
          __typename: "CustomReviewSetting",
          customReviewID: string,
          weight?: number | null,
        } > | null,
        goalReviewSetting:  {
          __typename: "GoalReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          goalRange: GoalRange,
          goalStatus: GoalStatus,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        competencyReviewSetting:  {
          __typename: "CompetencyReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        isWeight: boolean,
      },
      calculable: boolean,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateNewReviewTemplateMutationVariables = {
  input: CreateNewReviewTemplateInput,
  condition?: ModelNewReviewTemplateConditionInput | null,
};

export type CreateNewReviewTemplateMutation = {
  createNewReviewTemplate?:  {
    __typename: "NewReviewTemplate",
    reviewTemplateID: string,
    name: string,
    description?: string | null,
    reviewMethod:  {
      __typename: "ReviewMethod",
      isSelfReview: boolean,
      isFirstReview: boolean,
      isSecondReview: boolean,
      isFinalReview: boolean,
    },
    reviewSettings:  {
      __typename: "ReviewSetting",
      customReviewSettings?:  Array< {
        __typename: "CustomReviewSetting",
        customReviewID: string,
        weight?: number | null,
      } > | null,
      goalReviewSetting:  {
        __typename: "GoalReviewSetting",
        isReviewInclude: boolean,
        question: string,
        description?: string | null,
        goalRange: GoalRange,
        goalStatus: GoalStatus,
        answerType: AnswerType,
        options?:  Array< {
          __typename: "Option",
          item?: string | null,
          pointAllocation?: number | null,
        } > | null,
        numberRange?:  {
          __typename: "NumberRange",
          lowerLimit?: number | null,
          upperLimit?: number | null,
        } | null,
        isAnswerBasis: boolean,
        isAnswerBasisEntryOptional: boolean,
        weight?: number | null,
      },
      competencyReviewSetting:  {
        __typename: "CompetencyReviewSetting",
        isReviewInclude: boolean,
        question: string,
        description?: string | null,
        answerType: AnswerType,
        options?:  Array< {
          __typename: "Option",
          item?: string | null,
          pointAllocation?: number | null,
        } > | null,
        numberRange?:  {
          __typename: "NumberRange",
          lowerLimit?: number | null,
          upperLimit?: number | null,
        } | null,
        isAnswerBasis: boolean,
        isAnswerBasisEntryOptional: boolean,
        weight?: number | null,
      },
      isWeight: boolean,
    },
    calculable: boolean,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNewReviewTemplateMutationVariables = {
  input: UpdateNewReviewTemplateInput,
  condition?: ModelNewReviewTemplateConditionInput | null,
};

export type UpdateNewReviewTemplateMutation = {
  updateNewReviewTemplate?:  {
    __typename: "NewReviewTemplate",
    reviewTemplateID: string,
    name: string,
    description?: string | null,
    reviewMethod:  {
      __typename: "ReviewMethod",
      isSelfReview: boolean,
      isFirstReview: boolean,
      isSecondReview: boolean,
      isFinalReview: boolean,
    },
    reviewSettings:  {
      __typename: "ReviewSetting",
      customReviewSettings?:  Array< {
        __typename: "CustomReviewSetting",
        customReviewID: string,
        weight?: number | null,
      } > | null,
      goalReviewSetting:  {
        __typename: "GoalReviewSetting",
        isReviewInclude: boolean,
        question: string,
        description?: string | null,
        goalRange: GoalRange,
        goalStatus: GoalStatus,
        answerType: AnswerType,
        options?:  Array< {
          __typename: "Option",
          item?: string | null,
          pointAllocation?: number | null,
        } > | null,
        numberRange?:  {
          __typename: "NumberRange",
          lowerLimit?: number | null,
          upperLimit?: number | null,
        } | null,
        isAnswerBasis: boolean,
        isAnswerBasisEntryOptional: boolean,
        weight?: number | null,
      },
      competencyReviewSetting:  {
        __typename: "CompetencyReviewSetting",
        isReviewInclude: boolean,
        question: string,
        description?: string | null,
        answerType: AnswerType,
        options?:  Array< {
          __typename: "Option",
          item?: string | null,
          pointAllocation?: number | null,
        } > | null,
        numberRange?:  {
          __typename: "NumberRange",
          lowerLimit?: number | null,
          upperLimit?: number | null,
        } | null,
        isAnswerBasis: boolean,
        isAnswerBasisEntryOptional: boolean,
        weight?: number | null,
      },
      isWeight: boolean,
    },
    calculable: boolean,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNewReviewTemplateMutationVariables = {
  input: DeleteNewReviewTemplateInput,
  condition?: ModelNewReviewTemplateConditionInput | null,
};

export type DeleteNewReviewTemplateMutation = {
  deleteNewReviewTemplate?:  {
    __typename: "NewReviewTemplate",
    reviewTemplateID: string,
    name: string,
    description?: string | null,
    reviewMethod:  {
      __typename: "ReviewMethod",
      isSelfReview: boolean,
      isFirstReview: boolean,
      isSecondReview: boolean,
      isFinalReview: boolean,
    },
    reviewSettings:  {
      __typename: "ReviewSetting",
      customReviewSettings?:  Array< {
        __typename: "CustomReviewSetting",
        customReviewID: string,
        weight?: number | null,
      } > | null,
      goalReviewSetting:  {
        __typename: "GoalReviewSetting",
        isReviewInclude: boolean,
        question: string,
        description?: string | null,
        goalRange: GoalRange,
        goalStatus: GoalStatus,
        answerType: AnswerType,
        options?:  Array< {
          __typename: "Option",
          item?: string | null,
          pointAllocation?: number | null,
        } > | null,
        numberRange?:  {
          __typename: "NumberRange",
          lowerLimit?: number | null,
          upperLimit?: number | null,
        } | null,
        isAnswerBasis: boolean,
        isAnswerBasisEntryOptional: boolean,
        weight?: number | null,
      },
      competencyReviewSetting:  {
        __typename: "CompetencyReviewSetting",
        isReviewInclude: boolean,
        question: string,
        description?: string | null,
        answerType: AnswerType,
        options?:  Array< {
          __typename: "Option",
          item?: string | null,
          pointAllocation?: number | null,
        } > | null,
        numberRange?:  {
          __typename: "NumberRange",
          lowerLimit?: number | null,
          upperLimit?: number | null,
        } | null,
        isAnswerBasis: boolean,
        isAnswerBasisEntryOptional: boolean,
        weight?: number | null,
      },
      isWeight: boolean,
    },
    calculable: boolean,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCustomReviewMutationVariables = {
  input: CreateCustomReviewInput,
  condition?: ModelCustomReviewConditionInput | null,
};

export type CreateCustomReviewMutation = {
  createCustomReview?:  {
    __typename: "CustomReview",
    customReviewID: string,
    name: string,
    isWeight: boolean,
    customReviewItemList:  Array< {
      __typename: "CustomReviewItem",
      name: string,
      weight?: number | null,
    } >,
    question: string,
    description?: string | null,
    answerType: AnswerType,
    organizationGroup: string,
    options?:  Array< {
      __typename: "Option",
      item?: string | null,
      pointAllocation?: number | null,
    } > | null,
    numberRange?:  {
      __typename: "NumberRange",
      lowerLimit?: number | null,
      upperLimit?: number | null,
    } | null,
    isAnswerBasis: boolean,
    isAnswerBasisEntryOptional: boolean,
    calculable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomReviewMutationVariables = {
  input: UpdateCustomReviewInput,
  condition?: ModelCustomReviewConditionInput | null,
};

export type UpdateCustomReviewMutation = {
  updateCustomReview?:  {
    __typename: "CustomReview",
    customReviewID: string,
    name: string,
    isWeight: boolean,
    customReviewItemList:  Array< {
      __typename: "CustomReviewItem",
      name: string,
      weight?: number | null,
    } >,
    question: string,
    description?: string | null,
    answerType: AnswerType,
    organizationGroup: string,
    options?:  Array< {
      __typename: "Option",
      item?: string | null,
      pointAllocation?: number | null,
    } > | null,
    numberRange?:  {
      __typename: "NumberRange",
      lowerLimit?: number | null,
      upperLimit?: number | null,
    } | null,
    isAnswerBasis: boolean,
    isAnswerBasisEntryOptional: boolean,
    calculable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCustomReviewMutationVariables = {
  input: DeleteCustomReviewInput,
  condition?: ModelCustomReviewConditionInput | null,
};

export type DeleteCustomReviewMutation = {
  deleteCustomReview?:  {
    __typename: "CustomReview",
    customReviewID: string,
    name: string,
    isWeight: boolean,
    customReviewItemList:  Array< {
      __typename: "CustomReviewItem",
      name: string,
      weight?: number | null,
    } >,
    question: string,
    description?: string | null,
    answerType: AnswerType,
    organizationGroup: string,
    options?:  Array< {
      __typename: "Option",
      item?: string | null,
      pointAllocation?: number | null,
    } > | null,
    numberRange?:  {
      __typename: "NumberRange",
      lowerLimit?: number | null,
      upperLimit?: number | null,
    } | null,
    isAnswerBasis: boolean,
    isAnswerBasisEntryOptional: boolean,
    calculable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGoalReportsQueryVariables = {
  groupBy: OnoReportQueriesGroupBy,
  organizationID: string,
  organizationGroup: string,
  adminGroup: string,
  searchPeriod?: SearchPeriod | null,
};

export type ListGoalReportsQuery = {
  listGoalReports?:  {
    __typename: "ListGoalReportsResponse",
    graphDataLabels: Array< string | null >,
    graphData:  Array< {
      __typename: "GoalReportGraphDatum",
      id: Progress,
      title: string,
      values: Array< number | null >,
    } | null >,
    tableData:  Array< {
      __typename: "GoalReportTableDatum",
      id: string,
      title: string,
      allCount: number,
      notUpdatedCount: number,
      offTrackCount: number,
      progressingCount: number,
      onTrackCount: number,
      imperfectCount: number,
      perfectCount: number,
    } | null >,
  } | null,
};

export type AnalysisReportQueriesQueryVariables = {
  type: AnalysisReportType,
  adminGroup: string,
  start: string,
  end: string,
};

export type AnalysisReportQueriesQuery = {
  AnalysisReportQueries?:  {
    __typename: "AnalysisReportQueriesResponse",
    agendaTableData?:  Array< {
      __typename: "AnalysisAgendaTableData",
      type: AgendaType,
      title: string,
      completedCount: number,
      wholeCount: number,
    } | null > | null,
    onoTableData?:  Array< {
      __typename: "AnalysisOnoTableData",
      id: string,
      name: string,
      settingRate: number,
      memberItems:  Array< {
        __typename: "AnalysisOnoMemberItem",
        id: string,
        name: string,
        setting: boolean,
        wholeMeetings: number,
        participatedMeetings: number,
      } | null >,
    } | null > | null,
  } | null,
};

export type AnalysisOneOnOneQueryVariables = {
  organizationGroup: string,
  start: string,
  end: string,
};

export type AnalysisOneOnOneQuery = {
  AnalysisOneOnOne?:  Array< {
    __typename: "OneOnOneAnalysisResponse",
    managerID: string,
    managerName: string,
    settingRate: number,
    memberOneOnOneAnalysisDataArray:  Array< {
      __typename: "MemberOneOnOneAnalysisData",
      memberID: string,
      memberName: string,
      oneOnOneCount: number,
      participatedOneOnOneCount: number,
    } | null >,
  } > | null,
};

export type AnalysisAgendaQueryVariables = {
  organizationGroup: string,
  start: string,
  end: string,
};

export type AnalysisAgendaQuery = {
  AnalysisAgenda?:  Array< {
    __typename: "AgendaAnalysisResponse",
    type: AgendaType,
    title: string,
    displayCount: number,
    usedCount: number,
  } > | null,
};

export type DownloadOneOnOneHistoryQueryVariables = {
  type: OneOnOneHistoryType,
  currentUserID: string,
  organizationGroup: string,
};

export type DownloadOneOnOneHistoryQuery = {
  DownloadOneOnOneHistory?:  Array< {
    __typename: "DownloadOneOnOneHistoryResponse",
    id: string,
    managerName?: string | null,
    user1Name: string,
    user2Name?: string | null,
    startDateTime: string,
    status: OneOnOneStatus,
    agendaContents?:  Array< {
      __typename: "AgendaContent",
      agenda: string,
      comments?: Array< string > | null,
    } > | null,
    nextActions?:  Array< {
      __typename: "NextActionContent",
      nextAction: string,
      targetDate: string,
      manager: string,
    } > | null,
    shareMemos?:  Array< {
      __typename: "ShareMemoContent",
      memo: string,
      writer: string,
    } > | null,
  } > | null,
};

export type GetTeamQueryVariables = {
  id: string,
};

export type GetTeamQuery = {
  getTeam?:  {
    __typename: "Team",
    id: string,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    owner?: string | null,
  } | null,
};

export type ListTeamsQueryVariables = {
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsQuery = {
  listTeams?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTeamByAdminGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamByAdminGroupQuery = {
  listTeamByAdminGroup?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMemberManagerQueryVariables = {
  id: string,
};

export type GetMemberManagerQuery = {
  getMemberManager?:  {
    __typename: "MemberManager",
    id: string,
    managerID: string,
    memberID: string,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    owner?: string | null,
    manager:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    member:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type ListMemberManagersQueryVariables = {
  filter?: ModelMemberManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMemberManagersQuery = {
  listMemberManagers?:  {
    __typename: "ModelMemberManagerConnection",
    items:  Array< {
      __typename: "MemberManager",
      id: string,
      managerID: string,
      memberID: string,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      owner?: string | null,
      manager:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      member:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMemberByUserQueryVariables = {
  memberID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMemberByUserQuery = {
  listMemberByUser?:  {
    __typename: "ModelMemberManagerConnection",
    items:  Array< {
      __typename: "MemberManager",
      id: string,
      managerID: string,
      memberID: string,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      owner?: string | null,
      manager:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      member:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMemberByManagerQueryVariables = {
  managerID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMemberByManagerQuery = {
  listMemberByManager?:  {
    __typename: "ModelMemberManagerConnection",
    items:  Array< {
      __typename: "MemberManager",
      id: string,
      managerID: string,
      memberID: string,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      owner?: string | null,
      manager:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      member:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMemberManagerByAdminGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMemberManagerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMemberManagerByAdminGroupQuery = {
  listMemberManagerByAdminGroup?:  {
    __typename: "ModelMemberManagerConnection",
    items:  Array< {
      __typename: "MemberManager",
      id: string,
      managerID: string,
      memberID: string,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      owner?: string | null,
      manager:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      member:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    account: string,
    owner?: string | null,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationID: string,
    status: UserStatus,
    admin?: boolean | null,
    sub: string,
    socialType?: SocialType | null,
    drivingScore?: number | null,
    analyticalScore?: number | null,
    amiableScore?: number | null,
    expressiveScore?: number | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    email?: string | null,
    teamID?: string | null,
    managers?: Array< string | null > | null,
    googleRefreshToken?: string | null,
    googleAccountEmail?: string | null,
    about?: string | null,
    delete?: boolean | null,
    deleteStatus: string,
    employeeId?: string | null,
    teamCode?: string | null,
    readingFirstName?: string | null,
    readingLastName?: string | null,
    birthDay?: string | null,
    gender?: Gender | null,
    address?: string | null,
    joinedCompanyDay?: string | null,
    retirementDay?: string | null,
    jobType?: string | null,
    phoneNumber?: string | null,
    enrollmentStatus?: EnrollmentStatus | null,
    recruitmentClassification?: RecruitmentClassification | null,
    department?: string | null,
    division?: string | null,
    section?: string | null,
    position?: string | null,
    gradeID?: string | null,
    allowance?: number | null,
    bonus?: number | null,
    gradeMatrixID?: string | null,
    myMembers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    myManagers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeMatrix?:  {
      __typename: "GradeMatrix",
      gradeMatrixID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      memberList?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyAreaList?:  {
        __typename: "ModelCompetencyAreaConnection",
        items:  Array< {
          __typename: "CompetencyArea",
          competencyAreaID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeList?:  {
        __typename: "ModelGradeConnection",
        items:  Array< {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyItemList?:  {
        __typename: "ModelCompetencyItemConnection",
        items:  Array< {
          __typename: "CompetencyItem",
          competencyItemID: string,
          item: string,
          gradeID: string,
          competencyAreaID: string,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserByOrganizationIDQueryVariables = {
  organizationID?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserByOrganizationIDQuery = {
  listUserByOrganizationID?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserByOrganizationQueryVariables = {
  organizationID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserByOrganizationQuery = {
  listUserByOrganization?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserByAdminGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserByAdminGroupQuery = {
  listUserByAdminGroup?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserByEmailQuery = {
  listUserByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserByTeamIDQueryVariables = {
  teamID?: string | null,
  adminGroup?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserByTeamIDQuery = {
  listUserByTeamID?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserByGradeMatrixIDQueryVariables = {
  gradeMatrixID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserByGradeMatrixIDQuery = {
  listUserByGradeMatrixID?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHistoryQueryVariables = {
  id: string,
};

export type GetHistoryQuery = {
  getHistory?:  {
    __typename: "History",
    id: string,
    user: string,
    value?: string | null,
    subValue?: string | null,
    start?: string | null,
    end?: string | null,
    type: HistoryType,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    groupsCanAccess: Array< string | null >,
    owners?: string | null,
    owner?: string | null,
  } | null,
};

export type ListHistorysQueryVariables = {
  filter?: ModelHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHistorysQuery = {
  listHistorys?:  {
    __typename: "ModelHistoryConnection",
    items:  Array< {
      __typename: "History",
      id: string,
      user: string,
      value?: string | null,
      subValue?: string | null,
      start?: string | null,
      end?: string | null,
      type: HistoryType,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      groupsCanAccess: Array< string | null >,
      owners?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListHistoryByGroupQueryVariables = {
  adminGroup?: string | null,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHistoryByGroupQuery = {
  listHistoryByGroup?:  {
    __typename: "ModelHistoryConnection",
    items:  Array< {
      __typename: "History",
      id: string,
      user: string,
      value?: string | null,
      subValue?: string | null,
      start?: string | null,
      end?: string | null,
      type: HistoryType,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      groupsCanAccess: Array< string | null >,
      owners?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListHistoryByUserQueryVariables = {
  user?: string | null,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHistoryByUserQuery = {
  listHistoryByUser?:  {
    __typename: "ModelHistoryConnection",
    items:  Array< {
      __typename: "History",
      id: string,
      user: string,
      value?: string | null,
      subValue?: string | null,
      start?: string | null,
      end?: string | null,
      type: HistoryType,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      groupsCanAccess: Array< string | null >,
      owners?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSettingQueryVariables = {
  id: string,
};

export type GetSettingQuery = {
  getSetting?:  {
    __typename: "Setting",
    id: string,
    notification?:  {
      __typename: "SettingNotificationPart",
      disableAll?: boolean | null,
      email?:  {
        __typename: "SettingNotificationItem",
        enable?: boolean | null,
        reportLike?: boolean | null,
        reportComment?: boolean | null,
        onoRemind?: boolean | null,
        onoLookBack?: boolean | null,
        goalUpdate?: boolean | null,
        goalActivity?: boolean | null,
        goalRemind?: boolean | null,
      } | null,
    } | null,
    calendar?:  {
      __typename: "SettingCalendarPart",
      gmailAddress?: string | null,
      syncLimit?: number | null,
      syncInterval?: number | null,
      syncToken?: string | null,
    } | null,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    createdAt: string,
    updatedAt: string,
    groupsCanAccess: Array< string | null >,
  } | null,
};

export type ListSettingsQueryVariables = {
  filter?: ModelSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSettingsQuery = {
  listSettings?:  {
    __typename: "ModelSettingConnection",
    items:  Array< {
      __typename: "Setting",
      id: string,
      notification?:  {
        __typename: "SettingNotificationPart",
        disableAll?: boolean | null,
        email?:  {
          __typename: "SettingNotificationItem",
          enable?: boolean | null,
          reportLike?: boolean | null,
          reportComment?: boolean | null,
          onoRemind?: boolean | null,
          onoLookBack?: boolean | null,
          goalUpdate?: boolean | null,
          goalActivity?: boolean | null,
          goalRemind?: boolean | null,
        } | null,
      } | null,
      calendar?:  {
        __typename: "SettingCalendarPart",
        gmailAddress?: string | null,
        syncLimit?: number | null,
        syncInterval?: number | null,
        syncToken?: string | null,
      } | null,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      createdAt: string,
      updatedAt: string,
      groupsCanAccess: Array< string | null >,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSettingByAdminGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSettingByAdminGroupQuery = {
  listSettingByAdminGroup?:  {
    __typename: "ModelSettingConnection",
    items:  Array< {
      __typename: "Setting",
      id: string,
      notification?:  {
        __typename: "SettingNotificationPart",
        disableAll?: boolean | null,
        email?:  {
          __typename: "SettingNotificationItem",
          enable?: boolean | null,
          reportLike?: boolean | null,
          reportComment?: boolean | null,
          onoRemind?: boolean | null,
          onoLookBack?: boolean | null,
          goalUpdate?: boolean | null,
          goalActivity?: boolean | null,
          goalRemind?: boolean | null,
        } | null,
      } | null,
      calendar?:  {
        __typename: "SettingCalendarPart",
        gmailAddress?: string | null,
        syncLimit?: number | null,
        syncInterval?: number | null,
        syncToken?: string | null,
      } | null,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      createdAt: string,
      updatedAt: string,
      groupsCanAccess: Array< string | null >,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTaskQueryVariables = {
  taskID: string,
};

export type GetTaskQuery = {
  getTask?:  {
    __typename: "Task",
    taskID: string,
    userID: string,
    content: string,
    title: string,
    taskType: TaskType,
    complete: boolean,
    createdAt: string,
    updatedAt: string,
    dueDate?: string | null,
    taskLink?: string | null,
    organizationGroup: string,
    reviewSheetID?: string | null,
    goalID?: string | null,
  } | null,
};

export type ListTasksQueryVariables = {
  taskID?: string | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTasksQuery = {
  listTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      taskID: string,
      userID: string,
      content: string,
      title: string,
      taskType: TaskType,
      complete: boolean,
      createdAt: string,
      updatedAt: string,
      dueDate?: string | null,
      taskLink?: string | null,
      organizationGroup: string,
      reviewSheetID?: string | null,
      goalID?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTaskByUserQueryVariables = {
  organizationGroup?: string | null,
  userID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaskByUserQuery = {
  listTaskByUser?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      taskID: string,
      userID: string,
      content: string,
      title: string,
      taskType: TaskType,
      complete: boolean,
      createdAt: string,
      updatedAt: string,
      dueDate?: string | null,
      taskLink?: string | null,
      organizationGroup: string,
      reviewSheetID?: string | null,
      goalID?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewerTaskQueryVariables = {
  reviewSheetID?: string | null,
  userID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewerTaskQuery = {
  listReviewerTask?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      taskID: string,
      userID: string,
      content: string,
      title: string,
      taskType: TaskType,
      complete: boolean,
      createdAt: string,
      updatedAt: string,
      dueDate?: string | null,
      taskLink?: string | null,
      organizationGroup: string,
      reviewSheetID?: string | null,
      goalID?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewTaskQueryVariables = {
  reviewSheetID?: string | null,
  taskType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewTaskQuery = {
  listReviewTask?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      taskID: string,
      userID: string,
      content: string,
      title: string,
      taskType: TaskType,
      complete: boolean,
      createdAt: string,
      updatedAt: string,
      dueDate?: string | null,
      taskLink?: string | null,
      organizationGroup: string,
      reviewSheetID?: string | null,
      goalID?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTaskByGoalQueryVariables = {
  goalID?: string | null,
  userID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaskByGoalQuery = {
  listTaskByGoal?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      taskID: string,
      userID: string,
      content: string,
      title: string,
      taskType: TaskType,
      complete: boolean,
      createdAt: string,
      updatedAt: string,
      dueDate?: string | null,
      taskLink?: string | null,
      organizationGroup: string,
      reviewSheetID?: string | null,
      goalID?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    domain?: string | null,
    webhookURL?: string | null,
    mailingList?: string | null,
    adminGroupName: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    groupName: string,
    numberOfUsers?: number | null,
    agreementUpdateDate?: string | null,
    assessmentFromDate?: string | null,
    assessmentToDate?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    totalAudioDuration?: number | null,
    status?: OrganizationStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    microsoftAppId?: string | null,
    microsoftTenant?: string | null,
    microsoftClientSecret?: string | null,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      domain?: string | null,
      webhookURL?: string | null,
      mailingList?: string | null,
      adminGroupName: string,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      groupName: string,
      numberOfUsers?: number | null,
      agreementUpdateDate?: string | null,
      assessmentFromDate?: string | null,
      assessmentToDate?: string | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      totalAudioDuration?: number | null,
      status?: OrganizationStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      microsoftAppId?: string | null,
      microsoftTenant?: string | null,
      microsoftClientSecret?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrganizationsByNameQueryVariables = {
  name?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsByNameQuery = {
  listOrganizationsByName?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      domain?: string | null,
      webhookURL?: string | null,
      mailingList?: string | null,
      adminGroupName: string,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      groupName: string,
      numberOfUsers?: number | null,
      agreementUpdateDate?: string | null,
      assessmentFromDate?: string | null,
      assessmentToDate?: string | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      totalAudioDuration?: number | null,
      status?: OrganizationStatus | null,
      startDate?: string | null,
      endDate?: string | null,
      microsoftAppId?: string | null,
      microsoftTenant?: string | null,
      microsoftClientSecret?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGoal3QueryVariables = {
  id: string,
};

export type GetGoal3Query = {
  getGoal3?:  {
    __typename: "Goal3",
    id: string,
    organization?: string | null,
    teamID?: string | null,
    value?: string | null,
    detail?: string | null,
    progressType: GoalProgressType,
    startDate?: string | null,
    endDate?: string | null,
    owners?: Array< string > | null,
    type: GoalType2,
    visibility: GoalVisibility,
    readers?: Array< string | null > | null,
    parentGoalID?: string | null,
    priority?: number | null,
    achievement?: GoalAchievement | null,
    progress: Progress,
    totalValue?: number | null,
    start?: number | null,
    target?: number | null,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    archiveStatus: string,
    goalProgressList?:  {
      __typename: "ModelGoalProgressConnection",
      items:  Array< {
        __typename: "GoalProgress",
        goalProgressID: string,
        organizationGroup: string,
        goalID: string,
        progress: Progress,
        progressDetail?: string | null,
        value?: number | null,
        creatorID: string,
        createdAt: string,
        deleteStatus: string,
        updatedAt: string,
        comments?:  {
          __typename: "ModelGoalProgressCommentConnection",
          items:  Array< {
            __typename: "GoalProgressComment",
            goalProgressCommentID: string,
            organizationGroup: string,
            commenterID: string,
            commentContent?: string | null,
            goalProgressID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListGoal3sQueryVariables = {
  filter?: ModelGoal3FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoal3sQuery = {
  listGoal3s?:  {
    __typename: "ModelGoal3Connection",
    items:  Array< {
      __typename: "Goal3",
      id: string,
      organization?: string | null,
      teamID?: string | null,
      value?: string | null,
      detail?: string | null,
      progressType: GoalProgressType,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string > | null,
      type: GoalType2,
      visibility: GoalVisibility,
      readers?: Array< string | null > | null,
      parentGoalID?: string | null,
      priority?: number | null,
      achievement?: GoalAchievement | null,
      progress: Progress,
      totalValue?: number | null,
      start?: number | null,
      target?: number | null,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      archiveStatus: string,
      goalProgressList?:  {
        __typename: "ModelGoalProgressConnection",
        items:  Array< {
          __typename: "GoalProgress",
          goalProgressID: string,
          organizationGroup: string,
          goalID: string,
          progress: Progress,
          progressDetail?: string | null,
          value?: number | null,
          creatorID: string,
          createdAt: string,
          deleteStatus: string,
          updatedAt: string,
          comments?:  {
            __typename: "ModelGoalProgressCommentConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGoal3ByAdminGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGoal3FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoal3ByAdminGroupQuery = {
  listGoal3ByAdminGroup?:  {
    __typename: "ModelGoal3Connection",
    items:  Array< {
      __typename: "Goal3",
      id: string,
      organization?: string | null,
      teamID?: string | null,
      value?: string | null,
      detail?: string | null,
      progressType: GoalProgressType,
      startDate?: string | null,
      endDate?: string | null,
      owners?: Array< string > | null,
      type: GoalType2,
      visibility: GoalVisibility,
      readers?: Array< string | null > | null,
      parentGoalID?: string | null,
      priority?: number | null,
      achievement?: GoalAchievement | null,
      progress: Progress,
      totalValue?: number | null,
      start?: number | null,
      target?: number | null,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      archiveStatus: string,
      goalProgressList?:  {
        __typename: "ModelGoalProgressConnection",
        items:  Array< {
          __typename: "GoalProgress",
          goalProgressID: string,
          organizationGroup: string,
          goalID: string,
          progress: Progress,
          progressDetail?: string | null,
          value?: number | null,
          creatorID: string,
          createdAt: string,
          deleteStatus: string,
          updatedAt: string,
          comments?:  {
            __typename: "ModelGoalProgressCommentConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGoalQueryVariables = {
  goalID: string,
};

export type GetGoalQuery = {
  getGoal?:  {
    __typename: "Goal",
    goalID: string,
    organizationGroup: string,
    parentGoalID?: string | null,
    departmentID?: string | null,
    directorIDs: Array< string >,
    readerIDs?: Array< string > | null,
    goalClassification: GoalClassification,
    goalTitle: string,
    goalDetail?: string | null,
    measuringType: MeasuringType,
    startDate: string,
    targetDate: string,
    goalType: GoalType,
    publicScope: PublicScope,
    archiveStatus: string,
    startValue?: number | null,
    targetValue?: number | null,
    createdAt: string,
    updatedAt: string,
    deleteStatus: string,
    goalWeightPoint?: number | null,
    goalProgressList?:  {
      __typename: "ModelGoalProgressConnection",
      items:  Array< {
        __typename: "GoalProgress",
        goalProgressID: string,
        organizationGroup: string,
        goalID: string,
        progress: Progress,
        progressDetail?: string | null,
        value?: number | null,
        creatorID: string,
        createdAt: string,
        deleteStatus: string,
        updatedAt: string,
        comments?:  {
          __typename: "ModelGoalProgressCommentConnection",
          items:  Array< {
            __typename: "GoalProgressComment",
            goalProgressCommentID: string,
            organizationGroup: string,
            commenterID: string,
            commentContent?: string | null,
            goalProgressID: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListGoalsQueryVariables = {
  goalID?: string | null,
  filter?: ModelGoalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGoalsQuery = {
  listGoals?:  {
    __typename: "ModelGoalConnection",
    items:  Array< {
      __typename: "Goal",
      goalID: string,
      organizationGroup: string,
      parentGoalID?: string | null,
      departmentID?: string | null,
      directorIDs: Array< string >,
      readerIDs?: Array< string > | null,
      goalClassification: GoalClassification,
      goalTitle: string,
      goalDetail?: string | null,
      measuringType: MeasuringType,
      startDate: string,
      targetDate: string,
      goalType: GoalType,
      publicScope: PublicScope,
      archiveStatus: string,
      startValue?: number | null,
      targetValue?: number | null,
      createdAt: string,
      updatedAt: string,
      deleteStatus: string,
      goalWeightPoint?: number | null,
      goalProgressList?:  {
        __typename: "ModelGoalProgressConnection",
        items:  Array< {
          __typename: "GoalProgress",
          goalProgressID: string,
          organizationGroup: string,
          goalID: string,
          progress: Progress,
          progressDetail?: string | null,
          value?: number | null,
          creatorID: string,
          createdAt: string,
          deleteStatus: string,
          updatedAt: string,
          comments?:  {
            __typename: "ModelGoalProgressCommentConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGoalByOrganizationGroupQueryVariables = {
  organizationGroup?: string | null,
  archiveStatusDeleteStatus?: ModelGoalListGoalByOrganizationGroupCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGoalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoalByOrganizationGroupQuery = {
  listGoalByOrganizationGroup?:  {
    __typename: "ModelGoalConnection",
    items:  Array< {
      __typename: "Goal",
      goalID: string,
      organizationGroup: string,
      parentGoalID?: string | null,
      departmentID?: string | null,
      directorIDs: Array< string >,
      readerIDs?: Array< string > | null,
      goalClassification: GoalClassification,
      goalTitle: string,
      goalDetail?: string | null,
      measuringType: MeasuringType,
      startDate: string,
      targetDate: string,
      goalType: GoalType,
      publicScope: PublicScope,
      archiveStatus: string,
      startValue?: number | null,
      targetValue?: number | null,
      createdAt: string,
      updatedAt: string,
      deleteStatus: string,
      goalWeightPoint?: number | null,
      goalProgressList?:  {
        __typename: "ModelGoalProgressConnection",
        items:  Array< {
          __typename: "GoalProgress",
          goalProgressID: string,
          organizationGroup: string,
          goalID: string,
          progress: Progress,
          progressDetail?: string | null,
          value?: number | null,
          creatorID: string,
          createdAt: string,
          deleteStatus: string,
          updatedAt: string,
          comments?:  {
            __typename: "ModelGoalProgressCommentConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNotDeletedGoalByOrganizationGroupQueryVariables = {
  organizationGroup?: string | null,
  deleteStatus?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGoalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotDeletedGoalByOrganizationGroupQuery = {
  listNotDeletedGoalByOrganizationGroup?:  {
    __typename: "ModelGoalConnection",
    items:  Array< {
      __typename: "Goal",
      goalID: string,
      organizationGroup: string,
      parentGoalID?: string | null,
      departmentID?: string | null,
      directorIDs: Array< string >,
      readerIDs?: Array< string > | null,
      goalClassification: GoalClassification,
      goalTitle: string,
      goalDetail?: string | null,
      measuringType: MeasuringType,
      startDate: string,
      targetDate: string,
      goalType: GoalType,
      publicScope: PublicScope,
      archiveStatus: string,
      startValue?: number | null,
      targetValue?: number | null,
      createdAt: string,
      updatedAt: string,
      deleteStatus: string,
      goalWeightPoint?: number | null,
      goalProgressList?:  {
        __typename: "ModelGoalProgressConnection",
        items:  Array< {
          __typename: "GoalProgress",
          goalProgressID: string,
          organizationGroup: string,
          goalID: string,
          progress: Progress,
          progressDetail?: string | null,
          value?: number | null,
          creatorID: string,
          createdAt: string,
          deleteStatus: string,
          updatedAt: string,
          comments?:  {
            __typename: "ModelGoalProgressCommentConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGoalActivityQueryVariables = {
  id: string,
};

export type GetGoalActivityQuery = {
  getGoalActivity?:  {
    __typename: "GoalActivity",
    id: string,
    userID: string,
    goalID?: string | null,
    keyResultID?: string | null,
    date?: string | null,
    value?: number | null,
    detail?: string | null,
    progress?: GoalProgress2 | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
  } | null,
};

export type ListGoalActivitysQueryVariables = {
  filter?: ModelGoalActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoalActivitysQuery = {
  listGoalActivitys?:  {
    __typename: "ModelGoalActivityConnection",
    items:  Array< {
      __typename: "GoalActivity",
      id: string,
      userID: string,
      goalID?: string | null,
      keyResultID?: string | null,
      date?: string | null,
      value?: number | null,
      detail?: string | null,
      progress?: GoalProgress2 | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGoalActivityByGoalIdQueryVariables = {
  goalID?: string | null,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGoalActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoalActivityByGoalIdQuery = {
  listGoalActivityByGoalId?:  {
    __typename: "ModelGoalActivityConnection",
    items:  Array< {
      __typename: "GoalActivity",
      id: string,
      userID: string,
      goalID?: string | null,
      keyResultID?: string | null,
      date?: string | null,
      value?: number | null,
      detail?: string | null,
      progress?: GoalProgress2 | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGoalActivityByUserQueryVariables = {
  userID?: string | null,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGoalActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoalActivityByUserQuery = {
  listGoalActivityByUser?:  {
    __typename: "ModelGoalActivityConnection",
    items:  Array< {
      __typename: "GoalActivity",
      id: string,
      userID: string,
      goalID?: string | null,
      keyResultID?: string | null,
      date?: string | null,
      value?: number | null,
      detail?: string | null,
      progress?: GoalProgress2 | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGoalActivityByAdminGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGoalActivityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoalActivityByAdminGroupQuery = {
  listGoalActivityByAdminGroup?:  {
    __typename: "ModelGoalActivityConnection",
    items:  Array< {
      __typename: "GoalActivity",
      id: string,
      userID: string,
      goalID?: string | null,
      keyResultID?: string | null,
      date?: string | null,
      value?: number | null,
      detail?: string | null,
      progress?: GoalProgress2 | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGoalProgressQueryVariables = {
  goalProgressID: string,
};

export type GetGoalProgressQuery = {
  getGoalProgress?:  {
    __typename: "GoalProgress",
    goalProgressID: string,
    organizationGroup: string,
    goalID: string,
    progress: Progress,
    progressDetail?: string | null,
    value?: number | null,
    creatorID: string,
    createdAt: string,
    deleteStatus: string,
    updatedAt: string,
    comments?:  {
      __typename: "ModelGoalProgressCommentConnection",
      items:  Array< {
        __typename: "GoalProgressComment",
        goalProgressCommentID: string,
        organizationGroup: string,
        commenterID: string,
        commentContent?: string | null,
        goalProgressID: string,
        createdAt: string,
        updatedAt: string,
        commenter:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListGoalProgresssQueryVariables = {
  goalProgressID?: string | null,
  filter?: ModelGoalProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGoalProgresssQuery = {
  listGoalProgresss?:  {
    __typename: "ModelGoalProgressConnection",
    items:  Array< {
      __typename: "GoalProgress",
      goalProgressID: string,
      organizationGroup: string,
      goalID: string,
      progress: Progress,
      progressDetail?: string | null,
      value?: number | null,
      creatorID: string,
      createdAt: string,
      deleteStatus: string,
      updatedAt: string,
      comments?:  {
        __typename: "ModelGoalProgressCommentConnection",
        items:  Array< {
          __typename: "GoalProgressComment",
          goalProgressCommentID: string,
          organizationGroup: string,
          commenterID: string,
          commentContent?: string | null,
          goalProgressID: string,
          createdAt: string,
          updatedAt: string,
          commenter:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGoalProgressByGoalIDQueryVariables = {
  goalID?: string | null,
  deleteStatus?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGoalProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoalProgressByGoalIDQuery = {
  listGoalProgressByGoalID?:  {
    __typename: "ModelGoalProgressConnection",
    items:  Array< {
      __typename: "GoalProgress",
      goalProgressID: string,
      organizationGroup: string,
      goalID: string,
      progress: Progress,
      progressDetail?: string | null,
      value?: number | null,
      creatorID: string,
      createdAt: string,
      deleteStatus: string,
      updatedAt: string,
      comments?:  {
        __typename: "ModelGoalProgressCommentConnection",
        items:  Array< {
          __typename: "GoalProgressComment",
          goalProgressCommentID: string,
          organizationGroup: string,
          commenterID: string,
          commentContent?: string | null,
          goalProgressID: string,
          createdAt: string,
          updatedAt: string,
          commenter:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGoalProgressCommentQueryVariables = {
  goalProgressCommentID: string,
};

export type GetGoalProgressCommentQuery = {
  getGoalProgressComment?:  {
    __typename: "GoalProgressComment",
    goalProgressCommentID: string,
    organizationGroup: string,
    commenterID: string,
    commentContent?: string | null,
    goalProgressID: string,
    createdAt: string,
    updatedAt: string,
    commenter:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type ListGoalProgressCommentsQueryVariables = {
  goalProgressCommentID?: string | null,
  filter?: ModelGoalProgressCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGoalProgressCommentsQuery = {
  listGoalProgressComments?:  {
    __typename: "ModelGoalProgressCommentConnection",
    items:  Array< {
      __typename: "GoalProgressComment",
      goalProgressCommentID: string,
      organizationGroup: string,
      commenterID: string,
      commentContent?: string | null,
      goalProgressID: string,
      createdAt: string,
      updatedAt: string,
      commenter:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCommentsByGoalProgressIDQueryVariables = {
  goalProgressID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGoalProgressCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByGoalProgressIDQuery = {
  listCommentsByGoalProgressID?:  {
    __typename: "ModelGoalProgressCommentConnection",
    items:  Array< {
      __typename: "GoalProgressComment",
      goalProgressCommentID: string,
      organizationGroup: string,
      commenterID: string,
      commentContent?: string | null,
      goalProgressID: string,
      createdAt: string,
      updatedAt: string,
      commenter:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOnoQueryVariables = {
  id: string,
};

export type GetOnoQuery = {
  getOno?:  {
    __typename: "Ono",
    id: string,
    member: string,
    manager: string,
    frequency?: FrequencyType | null,
    nextMeetingDate?: string | null,
    nextMeetingStart: string,
    templateID?: string | null,
    googleChannelResourceID?: string | null,
    iCalUID?: string | null,
    googleAccountEmail?: string | null,
    status?: OnoStatus | null,
    editors: Array< string | null >,
    createdAt: string,
    updatedAt: string,
    adminGroup: string,
    organizationGroup?: string | null,
    groupsCanAccess: Array< string | null >,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    outlookUserId?: string | null,
    outlookEventId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListOnosQueryVariables = {
  filter?: ModelOnoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOnosQuery = {
  listOnos?:  {
    __typename: "ModelOnoConnection",
    items:  Array< {
      __typename: "Ono",
      id: string,
      member: string,
      manager: string,
      frequency?: FrequencyType | null,
      nextMeetingDate?: string | null,
      nextMeetingStart: string,
      templateID?: string | null,
      googleChannelResourceID?: string | null,
      iCalUID?: string | null,
      googleAccountEmail?: string | null,
      status?: OnoStatus | null,
      editors: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      adminGroup: string,
      organizationGroup?: string | null,
      groupsCanAccess: Array< string | null >,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      outlookUserId?: string | null,
      outlookEventId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOnoByGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOnoByGroupQuery = {
  listOnoByGroup?:  {
    __typename: "ModelOnoConnection",
    items:  Array< {
      __typename: "Ono",
      id: string,
      member: string,
      manager: string,
      frequency?: FrequencyType | null,
      nextMeetingDate?: string | null,
      nextMeetingStart: string,
      templateID?: string | null,
      googleChannelResourceID?: string | null,
      iCalUID?: string | null,
      googleAccountEmail?: string | null,
      status?: OnoStatus | null,
      editors: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      adminGroup: string,
      organizationGroup?: string | null,
      groupsCanAccess: Array< string | null >,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      outlookUserId?: string | null,
      outlookEventId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOnoByAdminGroupWithDeletionQueryVariables = {
  adminGroup?: string | null,
  deleteStatusUpdatedAt?: ModelOnoListOnoByAdminGroupWithDeletionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOnoByAdminGroupWithDeletionQuery = {
  listOnoByAdminGroupWithDeletion?:  {
    __typename: "ModelOnoConnection",
    items:  Array< {
      __typename: "Ono",
      id: string,
      member: string,
      manager: string,
      frequency?: FrequencyType | null,
      nextMeetingDate?: string | null,
      nextMeetingStart: string,
      templateID?: string | null,
      googleChannelResourceID?: string | null,
      iCalUID?: string | null,
      googleAccountEmail?: string | null,
      status?: OnoStatus | null,
      editors: Array< string | null >,
      createdAt: string,
      updatedAt: string,
      adminGroup: string,
      organizationGroup?: string | null,
      groupsCanAccess: Array< string | null >,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      outlookUserId?: string | null,
      outlookEventId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMeetingQueryVariables = {
  id: string,
};

export type GetMeetingQuery = {
  getMeeting?:  {
    __typename: "Meeting",
    id: string,
    member: string,
    manager: string,
    startDate: string,
    ono?: string | null,
    status?: OnoStatus | null,
    templateID?: string | null,
    temporary?: boolean | null,
    createdAt: string,
    updatedAt: string,
    adminGroup: string,
    organizationGroup?: string | null,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    participated?: boolean | null,
    groupsCanAccess: Array< string | null >,
  } | null,
};

export type ListMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsQuery = {
  listMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      member: string,
      manager: string,
      startDate: string,
      ono?: string | null,
      status?: OnoStatus | null,
      templateID?: string | null,
      temporary?: boolean | null,
      createdAt: string,
      updatedAt: string,
      adminGroup: string,
      organizationGroup?: string | null,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      participated?: boolean | null,
      groupsCanAccess: Array< string | null >,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMeetingByMemberQueryVariables = {
  member?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingByMemberQuery = {
  listMeetingByMember?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      member: string,
      manager: string,
      startDate: string,
      ono?: string | null,
      status?: OnoStatus | null,
      templateID?: string | null,
      temporary?: boolean | null,
      createdAt: string,
      updatedAt: string,
      adminGroup: string,
      organizationGroup?: string | null,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      participated?: boolean | null,
      groupsCanAccess: Array< string | null >,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMeetingByOnoQueryVariables = {
  ono?: string | null,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingByOnoQuery = {
  listMeetingByOno?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      member: string,
      manager: string,
      startDate: string,
      ono?: string | null,
      status?: OnoStatus | null,
      templateID?: string | null,
      temporary?: boolean | null,
      createdAt: string,
      updatedAt: string,
      adminGroup: string,
      organizationGroup?: string | null,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      participated?: boolean | null,
      groupsCanAccess: Array< string | null >,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMeetingByOnoWithDeletionQueryVariables = {
  ono?: string | null,
  startDateDeleteStatus?: ModelMeetingListMeetingByOnoWithDeletionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingByOnoWithDeletionQuery = {
  listMeetingByOnoWithDeletion?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      member: string,
      manager: string,
      startDate: string,
      ono?: string | null,
      status?: OnoStatus | null,
      templateID?: string | null,
      temporary?: boolean | null,
      createdAt: string,
      updatedAt: string,
      adminGroup: string,
      organizationGroup?: string | null,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      participated?: boolean | null,
      groupsCanAccess: Array< string | null >,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListMeetingsByAdminGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsByAdminGroupQuery = {
  listMeetingsByAdminGroup?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      member: string,
      manager: string,
      startDate: string,
      ono?: string | null,
      status?: OnoStatus | null,
      templateID?: string | null,
      temporary?: boolean | null,
      createdAt: string,
      updatedAt: string,
      adminGroup: string,
      organizationGroup?: string | null,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      participated?: boolean | null,
      groupsCanAccess: Array< string | null >,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTodoQueryVariables = {
  id: string,
};

export type GetTodoQuery = {
  getTodo?:  {
    __typename: "Todo",
    id: string,
    name: string,
    ono: string,
    assignmentUserId?: string | null,
    status: TodoStatus,
    createdMeetingID?: string | null,
    completedMeetingID?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    owners: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type ListTodosQueryVariables = {
  filter?: ModelTodoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTodosQuery = {
  listTodos?:  {
    __typename: "ModelTodoConnection",
    items:  Array< {
      __typename: "Todo",
      id: string,
      name: string,
      ono: string,
      assignmentUserId?: string | null,
      status: TodoStatus,
      createdMeetingID?: string | null,
      completedMeetingID?: string | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      owners: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTodoByGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTodoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTodoByGroupQuery = {
  listTodoByGroup?:  {
    __typename: "ModelTodoConnection",
    items:  Array< {
      __typename: "Todo",
      id: string,
      name: string,
      ono: string,
      assignmentUserId?: string | null,
      status: TodoStatus,
      createdMeetingID?: string | null,
      completedMeetingID?: string | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      owners: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTodoByOnoWithDeletionQueryVariables = {
  ono?: string | null,
  deleteStatusUpdatedAt?: ModelTodoListTodoByOnoWithDeletionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTodoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTodoByOnoWithDeletionQuery = {
  listTodoByOnoWithDeletion?:  {
    __typename: "ModelTodoConnection",
    items:  Array< {
      __typename: "Todo",
      id: string,
      name: string,
      ono: string,
      assignmentUserId?: string | null,
      status: TodoStatus,
      createdMeetingID?: string | null,
      completedMeetingID?: string | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      owners: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTodoByAssignmentUserIdWithDeletionQueryVariables = {
  assignmentUserId?: string | null,
  deleteStatusUpdatedAt?: ModelTodoListTodoByAssignmentUserIdWithDeletionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTodoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTodoByAssignmentUserIdWithDeletionQuery = {
  listTodoByAssignmentUserIdWithDeletion?:  {
    __typename: "ModelTodoConnection",
    items:  Array< {
      __typename: "Todo",
      id: string,
      name: string,
      ono: string,
      assignmentUserId?: string | null,
      status: TodoStatus,
      createdMeetingID?: string | null,
      completedMeetingID?: string | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      owners: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNextActionQueryVariables = {
  id: string,
};

export type GetNextActionQuery = {
  getNextAction?:  {
    __typename: "NextAction",
    id: string,
    title: string,
    oneOnOneSettingID: string,
    managerID: string,
    addedOneOnOneID: string,
    completedOneOnOneID?: string | null,
    targetDate: string,
    isDone: boolean,
    createdAt: string,
    updatedAt: string,
    organizationGroup: string,
    manager:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type ListNextActionsQueryVariables = {
  filter?: ModelNextActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNextActionsQuery = {
  listNextActions?:  {
    __typename: "ModelNextActionConnection",
    items:  Array< {
      __typename: "NextAction",
      id: string,
      title: string,
      oneOnOneSettingID: string,
      managerID: string,
      addedOneOnOneID: string,
      completedOneOnOneID?: string | null,
      targetDate: string,
      isDone: boolean,
      createdAt: string,
      updatedAt: string,
      organizationGroup: string,
      manager:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNextActionByManagerQueryVariables = {
  managerID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNextActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNextActionByManagerQuery = {
  listNextActionByManager?:  {
    __typename: "ModelNextActionConnection",
    items:  Array< {
      __typename: "NextAction",
      id: string,
      title: string,
      oneOnOneSettingID: string,
      managerID: string,
      addedOneOnOneID: string,
      completedOneOnOneID?: string | null,
      targetDate: string,
      isDone: boolean,
      createdAt: string,
      updatedAt: string,
      organizationGroup: string,
      manager:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNextActionByOneOnOneSettingQueryVariables = {
  oneOnOneSettingID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNextActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNextActionByOneOnOneSettingQuery = {
  listNextActionByOneOnOneSetting?:  {
    __typename: "ModelNextActionConnection",
    items:  Array< {
      __typename: "NextAction",
      id: string,
      title: string,
      oneOnOneSettingID: string,
      managerID: string,
      addedOneOnOneID: string,
      completedOneOnOneID?: string | null,
      targetDate: string,
      isDone: boolean,
      createdAt: string,
      updatedAt: string,
      organizationGroup: string,
      manager:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNextActionByGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNextActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNextActionByGroupQuery = {
  listNextActionByGroup?:  {
    __typename: "ModelNextActionConnection",
    items:  Array< {
      __typename: "NextAction",
      id: string,
      title: string,
      oneOnOneSettingID: string,
      managerID: string,
      addedOneOnOneID: string,
      completedOneOnOneID?: string | null,
      targetDate: string,
      isDone: boolean,
      createdAt: string,
      updatedAt: string,
      organizationGroup: string,
      manager:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationAgendaQueryVariables = {
  id: string,
};

export type GetOrganizationAgendaQuery = {
  getOrganizationAgenda?:  {
    __typename: "OrganizationAgenda",
    id: string,
    title: string,
    order: number,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrganizationAgendasQueryVariables = {
  filter?: ModelOrganizationAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationAgendasQuery = {
  listOrganizationAgendas?:  {
    __typename: "ModelOrganizationAgendaConnection",
    items:  Array< {
      __typename: "OrganizationAgenda",
      id: string,
      title: string,
      order: number,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOrganizationAgendaByGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationAgendaByGroupQuery = {
  listOrganizationAgendaByGroup?:  {
    __typename: "ModelOrganizationAgendaConnection",
    items:  Array< {
      __typename: "OrganizationAgenda",
      id: string,
      title: string,
      order: number,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAgendaTemplateQueryVariables = {
  id: string,
};

export type GetAgendaTemplateQuery = {
  getAgendaTemplate?:  {
    __typename: "AgendaTemplate",
    id: string,
    title: string,
    purpose?: string | null,
    organizationGroup: string,
    ownerID: string,
    status: AgendaTemplateStatus,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    agendas?:  {
      __typename: "ModelAgendaTemplateAgendaConnection",
      items:  Array< {
        __typename: "AgendaTemplateAgenda",
        id: string,
        agendaTemplateID: string,
        title: string,
        order: number,
        ownerID: string,
        createdAt: string,
        updatedAt: string,
        organizationGroup: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListAgendaTemplatesQueryVariables = {
  filter?: ModelAgendaTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaTemplatesQuery = {
  listAgendaTemplates?:  {
    __typename: "ModelAgendaTemplateConnection",
    items:  Array< {
      __typename: "AgendaTemplate",
      id: string,
      title: string,
      purpose?: string | null,
      organizationGroup: string,
      ownerID: string,
      status: AgendaTemplateStatus,
      createdAt: string,
      updatedAt: string,
      owner:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      agendas?:  {
        __typename: "ModelAgendaTemplateAgendaConnection",
        items:  Array< {
          __typename: "AgendaTemplateAgenda",
          id: string,
          agendaTemplateID: string,
          title: string,
          order: number,
          ownerID: string,
          createdAt: string,
          updatedAt: string,
          organizationGroup: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgendaTemplateByOrganizationGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgendaTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaTemplateByOrganizationGroupQuery = {
  listAgendaTemplateByOrganizationGroup?:  {
    __typename: "ModelAgendaTemplateConnection",
    items:  Array< {
      __typename: "AgendaTemplate",
      id: string,
      title: string,
      purpose?: string | null,
      organizationGroup: string,
      ownerID: string,
      status: AgendaTemplateStatus,
      createdAt: string,
      updatedAt: string,
      owner:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      agendas?:  {
        __typename: "ModelAgendaTemplateAgendaConnection",
        items:  Array< {
          __typename: "AgendaTemplateAgenda",
          id: string,
          agendaTemplateID: string,
          title: string,
          order: number,
          ownerID: string,
          createdAt: string,
          updatedAt: string,
          organizationGroup: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAgendaTemplateAgendaQueryVariables = {
  id: string,
};

export type GetAgendaTemplateAgendaQuery = {
  getAgendaTemplateAgenda?:  {
    __typename: "AgendaTemplateAgenda",
    id: string,
    agendaTemplateID: string,
    title: string,
    order: number,
    ownerID: string,
    createdAt: string,
    updatedAt: string,
    organizationGroup: string,
  } | null,
};

export type ListAgendaTemplateAgendasQueryVariables = {
  filter?: ModelAgendaTemplateAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaTemplateAgendasQuery = {
  listAgendaTemplateAgendas?:  {
    __typename: "ModelAgendaTemplateAgendaConnection",
    items:  Array< {
      __typename: "AgendaTemplateAgenda",
      id: string,
      agendaTemplateID: string,
      title: string,
      order: number,
      ownerID: string,
      createdAt: string,
      updatedAt: string,
      organizationGroup: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgendaTemplateAgendaByGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgendaTemplateAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaTemplateAgendaByGroupQuery = {
  listAgendaTemplateAgendaByGroup?:  {
    __typename: "ModelAgendaTemplateAgendaConnection",
    items:  Array< {
      __typename: "AgendaTemplateAgenda",
      id: string,
      agendaTemplateID: string,
      title: string,
      order: number,
      ownerID: string,
      createdAt: string,
      updatedAt: string,
      organizationGroup: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgendaTemplateAgendaByAgendaTemplateIDQueryVariables = {
  agendaTemplateID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgendaTemplateAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaTemplateAgendaByAgendaTemplateIDQuery = {
  listAgendaTemplateAgendaByAgendaTemplateID?:  {
    __typename: "ModelAgendaTemplateAgendaConnection",
    items:  Array< {
      __typename: "AgendaTemplateAgenda",
      id: string,
      agendaTemplateID: string,
      title: string,
      order: number,
      ownerID: string,
      createdAt: string,
      updatedAt: string,
      organizationGroup: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTemplateQueryVariables = {
  id: string,
};

export type GetTemplateQuery = {
  getTemplate?:  {
    __typename: "Template",
    id: string,
    title?: string | null,
    ono?: string | null,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type ListTemplatesQueryVariables = {
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplatesQuery = {
  listTemplates?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      id: string,
      title?: string | null,
      ono?: string | null,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTemplateByAdminGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateByAdminGroupQuery = {
  listTemplateByAdminGroup?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      id: string,
      title?: string | null,
      ono?: string | null,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTemplateByAdminGroupWithDeletionQueryVariables = {
  adminGroup?: string | null,
  deleteStatusUpdatedAt?: ModelTemplateListTemplateByAdminGroupWithDeletionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateByAdminGroupWithDeletionQuery = {
  listTemplateByAdminGroupWithDeletion?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      id: string,
      title?: string | null,
      ono?: string | null,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTemplateAgendaQueryVariables = {
  id: string,
};

export type GetTemplateAgendaQuery = {
  getTemplateAgenda?:  {
    __typename: "TemplateAgenda",
    id: string,
    template?: string | null,
    title: string,
    fromOrganization?: string | null,
    order: number,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type ListTemplateAgendasQueryVariables = {
  filter?: ModelTemplateAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateAgendasQuery = {
  listTemplateAgendas?:  {
    __typename: "ModelTemplateAgendaConnection",
    items:  Array< {
      __typename: "TemplateAgenda",
      id: string,
      template?: string | null,
      title: string,
      fromOrganization?: string | null,
      order: number,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTemplateAgendaByTemplateQueryVariables = {
  template?: string | null,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateAgendaByTemplateQuery = {
  listTemplateAgendaByTemplate?:  {
    __typename: "ModelTemplateAgendaConnection",
    items:  Array< {
      __typename: "TemplateAgenda",
      id: string,
      template?: string | null,
      title: string,
      fromOrganization?: string | null,
      order: number,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTemplateAgendaByTemplateWithDeletionQueryVariables = {
  template?: string | null,
  deleteStatusOrder?: ModelTemplateAgendaListTemplateAgendaByTemplateWithDeletionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateAgendaByTemplateWithDeletionQuery = {
  listTemplateAgendaByTemplateWithDeletion?:  {
    __typename: "ModelTemplateAgendaConnection",
    items:  Array< {
      __typename: "TemplateAgenda",
      id: string,
      template?: string | null,
      title: string,
      fromOrganization?: string | null,
      order: number,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTemplateAgendaByFromOrganizationQueryVariables = {
  fromOrganization?: string | null,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateAgendaByFromOrganizationQuery = {
  listTemplateAgendaByFromOrganization?:  {
    __typename: "ModelTemplateAgendaConnection",
    items:  Array< {
      __typename: "TemplateAgenda",
      id: string,
      template?: string | null,
      title: string,
      fromOrganization?: string | null,
      order: number,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTemplateAgendaByFromOrganizationWithDeletionQueryVariables = {
  fromOrganization?: string | null,
  deleteStatusOrder?: ModelTemplateAgendaListTemplateAgendaByFromOrganizationWithDeletionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateAgendaByFromOrganizationWithDeletionQuery = {
  listTemplateAgendaByFromOrganizationWithDeletion?:  {
    __typename: "ModelTemplateAgendaConnection",
    items:  Array< {
      __typename: "TemplateAgenda",
      id: string,
      template?: string | null,
      title: string,
      fromOrganization?: string | null,
      order: number,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTemplateAgendaByAdminGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateAgendaByAdminGroupQuery = {
  listTemplateAgendaByAdminGroup?:  {
    __typename: "ModelTemplateAgendaConnection",
    items:  Array< {
      __typename: "TemplateAgenda",
      id: string,
      template?: string | null,
      title: string,
      fromOrganization?: string | null,
      order: number,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAgendaQueryVariables = {
  id: string,
};

export type GetAgendaQuery = {
  getAgenda?:  {
    __typename: "Agenda",
    id: string,
    oneOnOneID: string,
    title: string,
    order: number,
    referenceID: string,
    agendaType: AgendaType,
    isDone: boolean,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owners: Array< string | null >,
    creator?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    comments?:  {
      __typename: "ModelAgendaCommentConnection",
      items:  Array< {
        __typename: "AgendaComment",
        id: string,
        oneOnOneID: string,
        agendaID: string,
        comment: string,
        commenterID: string,
        organizationGroup: string,
        aiAdvice?: string | null,
        createdAt: string,
        updatedAt: string,
        commenter:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListAgendasQueryVariables = {
  filter?: ModelAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendasQuery = {
  listAgendas?:  {
    __typename: "ModelAgendaConnection",
    items:  Array< {
      __typename: "Agenda",
      id: string,
      oneOnOneID: string,
      title: string,
      order: number,
      referenceID: string,
      agendaType: AgendaType,
      isDone: boolean,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      owners: Array< string | null >,
      creator?:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelAgendaCommentConnection",
        items:  Array< {
          __typename: "AgendaComment",
          id: string,
          oneOnOneID: string,
          agendaID: string,
          comment: string,
          commenterID: string,
          organizationGroup: string,
          aiAdvice?: string | null,
          createdAt: string,
          updatedAt: string,
          commenter:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgendaByGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaByGroupQuery = {
  listAgendaByGroup?:  {
    __typename: "ModelAgendaConnection",
    items:  Array< {
      __typename: "Agenda",
      id: string,
      oneOnOneID: string,
      title: string,
      order: number,
      referenceID: string,
      agendaType: AgendaType,
      isDone: boolean,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      owners: Array< string | null >,
      creator?:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelAgendaCommentConnection",
        items:  Array< {
          __typename: "AgendaComment",
          id: string,
          oneOnOneID: string,
          agendaID: string,
          comment: string,
          commenterID: string,
          organizationGroup: string,
          aiAdvice?: string | null,
          createdAt: string,
          updatedAt: string,
          commenter:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgendaByOneOnOneIDQueryVariables = {
  oneOnOneID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgendaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaByOneOnOneIDQuery = {
  listAgendaByOneOnOneID?:  {
    __typename: "ModelAgendaConnection",
    items:  Array< {
      __typename: "Agenda",
      id: string,
      oneOnOneID: string,
      title: string,
      order: number,
      referenceID: string,
      agendaType: AgendaType,
      isDone: boolean,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      owners: Array< string | null >,
      creator?:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      comments?:  {
        __typename: "ModelAgendaCommentConnection",
        items:  Array< {
          __typename: "AgendaComment",
          id: string,
          oneOnOneID: string,
          agendaID: string,
          comment: string,
          commenterID: string,
          organizationGroup: string,
          aiAdvice?: string | null,
          createdAt: string,
          updatedAt: string,
          commenter:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAgendaCommentQueryVariables = {
  id: string,
};

export type GetAgendaCommentQuery = {
  getAgendaComment?:  {
    __typename: "AgendaComment",
    id: string,
    oneOnOneID: string,
    agendaID: string,
    comment: string,
    commenterID: string,
    organizationGroup: string,
    aiAdvice?: string | null,
    createdAt: string,
    updatedAt: string,
    commenter:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type ListAgendaCommentsQueryVariables = {
  filter?: ModelAgendaCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaCommentsQuery = {
  listAgendaComments?:  {
    __typename: "ModelAgendaCommentConnection",
    items:  Array< {
      __typename: "AgendaComment",
      id: string,
      oneOnOneID: string,
      agendaID: string,
      comment: string,
      commenterID: string,
      organizationGroup: string,
      aiAdvice?: string | null,
      createdAt: string,
      updatedAt: string,
      commenter:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgendaCommentByAgendaIDQueryVariables = {
  agendaID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgendaCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaCommentByAgendaIDQuery = {
  listAgendaCommentByAgendaID?:  {
    __typename: "ModelAgendaCommentConnection",
    items:  Array< {
      __typename: "AgendaComment",
      id: string,
      oneOnOneID: string,
      agendaID: string,
      comment: string,
      commenterID: string,
      organizationGroup: string,
      aiAdvice?: string | null,
      createdAt: string,
      updatedAt: string,
      commenter:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAgendaCommentByGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAgendaCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAgendaCommentByGroupQuery = {
  listAgendaCommentByGroup?:  {
    __typename: "ModelAgendaCommentConnection",
    items:  Array< {
      __typename: "AgendaComment",
      id: string,
      oneOnOneID: string,
      agendaID: string,
      comment: string,
      commenterID: string,
      organizationGroup: string,
      aiAdvice?: string | null,
      createdAt: string,
      updatedAt: string,
      commenter:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNoteQueryVariables = {
  id: string,
};

export type GetNoteQuery = {
  getNote?:  {
    __typename: "Note",
    id: string,
    meeting: string,
    agendaID?: string | null,
    referenceID?: string | null,
    user: string,
    note?: string | null,
    type: NoteType,
    owners: Array< string | null >,
    organizationGroup?: string | null,
    adminGroup: string,
    createdAt: string,
    updatedAt: string,
    delete?: boolean | null,
    deleteStatus: DeleteStatus,
    groupsCanAccess: Array< string | null >,
    owner?: string | null,
  } | null,
};

export type ListNotesQueryVariables = {
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotesQuery = {
  listNotes?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      meeting: string,
      agendaID?: string | null,
      referenceID?: string | null,
      user: string,
      note?: string | null,
      type: NoteType,
      owners: Array< string | null >,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNoteByMeetingWithDeletionQueryVariables = {
  meeting?: string | null,
  deleteStatusType?: ModelNoteListNoteByMeetingWithDeletionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNoteByMeetingWithDeletionQuery = {
  listNoteByMeetingWithDeletion?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      meeting: string,
      agendaID?: string | null,
      referenceID?: string | null,
      user: string,
      note?: string | null,
      type: NoteType,
      owners: Array< string | null >,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNoteByMeetingQueryVariables = {
  meeting?: string | null,
  type?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNoteByMeetingQuery = {
  listNoteByMeeting?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      meeting: string,
      agendaID?: string | null,
      referenceID?: string | null,
      user: string,
      note?: string | null,
      type: NoteType,
      owners: Array< string | null >,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNoteByGroupQueryVariables = {
  adminGroup?: string | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNoteByGroupQuery = {
  listNoteByGroup?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      meeting: string,
      agendaID?: string | null,
      referenceID?: string | null,
      user: string,
      note?: string | null,
      type: NoteType,
      owners: Array< string | null >,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNoteByAgendaIDQueryVariables = {
  agendaID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNoteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNoteByAgendaIDQuery = {
  listNoteByAgendaID?:  {
    __typename: "ModelNoteConnection",
    items:  Array< {
      __typename: "Note",
      id: string,
      meeting: string,
      agendaID?: string | null,
      referenceID?: string | null,
      user: string,
      note?: string | null,
      type: NoteType,
      owners: Array< string | null >,
      organizationGroup?: string | null,
      adminGroup: string,
      createdAt: string,
      updatedAt: string,
      delete?: boolean | null,
      deleteStatus: DeleteStatus,
      groupsCanAccess: Array< string | null >,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOneOnOneSettingQueryVariables = {
  id: string,
};

export type GetOneOnOneSettingQuery = {
  getOneOnOneSetting?:  {
    __typename: "OneOnOneSetting",
    id: string,
    userID1: string,
    userID2: string,
    frequency: Frequency,
    nextOneOnOneDate: string,
    nextOneOnOneTime: string,
    agendaTemplateID?: string | null,
    status: OneOnOneSettingStatus,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user1:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    user2:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    oneOnOneList?:  {
      __typename: "ModelOneOnOneConnection",
      items:  Array< {
        __typename: "OneOnOne",
        id: string,
        userID1: string,
        userID2: string,
        managerID?: string | null,
        oneOnOneSettingID: string,
        frequency: Frequency,
        startDate: string,
        startTime: string,
        status: OneOnOneStatus,
        agendaTemplateID?: string | null,
        recordingAgendaID?: string | null,
        recordingTarget?: RecordingTarget | null,
        aiSummary?: string | null,
        calendarApp?: CalendarApp | null,
        eventID?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneSetting:  {
          __typename: "OneOnOneSetting",
          id: string,
          userID1: string,
          userID2: string,
          frequency: Frequency,
          nextOneOnOneDate: string,
          nextOneOnOneTime: string,
          agendaTemplateID?: string | null,
          status: OneOnOneSettingStatus,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneList?:  {
            __typename: "ModelOneOnOneConnection",
            nextToken?: string | null,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOneOnOneSettingsQueryVariables = {
  filter?: ModelOneOnOneSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOneOnOneSettingsQuery = {
  listOneOnOneSettings?:  {
    __typename: "ModelOneOnOneSettingConnection",
    items:  Array< {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOneOnOneSettingByOrganizationQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOneOnOneSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOneOnOneSettingByOrganizationQuery = {
  listOneOnOneSettingByOrganization?:  {
    __typename: "ModelOneOnOneSettingConnection",
    items:  Array< {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOneOnOneQueryVariables = {
  id: string,
};

export type GetOneOnOneQuery = {
  getOneOnOne?:  {
    __typename: "OneOnOne",
    id: string,
    userID1: string,
    userID2: string,
    managerID?: string | null,
    oneOnOneSettingID: string,
    frequency: Frequency,
    startDate: string,
    startTime: string,
    status: OneOnOneStatus,
    agendaTemplateID?: string | null,
    recordingAgendaID?: string | null,
    recordingTarget?: RecordingTarget | null,
    aiSummary?: string | null,
    calendarApp?: CalendarApp | null,
    eventID?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user1:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    user2:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
    oneOnOneSetting:  {
      __typename: "OneOnOneSetting",
      id: string,
      userID1: string,
      userID2: string,
      frequency: Frequency,
      nextOneOnOneDate: string,
      nextOneOnOneTime: string,
      agendaTemplateID?: string | null,
      status: OneOnOneSettingStatus,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneList?:  {
        __typename: "ModelOneOnOneConnection",
        items:  Array< {
          __typename: "OneOnOne",
          id: string,
          userID1: string,
          userID2: string,
          managerID?: string | null,
          oneOnOneSettingID: string,
          frequency: Frequency,
          startDate: string,
          startTime: string,
          status: OneOnOneStatus,
          agendaTemplateID?: string | null,
          recordingAgendaID?: string | null,
          recordingTarget?: RecordingTarget | null,
          aiSummary?: string | null,
          calendarApp?: CalendarApp | null,
          eventID?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          user1:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          user2:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          oneOnOneSetting:  {
            __typename: "OneOnOneSetting",
            id: string,
            userID1: string,
            userID2: string,
            frequency: Frequency,
            nextOneOnOneDate: string,
            nextOneOnOneTime: string,
            agendaTemplateID?: string | null,
            status: OneOnOneSettingStatus,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListOneOnOnesQueryVariables = {
  filter?: ModelOneOnOneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOneOnOnesQuery = {
  listOneOnOnes?:  {
    __typename: "ModelOneOnOneConnection",
    items:  Array< {
      __typename: "OneOnOne",
      id: string,
      userID1: string,
      userID2: string,
      managerID?: string | null,
      oneOnOneSettingID: string,
      frequency: Frequency,
      startDate: string,
      startTime: string,
      status: OneOnOneStatus,
      agendaTemplateID?: string | null,
      recordingAgendaID?: string | null,
      recordingTarget?: RecordingTarget | null,
      aiSummary?: string | null,
      calendarApp?: CalendarApp | null,
      eventID?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneSetting:  {
        __typename: "OneOnOneSetting",
        id: string,
        userID1: string,
        userID2: string,
        frequency: Frequency,
        nextOneOnOneDate: string,
        nextOneOnOneTime: string,
        agendaTemplateID?: string | null,
        status: OneOnOneSettingStatus,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneList?:  {
          __typename: "ModelOneOnOneConnection",
          items:  Array< {
            __typename: "OneOnOne",
            id: string,
            userID1: string,
            userID2: string,
            managerID?: string | null,
            oneOnOneSettingID: string,
            frequency: Frequency,
            startDate: string,
            startTime: string,
            status: OneOnOneStatus,
            agendaTemplateID?: string | null,
            recordingAgendaID?: string | null,
            recordingTarget?: RecordingTarget | null,
            aiSummary?: string | null,
            calendarApp?: CalendarApp | null,
            eventID?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOneOnOneBySettingIDQueryVariables = {
  oneOnOneSettingID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOneOnOneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOneOnOneBySettingIDQuery = {
  listOneOnOneBySettingID?:  {
    __typename: "ModelOneOnOneConnection",
    items:  Array< {
      __typename: "OneOnOne",
      id: string,
      userID1: string,
      userID2: string,
      managerID?: string | null,
      oneOnOneSettingID: string,
      frequency: Frequency,
      startDate: string,
      startTime: string,
      status: OneOnOneStatus,
      agendaTemplateID?: string | null,
      recordingAgendaID?: string | null,
      recordingTarget?: RecordingTarget | null,
      aiSummary?: string | null,
      calendarApp?: CalendarApp | null,
      eventID?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneSetting:  {
        __typename: "OneOnOneSetting",
        id: string,
        userID1: string,
        userID2: string,
        frequency: Frequency,
        nextOneOnOneDate: string,
        nextOneOnOneTime: string,
        agendaTemplateID?: string | null,
        status: OneOnOneSettingStatus,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneList?:  {
          __typename: "ModelOneOnOneConnection",
          items:  Array< {
            __typename: "OneOnOne",
            id: string,
            userID1: string,
            userID2: string,
            managerID?: string | null,
            oneOnOneSettingID: string,
            frequency: Frequency,
            startDate: string,
            startTime: string,
            status: OneOnOneStatus,
            agendaTemplateID?: string | null,
            recordingAgendaID?: string | null,
            recordingTarget?: RecordingTarget | null,
            aiSummary?: string | null,
            calendarApp?: CalendarApp | null,
            eventID?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOneOnOneByStartDateQueryVariables = {
  startDate?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOneOnOneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOneOnOneByStartDateQuery = {
  listOneOnOneByStartDate?:  {
    __typename: "ModelOneOnOneConnection",
    items:  Array< {
      __typename: "OneOnOne",
      id: string,
      userID1: string,
      userID2: string,
      managerID?: string | null,
      oneOnOneSettingID: string,
      frequency: Frequency,
      startDate: string,
      startTime: string,
      status: OneOnOneStatus,
      agendaTemplateID?: string | null,
      recordingAgendaID?: string | null,
      recordingTarget?: RecordingTarget | null,
      aiSummary?: string | null,
      calendarApp?: CalendarApp | null,
      eventID?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneSetting:  {
        __typename: "OneOnOneSetting",
        id: string,
        userID1: string,
        userID2: string,
        frequency: Frequency,
        nextOneOnOneDate: string,
        nextOneOnOneTime: string,
        agendaTemplateID?: string | null,
        status: OneOnOneSettingStatus,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneList?:  {
          __typename: "ModelOneOnOneConnection",
          items:  Array< {
            __typename: "OneOnOne",
            id: string,
            userID1: string,
            userID2: string,
            managerID?: string | null,
            oneOnOneSettingID: string,
            frequency: Frequency,
            startDate: string,
            startTime: string,
            status: OneOnOneStatus,
            agendaTemplateID?: string | null,
            recordingAgendaID?: string | null,
            recordingTarget?: RecordingTarget | null,
            aiSummary?: string | null,
            calendarApp?: CalendarApp | null,
            eventID?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListOneOnOneByStartDateInOrganizationQueryVariables = {
  organizationGroup?: string | null,
  startDate?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOneOnOneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOneOnOneByStartDateInOrganizationQuery = {
  listOneOnOneByStartDateInOrganization?:  {
    __typename: "ModelOneOnOneConnection",
    items:  Array< {
      __typename: "OneOnOne",
      id: string,
      userID1: string,
      userID2: string,
      managerID?: string | null,
      oneOnOneSettingID: string,
      frequency: Frequency,
      startDate: string,
      startTime: string,
      status: OneOnOneStatus,
      agendaTemplateID?: string | null,
      recordingAgendaID?: string | null,
      recordingTarget?: RecordingTarget | null,
      aiSummary?: string | null,
      calendarApp?: CalendarApp | null,
      eventID?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user1:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      user2:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
      oneOnOneSetting:  {
        __typename: "OneOnOneSetting",
        id: string,
        userID1: string,
        userID2: string,
        frequency: Frequency,
        nextOneOnOneDate: string,
        nextOneOnOneTime: string,
        agendaTemplateID?: string | null,
        status: OneOnOneSettingStatus,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        user1:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        user2:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        oneOnOneList?:  {
          __typename: "ModelOneOnOneConnection",
          items:  Array< {
            __typename: "OneOnOne",
            id: string,
            userID1: string,
            userID2: string,
            managerID?: string | null,
            oneOnOneSettingID: string,
            frequency: Frequency,
            startDate: string,
            startTime: string,
            status: OneOnOneStatus,
            agendaTemplateID?: string | null,
            recordingAgendaID?: string | null,
            recordingTarget?: RecordingTarget | null,
            aiSummary?: string | null,
            calendarApp?: CalendarApp | null,
            eventID?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetShareMemoQueryVariables = {
  id: string,
};

export type GetShareMemoQuery = {
  getShareMemo?:  {
    __typename: "ShareMemo",
    id: string,
    oneOnOneID: string,
    ownerID: string,
    memo?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type ListShareMemosQueryVariables = {
  filter?: ModelShareMemoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShareMemosQuery = {
  listShareMemos?:  {
    __typename: "ModelShareMemoConnection",
    items:  Array< {
      __typename: "ShareMemo",
      id: string,
      oneOnOneID: string,
      ownerID: string,
      memo?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      owner:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListShareMemoByOneOnOneIDQueryVariables = {
  oneOnOneID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShareMemoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShareMemoByOneOnOneIDQuery = {
  listShareMemoByOneOnOneID?:  {
    __typename: "ModelShareMemoConnection",
    items:  Array< {
      __typename: "ShareMemo",
      id: string,
      oneOnOneID: string,
      ownerID: string,
      memo?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      owner:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListShareMemoByGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShareMemoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShareMemoByGroupQuery = {
  listShareMemoByGroup?:  {
    __typename: "ModelShareMemoConnection",
    items:  Array< {
      __typename: "ShareMemo",
      id: string,
      oneOnOneID: string,
      ownerID: string,
      memo?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      owner:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPrivateMemoQueryVariables = {
  id: string,
};

export type GetPrivateMemoQuery = {
  getPrivateMemo?:  {
    __typename: "PrivateMemo",
    id: string,
    oneOnOneID: string,
    memo?: string | null,
    ownerID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    owner:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type ListPrivateMemosQueryVariables = {
  filter?: ModelPrivateMemoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrivateMemosQuery = {
  listPrivateMemos?:  {
    __typename: "ModelPrivateMemoConnection",
    items:  Array< {
      __typename: "PrivateMemo",
      id: string,
      oneOnOneID: string,
      memo?: string | null,
      ownerID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      owner:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPrivateMemoByOneOnOneIDQueryVariables = {
  oneOnOneID?: string | null,
  ownerID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrivateMemoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrivateMemoByOneOnOneIDQuery = {
  listPrivateMemoByOneOnOneID?:  {
    __typename: "ModelPrivateMemoConnection",
    items:  Array< {
      __typename: "PrivateMemo",
      id: string,
      oneOnOneID: string,
      memo?: string | null,
      ownerID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      owner:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGradeMatrixQueryVariables = {
  gradeMatrixID: string,
};

export type GetGradeMatrixQuery = {
  getGradeMatrix?:  {
    __typename: "GradeMatrix",
    gradeMatrixID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    memberList?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    competencyAreaList?:  {
      __typename: "ModelCompetencyAreaConnection",
      items:  Array< {
        __typename: "CompetencyArea",
        competencyAreaID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeList?:  {
      __typename: "ModelGradeConnection",
      items:  Array< {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    competencyItemList?:  {
      __typename: "ModelCompetencyItemConnection",
      items:  Array< {
        __typename: "CompetencyItem",
        competencyItemID: string,
        item: string,
        gradeID: string,
        competencyAreaID: string,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListGradeMatrixsQueryVariables = {
  gradeMatrixID?: string | null,
  filter?: ModelGradeMatrixFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGradeMatrixsQuery = {
  listGradeMatrixs?:  {
    __typename: "ModelGradeMatrixConnection",
    items:  Array< {
      __typename: "GradeMatrix",
      gradeMatrixID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      memberList?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyAreaList?:  {
        __typename: "ModelCompetencyAreaConnection",
        items:  Array< {
          __typename: "CompetencyArea",
          competencyAreaID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeList?:  {
        __typename: "ModelGradeConnection",
        items:  Array< {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyItemList?:  {
        __typename: "ModelCompetencyItemConnection",
        items:  Array< {
          __typename: "CompetencyItem",
          competencyItemID: string,
          item: string,
          gradeID: string,
          competencyAreaID: string,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGradeMatrixByOrganizationGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGradeMatrixFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGradeMatrixByOrganizationGroupQuery = {
  listGradeMatrixByOrganizationGroup?:  {
    __typename: "ModelGradeMatrixConnection",
    items:  Array< {
      __typename: "GradeMatrix",
      gradeMatrixID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      memberList?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyAreaList?:  {
        __typename: "ModelCompetencyAreaConnection",
        items:  Array< {
          __typename: "CompetencyArea",
          competencyAreaID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeList?:  {
        __typename: "ModelGradeConnection",
        items:  Array< {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyItemList?:  {
        __typename: "ModelCompetencyItemConnection",
        items:  Array< {
          __typename: "CompetencyItem",
          competencyItemID: string,
          item: string,
          gradeID: string,
          competencyAreaID: string,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompetencyAreaQueryVariables = {
  competencyAreaID: string,
};

export type GetCompetencyAreaQuery = {
  getCompetencyArea?:  {
    __typename: "CompetencyArea",
    competencyAreaID: string,
    title: string,
    description?: string | null,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompetencyAreasQueryVariables = {
  competencyAreaID?: string | null,
  filter?: ModelCompetencyAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompetencyAreasQuery = {
  listCompetencyAreas?:  {
    __typename: "ModelCompetencyAreaConnection",
    items:  Array< {
      __typename: "CompetencyArea",
      competencyAreaID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCompetencyAreaByGradeMatrixIDQueryVariables = {
  gradeMatrixID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompetencyAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompetencyAreaByGradeMatrixIDQuery = {
  listCompetencyAreaByGradeMatrixID?:  {
    __typename: "ModelCompetencyAreaConnection",
    items:  Array< {
      __typename: "CompetencyArea",
      competencyAreaID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGradeQueryVariables = {
  gradeID: string,
};

export type GetGradeQuery = {
  getGrade?:  {
    __typename: "Grade",
    gradeID: string,
    title: string,
    description?: string | null,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGradesQueryVariables = {
  gradeID?: string | null,
  filter?: ModelGradeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGradesQuery = {
  listGrades?:  {
    __typename: "ModelGradeConnection",
    items:  Array< {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListGradeByGradeMatrixIDQueryVariables = {
  gradeMatrixID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGradeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGradeByGradeMatrixIDQuery = {
  listGradeByGradeMatrixID?:  {
    __typename: "ModelGradeConnection",
    items:  Array< {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompetencyItemQueryVariables = {
  competencyItemID: string,
};

export type GetCompetencyItemQuery = {
  getCompetencyItem?:  {
    __typename: "CompetencyItem",
    competencyItemID: string,
    item: string,
    gradeID: string,
    competencyAreaID: string,
    gradeMatrixID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompetencyItemsQueryVariables = {
  competencyItemID?: string | null,
  filter?: ModelCompetencyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompetencyItemsQuery = {
  listCompetencyItems?:  {
    __typename: "ModelCompetencyItemConnection",
    items:  Array< {
      __typename: "CompetencyItem",
      competencyItemID: string,
      item: string,
      gradeID: string,
      competencyAreaID: string,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCompetencyItemByOrganizationGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompetencyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompetencyItemByOrganizationGroupQuery = {
  listCompetencyItemByOrganizationGroup?:  {
    __typename: "ModelCompetencyItemConnection",
    items:  Array< {
      __typename: "CompetencyItem",
      competencyItemID: string,
      item: string,
      gradeID: string,
      competencyAreaID: string,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCompetencyItemByGradeMatrixIDQueryVariables = {
  gradeMatrixID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompetencyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompetencyItemByGradeMatrixIDQuery = {
  listCompetencyItemByGradeMatrixID?:  {
    __typename: "ModelCompetencyItemConnection",
    items:  Array< {
      __typename: "CompetencyItem",
      competencyItemID: string,
      item: string,
      gradeID: string,
      competencyAreaID: string,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCompetencyItemByGradeIDQueryVariables = {
  gradeID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompetencyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompetencyItemByGradeIDQuery = {
  listCompetencyItemByGradeID?:  {
    __typename: "ModelCompetencyItemConnection",
    items:  Array< {
      __typename: "CompetencyItem",
      competencyItemID: string,
      item: string,
      gradeID: string,
      competencyAreaID: string,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSkillMapQueryVariables = {
  id: string,
};

export type GetSkillMapQuery = {
  getSkillMap?:  {
    __typename: "SkillMap",
    id: string,
    name: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSkillMapsQueryVariables = {
  filter?: ModelSkillMapFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSkillMapsQuery = {
  listSkillMaps?:  {
    __typename: "ModelSkillMapConnection",
    items:  Array< {
      __typename: "SkillMap",
      id: string,
      name: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSkillMapByOrganizationGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSkillMapFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSkillMapByOrganizationGroupQuery = {
  listSkillMapByOrganizationGroup?:  {
    __typename: "ModelSkillMapConnection",
    items:  Array< {
      __typename: "SkillMap",
      id: string,
      name: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSkillItemQueryVariables = {
  id: string,
};

export type GetSkillItemQuery = {
  getSkillItem?:  {
    __typename: "SkillItem",
    id: string,
    skillMapID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSkillItemsQueryVariables = {
  filter?: ModelSkillItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSkillItemsQuery = {
  listSkillItems?:  {
    __typename: "ModelSkillItemConnection",
    items:  Array< {
      __typename: "SkillItem",
      id: string,
      skillMapID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSkillItemBySkillMapIDQueryVariables = {
  skillMapID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSkillItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSkillItemBySkillMapIDQuery = {
  listSkillItemBySkillMapID?:  {
    __typename: "ModelSkillItemConnection",
    items:  Array< {
      __typename: "SkillItem",
      id: string,
      skillMapID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLevelQueryVariables = {
  id: string,
};

export type GetLevelQuery = {
  getLevel?:  {
    __typename: "Level",
    id: string,
    skillMapID: string,
    title: string,
    description?: string | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLevelsQueryVariables = {
  filter?: ModelLevelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLevelsQuery = {
  listLevels?:  {
    __typename: "ModelLevelConnection",
    items:  Array< {
      __typename: "Level",
      id: string,
      skillMapID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLevelBySkillMapIDQueryVariables = {
  skillMapID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLevelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLevelBySkillMapIDQuery = {
  listLevelBySkillMapID?:  {
    __typename: "ModelLevelConnection",
    items:  Array< {
      __typename: "Level",
      id: string,
      skillMapID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSkillQueryVariables = {
  id: string,
};

export type GetSkillQuery = {
  getSkill?:  {
    __typename: "Skill",
    id: string,
    skillMapID: string,
    levelID: string,
    skillItemID: string,
    outcome: string,
    process: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSkillsQueryVariables = {
  filter?: ModelSkillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSkillsQuery = {
  listSkills?:  {
    __typename: "ModelSkillConnection",
    items:  Array< {
      __typename: "Skill",
      id: string,
      skillMapID: string,
      levelID: string,
      skillItemID: string,
      outcome: string,
      process: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSkillBySkillMapIDQueryVariables = {
  skillMapID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSkillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSkillBySkillMapIDQuery = {
  listSkillBySkillMapID?:  {
    __typename: "ModelSkillConnection",
    items:  Array< {
      __typename: "Skill",
      id: string,
      skillMapID: string,
      levelID: string,
      skillItemID: string,
      outcome: string,
      process: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIndividualSkillQueryVariables = {
  id: string,
};

export type GetIndividualSkillQuery = {
  getIndividualSkill?:  {
    __typename: "IndividualSkill",
    id: string,
    skillMapID: string,
    skillID: string,
    acquisitionStatus: AcquisitionStatus,
    acquisitionStartDate?: string | null,
    acquisitionEndDate?: string | null,
    userID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } | null,
};

export type ListIndividualSkillsQueryVariables = {
  filter?: ModelIndividualSkillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIndividualSkillsQuery = {
  listIndividualSkills?:  {
    __typename: "ModelIndividualSkillConnection",
    items:  Array< {
      __typename: "IndividualSkill",
      id: string,
      skillMapID: string,
      skillID: string,
      acquisitionStatus: AcquisitionStatus,
      acquisitionStartDate?: string | null,
      acquisitionEndDate?: string | null,
      userID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIndividualSkillBySkillMapIDQueryVariables = {
  skillMapID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIndividualSkillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIndividualSkillBySkillMapIDQuery = {
  listIndividualSkillBySkillMapID?:  {
    __typename: "ModelIndividualSkillConnection",
    items:  Array< {
      __typename: "IndividualSkill",
      id: string,
      skillMapID: string,
      skillID: string,
      acquisitionStatus: AcquisitionStatus,
      acquisitionStartDate?: string | null,
      acquisitionEndDate?: string | null,
      userID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIndividualSkillBySkillUserIDQueryVariables = {
  userID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIndividualSkillFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIndividualSkillBySkillUserIDQuery = {
  listIndividualSkillBySkillUserID?:  {
    __typename: "ModelIndividualSkillConnection",
    items:  Array< {
      __typename: "IndividualSkill",
      id: string,
      skillMapID: string,
      skillID: string,
      acquisitionStatus: AcquisitionStatus,
      acquisitionStartDate?: string | null,
      acquisitionEndDate?: string | null,
      userID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewResultQueryVariables = {
  reviewResultID: string,
};

export type GetReviewResultQuery = {
  getReviewResult?:  {
    __typename: "ReviewResult",
    reviewResultID: string,
    reviewID: string,
    revieweeID: string,
    finalReview?: string | null,
    nextGradeID?: string | null,
    nextBasicSalary?: number | null,
    salaryIncrease?: number | null,
    bonus?: number | null,
    shareStatus: ShareStatus,
    openStatus: OpenStatus,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewee?:  {
      __typename: "User",
      id: string,
      firstName?: string | null,
      lastName?: string | null,
      account: string,
      owner?: string | null,
      originalPhotoUrl?: string | null,
      thumbnailPhotoUrl?: string | null,
      coverImageUrl?: string | null,
      organizationID: string,
      status: UserStatus,
      admin?: boolean | null,
      sub: string,
      socialType?: SocialType | null,
      drivingScore?: number | null,
      analyticalScore?: number | null,
      amiableScore?: number | null,
      expressiveScore?: number | null,
      createdAt: string,
      updatedAt: string,
      organizationGroup?: string | null,
      adminGroup: string,
      email?: string | null,
      teamID?: string | null,
      managers?: Array< string | null > | null,
      googleRefreshToken?: string | null,
      googleAccountEmail?: string | null,
      about?: string | null,
      delete?: boolean | null,
      deleteStatus: string,
      employeeId?: string | null,
      teamCode?: string | null,
      readingFirstName?: string | null,
      readingLastName?: string | null,
      birthDay?: string | null,
      gender?: Gender | null,
      address?: string | null,
      joinedCompanyDay?: string | null,
      retirementDay?: string | null,
      jobType?: string | null,
      phoneNumber?: string | null,
      enrollmentStatus?: EnrollmentStatus | null,
      recruitmentClassification?: RecruitmentClassification | null,
      department?: string | null,
      division?: string | null,
      section?: string | null,
      position?: string | null,
      gradeID?: string | null,
      allowance?: number | null,
      bonus?: number | null,
      gradeMatrixID?: string | null,
      myMembers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      myManagers?:  {
        __typename: "ModelMemberManagerConnection",
        items:  Array< {
          __typename: "MemberManager",
          id: string,
          managerID: string,
          memberID: string,
          organizationGroup?: string | null,
          adminGroup: string,
          createdAt: string,
          updatedAt: string,
          delete?: boolean | null,
          owner?: string | null,
          manager:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
          member:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeMatrix?:  {
        __typename: "GradeMatrix",
        gradeMatrixID: string,
        title: string,
        description?: string | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        memberList?:  {
          __typename: "ModelUserConnection",
          items:  Array< {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyAreaList?:  {
          __typename: "ModelCompetencyAreaConnection",
          items:  Array< {
            __typename: "CompetencyArea",
            competencyAreaID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeList?:  {
          __typename: "ModelGradeConnection",
          items:  Array< {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        competencyItemList?:  {
          __typename: "ModelCompetencyItemConnection",
          items:  Array< {
            __typename: "CompetencyItem",
            competencyItemID: string,
            item: string,
            gradeID: string,
            competencyAreaID: string,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    owner?: string | null,
    individualReviewSheetList?:  {
      __typename: "ModelIndividualReviewSheetConnection",
      items:  Array< {
        __typename: "IndividualReviewSheet",
        individualReviewSheetID: string,
        name: string,
        reviewerID: string,
        revieweeID: string,
        reviewOrder: ReviewOrder,
        summary?: string | null,
        responseStage: ResponseStage,
        reviewID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewAnswerList?:  Array< {
          __typename: "ReviewAnswer",
          reviewItemTitle: string,
          description?: string | null,
          customReviewID?: string | null,
          customReviewName?: string | null,
          goalID?: string | null,
          goalProgress?: Progress | null,
          finalValue?: number | null,
          targetValue?: number | null,
          goalMeasuringType?: MeasuringType | null,
          goalResultDate?: string | null,
          competencyItemID?: string | null,
          weight?: number | null,
          answer: string,
          point?: number | null,
          answerBasis?: string | null,
        } | null > | null,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListReviewResultsQueryVariables = {
  reviewResultID?: string | null,
  filter?: ModelReviewResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReviewResultsQuery = {
  listReviewResults?:  {
    __typename: "ModelReviewResultConnection",
    items:  Array< {
      __typename: "ReviewResult",
      reviewResultID: string,
      reviewID: string,
      revieweeID: string,
      finalReview?: string | null,
      nextGradeID?: string | null,
      nextBasicSalary?: number | null,
      salaryIncrease?: number | null,
      bonus?: number | null,
      shareStatus: ShareStatus,
      openStatus: OpenStatus,
      calculateResult?:  {
        __typename: "CalculateResult",
        overallCalculateResult?: number | null,
        averageCalculateResult?: number | null,
        customReviewCalculateResults?:  Array< {
          __typename: "CustomReviewCalculateResult",
          customReviewID?: string | null,
          result?: number | null,
          average?: number | null,
        } > | null,
        goalReviewCalculateResult?: number | null,
        goalReviewCalculateAverage?: number | null,
        competencyReviewCalculateResult?: number | null,
        competencyReviewCalculateAverage?: number | null,
      } | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewee?:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      individualReviewSheetList?:  {
        __typename: "ModelIndividualReviewSheetConnection",
        items:  Array< {
          __typename: "IndividualReviewSheet",
          individualReviewSheetID: string,
          name: string,
          reviewerID: string,
          revieweeID: string,
          reviewOrder: ReviewOrder,
          summary?: string | null,
          responseStage: ResponseStage,
          reviewID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          reviewAnswerList?:  Array< {
            __typename: "ReviewAnswer",
            reviewItemTitle: string,
            description?: string | null,
            customReviewID?: string | null,
            customReviewName?: string | null,
            goalID?: string | null,
            goalProgress?: Progress | null,
            finalValue?: number | null,
            targetValue?: number | null,
            goalMeasuringType?: MeasuringType | null,
            goalResultDate?: string | null,
            competencyItemID?: string | null,
            weight?: number | null,
            answer: string,
            point?: number | null,
            answerBasis?: string | null,
          } | null > | null,
          calculateResult?:  {
            __typename: "CalculateResult",
            overallCalculateResult?: number | null,
            averageCalculateResult?: number | null,
            goalReviewCalculateResult?: number | null,
            goalReviewCalculateAverage?: number | null,
            competencyReviewCalculateResult?: number | null,
            competencyReviewCalculateAverage?: number | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewResultByReviewIDQueryVariables = {
  reviewID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewResultByReviewIDQuery = {
  listReviewResultByReviewID?:  {
    __typename: "ModelReviewResultConnection",
    items:  Array< {
      __typename: "ReviewResult",
      reviewResultID: string,
      reviewID: string,
      revieweeID: string,
      finalReview?: string | null,
      nextGradeID?: string | null,
      nextBasicSalary?: number | null,
      salaryIncrease?: number | null,
      bonus?: number | null,
      shareStatus: ShareStatus,
      openStatus: OpenStatus,
      calculateResult?:  {
        __typename: "CalculateResult",
        overallCalculateResult?: number | null,
        averageCalculateResult?: number | null,
        customReviewCalculateResults?:  Array< {
          __typename: "CustomReviewCalculateResult",
          customReviewID?: string | null,
          result?: number | null,
          average?: number | null,
        } > | null,
        goalReviewCalculateResult?: number | null,
        goalReviewCalculateAverage?: number | null,
        competencyReviewCalculateResult?: number | null,
        competencyReviewCalculateAverage?: number | null,
      } | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewee?:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      individualReviewSheetList?:  {
        __typename: "ModelIndividualReviewSheetConnection",
        items:  Array< {
          __typename: "IndividualReviewSheet",
          individualReviewSheetID: string,
          name: string,
          reviewerID: string,
          revieweeID: string,
          reviewOrder: ReviewOrder,
          summary?: string | null,
          responseStage: ResponseStage,
          reviewID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          reviewAnswerList?:  Array< {
            __typename: "ReviewAnswer",
            reviewItemTitle: string,
            description?: string | null,
            customReviewID?: string | null,
            customReviewName?: string | null,
            goalID?: string | null,
            goalProgress?: Progress | null,
            finalValue?: number | null,
            targetValue?: number | null,
            goalMeasuringType?: MeasuringType | null,
            goalResultDate?: string | null,
            competencyItemID?: string | null,
            weight?: number | null,
            answer: string,
            point?: number | null,
            answerBasis?: string | null,
          } | null > | null,
          calculateResult?:  {
            __typename: "CalculateResult",
            overallCalculateResult?: number | null,
            averageCalculateResult?: number | null,
            goalReviewCalculateResult?: number | null,
            goalReviewCalculateAverage?: number | null,
            competencyReviewCalculateResult?: number | null,
            competencyReviewCalculateAverage?: number | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewResultByReviewAndRevieweeQueryVariables = {
  reviewID?: string | null,
  revieweeID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewResultByReviewAndRevieweeQuery = {
  listReviewResultByReviewAndReviewee?:  {
    __typename: "ModelReviewResultConnection",
    items:  Array< {
      __typename: "ReviewResult",
      reviewResultID: string,
      reviewID: string,
      revieweeID: string,
      finalReview?: string | null,
      nextGradeID?: string | null,
      nextBasicSalary?: number | null,
      salaryIncrease?: number | null,
      bonus?: number | null,
      shareStatus: ShareStatus,
      openStatus: OpenStatus,
      calculateResult?:  {
        __typename: "CalculateResult",
        overallCalculateResult?: number | null,
        averageCalculateResult?: number | null,
        customReviewCalculateResults?:  Array< {
          __typename: "CustomReviewCalculateResult",
          customReviewID?: string | null,
          result?: number | null,
          average?: number | null,
        } > | null,
        goalReviewCalculateResult?: number | null,
        goalReviewCalculateAverage?: number | null,
        competencyReviewCalculateResult?: number | null,
        competencyReviewCalculateAverage?: number | null,
      } | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewee?:  {
        __typename: "User",
        id: string,
        firstName?: string | null,
        lastName?: string | null,
        account: string,
        owner?: string | null,
        originalPhotoUrl?: string | null,
        thumbnailPhotoUrl?: string | null,
        coverImageUrl?: string | null,
        organizationID: string,
        status: UserStatus,
        admin?: boolean | null,
        sub: string,
        socialType?: SocialType | null,
        drivingScore?: number | null,
        analyticalScore?: number | null,
        amiableScore?: number | null,
        expressiveScore?: number | null,
        createdAt: string,
        updatedAt: string,
        organizationGroup?: string | null,
        adminGroup: string,
        email?: string | null,
        teamID?: string | null,
        managers?: Array< string | null > | null,
        googleRefreshToken?: string | null,
        googleAccountEmail?: string | null,
        about?: string | null,
        delete?: boolean | null,
        deleteStatus: string,
        employeeId?: string | null,
        teamCode?: string | null,
        readingFirstName?: string | null,
        readingLastName?: string | null,
        birthDay?: string | null,
        gender?: Gender | null,
        address?: string | null,
        joinedCompanyDay?: string | null,
        retirementDay?: string | null,
        jobType?: string | null,
        phoneNumber?: string | null,
        enrollmentStatus?: EnrollmentStatus | null,
        recruitmentClassification?: RecruitmentClassification | null,
        department?: string | null,
        division?: string | null,
        section?: string | null,
        position?: string | null,
        gradeID?: string | null,
        allowance?: number | null,
        bonus?: number | null,
        gradeMatrixID?: string | null,
        myMembers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        myManagers?:  {
          __typename: "ModelMemberManagerConnection",
          items:  Array< {
            __typename: "MemberManager",
            id: string,
            managerID: string,
            memberID: string,
            organizationGroup?: string | null,
            adminGroup: string,
            createdAt: string,
            updatedAt: string,
            delete?: boolean | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gradeMatrix?:  {
          __typename: "GradeMatrix",
          gradeMatrixID: string,
          title: string,
          description?: string | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          memberList?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          competencyAreaList?:  {
            __typename: "ModelCompetencyAreaConnection",
            nextToken?: string | null,
          } | null,
          gradeList?:  {
            __typename: "ModelGradeConnection",
            nextToken?: string | null,
          } | null,
          competencyItemList?:  {
            __typename: "ModelCompetencyItemConnection",
            nextToken?: string | null,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      grade?:  {
        __typename: "Grade",
        gradeID: string,
        title: string,
        description?: string | null,
        gradeMatrixID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      owner?: string | null,
      individualReviewSheetList?:  {
        __typename: "ModelIndividualReviewSheetConnection",
        items:  Array< {
          __typename: "IndividualReviewSheet",
          individualReviewSheetID: string,
          name: string,
          reviewerID: string,
          revieweeID: string,
          reviewOrder: ReviewOrder,
          summary?: string | null,
          responseStage: ResponseStage,
          reviewID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          reviewAnswerList?:  Array< {
            __typename: "ReviewAnswer",
            reviewItemTitle: string,
            description?: string | null,
            customReviewID?: string | null,
            customReviewName?: string | null,
            goalID?: string | null,
            goalProgress?: Progress | null,
            finalValue?: number | null,
            targetValue?: number | null,
            goalMeasuringType?: MeasuringType | null,
            goalResultDate?: string | null,
            competencyItemID?: string | null,
            weight?: number | null,
            answer: string,
            point?: number | null,
            answerBasis?: string | null,
          } | null > | null,
          calculateResult?:  {
            __typename: "CalculateResult",
            overallCalculateResult?: number | null,
            averageCalculateResult?: number | null,
            goalReviewCalculateResult?: number | null,
            goalReviewCalculateAverage?: number | null,
            competencyReviewCalculateResult?: number | null,
            competencyReviewCalculateAverage?: number | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIndividualReviewSheetQueryVariables = {
  individualReviewSheetID: string,
};

export type GetIndividualReviewSheetQuery = {
  getIndividualReviewSheet?:  {
    __typename: "IndividualReviewSheet",
    individualReviewSheetID: string,
    name: string,
    reviewerID: string,
    revieweeID: string,
    reviewOrder: ReviewOrder,
    summary?: string | null,
    responseStage: ResponseStage,
    reviewID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewAnswerList?:  Array< {
      __typename: "ReviewAnswer",
      reviewItemTitle: string,
      description?: string | null,
      customReviewID?: string | null,
      customReviewName?: string | null,
      goalID?: string | null,
      goalProgress?: Progress | null,
      finalValue?: number | null,
      targetValue?: number | null,
      goalMeasuringType?: MeasuringType | null,
      goalResultDate?: string | null,
      competencyItemID?: string | null,
      weight?: number | null,
      answer: string,
      point?: number | null,
      answerBasis?: string | null,
    } | null > | null,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
  } | null,
};

export type ListIndividualReviewSheetsQueryVariables = {
  individualReviewSheetID?: string | null,
  filter?: ModelIndividualReviewSheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListIndividualReviewSheetsQuery = {
  listIndividualReviewSheets?:  {
    __typename: "ModelIndividualReviewSheetConnection",
    items:  Array< {
      __typename: "IndividualReviewSheet",
      individualReviewSheetID: string,
      name: string,
      reviewerID: string,
      revieweeID: string,
      reviewOrder: ReviewOrder,
      summary?: string | null,
      responseStage: ResponseStage,
      reviewID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewAnswerList?:  Array< {
        __typename: "ReviewAnswer",
        reviewItemTitle: string,
        description?: string | null,
        customReviewID?: string | null,
        customReviewName?: string | null,
        goalID?: string | null,
        goalProgress?: Progress | null,
        finalValue?: number | null,
        targetValue?: number | null,
        goalMeasuringType?: MeasuringType | null,
        goalResultDate?: string | null,
        competencyItemID?: string | null,
        weight?: number | null,
        answer: string,
        point?: number | null,
        answerBasis?: string | null,
      } | null > | null,
      calculateResult?:  {
        __typename: "CalculateResult",
        overallCalculateResult?: number | null,
        averageCalculateResult?: number | null,
        customReviewCalculateResults?:  Array< {
          __typename: "CustomReviewCalculateResult",
          customReviewID?: string | null,
          result?: number | null,
          average?: number | null,
        } > | null,
        goalReviewCalculateResult?: number | null,
        goalReviewCalculateAverage?: number | null,
        competencyReviewCalculateResult?: number | null,
        competencyReviewCalculateAverage?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIndividualReviewSheetByReviewIDQueryVariables = {
  reviewID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIndividualReviewSheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIndividualReviewSheetByReviewIDQuery = {
  listIndividualReviewSheetByReviewID?:  {
    __typename: "ModelIndividualReviewSheetConnection",
    items:  Array< {
      __typename: "IndividualReviewSheet",
      individualReviewSheetID: string,
      name: string,
      reviewerID: string,
      revieweeID: string,
      reviewOrder: ReviewOrder,
      summary?: string | null,
      responseStage: ResponseStage,
      reviewID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewAnswerList?:  Array< {
        __typename: "ReviewAnswer",
        reviewItemTitle: string,
        description?: string | null,
        customReviewID?: string | null,
        customReviewName?: string | null,
        goalID?: string | null,
        goalProgress?: Progress | null,
        finalValue?: number | null,
        targetValue?: number | null,
        goalMeasuringType?: MeasuringType | null,
        goalResultDate?: string | null,
        competencyItemID?: string | null,
        weight?: number | null,
        answer: string,
        point?: number | null,
        answerBasis?: string | null,
      } | null > | null,
      calculateResult?:  {
        __typename: "CalculateResult",
        overallCalculateResult?: number | null,
        averageCalculateResult?: number | null,
        customReviewCalculateResults?:  Array< {
          __typename: "CustomReviewCalculateResult",
          customReviewID?: string | null,
          result?: number | null,
          average?: number | null,
        } > | null,
        goalReviewCalculateResult?: number | null,
        goalReviewCalculateAverage?: number | null,
        competencyReviewCalculateResult?: number | null,
        competencyReviewCalculateAverage?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIndividualReviewSheetByReviewerIDQueryVariables = {
  reviewerID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIndividualReviewSheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIndividualReviewSheetByReviewerIDQuery = {
  listIndividualReviewSheetByReviewerID?:  {
    __typename: "ModelIndividualReviewSheetConnection",
    items:  Array< {
      __typename: "IndividualReviewSheet",
      individualReviewSheetID: string,
      name: string,
      reviewerID: string,
      revieweeID: string,
      reviewOrder: ReviewOrder,
      summary?: string | null,
      responseStage: ResponseStage,
      reviewID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewAnswerList?:  Array< {
        __typename: "ReviewAnswer",
        reviewItemTitle: string,
        description?: string | null,
        customReviewID?: string | null,
        customReviewName?: string | null,
        goalID?: string | null,
        goalProgress?: Progress | null,
        finalValue?: number | null,
        targetValue?: number | null,
        goalMeasuringType?: MeasuringType | null,
        goalResultDate?: string | null,
        competencyItemID?: string | null,
        weight?: number | null,
        answer: string,
        point?: number | null,
        answerBasis?: string | null,
      } | null > | null,
      calculateResult?:  {
        __typename: "CalculateResult",
        overallCalculateResult?: number | null,
        averageCalculateResult?: number | null,
        customReviewCalculateResults?:  Array< {
          __typename: "CustomReviewCalculateResult",
          customReviewID?: string | null,
          result?: number | null,
          average?: number | null,
        } > | null,
        goalReviewCalculateResult?: number | null,
        goalReviewCalculateAverage?: number | null,
        competencyReviewCalculateResult?: number | null,
        competencyReviewCalculateAverage?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListIndividualReviewSheetByReviewAndRevieweeQueryVariables = {
  reviewID?: string | null,
  revieweeID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIndividualReviewSheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIndividualReviewSheetByReviewAndRevieweeQuery = {
  listIndividualReviewSheetByReviewAndReviewee?:  {
    __typename: "ModelIndividualReviewSheetConnection",
    items:  Array< {
      __typename: "IndividualReviewSheet",
      individualReviewSheetID: string,
      name: string,
      reviewerID: string,
      revieweeID: string,
      reviewOrder: ReviewOrder,
      summary?: string | null,
      responseStage: ResponseStage,
      reviewID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewAnswerList?:  Array< {
        __typename: "ReviewAnswer",
        reviewItemTitle: string,
        description?: string | null,
        customReviewID?: string | null,
        customReviewName?: string | null,
        goalID?: string | null,
        goalProgress?: Progress | null,
        finalValue?: number | null,
        targetValue?: number | null,
        goalMeasuringType?: MeasuringType | null,
        goalResultDate?: string | null,
        competencyItemID?: string | null,
        weight?: number | null,
        answer: string,
        point?: number | null,
        answerBasis?: string | null,
      } | null > | null,
      calculateResult?:  {
        __typename: "CalculateResult",
        overallCalculateResult?: number | null,
        averageCalculateResult?: number | null,
        customReviewCalculateResults?:  Array< {
          __typename: "CustomReviewCalculateResult",
          customReviewID?: string | null,
          result?: number | null,
          average?: number | null,
        } > | null,
        goalReviewCalculateResult?: number | null,
        goalReviewCalculateAverage?: number | null,
        competencyReviewCalculateResult?: number | null,
        competencyReviewCalculateAverage?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewSheetByReviewAndReviewerQueryVariables = {
  reviewID?: string | null,
  reviewerID?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIndividualReviewSheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewSheetByReviewAndReviewerQuery = {
  listReviewSheetByReviewAndReviewer?:  {
    __typename: "ModelIndividualReviewSheetConnection",
    items:  Array< {
      __typename: "IndividualReviewSheet",
      individualReviewSheetID: string,
      name: string,
      reviewerID: string,
      revieweeID: string,
      reviewOrder: ReviewOrder,
      summary?: string | null,
      responseStage: ResponseStage,
      reviewID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewAnswerList?:  Array< {
        __typename: "ReviewAnswer",
        reviewItemTitle: string,
        description?: string | null,
        customReviewID?: string | null,
        customReviewName?: string | null,
        goalID?: string | null,
        goalProgress?: Progress | null,
        finalValue?: number | null,
        targetValue?: number | null,
        goalMeasuringType?: MeasuringType | null,
        goalResultDate?: string | null,
        competencyItemID?: string | null,
        weight?: number | null,
        answer: string,
        point?: number | null,
        answerBasis?: string | null,
      } | null > | null,
      calculateResult?:  {
        __typename: "CalculateResult",
        overallCalculateResult?: number | null,
        averageCalculateResult?: number | null,
        customReviewCalculateResults?:  Array< {
          __typename: "CustomReviewCalculateResult",
          customReviewID?: string | null,
          result?: number | null,
          average?: number | null,
        } > | null,
        goalReviewCalculateResult?: number | null,
        goalReviewCalculateAverage?: number | null,
        competencyReviewCalculateResult?: number | null,
        competencyReviewCalculateAverage?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewSheetByReviewAndReviewerAndRevieweeQueryVariables = {
  reviewID?: string | null,
  reviewerIDRevieweeID?: ModelIndividualReviewSheetListReviewSheetByReviewAndReviewerAndRevieweeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIndividualReviewSheetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewSheetByReviewAndReviewerAndRevieweeQuery = {
  listReviewSheetByReviewAndReviewerAndReviewee?:  {
    __typename: "ModelIndividualReviewSheetConnection",
    items:  Array< {
      __typename: "IndividualReviewSheet",
      individualReviewSheetID: string,
      name: string,
      reviewerID: string,
      revieweeID: string,
      reviewOrder: ReviewOrder,
      summary?: string | null,
      responseStage: ResponseStage,
      reviewID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewAnswerList?:  Array< {
        __typename: "ReviewAnswer",
        reviewItemTitle: string,
        description?: string | null,
        customReviewID?: string | null,
        customReviewName?: string | null,
        goalID?: string | null,
        goalProgress?: Progress | null,
        finalValue?: number | null,
        targetValue?: number | null,
        goalMeasuringType?: MeasuringType | null,
        goalResultDate?: string | null,
        competencyItemID?: string | null,
        weight?: number | null,
        answer: string,
        point?: number | null,
        answerBasis?: string | null,
      } | null > | null,
      calculateResult?:  {
        __typename: "CalculateResult",
        overallCalculateResult?: number | null,
        averageCalculateResult?: number | null,
        customReviewCalculateResults?:  Array< {
          __typename: "CustomReviewCalculateResult",
          customReviewID?: string | null,
          result?: number | null,
          average?: number | null,
        } > | null,
        goalReviewCalculateResult?: number | null,
        goalReviewCalculateAverage?: number | null,
        competencyReviewCalculateResult?: number | null,
        competencyReviewCalculateAverage?: number | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewQueryVariables = {
  reviewID: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    reviewID: string,
    name: string,
    revieweeSetting: RevieweeSetting,
    revieweeReviewerSettings?:  Array< {
      __typename: "RevieweeReviewerSetting",
      revieweeID: string,
      gradeID?: string | null,
      firstReviewerIDs?: Array< string > | null,
      secondReviewerIDs?: Array< string > | null,
    } > | null,
    reviewTemplateID?: string | null,
    reviewPeriod?:  {
      __typename: "ReviewPeriod",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    targetGoalPeriod?:  {
      __typename: "TargetGoalPeriod",
      startDate?: string | null,
      endDate?: string | null,
    } | null,
    remindNotificationDate?: string | null,
    isSummary: boolean,
    isCalculate: boolean,
    reviewStage: ReviewStage,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewResultList?:  {
      __typename: "ModelReviewResultConnection",
      items:  Array< {
        __typename: "ReviewResult",
        reviewResultID: string,
        reviewID: string,
        revieweeID: string,
        finalReview?: string | null,
        nextGradeID?: string | null,
        nextBasicSalary?: number | null,
        salaryIncrease?: number | null,
        bonus?: number | null,
        shareStatus: ShareStatus,
        openStatus: OpenStatus,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewee?:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null,
        grade?:  {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        owner?: string | null,
        individualReviewSheetList?:  {
          __typename: "ModelIndividualReviewSheetConnection",
          items:  Array< {
            __typename: "IndividualReviewSheet",
            individualReviewSheetID: string,
            name: string,
            reviewerID: string,
            revieweeID: string,
            reviewOrder: ReviewOrder,
            summary?: string | null,
            responseStage: ResponseStage,
            reviewID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    individualReviewSheetList?:  {
      __typename: "ModelIndividualReviewSheetConnection",
      items:  Array< {
        __typename: "IndividualReviewSheet",
        individualReviewSheetID: string,
        name: string,
        reviewerID: string,
        revieweeID: string,
        reviewOrder: ReviewOrder,
        summary?: string | null,
        responseStage: ResponseStage,
        reviewID: string,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
        reviewAnswerList?:  Array< {
          __typename: "ReviewAnswer",
          reviewItemTitle: string,
          description?: string | null,
          customReviewID?: string | null,
          customReviewName?: string | null,
          goalID?: string | null,
          goalProgress?: Progress | null,
          finalValue?: number | null,
          targetValue?: number | null,
          goalMeasuringType?: MeasuringType | null,
          goalResultDate?: string | null,
          competencyItemID?: string | null,
          weight?: number | null,
          answer: string,
          point?: number | null,
          answerBasis?: string | null,
        } | null > | null,
        calculateResult?:  {
          __typename: "CalculateResult",
          overallCalculateResult?: number | null,
          averageCalculateResult?: number | null,
          customReviewCalculateResults?:  Array< {
            __typename: "CustomReviewCalculateResult",
            customReviewID?: string | null,
            result?: number | null,
            average?: number | null,
          } > | null,
          goalReviewCalculateResult?: number | null,
          goalReviewCalculateAverage?: number | null,
          competencyReviewCalculateResult?: number | null,
          competencyReviewCalculateAverage?: number | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    reviewTemplate?:  {
      __typename: "NewReviewTemplate",
      reviewTemplateID: string,
      name: string,
      description?: string | null,
      reviewMethod:  {
        __typename: "ReviewMethod",
        isSelfReview: boolean,
        isFirstReview: boolean,
        isSecondReview: boolean,
        isFinalReview: boolean,
      },
      reviewSettings:  {
        __typename: "ReviewSetting",
        customReviewSettings?:  Array< {
          __typename: "CustomReviewSetting",
          customReviewID: string,
          weight?: number | null,
        } > | null,
        goalReviewSetting:  {
          __typename: "GoalReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          goalRange: GoalRange,
          goalStatus: GoalStatus,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        competencyReviewSetting:  {
          __typename: "CompetencyReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        isWeight: boolean,
      },
      calculable: boolean,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListReviewsQueryVariables = {
  reviewID?: string | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      reviewID: string,
      name: string,
      revieweeSetting: RevieweeSetting,
      revieweeReviewerSettings?:  Array< {
        __typename: "RevieweeReviewerSetting",
        revieweeID: string,
        gradeID?: string | null,
        firstReviewerIDs?: Array< string > | null,
        secondReviewerIDs?: Array< string > | null,
      } > | null,
      reviewTemplateID?: string | null,
      reviewPeriod?:  {
        __typename: "ReviewPeriod",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      targetGoalPeriod?:  {
        __typename: "TargetGoalPeriod",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      remindNotificationDate?: string | null,
      isSummary: boolean,
      isCalculate: boolean,
      reviewStage: ReviewStage,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewResultList?:  {
        __typename: "ModelReviewResultConnection",
        items:  Array< {
          __typename: "ReviewResult",
          reviewResultID: string,
          reviewID: string,
          revieweeID: string,
          finalReview?: string | null,
          nextGradeID?: string | null,
          nextBasicSalary?: number | null,
          salaryIncrease?: number | null,
          bonus?: number | null,
          shareStatus: ShareStatus,
          openStatus: OpenStatus,
          calculateResult?:  {
            __typename: "CalculateResult",
            overallCalculateResult?: number | null,
            averageCalculateResult?: number | null,
            goalReviewCalculateResult?: number | null,
            goalReviewCalculateAverage?: number | null,
            competencyReviewCalculateResult?: number | null,
            competencyReviewCalculateAverage?: number | null,
          } | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          reviewee?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          owner?: string | null,
          individualReviewSheetList?:  {
            __typename: "ModelIndividualReviewSheetConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      individualReviewSheetList?:  {
        __typename: "ModelIndividualReviewSheetConnection",
        items:  Array< {
          __typename: "IndividualReviewSheet",
          individualReviewSheetID: string,
          name: string,
          reviewerID: string,
          revieweeID: string,
          reviewOrder: ReviewOrder,
          summary?: string | null,
          responseStage: ResponseStage,
          reviewID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          reviewAnswerList?:  Array< {
            __typename: "ReviewAnswer",
            reviewItemTitle: string,
            description?: string | null,
            customReviewID?: string | null,
            customReviewName?: string | null,
            goalID?: string | null,
            goalProgress?: Progress | null,
            finalValue?: number | null,
            targetValue?: number | null,
            goalMeasuringType?: MeasuringType | null,
            goalResultDate?: string | null,
            competencyItemID?: string | null,
            weight?: number | null,
            answer: string,
            point?: number | null,
            answerBasis?: string | null,
          } | null > | null,
          calculateResult?:  {
            __typename: "CalculateResult",
            overallCalculateResult?: number | null,
            averageCalculateResult?: number | null,
            goalReviewCalculateResult?: number | null,
            goalReviewCalculateAverage?: number | null,
            competencyReviewCalculateResult?: number | null,
            competencyReviewCalculateAverage?: number | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewTemplate?:  {
        __typename: "NewReviewTemplate",
        reviewTemplateID: string,
        name: string,
        description?: string | null,
        reviewMethod:  {
          __typename: "ReviewMethod",
          isSelfReview: boolean,
          isFirstReview: boolean,
          isSecondReview: boolean,
          isFinalReview: boolean,
        },
        reviewSettings:  {
          __typename: "ReviewSetting",
          customReviewSettings?:  Array< {
            __typename: "CustomReviewSetting",
            customReviewID: string,
            weight?: number | null,
          } > | null,
          goalReviewSetting:  {
            __typename: "GoalReviewSetting",
            isReviewInclude: boolean,
            question: string,
            description?: string | null,
            goalRange: GoalRange,
            goalStatus: GoalStatus,
            answerType: AnswerType,
            isAnswerBasis: boolean,
            isAnswerBasisEntryOptional: boolean,
            weight?: number | null,
          },
          competencyReviewSetting:  {
            __typename: "CompetencyReviewSetting",
            isReviewInclude: boolean,
            question: string,
            description?: string | null,
            answerType: AnswerType,
            isAnswerBasis: boolean,
            isAnswerBasisEntryOptional: boolean,
            weight?: number | null,
          },
          isWeight: boolean,
        },
        calculable: boolean,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewByOrganizationGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewByOrganizationGroupQuery = {
  listReviewByOrganizationGroup?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      reviewID: string,
      name: string,
      revieweeSetting: RevieweeSetting,
      revieweeReviewerSettings?:  Array< {
        __typename: "RevieweeReviewerSetting",
        revieweeID: string,
        gradeID?: string | null,
        firstReviewerIDs?: Array< string > | null,
        secondReviewerIDs?: Array< string > | null,
      } > | null,
      reviewTemplateID?: string | null,
      reviewPeriod?:  {
        __typename: "ReviewPeriod",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      targetGoalPeriod?:  {
        __typename: "TargetGoalPeriod",
        startDate?: string | null,
        endDate?: string | null,
      } | null,
      remindNotificationDate?: string | null,
      isSummary: boolean,
      isCalculate: boolean,
      reviewStage: ReviewStage,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      reviewResultList?:  {
        __typename: "ModelReviewResultConnection",
        items:  Array< {
          __typename: "ReviewResult",
          reviewResultID: string,
          reviewID: string,
          revieweeID: string,
          finalReview?: string | null,
          nextGradeID?: string | null,
          nextBasicSalary?: number | null,
          salaryIncrease?: number | null,
          bonus?: number | null,
          shareStatus: ShareStatus,
          openStatus: OpenStatus,
          calculateResult?:  {
            __typename: "CalculateResult",
            overallCalculateResult?: number | null,
            averageCalculateResult?: number | null,
            goalReviewCalculateResult?: number | null,
            goalReviewCalculateAverage?: number | null,
            competencyReviewCalculateResult?: number | null,
            competencyReviewCalculateAverage?: number | null,
          } | null,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          reviewee?:  {
            __typename: "User",
            id: string,
            firstName?: string | null,
            lastName?: string | null,
            account: string,
            owner?: string | null,
            originalPhotoUrl?: string | null,
            thumbnailPhotoUrl?: string | null,
            coverImageUrl?: string | null,
            organizationID: string,
            status: UserStatus,
            admin?: boolean | null,
            sub: string,
            socialType?: SocialType | null,
            drivingScore?: number | null,
            analyticalScore?: number | null,
            amiableScore?: number | null,
            expressiveScore?: number | null,
            createdAt: string,
            updatedAt: string,
            organizationGroup?: string | null,
            adminGroup: string,
            email?: string | null,
            teamID?: string | null,
            managers?: Array< string | null > | null,
            googleRefreshToken?: string | null,
            googleAccountEmail?: string | null,
            about?: string | null,
            delete?: boolean | null,
            deleteStatus: string,
            employeeId?: string | null,
            teamCode?: string | null,
            readingFirstName?: string | null,
            readingLastName?: string | null,
            birthDay?: string | null,
            gender?: Gender | null,
            address?: string | null,
            joinedCompanyDay?: string | null,
            retirementDay?: string | null,
            jobType?: string | null,
            phoneNumber?: string | null,
            enrollmentStatus?: EnrollmentStatus | null,
            recruitmentClassification?: RecruitmentClassification | null,
            department?: string | null,
            division?: string | null,
            section?: string | null,
            position?: string | null,
            gradeID?: string | null,
            allowance?: number | null,
            bonus?: number | null,
            gradeMatrixID?: string | null,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          owner?: string | null,
          individualReviewSheetList?:  {
            __typename: "ModelIndividualReviewSheetConnection",
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      individualReviewSheetList?:  {
        __typename: "ModelIndividualReviewSheetConnection",
        items:  Array< {
          __typename: "IndividualReviewSheet",
          individualReviewSheetID: string,
          name: string,
          reviewerID: string,
          revieweeID: string,
          reviewOrder: ReviewOrder,
          summary?: string | null,
          responseStage: ResponseStage,
          reviewID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
          reviewAnswerList?:  Array< {
            __typename: "ReviewAnswer",
            reviewItemTitle: string,
            description?: string | null,
            customReviewID?: string | null,
            customReviewName?: string | null,
            goalID?: string | null,
            goalProgress?: Progress | null,
            finalValue?: number | null,
            targetValue?: number | null,
            goalMeasuringType?: MeasuringType | null,
            goalResultDate?: string | null,
            competencyItemID?: string | null,
            weight?: number | null,
            answer: string,
            point?: number | null,
            answerBasis?: string | null,
          } | null > | null,
          calculateResult?:  {
            __typename: "CalculateResult",
            overallCalculateResult?: number | null,
            averageCalculateResult?: number | null,
            goalReviewCalculateResult?: number | null,
            goalReviewCalculateAverage?: number | null,
            competencyReviewCalculateResult?: number | null,
            competencyReviewCalculateAverage?: number | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      reviewTemplate?:  {
        __typename: "NewReviewTemplate",
        reviewTemplateID: string,
        name: string,
        description?: string | null,
        reviewMethod:  {
          __typename: "ReviewMethod",
          isSelfReview: boolean,
          isFirstReview: boolean,
          isSecondReview: boolean,
          isFinalReview: boolean,
        },
        reviewSettings:  {
          __typename: "ReviewSetting",
          customReviewSettings?:  Array< {
            __typename: "CustomReviewSetting",
            customReviewID: string,
            weight?: number | null,
          } > | null,
          goalReviewSetting:  {
            __typename: "GoalReviewSetting",
            isReviewInclude: boolean,
            question: string,
            description?: string | null,
            goalRange: GoalRange,
            goalStatus: GoalStatus,
            answerType: AnswerType,
            isAnswerBasis: boolean,
            isAnswerBasisEntryOptional: boolean,
            weight?: number | null,
          },
          competencyReviewSetting:  {
            __typename: "CompetencyReviewSetting",
            isReviewInclude: boolean,
            question: string,
            description?: string | null,
            answerType: AnswerType,
            isAnswerBasis: boolean,
            isAnswerBasisEntryOptional: boolean,
            weight?: number | null,
          },
          isWeight: boolean,
        },
        calculable: boolean,
        organizationGroup: string,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNewReviewTemplateQueryVariables = {
  reviewTemplateID: string,
};

export type GetNewReviewTemplateQuery = {
  getNewReviewTemplate?:  {
    __typename: "NewReviewTemplate",
    reviewTemplateID: string,
    name: string,
    description?: string | null,
    reviewMethod:  {
      __typename: "ReviewMethod",
      isSelfReview: boolean,
      isFirstReview: boolean,
      isSecondReview: boolean,
      isFinalReview: boolean,
    },
    reviewSettings:  {
      __typename: "ReviewSetting",
      customReviewSettings?:  Array< {
        __typename: "CustomReviewSetting",
        customReviewID: string,
        weight?: number | null,
      } > | null,
      goalReviewSetting:  {
        __typename: "GoalReviewSetting",
        isReviewInclude: boolean,
        question: string,
        description?: string | null,
        goalRange: GoalRange,
        goalStatus: GoalStatus,
        answerType: AnswerType,
        options?:  Array< {
          __typename: "Option",
          item?: string | null,
          pointAllocation?: number | null,
        } > | null,
        numberRange?:  {
          __typename: "NumberRange",
          lowerLimit?: number | null,
          upperLimit?: number | null,
        } | null,
        isAnswerBasis: boolean,
        isAnswerBasisEntryOptional: boolean,
        weight?: number | null,
      },
      competencyReviewSetting:  {
        __typename: "CompetencyReviewSetting",
        isReviewInclude: boolean,
        question: string,
        description?: string | null,
        answerType: AnswerType,
        options?:  Array< {
          __typename: "Option",
          item?: string | null,
          pointAllocation?: number | null,
        } > | null,
        numberRange?:  {
          __typename: "NumberRange",
          lowerLimit?: number | null,
          upperLimit?: number | null,
        } | null,
        isAnswerBasis: boolean,
        isAnswerBasisEntryOptional: boolean,
        weight?: number | null,
      },
      isWeight: boolean,
    },
    calculable: boolean,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNewReviewTemplatesQueryVariables = {
  reviewTemplateID?: string | null,
  filter?: ModelNewReviewTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListNewReviewTemplatesQuery = {
  listNewReviewTemplates?:  {
    __typename: "ModelNewReviewTemplateConnection",
    items:  Array< {
      __typename: "NewReviewTemplate",
      reviewTemplateID: string,
      name: string,
      description?: string | null,
      reviewMethod:  {
        __typename: "ReviewMethod",
        isSelfReview: boolean,
        isFirstReview: boolean,
        isSecondReview: boolean,
        isFinalReview: boolean,
      },
      reviewSettings:  {
        __typename: "ReviewSetting",
        customReviewSettings?:  Array< {
          __typename: "CustomReviewSetting",
          customReviewID: string,
          weight?: number | null,
        } > | null,
        goalReviewSetting:  {
          __typename: "GoalReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          goalRange: GoalRange,
          goalStatus: GoalStatus,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        competencyReviewSetting:  {
          __typename: "CompetencyReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        isWeight: boolean,
      },
      calculable: boolean,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewTemplateByOrganizationGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNewReviewTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewTemplateByOrganizationGroupQuery = {
  listReviewTemplateByOrganizationGroup?:  {
    __typename: "ModelNewReviewTemplateConnection",
    items:  Array< {
      __typename: "NewReviewTemplate",
      reviewTemplateID: string,
      name: string,
      description?: string | null,
      reviewMethod:  {
        __typename: "ReviewMethod",
        isSelfReview: boolean,
        isFirstReview: boolean,
        isSecondReview: boolean,
        isFinalReview: boolean,
      },
      reviewSettings:  {
        __typename: "ReviewSetting",
        customReviewSettings?:  Array< {
          __typename: "CustomReviewSetting",
          customReviewID: string,
          weight?: number | null,
        } > | null,
        goalReviewSetting:  {
          __typename: "GoalReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          goalRange: GoalRange,
          goalStatus: GoalStatus,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        competencyReviewSetting:  {
          __typename: "CompetencyReviewSetting",
          isReviewInclude: boolean,
          question: string,
          description?: string | null,
          answerType: AnswerType,
          options?:  Array< {
            __typename: "Option",
            item?: string | null,
            pointAllocation?: number | null,
          } > | null,
          numberRange?:  {
            __typename: "NumberRange",
            lowerLimit?: number | null,
            upperLimit?: number | null,
          } | null,
          isAnswerBasis: boolean,
          isAnswerBasisEntryOptional: boolean,
          weight?: number | null,
        },
        isWeight: boolean,
      },
      calculable: boolean,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomReviewQueryVariables = {
  customReviewID: string,
};

export type GetCustomReviewQuery = {
  getCustomReview?:  {
    __typename: "CustomReview",
    customReviewID: string,
    name: string,
    isWeight: boolean,
    customReviewItemList:  Array< {
      __typename: "CustomReviewItem",
      name: string,
      weight?: number | null,
    } >,
    question: string,
    description?: string | null,
    answerType: AnswerType,
    organizationGroup: string,
    options?:  Array< {
      __typename: "Option",
      item?: string | null,
      pointAllocation?: number | null,
    } > | null,
    numberRange?:  {
      __typename: "NumberRange",
      lowerLimit?: number | null,
      upperLimit?: number | null,
    } | null,
    isAnswerBasis: boolean,
    isAnswerBasisEntryOptional: boolean,
    calculable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomReviewsQueryVariables = {
  customReviewID?: string | null,
  filter?: ModelCustomReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCustomReviewsQuery = {
  listCustomReviews?:  {
    __typename: "ModelCustomReviewConnection",
    items:  Array< {
      __typename: "CustomReview",
      customReviewID: string,
      name: string,
      isWeight: boolean,
      customReviewItemList:  Array< {
        __typename: "CustomReviewItem",
        name: string,
        weight?: number | null,
      } >,
      question: string,
      description?: string | null,
      answerType: AnswerType,
      organizationGroup: string,
      options?:  Array< {
        __typename: "Option",
        item?: string | null,
        pointAllocation?: number | null,
      } > | null,
      numberRange?:  {
        __typename: "NumberRange",
        lowerLimit?: number | null,
        upperLimit?: number | null,
      } | null,
      isAnswerBasis: boolean,
      isAnswerBasisEntryOptional: boolean,
      calculable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCustomReviewByOrganizationGroupQueryVariables = {
  organizationGroup?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCustomReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomReviewByOrganizationGroupQuery = {
  listCustomReviewByOrganizationGroup?:  {
    __typename: "ModelCustomReviewConnection",
    items:  Array< {
      __typename: "CustomReview",
      customReviewID: string,
      name: string,
      isWeight: boolean,
      customReviewItemList:  Array< {
        __typename: "CustomReviewItem",
        name: string,
        weight?: number | null,
      } >,
      question: string,
      description?: string | null,
      answerType: AnswerType,
      organizationGroup: string,
      options?:  Array< {
        __typename: "Option",
        item?: string | null,
        pointAllocation?: number | null,
      } > | null,
      numberRange?:  {
        __typename: "NumberRange",
        lowerLimit?: number | null,
        upperLimit?: number | null,
      } | null,
      isAnswerBasis: boolean,
      isAnswerBasisEntryOptional: boolean,
      calculable: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    account: string,
    owner?: string | null,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationID: string,
    status: UserStatus,
    admin?: boolean | null,
    sub: string,
    socialType?: SocialType | null,
    drivingScore?: number | null,
    analyticalScore?: number | null,
    amiableScore?: number | null,
    expressiveScore?: number | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    email?: string | null,
    teamID?: string | null,
    managers?: Array< string | null > | null,
    googleRefreshToken?: string | null,
    googleAccountEmail?: string | null,
    about?: string | null,
    delete?: boolean | null,
    deleteStatus: string,
    employeeId?: string | null,
    teamCode?: string | null,
    readingFirstName?: string | null,
    readingLastName?: string | null,
    birthDay?: string | null,
    gender?: Gender | null,
    address?: string | null,
    joinedCompanyDay?: string | null,
    retirementDay?: string | null,
    jobType?: string | null,
    phoneNumber?: string | null,
    enrollmentStatus?: EnrollmentStatus | null,
    recruitmentClassification?: RecruitmentClassification | null,
    department?: string | null,
    division?: string | null,
    section?: string | null,
    position?: string | null,
    gradeID?: string | null,
    allowance?: number | null,
    bonus?: number | null,
    gradeMatrixID?: string | null,
    myMembers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    myManagers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeMatrix?:  {
      __typename: "GradeMatrix",
      gradeMatrixID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      memberList?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyAreaList?:  {
        __typename: "ModelCompetencyAreaConnection",
        items:  Array< {
          __typename: "CompetencyArea",
          competencyAreaID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeList?:  {
        __typename: "ModelGradeConnection",
        items:  Array< {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyItemList?:  {
        __typename: "ModelCompetencyItemConnection",
        items:  Array< {
          __typename: "CompetencyItem",
          competencyItemID: string,
          item: string,
          gradeID: string,
          competencyAreaID: string,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    account: string,
    owner?: string | null,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationID: string,
    status: UserStatus,
    admin?: boolean | null,
    sub: string,
    socialType?: SocialType | null,
    drivingScore?: number | null,
    analyticalScore?: number | null,
    amiableScore?: number | null,
    expressiveScore?: number | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    email?: string | null,
    teamID?: string | null,
    managers?: Array< string | null > | null,
    googleRefreshToken?: string | null,
    googleAccountEmail?: string | null,
    about?: string | null,
    delete?: boolean | null,
    deleteStatus: string,
    employeeId?: string | null,
    teamCode?: string | null,
    readingFirstName?: string | null,
    readingLastName?: string | null,
    birthDay?: string | null,
    gender?: Gender | null,
    address?: string | null,
    joinedCompanyDay?: string | null,
    retirementDay?: string | null,
    jobType?: string | null,
    phoneNumber?: string | null,
    enrollmentStatus?: EnrollmentStatus | null,
    recruitmentClassification?: RecruitmentClassification | null,
    department?: string | null,
    division?: string | null,
    section?: string | null,
    position?: string | null,
    gradeID?: string | null,
    allowance?: number | null,
    bonus?: number | null,
    gradeMatrixID?: string | null,
    myMembers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    myManagers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeMatrix?:  {
      __typename: "GradeMatrix",
      gradeMatrixID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      memberList?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyAreaList?:  {
        __typename: "ModelCompetencyAreaConnection",
        items:  Array< {
          __typename: "CompetencyArea",
          competencyAreaID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeList?:  {
        __typename: "ModelGradeConnection",
        items:  Array< {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyItemList?:  {
        __typename: "ModelCompetencyItemConnection",
        items:  Array< {
          __typename: "CompetencyItem",
          competencyItemID: string,
          item: string,
          gradeID: string,
          competencyAreaID: string,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    firstName?: string | null,
    lastName?: string | null,
    account: string,
    owner?: string | null,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    organizationID: string,
    status: UserStatus,
    admin?: boolean | null,
    sub: string,
    socialType?: SocialType | null,
    drivingScore?: number | null,
    analyticalScore?: number | null,
    amiableScore?: number | null,
    expressiveScore?: number | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    email?: string | null,
    teamID?: string | null,
    managers?: Array< string | null > | null,
    googleRefreshToken?: string | null,
    googleAccountEmail?: string | null,
    about?: string | null,
    delete?: boolean | null,
    deleteStatus: string,
    employeeId?: string | null,
    teamCode?: string | null,
    readingFirstName?: string | null,
    readingLastName?: string | null,
    birthDay?: string | null,
    gender?: Gender | null,
    address?: string | null,
    joinedCompanyDay?: string | null,
    retirementDay?: string | null,
    jobType?: string | null,
    phoneNumber?: string | null,
    enrollmentStatus?: EnrollmentStatus | null,
    recruitmentClassification?: RecruitmentClassification | null,
    department?: string | null,
    division?: string | null,
    section?: string | null,
    position?: string | null,
    gradeID?: string | null,
    allowance?: number | null,
    bonus?: number | null,
    gradeMatrixID?: string | null,
    myMembers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    myManagers?:  {
      __typename: "ModelMemberManagerConnection",
      items:  Array< {
        __typename: "MemberManager",
        id: string,
        managerID: string,
        memberID: string,
        organizationGroup?: string | null,
        adminGroup: string,
        createdAt: string,
        updatedAt: string,
        delete?: boolean | null,
        owner?: string | null,
        manager:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
        member:  {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    gradeMatrix?:  {
      __typename: "GradeMatrix",
      gradeMatrixID: string,
      title: string,
      description?: string | null,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
      memberList?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName?: string | null,
          lastName?: string | null,
          account: string,
          owner?: string | null,
          originalPhotoUrl?: string | null,
          thumbnailPhotoUrl?: string | null,
          coverImageUrl?: string | null,
          organizationID: string,
          status: UserStatus,
          admin?: boolean | null,
          sub: string,
          socialType?: SocialType | null,
          drivingScore?: number | null,
          analyticalScore?: number | null,
          amiableScore?: number | null,
          expressiveScore?: number | null,
          createdAt: string,
          updatedAt: string,
          organizationGroup?: string | null,
          adminGroup: string,
          email?: string | null,
          teamID?: string | null,
          managers?: Array< string | null > | null,
          googleRefreshToken?: string | null,
          googleAccountEmail?: string | null,
          about?: string | null,
          delete?: boolean | null,
          deleteStatus: string,
          employeeId?: string | null,
          teamCode?: string | null,
          readingFirstName?: string | null,
          readingLastName?: string | null,
          birthDay?: string | null,
          gender?: Gender | null,
          address?: string | null,
          joinedCompanyDay?: string | null,
          retirementDay?: string | null,
          jobType?: string | null,
          phoneNumber?: string | null,
          enrollmentStatus?: EnrollmentStatus | null,
          recruitmentClassification?: RecruitmentClassification | null,
          department?: string | null,
          division?: string | null,
          section?: string | null,
          position?: string | null,
          gradeID?: string | null,
          allowance?: number | null,
          bonus?: number | null,
          gradeMatrixID?: string | null,
          myMembers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          myManagers?:  {
            __typename: "ModelMemberManagerConnection",
            nextToken?: string | null,
          } | null,
          gradeMatrix?:  {
            __typename: "GradeMatrix",
            gradeMatrixID: string,
            title: string,
            description?: string | null,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
          grade?:  {
            __typename: "Grade",
            gradeID: string,
            title: string,
            description?: string | null,
            gradeMatrixID: string,
            organizationGroup: string,
            createdAt: string,
            updatedAt: string,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyAreaList?:  {
        __typename: "ModelCompetencyAreaConnection",
        items:  Array< {
          __typename: "CompetencyArea",
          competencyAreaID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      gradeList?:  {
        __typename: "ModelGradeConnection",
        items:  Array< {
          __typename: "Grade",
          gradeID: string,
          title: string,
          description?: string | null,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      competencyItemList?:  {
        __typename: "ModelCompetencyItemConnection",
        items:  Array< {
          __typename: "CompetencyItem",
          competencyItemID: string,
          item: string,
          gradeID: string,
          competencyAreaID: string,
          gradeMatrixID: string,
          organizationGroup: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    grade?:  {
      __typename: "Grade",
      gradeID: string,
      title: string,
      description?: string | null,
      gradeMatrixID: string,
      organizationGroup: string,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    domain?: string | null,
    webhookURL?: string | null,
    mailingList?: string | null,
    adminGroupName: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    groupName: string,
    numberOfUsers?: number | null,
    agreementUpdateDate?: string | null,
    assessmentFromDate?: string | null,
    assessmentToDate?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    totalAudioDuration?: number | null,
    status?: OrganizationStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    microsoftAppId?: string | null,
    microsoftTenant?: string | null,
    microsoftClientSecret?: string | null,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    domain?: string | null,
    webhookURL?: string | null,
    mailingList?: string | null,
    adminGroupName: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    groupName: string,
    numberOfUsers?: number | null,
    agreementUpdateDate?: string | null,
    assessmentFromDate?: string | null,
    assessmentToDate?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    totalAudioDuration?: number | null,
    status?: OrganizationStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    microsoftAppId?: string | null,
    microsoftTenant?: string | null,
    microsoftClientSecret?: string | null,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    domain?: string | null,
    webhookURL?: string | null,
    mailingList?: string | null,
    adminGroupName: string,
    originalPhotoUrl?: string | null,
    thumbnailPhotoUrl?: string | null,
    coverImageUrl?: string | null,
    groupName: string,
    numberOfUsers?: number | null,
    agreementUpdateDate?: string | null,
    assessmentFromDate?: string | null,
    assessmentToDate?: string | null,
    createdAt: string,
    updatedAt: string,
    organizationGroup?: string | null,
    adminGroup: string,
    delete?: boolean | null,
    totalAudioDuration?: number | null,
    status?: OrganizationStatus | null,
    startDate?: string | null,
    endDate?: string | null,
    microsoftAppId?: string | null,
    microsoftTenant?: string | null,
    microsoftClientSecret?: string | null,
  } | null,
};

export type OnCreateIndividualReviewSheetSubscriptionVariables = {
};

export type OnCreateIndividualReviewSheetSubscription = {
  onCreateIndividualReviewSheet?:  {
    __typename: "IndividualReviewSheet",
    individualReviewSheetID: string,
    name: string,
    reviewerID: string,
    revieweeID: string,
    reviewOrder: ReviewOrder,
    summary?: string | null,
    responseStage: ResponseStage,
    reviewID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewAnswerList?:  Array< {
      __typename: "ReviewAnswer",
      reviewItemTitle: string,
      description?: string | null,
      customReviewID?: string | null,
      customReviewName?: string | null,
      goalID?: string | null,
      goalProgress?: Progress | null,
      finalValue?: number | null,
      targetValue?: number | null,
      goalMeasuringType?: MeasuringType | null,
      goalResultDate?: string | null,
      competencyItemID?: string | null,
      weight?: number | null,
      answer: string,
      point?: number | null,
      answerBasis?: string | null,
    } | null > | null,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
  } | null,
};

export type OnUpdateIndividualReviewSheetSubscriptionVariables = {
};

export type OnUpdateIndividualReviewSheetSubscription = {
  onUpdateIndividualReviewSheet?:  {
    __typename: "IndividualReviewSheet",
    individualReviewSheetID: string,
    name: string,
    reviewerID: string,
    revieweeID: string,
    reviewOrder: ReviewOrder,
    summary?: string | null,
    responseStage: ResponseStage,
    reviewID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewAnswerList?:  Array< {
      __typename: "ReviewAnswer",
      reviewItemTitle: string,
      description?: string | null,
      customReviewID?: string | null,
      customReviewName?: string | null,
      goalID?: string | null,
      goalProgress?: Progress | null,
      finalValue?: number | null,
      targetValue?: number | null,
      goalMeasuringType?: MeasuringType | null,
      goalResultDate?: string | null,
      competencyItemID?: string | null,
      weight?: number | null,
      answer: string,
      point?: number | null,
      answerBasis?: string | null,
    } | null > | null,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
  } | null,
};

export type OnDeleteIndividualReviewSheetSubscriptionVariables = {
};

export type OnDeleteIndividualReviewSheetSubscription = {
  onDeleteIndividualReviewSheet?:  {
    __typename: "IndividualReviewSheet",
    individualReviewSheetID: string,
    name: string,
    reviewerID: string,
    revieweeID: string,
    reviewOrder: ReviewOrder,
    summary?: string | null,
    responseStage: ResponseStage,
    reviewID: string,
    organizationGroup: string,
    createdAt: string,
    updatedAt: string,
    reviewAnswerList?:  Array< {
      __typename: "ReviewAnswer",
      reviewItemTitle: string,
      description?: string | null,
      customReviewID?: string | null,
      customReviewName?: string | null,
      goalID?: string | null,
      goalProgress?: Progress | null,
      finalValue?: number | null,
      targetValue?: number | null,
      goalMeasuringType?: MeasuringType | null,
      goalResultDate?: string | null,
      competencyItemID?: string | null,
      weight?: number | null,
      answer: string,
      point?: number | null,
      answerBasis?: string | null,
    } | null > | null,
    calculateResult?:  {
      __typename: "CalculateResult",
      overallCalculateResult?: number | null,
      averageCalculateResult?: number | null,
      customReviewCalculateResults?:  Array< {
        __typename: "CustomReviewCalculateResult",
        customReviewID?: string | null,
        result?: number | null,
        average?: number | null,
      } > | null,
      goalReviewCalculateResult?: number | null,
      goalReviewCalculateAverage?: number | null,
      competencyReviewCalculateResult?: number | null,
      competencyReviewCalculateAverage?: number | null,
    } | null,
  } | null,
};

export type OnCreateCustomReviewSubscriptionVariables = {
};

export type OnCreateCustomReviewSubscription = {
  onCreateCustomReview?:  {
    __typename: "CustomReview",
    customReviewID: string,
    name: string,
    isWeight: boolean,
    customReviewItemList:  Array< {
      __typename: "CustomReviewItem",
      name: string,
      weight?: number | null,
    } >,
    question: string,
    description?: string | null,
    answerType: AnswerType,
    organizationGroup: string,
    options?:  Array< {
      __typename: "Option",
      item?: string | null,
      pointAllocation?: number | null,
    } > | null,
    numberRange?:  {
      __typename: "NumberRange",
      lowerLimit?: number | null,
      upperLimit?: number | null,
    } | null,
    isAnswerBasis: boolean,
    isAnswerBasisEntryOptional: boolean,
    calculable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomReviewSubscriptionVariables = {
};

export type OnUpdateCustomReviewSubscription = {
  onUpdateCustomReview?:  {
    __typename: "CustomReview",
    customReviewID: string,
    name: string,
    isWeight: boolean,
    customReviewItemList:  Array< {
      __typename: "CustomReviewItem",
      name: string,
      weight?: number | null,
    } >,
    question: string,
    description?: string | null,
    answerType: AnswerType,
    organizationGroup: string,
    options?:  Array< {
      __typename: "Option",
      item?: string | null,
      pointAllocation?: number | null,
    } > | null,
    numberRange?:  {
      __typename: "NumberRange",
      lowerLimit?: number | null,
      upperLimit?: number | null,
    } | null,
    isAnswerBasis: boolean,
    isAnswerBasisEntryOptional: boolean,
    calculable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomReviewSubscriptionVariables = {
};

export type OnDeleteCustomReviewSubscription = {
  onDeleteCustomReview?:  {
    __typename: "CustomReview",
    customReviewID: string,
    name: string,
    isWeight: boolean,
    customReviewItemList:  Array< {
      __typename: "CustomReviewItem",
      name: string,
      weight?: number | null,
    } >,
    question: string,
    description?: string | null,
    answerType: AnswerType,
    organizationGroup: string,
    options?:  Array< {
      __typename: "Option",
      item?: string | null,
      pointAllocation?: number | null,
    } > | null,
    numberRange?:  {
      __typename: "NumberRange",
      lowerLimit?: number | null,
      upperLimit?: number | null,
    } | null,
    isAnswerBasis: boolean,
    isAnswerBasisEntryOptional: boolean,
    calculable: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type MyQueryQueryVariables = {
};

export type MyQueryQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      account: string,
      admin?: boolean | null,
    } | null >,
  } | null,
};
