import { Stack, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { createContext, useContext, ReactNode, useCallback } from 'react';
import { RiCloseFill, RiInformationFill } from 'react-icons/ri';

interface ErrorContextType {
  setErrorSnackbar: (title?: string, detail?: string) => void;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { palette } = useTheme();

  const setErrorSnackbar = useCallback(
    (
      title = '通信エラーが発生しました',
      detail = '繰り返し失敗する場合は画面右下の問い合わせボタンから問い合わせてください'
    ) => {
      enqueueSnackbar(title, {
        autoHideDuration: 7000,
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        content: (key, message) => (
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'top',
              backgroundColor: palette.surface.primary,
              borderLeft: `4px solid ${palette.error.main}`,
              padding: '16px',
              borderRadius: '4px',
              boxShadow: '3px 3px 8px 0px rgba(43, 46, 67, 0.50)',
              color: '#000',
              px: '16px',
              py: '10px',
              width: '438px',
              gap: '12px',
              marginLeft: '20px',
              marginBottom: '30px',
            }}
          >
            <RiInformationFill color={palette.error.main} size={20} style={{ marginTop: '4px' }} />
            <Stack gap="8px" flex={1}>
              <Typography variant="titleM">{message}</Typography>
              <Typography variant="textM">{detail}</Typography>
            </Stack>
            <RiCloseFill
              onClick={() => closeSnackbar(key)}
              color={palette.common.black}
              size={20}
              style={{ marginTop: '4px', cursor: 'pointer' }}
            />
          </Stack>
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar, palette]
  );

  return <ErrorContext.Provider value={{ setErrorSnackbar }}>{children}</ErrorContext.Provider>;
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};
